import React, {useEffect, useState} from "react";
import localforage from "localforage";
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";

const TaskBoard: React.FC = () => {

    const [pageTitle, setPageTitle] = useState("My Tasks")
    const [clinicName, setClinicName] = useState<any>("Facility")

    useEffect(()=>{
        document.title = "Uzima - Tasks"
        localforage.getItem("clinic_name").then((clini)=>{
            setClinicName(clini)
        })
    }, [])

    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0  ">{clinicName} - {pageTitle} &nbsp;
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href="javascript: void(0);">Dashboards</a></li>
                                            <li className="breadcrumb-item active">Taskboard</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <canvas></canvas>
        </>
    )
}

export default TaskBoard