import React from "react";
import { Link } from "react-router-dom";

const SideMenu: React.FC = () => {
    return (
        <>
        <div className="app-menu navbar-menu">

            <div className="navbar-brand-box">

                <Link to={"/institution/home"} className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="48" />
                    </span>
                    <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`} alt="" height="17" />
                    </span>
                </Link>

                <Link to={"/institution/home"} className="logo logo-light">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`} alt="" height="44" />
                    </span>
                </Link>
                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                    <i className="ri-record-circle-line"> </i>
                </button>
            </div>

            <div id="scrollbar">
                <div className="container-fluid">

                    <div id="two-column-menu">
                    </div>
                    <ul className="navbar-nav" id="navbar-nav">
                        <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                        <li className="nav-item">
                            <Link className="nav-link menu-link" to={"/institution/home"} >
                                <i className="bx bxs-dashboard"> </i> <span data-key="t-dashboards">Uzima Dashboard</span>
                            </Link>
                        </li>

                        <li className="menu-title">
                            <i className="ri-more-fill">

                            </i>
                            <span data-key="t-components"> Uzima Community </span>
                        </li>

                        <li className={"nav-item"}>
                            <a className="nav-link menu-link" href={"#sidebarBrowse"} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarBrowse">
                                <i className="bx bx-server"> </i> <span data-key="t-browse">Uzima Service Center</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarBrowse">
                                <ul className="nav nav-sm flex-column">
                                    <li className="nav-item">
                                        <Link to={"/institution/service-lander"} className="nav-link" data-key="t-calendar"> Launch Service Center </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/services"} className="nav-link" data-key="t-calendar"> Create Public Request </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/fnd-service"} className="nav-link" data-key="t-chat">Browse Public Requests</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/applicants"} className="nav-link" data-key="t-chat">Received Applications</Link>
                                    </li>


                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a href={"#sidebarCommunication"} className="nav-link menu-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarCommunication" data-key="t-comms">
                                <i className="bx bx-phone "> </i> <span data-key="t-comms">Communication Center</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarCommunication">
                                <ul className="nav nav-sm flex-column">
                                    {/*
                                        - Set up appointments
                                        - set meetings: one to many[tak for an intervention], few to many[townhal discussion], roundtable,,,
                                        - arrange chats
                                        - notifications
                                        - message and be messaged
                                        - share documents [giving access]

                                        --- context of selected partner or patient
                                    */}
                                    <li className="nav-item">
                                        <Link to="/institution/communication" className="nav-link"
                                              data-key="t-products"> Communication Center <span className="topbar-badge badge rounded-pill bg-success">0 </span> </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/notifications" className="nav-link"
                                              data-key="t-products"> Notifications <span className="topbar-badge badge rounded-pill bg-success">0 </span> </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/messaging" className="nav-link"
                                              data-key="t-products"> Messages <span className="topbar-badge badge rounded-pill bg-success">0 </span>  </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/video" className="nav-link"
                                              data-key="t-product-Details"> E-Conferencing</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/document_exchange" className="nav-link"
                                              data-key="t-orders"> Document Exchange </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a href={"#sidebarCollaborationTools"} className="nav-link menu-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarCollaborationTools" data-key="t-collab">
                                <i className="bx bx-chip "> </i> <span data-key="t-collab">Collaboration Center</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarCollaborationTools">
                                <ul className="nav nav-sm flex-column">

                                    <li className="nav-item">
                                        <Link to="/institution/collaborations" className="nav-link"
                                              data-key="t-products"> Launch App
                                        </Link>

                                    </li>

                                    <li className="nav-item">
                                        <Link to="" className="nav-link"
                                              data-key="t-products"> Find Requests <span className="topbar-badge badge rounded-pill bg-success">0 </span>
                                        </Link>

                                    </li>

                                    <li className="nav-item">

                                        <Link to="/institution/services/partnership-requests" className="nav-link"
                                              data-key="t-products"> Partnership Requests <span className="topbar-badge badge rounded-pill bg-success">0 </span>
                                        </Link>
                                        {/*
                                            - give service type (perma engagement, term engangement[specify duration: locums etc])
                                            - give service description (200 char)
                                            - partnership framework [select someone based on quals and make agreements(professionals)] base on payment per persion or request, specify response time... respond on time, give price guide or checkbox to specify, expected volume of work, other field
                                            -
                                           */}
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/institution/services/received-requests" className="nav-link"
                                              data-key="t-product-Details"> Received Requests
                                        </Link>

                                        {/*
                                            - accept request terms or give counter offer
                                            - text field: clarify
                                            - price counter offer & negotiates

                                        */}
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/accounting" className="nav-link"
                                              data-key="t-orders"> Accounting </Link>



                                        {/*
                                        - pick up the summary of transactions which are open or pending
                                        - amounts invoiced/open
                                        - summary per partner
                                        - amounts invoiced to you by your partners for the transaction
                                        - amounts invoiced to patients against a particular service or send someone to pay direct

                                        */}

                                        {/*
                                            - patient journey map logging... should be one record that can be followed until when the patient has been served
                                            - summary of a partner (transactions for partners in a period)
                                        */}
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/institution/patients" className="nav-link"
                                              data-key="t-orders"> Patient Center </Link>



                                        {/*
                                            - view records associated with the patients at the various stages... go to reports or patient center
                                            - patient status and which partner is attending to the patient
                                            - medication history for the patient

                                        */}
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/institution/resources" className="nav-link"
                                              data-key="t-orders"> Resources & Reports </Link>



                                        {/*
                                            - view records associated with the patients at the various stages... go to reports or patient center
                                            - patient status and which partner is attending to the patient
                                            - medication history for the patient

                                        */}
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/institution/resources" className="nav-link"
                                              data-key="t-orders"> Resources </Link>
                                        {/*
                                            - find active forums of uzima
                                            - downloads
                                            - webinars,
                                            - tcs
                                            - IoT

                                        */}
                                    </li>


                                </ul>
                            </div>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
                                <i className="bx bx-layer"> </i> <span data-key="t-apps">Uzima - Apps</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarApps">
                                <ul className="nav nav-sm flex-column">
                                    <li className="nav-item">
                                        <Link to={"/calendar"} className="nav-link" data-key="t-calendar"> Calendar & Scheduling </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/billing-home" className="nav-link" data-key="t-chat"> Billing & Payments </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/institution/resources" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarTasks"
                                           data-key="t-tasks"> Report Center
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/patient-list"} className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarInvoices" data-key="t-invoices">
                                            Patient Tracker
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/tickets"} className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarTickets"
                                           data-key="t-supprt-tickets"> Support & Tickets
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/file-manager" className="nav-link"> <span
                                            data-key="t-file-manager">File Manager</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>






                        <li className="menu-title"> <i className="ri-more-fill"></i> <span data-key="t-components">Uzima -  System</span>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse"
                               role="button" aria-expanded="false" aria-controls="sidebarUI">
                                <i className="bx bx-palette"> </i> <span data-key="t-base-ui">Patient Center</span>
                            </a>
                            <div className="collapse menu-dropdown mega-dropdown-menu" id="sidebarUI">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <Link to={"/institution/patients"} className="nav-link" data-key="t-alerts">Launch</Link>
                                            </li>

                                            <li className={"nav-item"}>
                                                <a className={"nav-link menu-link"} href={"#sidebarPartners"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={"false"} aria-controls={"sidebarPartners"}>

                                                    <span data-key={"t-advance-ui"}> Patient Management</span>
                                                </a>
                                                <div className={"collapse menu-dropdown"} id={"sidebarPartners"}>
                                                    <ul className={"nav nav-sm flex-column"}>
                                                        <li className={"nav-item"}>
                                                            <a href={"#pros"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={"false"} aria-controls={"pros"} className={"nav-link menu-link"}>Professionals</a>
                                                            <div className={"collapse menu-dropown"} id={"pros"}>
                                                                <ul className={"nav nav-sm flex-column"}>
                                                                    <li className={"nav-item"}>
                                                                        <Link to={"/institutions/partners/1/1"} className={"nav-link"}>Doctors</Link>
                                                                    </li>
                                                                    <li className={"nav-item"}>
                                                                        <Link to={"/institutions/partners/1/2"} className={"nav-link"}>Clinicians</Link>
                                                                    </li>
                                                                    <li className={"nav-item"}>
                                                                        <Link to={"/institutions/partners/1/3"} className={"nav-link"}>Technologists</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>

                                                        <li className={"nav-item"}>
                                                            <Link to={"/institutions/partners/3/1"} className={"nav-link"}>Laboratories</Link>
                                                        </li>

                                                        <li className={"nav-item"}>
                                                            <Link to={"/institutions/partners/ambulances/ambulance"} className={"nav-link"}>Ambulances</Link>
                                                        </li>

                                                        <li className={"nav-item"}>
                                                            <Link to={"/institutions/partners/clinics/clinic"} className={"nav-link"}>Clinics</Link>
                                                        </li>

                                                        <li className={"nav-item"}>
                                                            <Link to={"/institutions/partners/hospitals/hosp"} className={"nav-link"}>Hospitals</Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>



                        <li className="nav-item">
                            <a className="nav-link menu-link" href={"#sidebarAdvanceUI"} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAdvanceUI">
                                <i className={"bx bx-briefcase-alt"}> </i> <span data-key="t-advance-ui">My Facility</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarAdvanceUI">
                                <ul className="nav nav-sm flex-column">
                                    <li className="nav-item">
                                        <Link to={"/institution/clinics/launch"} className="nav-link" data-key="t-sweet-alerts">Launch</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/clinics"} className="nav-link" data-key="t-sweet-alerts">My Facilities</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/add-clinic"} className="nav-link" data-key="t-nestable-list">Add Facility</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/clinic-details"} className="nav-link" data-key="t-nestable-list">Facility Summary</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>




                        <li className="menu-title"><i className="ri-more-fill"> </i> <span  data-key="t-pages">My Account</span></li>



                        <li className="nav-item">
                            <a className="nav-link menu-link" href="#sidebarPages" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPages">
                                <i className="bx bx-file"> </i>
                                <span data-key="t-pages">Help & Support</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarPages">
                                <ul className="nav nav-sm flex-column">
                                    <li className="nav-item">
                                        <Link to="/institution/faqs" className="nav-link" data-key="t-starter"> FAQs</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/ticket-list" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarProfile" data-key="t-profile">
                                            Tickets
                                        </Link>
                                        <div className="collapse menu-dropdown" id="sidebarProfile">
                                            <ul className="nav nav-sm flex-column">
                                                <li className="nav-item">
                                                    <Link to="/institution/create-ticket" className="nav-link"
                                                       data-key="t-simple-page"> Create Ticket </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/institution/ticket-list" className="nav-link"
                                                       data-key="t-settings"> My Tickets </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/notices"} className="nav-link" data-key="t-team"> Notices</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/timelines"} className="nav-link" data-key="t-timeline"> Timeline </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link" data-key="t-maintenance"> Maintenance </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/help"} className="nav-link" data-key="t-coming-soon"> Account Help </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a href={"/institution/documentation"} className="nav-link" data-key="t-sitemap"> Documentation </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={"/institution/billing-help"} className="nav-link" data-key="t-search-results"> Billing
                                            help </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/privacy" className="nav-link"><span data-key="t-privacy-policy">Privacy Policy</span>
                                            <span className="badge badge-pill bg-success"
                                                  data-key="t-new">New</span></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/institution/terms" className="nav-link"><span data-key="t-term-conditions">Term & Conditions</span>
                                            <span className="badge badge-pill bg-success"
                                                  data-key="t-new">New</span></Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li className={"nav-item"}>
                            <a className={"nav-link menu-link"} href={"#sidebarBackups"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={"false"} aria-controls={"sidebarBackups"}>
                                <i className="bx bx-bot"></i>
                                <span data-key={"t-backups"}>Easy Backup</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarBackups">
                                <ul className="nav nav-sm flex-column">

                                    <li className="nav-item">
                                        <Link to={"/institution/create-backup"} className="nav-link" data-key="t-backups">Create Backup</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to={"/institution/restore-backup"} className="nav-link" data-key="t-backups">Restore Backup</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to={"/institution/request-backup"} className="nav-link" data-key="t-backups">My Backups</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to={"/institution/request-backup"} className="nav-link" data-key="t-backups">Request Access</Link>
                                    </li>

                                </ul>

                            </div>

                        </li>

                    </ul>
                </div>
            </div>

            <div className="sidebar-background"> </div>
        </div>

        </>
    )
}

export default SideMenu;