import React, {useEffect, useMemo, useState} from "react";
import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import set = Reflect.set;
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import axios from "axios";
import localforage from "localforage";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import {useBaseUrl} from "../../../utils/utils";



interface Props {
    data: any[],
    count: number
}


const Datatable: React.FC<Props> = ({data, count}) =>{
    let clinic_id: any = localforage.getItem("clinic_id")
    let staff_id: any = localforage.getItem("staff_id")
    let institution_id: any = localforage.getItem("institution_id")
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [step, setStep] = useState(1)
    const [display, setDisplay] = useState();
    const [isLoading, setIsLoading] =useState<boolean>(false)
    const [record, setRecord] = useState<any>([])


    const base_url = useBaseUrl()

    const handleRecordIdClick = (value: any)=>{
        setDisplay(value)
        load_record_by_id(value)
        setStep(2);

    }
    useEffect(()=>{

    }, [record])
    const columns = useMemo(()=>[
        {Header: 'Record ID', accessor: 'id', Cell: ({ value }: { value: number | string }) => (
                <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => handleRecordIdClick( value)}
                >
        {value}
      </span>
            ),},
        {Header: 'Patient', accessor: 'patient'},
        {Header: 'Record Name', accessor: 'name'},
        {Header: 'Examiner', accessor: 'examiner.examiner'},
        {
            Header: 'Date Created',
            accessor: 'created',
            Cell: ({ value }: { value: string }) => {
                const formattedDate = new Date(value).toLocaleString('en-US', {
                    dateStyle: 'long',
                    timeStyle: 'short'
                });

                return <span>{formattedDate}</span>;
            }
        }
    ], []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,} = useTable({
        columns,
        data: data,
    },
        useGlobalFilter,
        useSortBy,
        usePagination

    );

    const pageCount = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = ({ selected }: { selected: number }) => {
        setCurrentPage(selected);
    };

    const setty = (recrt: any) =>{

        setRecord(recrt);

        return true;

    }

    const load_record_by_id = async (records: any = 0) => {
        clinic_id = 1
        institution_id = 1
        staff_id = 1

        setIsLoading(true)

        let url = base_url + "/ehr/record-by-id"

        try{

            const formData = new FormData()

            formData.append("clinic_id", clinic_id)
            formData.append("staff_id", staff_id)
            formData.append("record_id", records)

            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if( status === "success"){

                if(data === "undefined" || data === undefined){

                    setRecord([])

                }

               setRecord(response.data.data)

            }else{
                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })


            }

        }catch (e: any) {

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Unable to initiate server connection"
            })



        }
    }


    return(
        <>
            {
                step==1 && (
                    <>
                        {count == 0 && (
                            <>
                                <div className={"alert alert-warning"}>There are no records to be displayed.</div>
                            </>
                        )}
                        {count >= 1 && (
                            <>
                               <div className={"card card-animate"}>
                                   <div className={"card-header"} style={{backgroundColor: "#553e93", color: "white"}}>
                                        Record List - Read Only
                                   </div>
                                   <div className={"card-body"}>
                                       <table {...getTableProps()} className={"table table-stripped table-hover"}>

                                           <thead>
                                           {
                                               headerGroups.map((headerGroup) => (
                                                   <tr {...headerGroup.getHeaderGroupProps()}>
                                                       {headerGroup.headers.map((column) => (
                                                           <>
                                                               <th {...column.getHeaderProps()}>
                                                                   {column.render('Header')}
                                                               </th>
                                                           </>
                                                       ))}
                                                   </tr>
                                               ))
                                           }
                                           </thead>
                                           <tbody {...getTableBodyProps()}>
                                           {
                                               rows.map((row) => {
                                                   prepareRow(row);
                                                   return (
                                                       <tr {...row.getRowProps()}>
                                                           {row.cells.map((cell) => (
                                                               <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                           ))}
                                                       </tr>
                                                   )
                                               })
                                           }
                                           </tbody>

                                       </table>
                                   </div>
                               </div>
                                <div className="pagination-container">
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={10}
                                        marginPagesDisplayed={4}
                                        onPageChange={handlePageChange}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                    />
                                </div>
                            </>
                        )}
                    </>
                )
            }
            {
                step==2 && (
                    <>
                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <div className={"card card-animate"}>
                                    <div className={"card-header"}>
                                        <h5>Medical Record</h5>
                                    </div>
                                    <div className={"card-body"}>

                                        <>
                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"form-label"}>Patient</h6>
                                                    <input disabled={true} className={"form-control mt-2"} value={record.patient}/>

                                                </div>

                                                <div className={"col"}>
                                                    <h6 className={"form-label"}>Examiner</h6>
                                                    <input disabled={true} className={"form-control mt-2"} value={record.examiner}/>

                                                </div>
                                            </div>

                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"mt-2 form-label"}>Chief Complaint:</h6>
                                                    <p>{record.complaint}</p>
                                                </div>
                                            </div>

                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"mt-2 form-label"}>Medical History:</h6>
                                                    <p>{record.medical_history}</p>
                                                </div>
                                            </div>

                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"mt-2 form-label"}>Family History:</h6>
                                                    <p>{record.family_history}</p>
                                                </div>
                                            </div>

                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"mt-2 form-label"}>Social History:</h6>
                                                    <p>{record.social_history}</p>
                                                </div>
                                            </div>

                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"mt-2 form-label"}>Physical Examination:</h6>
                                                    <p>{record.physical}</p>
                                                </div>
                                            </div>

                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"mt-2 form-label"}>Patient Assessment:</h6>
                                                    <p>{record.assessment}</p>
                                                </div>
                                            </div>

                                            <div className={"row mt-3"}>
                                                <div className={"col"}>
                                                    <h6 className={"mt-2 form-label"}>Treatment Plan:</h6>
                                                    <p>{record.plan}</p>
                                                </div>
                                            </div>
                                        </>


                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <button className={"btn btn-success"} onClick={()=>{setStep(1)}}>Back To List</button>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default Datatable;