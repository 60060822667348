import React, {useEffect, useReducer} from 'react'
import DashboardFooter from '../../layouts/dashboard_footer';
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import {useUserNames} from "../../../utils/utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {Link} from "react-router-dom";

const PartnershipRequests: React.FC = () => {

    let user_info: any = {first_name: "", last_name: "", clinic: "", staff_id: "", avatar: ""}

    useEffect(()=>{

        const GetUsers = async () => {

            const user_info = useUserNames()

        }

        user_info = GetUsers()

    }, [])

    const initialState = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        header: "Uzima Community Service Center - Partnership Requests",
        subHeader: "Create Partnerships and Find Collaborators specific to your needs.",
        enType: "",
        duration: "",
        requestInfo: "",
        months: "",
        days: "",
        hours: "",
        furtherDesc: ""
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case 'SET_STEP':
                return {...state, step: action.payload}

            case 'SET_PAGE_HEADING':
                return {...state, header: action.payload}

            case 'SET_SUB_HEADER':
                return {...state, subHeader: action.payload}

            case 'SET_SUB_STEP':
                return {...state, subStep: action.payload}

            case 'SET_CARD_STEP':
                return {...state, cardStep: action.payload}

            case 'SET_ENTYPE':
                return {...state, enType: action.payload}

            case 'SET_DURATION':
                return {...state, duration: action.payload}

            case 'SET_START':
                return {...state, startDate: action.payload}

            case 'SET_END':
                return {...state, endDate: action.payload}

            case 'SET_REQUEST_INFO':
                return {...state, requestInfo: action.payload}

            case 'SET_HOURS':
                return {...state, hours: action.payload}

            case 'SET_DAYS':
                return {...state, days: action.payload}

            case 'SET_MONTHS':
                return {...state, months: action.payload}

            case 'SET_FURTHER_DESC':
                return {...state, months: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <>
            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />
            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        {state.step === 0 && (
                            <>
                                <div className={"row mt-4 text-center"}>
                                    <div className={"col-12"}>
                                        <h3>{state.header}</h3>
                                    </div>
                                </div>

                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <h6>Create Partnerships and Find Collaborators specific to your needs.</h6>
                                    </div>
                                </div>


                                <div className={"row mt-4"}>

                                    <div className={"col"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_SUB_HEADER", payload: "Create a general request"})
                                            dispatch({type: "SET_STEP", payload: 1})
                                        }} >

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/ls.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Create General Request</h5>

                                                <br />

                                            </div>

                                        </div>


                                    </div>

                                    <div className={"col"}>

                                        <div className={"card card-animate"}>

                                            <Link to={"/institution/collaborations"} className="card-body p-4 text-center" >
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/service.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Create Specific Request</h5>

                                                <br />

                                            </Link>

                                        </div>

                                    </div>

                                </div>
                            </>
                        )}

                        {state.step === 1 && (
                            <>
                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <h5>{state.subHeader}</h5>
                                    </div>
                                </div>

                                <div  className={"row mt-4"}>
                                    {state.subStep === 0 && (
                                        <>

                                            <div className={"row mt-4"}>

                                                <div className={"card card-animate"}>

                                                    <div className={"card-header"}>

                                                        {state.cardStep === 0 && (
                                                            <>
                                                                Howdy {user_info.first_name}
                                                            </>
                                                        )}

                                                        {state.cardStep === 1 && (
                                                            <>
                                                                {user_info.first_name}, what is your engagement type?
                                                            </>
                                                        )}

                                                        {state.cardStep === 2 && (
                                                            <>
                                                                {user_info.first_name}, let's get your service description.
                                                            </>
                                                        )}

                                                        {state.cardStep === 3 && (
                                                            <>
                                                                {user_info.first_name}, response time.
                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>
                                                                {user_info.first_name}, let's get your partnership framework.
                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>
                                                                {user_info.first_name}, your price range.
                                                            </>
                                                        )}

                                                        {state.cardStep === 5 && (
                                                            <>
                                                                {user_info.first_name}, your volume of work.
                                                            </>
                                                        )}

                                                        {state.cardStep === 6 && (
                                                            <>
                                                                {user_info.first_name}, Verify and Submit.
                                                            </>
                                                        )}

                                                    </div>

                                                    <div className={"card-body"}>

                                                        {state.cardStep === 0 && (
                                                            <>
                                                                <em>

                                                                    <p>Welcome to Uzima Collaboration System.</p>
                                                                    <p>I'm going to guide you through the process of creating a request to find a partner suitable for you!</p>
                                                                    <p>When ready click on the "Proceed Button"!</p>

                                                                </em>
                                                            </>
                                                        )}


                                                        {state.cardStep === 1 && (
                                                            <>
                                                                <div className={"row mt-2"}>

                                                                    <div className={"col"}>

                                                                        <label>Service Engagement Type:</label>

                                                                        <select className={"form-control"} onChange={(e)=>{
                                                                            dispatch({type: "SET_ENTYPE", payload: e.currentTarget.value})
                                                                        }}>
                                                                            <option value={"perma"}>Permanent Engagement</option>
                                                                            <option value={"term"}>Term Engagement</option>

                                                                        </select>

                                                                    </div>

                                                                    {state.enType === "term" && (
                                                                        <div className={"col"}>
                                                                            <label>Select Duration</label>
                                                                            <select className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_DURATION", payload: e.currentTarget.value})
                                                                            }}>
                                                                                <option>Long Term</option>
                                                                                <option>Intermediate</option>
                                                                                <option>Short Term</option>

                                                                            </select>
                                                                        </div>
                                                                    )}



                                                                </div>

                                                                {state.enType==="term" && (
                                                                    <div className={"row mt-4"}>

                                                                        <div className={"col"}>

                                                                            <label>Start Date</label>
                                                                            <input type={"datetime-local"} className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_START", payload: e.currentTarget.value})
                                                                            }}/>

                                                                        </div>

                                                                        <div className={"col"}>

                                                                            <label>End Date</label>
                                                                            <input type={"datetime-local"} className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_END", payload: e.currentTarget.value})
                                                                            }}/>

                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                                                        {state.cardStep === 2 && (
                                                            <>
                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <label>
                                                                            Give a clear description of what you need <em style={{"fontWeight": "bold"}}>1000 characters</em>
                                                                        </label>

                                                                        <br />

                                                                        <CKEditor editor={ClassicEditor} data={state.requestInfo} onChange={(d: any, e: any)=> {
                                                                            const data = e.getData();
                                                                            dispatch({type: "SET_REQUEST_INFO", payload: data})
                                                                        }} />

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                        {state.cardStep === 3 && (
                                                            <>
                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <label>What is the maximum response time for you?</label>
                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>
                                                                        <label>Months</label>
                                                                        <select className={"form-control"} onChange={(e)=>{
                                                                            dispatch({type: "SET_MONTHS", payload: e.currentTarget.value})
                                                                        }}>

                                                                            <option value={"0"}>0</option>
                                                                            <option value={"1"}>1</option>
                                                                            <option value={"2"}>2</option>
                                                                            <option value={"3"}>3</option>
                                                                            <option value={"4"}>4</option>

                                                                        </select>
                                                                    </div>

                                                                    {state.months !== "" && (
                                                                        <div className={"col"}>

                                                                            <label>Days</label>
                                                                            <select className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_DAYS", payload: e.currentTarget.value})
                                                                            }}>

                                                                                <option value={"0"}>0</option>
                                                                                <option value={"1"}>1</option>
                                                                                <option value={"2"}>2</option>
                                                                                <option value={"3"}>3</option>
                                                                                <option value={"4"}>4</option>
                                                                                <option value={"5"}>5</option>
                                                                                <option value={"6"}>6</option>
                                                                                <option value={"7"}>7</option>

                                                                            </select>

                                                                        </div>
                                                                    )}

                                                                    {state.days !== "" && (
                                                                        <div className={"col"}>
                                                                            <label>Hours</label>

                                                                            <select className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_HOURS", payload: e.currentTarget.value})
                                                                            }}>

                                                                                {state.hours !== "0" && (
                                                                                    <>
                                                                                        <option value={"0"}>0</option>
                                                                                    </>
                                                                                )}
                                                                                <option value={"1"}>1</option>
                                                                                <option value={"2"}>2</option>
                                                                                <option value={"3"}>3</option>
                                                                                <option value={"4"}>4</option>
                                                                                <option value={"5"}>5</option>
                                                                                <option value={"6"}>6</option>
                                                                                <option value={"7"}>7</option>
                                                                                <option value={"24"}>24</option>

                                                                            </select>

                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>
                                                                <div className={"row mt-1"}>

                                                                    <div className={"col"}>

                                                                        <label>How do yu want to pay your partners? :</label>

                                                                        <select className={"form-control"} onChange={(e)=>{
                                                                            dispatch({type: "SET_PAYMENT", payload: e.currentTarget.value})
                                                                        }}>

                                                                            <option value={"0"}>Per Person</option>
                                                                            <option value={"1"}>Request</option>
                                                                            <option value={"2"}>Custom</option>
                                                                            <option value={"3"}>Milestone</option>

                                                                        </select>

                                                                    </div>

                                                                </div>

                                                                <div className={"row mt-4"}>

                                                                    <label>Enter your price range as per your selected mode:</label>

                                                                    <div className={"col"}>

                                                                        <label>Your Minimum:</label>

                                                                        <input className={"form-control"} />

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <label>Your Maximum:</label>

                                                                        <input className={"form-control"} />

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 5 && (
                                                            <>
                                                                <div className={"row mt-1"}>

                                                                    <div className={"col"}>

                                                                        <label>Expected Patient Flow :</label>

                                                                        <input type={"number"} className={"form-control"} />



                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <label>Rate :</label>
                                                                        <select className={"form-control"}>

                                                                            <option>Hourly</option>
                                                                            <option value={"daily"}>Daily</option>
                                                                            <option>Weekly</option>
                                                                            <option>Monthly</option>
                                                                            <option>Annually</option>

                                                                        </select>



                                                                    </div>

                                                                </div>


                                                                <div className={"row mt-4"}>

                                                                    <div className={"col"}>

                                                                        <label>Please further describe your volume of work</label>

                                                                        <CKEditor editor={ClassicEditor} data={state.requestInfo} onChange={(d: any, e: any)=> {
                                                                            const data = e.getData();
                                                                            dispatch({type: "SET_FURTHER_DESC", payload: data})
                                                                        }} />



                                                                    </div>

                                                                </div>


                                                            </>
                                                        )}

                                                        {state.cardStep === 6 && (
                                                            <>
                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <p>
                                                                            <em>That's it!</em>
                                                                        </p>

                                                                        <p>
                                                                            <em>Your request is ready for publishing. If you wish to go back to a specific step just click the step.</em>
                                                                        </p>


                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Step to review:</label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <label>Action:</label>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Request Description: </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 1})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Response Time : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 2})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Price Range : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 3})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Partnership Framework : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 4})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Volume of work : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 5})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                    </div>

                                                    <div className={"card-footer"}>

                                                        {state.cardStep === 0 && (
                                                            <div className={"row"}>

                                                                <div className={"col"}>

                                                                    <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                        dispatch({type: "SET_CARD_STEP", payload: 1})
                                                                    }}>
                                                                        Let's Proceed
                                                                    </button>

                                                                </div>

                                                                <div className={"col"}>

                                                                    <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                        dispatch({type: "SET_STEP", payload: 0})
                                                                    }}>
                                                                        I'll do later
                                                                    </button>

                                                                </div>

                                                            </div>
                                                        )}
                                                        {state.cardStep === 1 && (
                                                            <>
                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 2})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_STEP", payload: 0})
                                                                        }}>
                                                                            Cancel Process
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        )}
                                                        {state.cardStep === 2 && (
                                                            <>
                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 3})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        )}

                                                        {state.cardStep === 3 && (
                                                            <>
                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 4})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>

                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 5})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 5 && (
                                                            <>

                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 6})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 6 && (
                                                            <>

                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 6})
                                                                        }}>
                                                                            Accept and Submit
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_STEP", payload: 0})
                                                                        }}>
                                                                            Cancel Process
                                                                        </button>

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                    </div>

                                                </div>

                                            </div>

                                        </>
                                    )}

                                </div>
                            </>
                        )}

                        {state.step === 2 && (
                            <>
                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <h5>{state.subHeader}</h5>
                                    </div>
                                </div>

                                <div  className={"row mt-4"}>
                                    {state.subStep === 0 && (
                                        <>

                                            <div className={"row mt-4"}>

                                                <div className={"card card-animate"}>

                                                    <div className={"card-header"}>

                                                        {state.cardStep === 0 && (
                                                            <>
                                                                Howdy {user_info.first_name}
                                                            </>
                                                        )}

                                                        {state.cardStep === 1 && (
                                                            <>
                                                                {user_info.first_name}, what is your engagement type?
                                                            </>
                                                        )}

                                                        {state.cardStep === 2 && (
                                                            <>
                                                                {user_info.first_name}, let's get your service description.
                                                            </>
                                                        )}

                                                        {state.cardStep === 3 && (
                                                            <>
                                                                {user_info.first_name}, response time.
                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>
                                                                {user_info.first_name}, let's get your partnership framework.
                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>
                                                                {user_info.first_name}, your price range.
                                                            </>
                                                        )}

                                                        {state.cardStep === 5 && (
                                                            <>
                                                                {user_info.first_name}, your volume of work.
                                                            </>
                                                        )}

                                                        {state.cardStep === 6 && (
                                                            <>
                                                                {user_info.first_name}, Verify and Submit.
                                                            </>
                                                        )}

                                                    </div>

                                                    <div className={"card-body"}>

                                                        {state.cardStep === 0 && (
                                                            <>
                                                                <em>

                                                                    <p>Welcome to Uzima Collaboration System.</p>
                                                                    <p>I'm going to guide you through the process of creating a request to find a partner suitable for you!</p>
                                                                    <p>When ready click on the "Proceed Button"!</p>

                                                                </em>
                                                            </>
                                                        )}


                                                        {state.cardStep === 1 && (
                                                            <>
                                                                <div className={"row mt-2"}>

                                                                    <div className={"col"}>

                                                                        <label>Service Engagement Type:</label>

                                                                        <select className={"form-control"} onChange={(e)=>{
                                                                            dispatch({type: "SET_ENTYPE", payload: e.currentTarget.value})
                                                                        }}>
                                                                            <option value={"perma"}>Permanent Engagement</option>
                                                                            <option value={"term"}>Term Engagement</option>

                                                                        </select>

                                                                    </div>

                                                                    {state.enType === "term" && (
                                                                        <div className={"col"}>
                                                                            <label>Select Duration</label>
                                                                            <select className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_DURATION", payload: e.currentTarget.value})
                                                                            }}>
                                                                                <option>Long Term</option>
                                                                                <option>Intermediate</option>
                                                                                <option>Short Term</option>

                                                                            </select>
                                                                        </div>
                                                                    )}



                                                                </div>

                                                                {state.enType==="term" && (
                                                                    <div className={"row mt-4"}>

                                                                        <div className={"col"}>

                                                                            <label>Start Date</label>
                                                                            <input type={"datetime-local"} className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_START", payload: e.currentTarget.value})
                                                                            }}/>

                                                                        </div>

                                                                        <div className={"col"}>

                                                                            <label>End Date</label>
                                                                            <input type={"datetime-local"} className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_END", payload: e.currentTarget.value})
                                                                            }}/>

                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                                                        {state.cardStep === 2 && (
                                                            <>
                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <label>
                                                                            Give a clear description of what you need <em style={{"fontWeight": "bold"}}>1000 characters</em>
                                                                        </label>

                                                                        <br />

                                                                        <CKEditor editor={ClassicEditor} data={state.requestInfo} onChange={(d: any, e: any)=> {
                                                                            const data = e.getData();
                                                                            dispatch({type: "SET_REQUEST_INFO", payload: data})
                                                                        }} />

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                        {state.cardStep === 3 && (
                                                            <>
                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <label>What is the maximum response time for you?</label>
                                                                    </div>
                                                                </div>

                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>
                                                                        <label>Months</label>
                                                                        <select className={"form-control"} onChange={(e)=>{
                                                                            dispatch({type: "SET_MONTHS", payload: e.currentTarget.value})
                                                                        }}>

                                                                            <option value={"0"}>0</option>
                                                                            <option value={"1"}>1</option>
                                                                            <option value={"2"}>2</option>
                                                                            <option value={"3"}>3</option>
                                                                            <option value={"4"}>4</option>

                                                                        </select>
                                                                    </div>

                                                                    {state.months !== "" && (
                                                                        <div className={"col"}>

                                                                            <label>Days</label>
                                                                            <select className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_DAYS", payload: e.currentTarget.value})
                                                                            }}>

                                                                                <option value={"0"}>0</option>
                                                                                <option value={"1"}>1</option>
                                                                                <option value={"2"}>2</option>
                                                                                <option value={"3"}>3</option>
                                                                                <option value={"4"}>4</option>
                                                                                <option value={"5"}>5</option>
                                                                                <option value={"6"}>6</option>
                                                                                <option value={"7"}>7</option>

                                                                            </select>

                                                                        </div>
                                                                    )}

                                                                    {state.days !== "" && (
                                                                        <div className={"col"}>
                                                                            <label>Hours</label>

                                                                            <select className={"form-control"} onChange={(e)=>{
                                                                                dispatch({type: "SET_HOURS", payload: e.currentTarget.value})
                                                                            }}>

                                                                                {state.hours !== "0" && (
                                                                                    <>
                                                                                        <option value={"0"}>0</option>
                                                                                    </>
                                                                                )}
                                                                                <option value={"1"}>1</option>
                                                                                <option value={"2"}>2</option>
                                                                                <option value={"3"}>3</option>
                                                                                <option value={"4"}>4</option>
                                                                                <option value={"5"}>5</option>
                                                                                <option value={"6"}>6</option>
                                                                                <option value={"7"}>7</option>
                                                                                <option value={"24"}>24</option>

                                                                            </select>

                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>
                                                                <div className={"row mt-1"}>

                                                                    <div className={"col"}>

                                                                        <label>How do yu want to pay your partners? :</label>

                                                                        <select className={"form-control"} onChange={(e)=>{
                                                                            dispatch({type: "SET_PAYMENT", payload: e.currentTarget.value})
                                                                        }}>

                                                                            <option value={"0"}>Per Person</option>
                                                                            <option value={"1"}>Request</option>
                                                                            <option value={"2"}>Custom</option>
                                                                            <option value={"3"}>Milestone</option>

                                                                        </select>

                                                                    </div>

                                                                </div>

                                                                <div className={"row mt-4"}>

                                                                    <label>Enter your price range as per your selected mode:</label>

                                                                    <div className={"col"}>

                                                                        <label>Your Minimum:</label>

                                                                        <input className={"form-control"} />

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <label>Your Maximum:</label>

                                                                        <input className={"form-control"} />

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 5 && (
                                                            <>
                                                                <div className={"row mt-1"}>

                                                                    <div className={"col"}>

                                                                        <label>Expected Patient Flow :</label>

                                                                        <input type={"number"} className={"form-control"} />



                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <label>Rate :</label>
                                                                        <select className={"form-control"}>

                                                                            <option>Hourly</option>
                                                                            <option value={"daily"}>Daily</option>
                                                                            <option>Weekly</option>
                                                                            <option>Monthly</option>
                                                                            <option>Annually</option>

                                                                        </select>



                                                                    </div>

                                                                </div>


                                                                <div className={"row mt-4"}>

                                                                    <div className={"col"}>

                                                                        <label>Please further describe your volume of work</label>

                                                                        <CKEditor editor={ClassicEditor} data={state.requestInfo} onChange={(d: any, e: any)=> {
                                                                            const data = e.getData();
                                                                            dispatch({type: "SET_FURTHER_DESC", payload: data})
                                                                        }} />



                                                                    </div>

                                                                </div>


                                                            </>
                                                        )}

                                                        {state.cardStep === 6 && (
                                                            <>
                                                                <div className={"row mt-1"}>
                                                                    <div className={"col"}>

                                                                        <p>
                                                                            <em>That's it!</em>
                                                                        </p>

                                                                        <p>
                                                                            <em>Your request is ready for publishing. If you wish to go back to a specific step just click the step.</em>
                                                                        </p>


                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Step to review:</label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <label>Action:</label>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Request Description: </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 1})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Response Time : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 2})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Price Range : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 3})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Partnership Framework : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 4})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"row mt-4"}>
                                                                            <div className={"col"}>
                                                                                <label>Volume of work : </label>
                                                                            </div>

                                                                            <div className={"col"}>
                                                                                <button onClick={(e)=>{

                                                                                    dispatch({type: "SET_CARD_STEP", payload: 5})

                                                                                }} className={"btn btn-warning"}>Go To Step</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                    </div>

                                                    <div className={"card-footer"}>

                                                        {state.cardStep === 0 && (
                                                            <div className={"row"}>

                                                                <div className={"col"}>

                                                                    <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                        dispatch({type: "SET_CARD_STEP", payload: 1})
                                                                    }}>
                                                                        Let's Proceed
                                                                    </button>

                                                                </div>

                                                                <div className={"col"}>

                                                                    <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                        dispatch({type: "SET_STEP", payload: 0})
                                                                    }}>
                                                                        I'll do later
                                                                    </button>

                                                                </div>

                                                            </div>
                                                        )}
                                                        {state.cardStep === 1 && (
                                                            <>
                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 2})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_STEP", payload: 0})
                                                                        }}>
                                                                            Cancel Process
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        )}
                                                        {state.cardStep === 2 && (
                                                            <>
                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 3})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        )}

                                                        {state.cardStep === 3 && (
                                                            <>
                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 4})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        )}

                                                        {state.cardStep === 4 && (
                                                            <>

                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 5})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 5 && (
                                                            <>

                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 6})
                                                                        }}>
                                                                            Next Step
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: state.cardStep-1})
                                                                        }}>
                                                                            Previous Step
                                                                        </button>

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                        {state.cardStep === 6 && (
                                                            <>

                                                                <div className={"row"}>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_CARD_STEP", payload: 6})
                                                                        }}>
                                                                            Accept and Submit
                                                                        </button>

                                                                    </div>

                                                                    <div className={"col"}>

                                                                        <button style={{"width": "100%"}} className={"btn btn-primary"} onClick={(e)=>{
                                                                            dispatch({type: "SET_STEP", payload: 0})
                                                                        }}>
                                                                            Cancel Process
                                                                        </button>

                                                                    </div>

                                                                </div>

                                                            </>
                                                        )}

                                                    </div>

                                                </div>

                                            </div>

                                        </>
                                    )}

                                </div>
                            </>
                        )}

                    </div>

                </div>

            </div>

            <DashboardFooter></DashboardFooter>

        </>
    )

}

export default PartnershipRequests