import React, {useContext, useEffect, useState, useReducer} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";
import {HomeContext} from "../dashboard_pages/HomeProvider";
import {useAppName, useBaseUrl, usePostData} from "../../utils/utils";
import localforage from "localforage";
import {Link, useNavigate} from "react-router-dom";
import localForage from "localforage";
import Canvas from "./dahboard_canvas";



interface Service {
    service_title: string;
    service_subtitle: string;
    service_image: string;
}

const DMainTags: React.FC = () => {

    const navigate = useNavigate()

    const initialState = {
        category: "",
        fName: "",
        lName: "",
        clinic: "",
        greeting: "",
        currentTime: ""
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case "SET_CATEGORY":
                return {...state, category: action.payload}

            case "SET_FNAME":
                return {...state, fName: action.payload}

            case "SET_LNAME":
                return {...state, lName: action.payload}

            case "SET_CLINIC":
                return {...state, clinic: action.payload}

            case "SET_GREETING":
                return {...state, greeting: action.payload}

            case "SET_TIME":
                return {...state, currentTime: action.payload}

            default:
                return state
        }


    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const appName= useAppName()

    useEffect(() => {

        const fetchTime = async () => {
            try {

                const response = await fetch('http://worldtimeapi.org/api/timezone/Africa/Nairobi')

                const data = await response.json();

                dispatch({type: "SET_TYPE", payload: data.datetime})

                const currentHour: any = new Date(data.datetime).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', hour12: false, hour: 'numeric' });

                if (currentHour >= 0 && currentHour < 12) {
                    dispatch({type: "SET_GREETING", payload: "Good Morning"})
                } else if (currentHour >= 12 && currentHour < 18) {
                    dispatch({type: "SET_GREETING", payload: "Good Afternoon"})
                } else {
                    dispatch({type: "SET_GREETING", payload: "Good Evening"})
                }

            } catch (error) {

                console.error('Error fetching time:', error);
            }
        };

        fetchTime();
    }, []);

    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            // Change the data-layout attribute
            rootElement.setAttribute('data-layout', 'semi-box');
        }

        document.title = appName

        localforage.getItem("clinic_name").then((clin: any)=>{
            dispatch({type: "SET_CLINIC", payload: clin})
        })

        localforage.getItem("fname").then((frs: any)=>{
            dispatch({type: "SET_FNAME", payload: frs})
        })

        localforage.getItem("lname").then((lrs: any)=>{
            dispatch({type: "SET_LNAME", payload: lrs})
        })

        localforage.getItem("category").then((cat: any)=> {
            dispatch({type: "SET_CATEGORY", payload: cat})
        })
    }, []);

    const services: Service[] = [
        {
            service_title: 'Service 1',
            service_subtitle: 'Subtitle 1',
            service_image: 'img-1.png',
        },
        {
            service_title: 'Service 2',
            service_subtitle: 'Subtitle 2',
            service_image: 'img-2.png',
        },
        {
            service_title: 'Service 3',
            service_subtitle: 'Subtitle 3',
            service_image: 'img-3.png',
        },
        {
            service_title: 'Service 4',
            service_subtitle: 'Subtitle 4',
            service_image: 'img-4.png',
        },
        {
            service_title: 'Service 5',
            service_subtitle: 'Subtitle 5',
            service_image: 'img-5.png',
        },
        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },
        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },
    ];

    const medicalCategories: string[] = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'];

    const shuffleArray = <T extends unknown>(array: T[]): T[] => {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const shuffledServices = shuffleArray(services);

    const rows: JSX.Element[][] = [];
    let currentRow: JSX.Element[] = [];

    shuffledServices.forEach((service, index) => {
        const { service_title, service_subtitle, service_image } = service;

        const card = (
            <div className="col" key={index}>

            </div>
        );

        currentRow.push(card);

        if (currentRow.length === 5 || index === shuffledServices.length - 1) {
            rows.push(currentRow);
            currentRow = [];
        }
    });

    const {content} = useContext(HomeContext)

    const [showButton, setShowButton] = useState(true);

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const ScrollFunction = () => {
        if (document.documentElement.scrollTop > 20) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    window.onscroll = ScrollFunction;
    
    return(
        <>
            <div id="layout-wrapper">
                <DashboardHeader></DashboardHeader>


                <SideMenu></SideMenu>

                <div className="vertical-overlay">

                </div>

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0  ">{state.clinic} - {state.category}'s Staff Access &nbsp;
                                        </h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a
                                                    href="javascript: void(0);">Dashboards</a></li>
                                                <li className="breadcrumb-item active">Facility</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={"row mt-4 text-center"}>
                                <div className={"col-12"}>
                                    <h3>{state.greeting} - {state.fName} {state.lName}</h3>
                                </div>
                            </div>

                            <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                            <h6>Welcome to uzima Healthcare! Here you have access to various tools to assist you in your professional activities.</h6>
                                    </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-xxl-12">

                                    <h4 className="card-title mb-0 flex-grow-1">My Shortcuts  <i className={"bx bx-reset"}> </i></h4>

                                    <div className={"row mt-2 mb-2"}>

                                        <div className={"col"}>
                                            <Link to={"/institution/ehr"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/record.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Patient EHR</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/clinics/launch"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/clinic.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">My Facility</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/resources"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/resources.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Resources</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/collaborations"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/collaborations.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Find Partners</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/notifications"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/notifications.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Notifications</h5>

                                                </div>
                                            </Link>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row project-wrapper">
                                <div className="col-xxl-6">
                                    <div className="card">
                                        <div className="card-header d-flex align-items-center">
                                            <h4 className="card-title flex-grow-1 mb-0">Active Collaborations</h4>
                                            <div className="flex-shrink-0">
                                                <a href="javascript:void(0);" className="btn btn-soft-info btn-sm">Download Report</a>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table table-nowrap table-centered align-middle">
                                                    <thead className="bg-light text-muted">
                                                    <tr>
                                                        <th scope="col">Activity</th>
                                                        <th scope="col">Lead Partner</th>
                                                        <th scope="col">Progress</th>
                                                        <th scope="col">Partner</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" style={{width: "10%"}}>Due Date</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    <tr>
                                                        <td className="fw-medium">Activity One</td>
                                                        <td>
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`} className="avatar-xxs rounded-circle me-1" alt="" />
                                                                <a href="javascript: void(0);" className="text-reset">User One</a>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-1 text-muted fs-13">53%</div>
                                                                <div className="progress progress-sm  flex-grow-1" style={{width: "68%"}}>
                                                                    <div className="progress-bar bg-primary rounded" role="progressbar" style={{width: "53%"}} aria-valuenow={53} aria-valuemin={0} aria-valuemax={100}> </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="avatar-group flex-nowrap">
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><span className="badge badge-soft-warning">Inprogress</span></td>
                                                        <td className="text-muted">06 Sep 2023</td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="fw-medium">Activity Two</td>
                                                        <td>
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`} className="avatar-xxs rounded-circle me-1" alt="" />
                                                                <a href="javascript: void(0);" className="text-reset">User Ten</a>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 text-muted me-1">0%</div>
                                                                <div className="progress progress-sm flex-grow-1" style={{width: "68%;"}}>
                                                                    <div className="progress-bar bg-primary rounded" role="progressbar" style={{width: "0%"}} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}> </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="avatar-group">
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-5.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-6.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><span className="badge badge-soft-danger">Pending</span></td>
                                                        <td className="text-muted">13 Nov 2024</td>
                                                    </tr>{/* end tr */}
                                                    <tr > </tr>
                                                    <tr>  </tr>
                                                    <tr>   </tr>
                                                    </tbody>{/* end tbody */}
                                                </table>{/* end table */}
                                            </div>

                                            <div className="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">
                                                <div className="flex-shrink-0">
                                                    <div className="text-muted">Showing <span className="fw-semibold">5</span> of <span className="fw-semibold">25</span> Results
                                                    </div>
                                                </div>
                                                <ul className="pagination pagination-separated pagination-sm mb-0">
                                                    <li className="page-item disabled">
                                                        <a href="#" className="page-link">←</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a href="#" className="page-link">1</a>
                                                    </li>
                                                    <li className="page-item active">
                                                        <a href="#" className="page-link">2</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a href="#" className="page-link">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a href="#" className="page-link">→</a>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>{/* end card body */}
                                    </div>
                                </div>
                                <div className="col-xxl-6">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Facility Staff</h4>
                                            <div className="flex-shrink-0">
                                                {/*<div className="dropdown card-header-dropdown">
                                                    <a className="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="fw-semibold text-uppercase fs-12">Sort by: </span><span className="text-muted">Last 30 Days<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">Today</a>
                                                        <a className="dropdown-item" href="#">Yesterday</a>
                                                        <a className="dropdown-item" href="#">Last 7 Days</a>
                                                        <a className="dropdown-item" href="#">Last 30 Days</a>
                                                        <a className="dropdown-item" href="#">This Month</a>
                                                        <a className="dropdown-item" href="#">Last Month</a>
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>{/* end card header */}

                                        <div className="card-body">

                                            <div className="table-responsive table-card">
                                                <table className="table table-borderless table-nowrap align-middle mb-0">
                                                    <thead className="table-light text-muted">
                                                    <tr>
                                                        <th scope="col">Member</th>
                                                        <th scope="col">Hours</th>
                                                        <th scope="col">Tasks</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Two</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Category 1</p>
                                                                </div>
                                                        </td>
                                                        <td>
                                                            <h6 className="mb-0">110h : <span className="text-muted">150h</span>
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            258
                                                        </td>
                                                        <td style={{width:"5%"}}>
                                                            <div id="radialBar_chart_1" data-colors='["--vz-primary"]' data-chart-series={50} className="apex-charts" dir="ltr"> </div>
                                                        </td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-4.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Three</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Radiologist</p>
                                                                </div>
                                                        </td>
                                                        <td>
                                                            <h6 className="mb-0">01h : <span className="text-muted">15h</span></h6>
                                                        </td>
                                                        <td>
                                                            85
                                                        </td>
                                                        <td>
                                                            <div id="radialBar_chart_4" data-colors='["--vz-warning"]' data-chart-series={25} className="apex-charts" dir="ltr"> </div>
                                                        </td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-6.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Four</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Category Nine</p>
                                                                </div>
                                                        </td>
                                                        <td>
                                                            <h6 className="mb-0">76h : <span className="text-muted">150h</span></h6>
                                                        </td>
                                                        <td>
                                                            69
                                                        </td>
                                                        <td>
                                                            <div id="radialBar_chart_5" data-colors='["--vz-primary"]' data-chart-series={60} className="apex-charts" dir="ltr"> </div>
                                                        </td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-5.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Five</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Category Twenty</p>
                                            </div>
                                        </td>

                                        <td>
                                            <h6 className="mb-0">123h : <span className="text-muted">150h</span>
                                            </h6>
                                        </td>
                                        <td>
                                            658
                                        </td>
                                        <td>
                                            <div id="radialBar_chart_6" data-colors='["--vz-success"]' data-chart-series={85} className="apex-charts" dir="ltr"> </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                <div>
                                                    <h5 className="fs-13 mb-0">User Six</h5>
                                                    <p className="fs-12 mb-0 text-muted">Category Thirty</p>
                                                </div>
                                        </td>
                                        <td>
                                            <h6 className="mb-0">11h : <span className="text-muted">10h</span>
                                            </h6>
                                        </td>
                                        <td>
                                            125
                                        </td>
                                        <td>
                                            <div id="radialBar_chart_7" data-colors='["--vz-primary"]' data-chart-series={70} className="apex-charts" dir="ltr"> </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                        </div>
                    </div>

                </div>


                <DashboardFooter></DashboardFooter>
            </div>

            <button onClick={topFunction} className="btn btn-danger btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>


            <Canvas />


    </>
    )
}

export default DMainTags;


