import React, {useEffect, useReducer} from 'react'
import localforage from "localforage";
import { useBaseUrl, usePostData } from '../../utils/utils';

const Staff: React.FC = () => {

    useEffect(()=>{
        localforage.getItem("clinic_name").then((clini: any)=>{
            dispatch({type: "SET_FACILITY", payload: clini})
        })

        localforage.getItem("clinic_id").then((clid: any)=>{
            dispatch({type: "SET_CLINIC_ID", payload: clid})
        })

        localforage.getItem("staff_id").then((stid: any)=>{
            dispatch({type:"SET_STAFF_ID", payload: stid})
        })

        localforage.getItem("institution_id").then((instd: any)=>{
            dispatch({type: "SET_INSTITUTION_ID", payload: instd})
        })

        GetAuthorizedUsers()
    }, [])

    const initialState = {
        userType: "admin",
        step: 0,
        subStep: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: "",
        category: "",
        specialization : "",
        pMatch: false,
        authorizedUsers: [],
        staff_id: "",
        institution_id: "",
        clinic_id: ""
    }

    const reducer = (state:any, action: any) => {

        switch(action.type){

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_USER_TYPE":
                return {...state, userType: action.payload}

            case "SET_FACILITY":
                return {...state, facility: action.payload}

            case "SET_FNAME":
                return {...state, firstName: action.payload}

            case "SET_LNAME":
                return {...state, lastName: action.payload}

            case "SET_MNAME":
                return {...state, middleName: action.payload}

            case "SET_EMAIL":
                return {...state, email: action.payload}

            case "SET_PASSWORD":
                return {...state, password: action.payload}

            case "SET_CATEGORY":
                return {...state, category: action.payload}

            case "SET_SPECIALIZATION":
                return {...state, specialization: action.payload}

            case "SET_PMATCH":
                return {...state, pMatch: action.payload}

            case "SET_USERS":
                return {...state, authorizedUsers: action.payload}

            case "SET_STAFF_ID":
                return {...state, staff_id: action.payload}

            case "SET_CLINIC_ID":
                return {...state, clinic_id: action.payload}

            case "SET_INSTITUTION_ID":
                return {...state, institution_id: action.payload}

            default:
                return state
        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const GetAuthorizedUsers = async () => {

        let url = `${useBaseUrl}/facilities/clinic-users`

        const formData = new FormData()

        formData.append("staff_id", state.staff_id)

        formData.append("clinic_id", state.clinic_id)

        formData.append("institution_id", state.institution_id)

        const users = await usePostData(url, formData)

        dispatch({type: "SET_USERS", payload: users})
    }




    return (
        <>
            <div className={"row mt-4"}>

                <div className={"col-md-2"}>
                    <div className="card card-animate" style={{borderRadius: "3%"}} onClick={()=>{

                        dispatch({type: "SET_STEP", payload: 0})
                    }}>
                        <div className="card-body p-4 text-center">
                            <div className="mx-auto avatar-md mb-3">
                                <img style={{maxHeight: "65px"}} src={`${process.env.PUBLIC_URL}/assets/images/user_add.png`} alt=""
                                     className="img-fluid"/>
                            </div>
                            <h6 className="card-title mb-1">Add User</h6>

                        </div>
                    </div>
                </div>

                <div className={"col-md-2"}>
                    <div className="card card-animate" style={{borderRadius: "3%"}} onClick={()=>{

                        dispatch({type: "SET_STEP", payload: 1})
                    }}>
                        <div className="card-body p-4 text-center">
                            <div className="mx-auto avatar-md mb-3">
                                <img style={{maxHeight: "65px"}} src={`${process.env.PUBLIC_URL}/assets/images/users.png`} alt=""
                                     className="img-fluid"/>
                            </div>
                            <h6 className="card-title mb-1">List Users</h6>

                        </div>
                    </div>
                </div>

                <div className={"col-md-2"}>
                    <div className="card card-animate" style={{borderRadius: "3%"}} onClick={()=>{

                        dispatch({type: "SET_STEP", payload: 2})

                    }}>
                        <div className="card-body p-4 text-center">
                            <div className="mx-auto avatar-md mb-3">
                                <img style={{maxHeight: "65px"}} src={`${process.env.PUBLIC_URL}/assets/images/user_groups.png`} alt=""
                                     className="img-fluid"/>
                            </div>
                            <h6 className="card-title mb-1">Groups</h6>

                        </div>
                    </div>
                </div>

                <div className={"col-md-2"}>
                    <div className="card card-animate" style={{borderRadius: "3%"}} onClick={()=>{

                        dispatch({type: "SET_STEP", payload: 3})

                    }}>
                        <div className="card-body p-4 text-center">
                            <div className="mx-auto avatar-md mb-3">
                                <img style={{maxHeight: "65px"}} src={`${process.env.PUBLIC_URL}/assets/images/permissions.png`} alt=""
                                     className="img-fluid"/>
                            </div>
                            <h6 className="card-title mb-1">User Zones</h6>

                        </div>
                    </div>
                </div>

            </div>

            {state.step === 0 && (
                <div className={"row mt-4"}>

                    <div className={"col"}>

                        <div className={"card"}>
                            <div style={{backgroundColor: "#577BC4", color: "white", fontSize: "15px", fontWeight: "Bold"}} className={"card-header d-flex justify-content-between align-items-center"}>
                                    <span>Add New User To: {state.facility}</span>
                                <img style={{maxHeight: "25px"}} onClick={(e)=>{
                                    dispatch({type: "SET_SUB_STEP", payload: 0})
                                    dispatch({type: "SET_STEP", payload: 4})

                                }} src={`${process.env.PUBLIC_URL}/assets/images/close.png`} alt=""
                                     className="img-fluid card-animate"/>

                            </div>

                            <div style={{fontSize: "15px"}} className={"card-body"}>

                                {state.subStep === 0 && (
                                    <>
                                        <div className={"row mt-2"}>
                                            <div className={"col"}>

                                                <label>
                                                    <i className="bx bx-user"> </i> &nbsp;
                                                    First Name
                                                </label>
                                                <input type={"text"} value={state.firstName} className={"form-control mt-1"} onChange={(e)=>{
                                                    dispatch({type: "SET_FNAME", payload: e.currentTarget.value})
                                                }} />

                                            </div>

                                            <div className={"col"}>

                                                <label>
                                                    <i className="bx bx-user"> </i> &nbsp;
                                                    Middle Name
                                                </label>
                                                <input type={"text"} value={state.middleName} className={"form-control mt-1"} onChange={(e)=>{
                                                    dispatch({type: "SET_MNAME", payload: e.currentTarget.value})
                                                }} />

                                            </div>

                                            <div className={"col"}>

                                                <label>
                                                    <i className="bx bx-user"> </i> &nbsp;
                                                    Last Name
                                                </label>
                                                <input type={"text"} value={state.lastName} className={"form-control mt-1"} onChange={(e)=>{
                                                    dispatch({type: "SET_LNAME", payload: e.currentTarget.value})
                                                }} />

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>
                                            <div className={"col"}>

                                                <label>
                                                    <i className="bx bx-key"> </i> &nbsp;
                                                    Password
                                                </label>
                                                <input type={"password"} value={state.password} className={"form-control mt-1"} onChange={(e)=>{
                                                    dispatch({type: "SET_PASSWORD", payload: e.currentTarget.value})
                                                }} />

                                            </div>

                                            <div className={"col"}>

                                                <label>
                                                    <i className="bx bx-key"> </i> &nbsp;
                                                    Confirm Password
                                                </label>
                                                <input type={"password"} className={"form-control mt-1"} onChange={(e)=>{
                                                    dispatch({type: "SET_PMATCH", payload: state.password === e.currentTarget.value})
                                                }} />

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>
                                            <div className={"col"}>

                                                <label>
                                                    <i className="bx bx-at"> </i>
                                                    &nbsp;
                                                    User Email
                                                </label>
                                                <input type={"text"} value={state.email} className={"form-control mt-1"} onChange={(e)=>{
                                                    dispatch({type: "SET_EMAIL", payload: e.currentTarget.value})
                                                }} />

                                            </div>
                                        </div>
                                    </>
                                )}

                                {state.subStep === 1 &&(
                                    <div className={"row mt-2"}>
                                        <div className={"col"}>
                                            <label>
                                                <i className="bx bxs-user-badge"> </i> &nbsp;
                                                User Group
                                            </label>

                                            <select className={"form-control mt-2"} value={state.category} onChange={(e)=>{
                                                dispatch({type: "SET_CATEGORY", payload: e.currentTarget.value})
                                            }}>
                                                <option>Choose One </option>
                                                <option value={1}>Staff</option>
                                            </select>
                                        </div>

                                        <div className={"col"}>
                                            <label>
                                                <i className="bx bx-file"> </i> &nbsp;
                                                User Category
                                            </label>

                                            <select className={"form-control mt-2"} onChange={(e)=>{
                                                dispatch({type: "SET_CATEGORY", payload: e.currentTarget.value})
                                            }}>
                                                <option value={1}>Staff</option>
                                            </select>
                                        </div>
                                    </div>
                                )}

                            </div>

                            <div className={"card-footer"}>
                                {state.subStep === 0 && (
                                    <div className={"row mt-1 mb-1"}>
                                        <div className={"col"}>
                                            <button disabled={!state.pMatch} style={{width: "100%"}} onClick={(e)=>{
                                                dispatch({type: "SET_SUB_STEP", payload: 1})
                                            }} className={'btn btn-primary'}>Next Step</button>
                                        </div>

                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={'btn btn-primary'}>Reset Fields</button>
                                        </div>

                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={'btn btn-primary'}>Cancel Process</button>
                                        </div>
                                    </div>
                                )}
                                {state.subStep === 1 && (
                                    <>
                                        <div className={"row mt-1 mb-1"}>
                                            <div className={"col"}>
                                                <button style={{width: "100%"}} onClick={(e)=>{

                                                }} className={'btn btn-primary'}>Create User</button>
                                            </div>

                                            <div className={"col"}>
                                                <button style={{width: "100%"}} className={'btn btn-primary'}>Reset Fields</button>
                                            </div>

                                            <div className={"col"}>
                                                <button style={{width: "100%"}} className={'btn btn-primary'}>Cancel Process</button>
                                            </div>
                                        </div>

                                    </>
                                )}
                            </div>
                        </div>

                    </div>



                </div>
            )}

            {state.step === 1 && (
                <div className={"row mt-4"}>

                    <div className={"col"}>


                        <div className={"card"}>

                            <div style={{backgroundColor: "#577BC4", color: "white", fontSize: "15px", fontWeight: "Bold"}} className={"card-header d-flex justify-content-between align-items-center"}>
                                <span>{state.facility}'s Authorized Users</span>
                                <img style={{maxHeight: "25px"}} onClick={(e)=>{
                                    dispatch({type: "SET_SUB_STEP", payload: 0})
                                    dispatch({type: "SET_STEP", payload: 4})

                                }} src={`${process.env.PUBLIC_URL}/assets/images/close.png`} alt=""
                                     className="img-fluid card-animate"/>

                            </div>

                            <div className={"card-body"}>

                            </div>

                        </div>

                    </div>



                </div>
            )}

            {state.step === 2 && (
                <div className={"row mt-4"}>



                </div>
            )}

            {state.step === 3 && (
                <div className={"row mt-4"}>



                </div>
            )}
            {state.step === 4 && (
                <>
                    {/*Leave this blank*/}
                </>
            )}
        </>
    )
}

export default Staff