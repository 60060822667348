import React, {useEffect, useState} from "react";
import localforage from "localforage";
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const Backup: React.FC = () => {

    const [pageTitle, setPageTitle] = useState("Create Backups")
    const [clinicName, setClinicName] = useState<any>("Facility")
    const [step, setStep] = useState(1)
    const [created, setCreated] = useState<any>("none")


    useEffect(()=>{
        document.title = "Uzima - Billing Account"
        localforage.getItem("clinic_name").then((clini)=>{
            setClinicName(clini)
        })

        localforage.getItem("bup").then((r)=>{
            setCreated(r)
        })

        localforage.setItem("bup", "created").then(r => {

            setCreated(r)
        })
    }, [])

    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0  ">{clinicName} - {pageTitle} &nbsp;
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href="javascript: void(0);">Dashboards</a></li>
                                            <li className="breadcrumb-item active">Backups</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col"}>

                                <div className={"container-fluid"}>

                                    {created === "none" && (
                                        <>

                                            <div className={"col-md-3"}>

                                            </div>

                                            <div className={"col-md-6"}>

                                                <label>Request Notes</label>

                                                <textarea className={"mt-2"}>

                                                </textarea>

                                            </div>

                                            <div className={"col-md-3"}>

                                            </div>

                                        </>
                                    )}

                                    {created === "created" && (
                                        <>
                                            <div className={"col-md-3"}>

                                            </div>

                                            <div className={"col-md-6"}>

                                                <div className={"card card-animate"}>

                                                    <div className={"card-body text-center"}>

                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/bar.gif`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>

                                                        <p className={"text-success mt-4"}>

                                                            You already requested a copy of your data. please wait until we approve before applying again.

                                                        </p>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className={"col-md-3"}>

                                            </div>
                                        </>
                                    )}



                                </div>

                            </div>

                        </div>


                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <canvas></canvas>
        </>
    )
}

export default Backup