import React, {useEffect, useReducer} from 'react'
import DashboardHeader from "./layouts/dashboard_header";
import SideMenu from "./layouts/dashboard_menu";
import {useAppName, useBreadcrumbs} from "../utils/utils";
import localforage from "localforage";
import DashboardFooter from "./layouts/dashboard_footer";

const Notifications: React.FC = () => {

    const initialState = {
        clinicName: "",
        appName: useAppName(),
        fname: ""
    }



    const reducer = (state: any, action: any) =>{

        switch (action.type){

            case "SET_CLINIC_NAME":
                return {...state, clinicName: action.payload}

            case "SET_FIRST_NAME":
                return {...state, fname: action.payload}

            default:
                return state

        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=>{
        document.title = "Uzima - Communication Center"

        localforage.getItem("clinic_name").then((clin: any)=>{

            dispatch({type: "SET_CLINIC_NAME", payload: clin})

        })

        localforage.getItem("fname").then((fnm: any)=>{

            dispatch({type: "SET_FIRST_NAME", payload: fnm})

        })
    }, [])

    return (
        <>
            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"row mt-2"}>
                        <div className={"col"}>

                            {useBreadcrumbs(state.clinicName, `Communication Manager `, "Notifications", "Notis")}

                        </div>
                    </div>

                    <div className={"row mt-4"}>

                        <div className={"col text-center"}>

                            <h4>Notifications <sup><span className="topbar-badge badge rounded-pill bg-success">0 </span></sup></h4>

                        </div>

                    </div>

                    <div className={"row mt-2"}>
                        <div className={"alert alert-info"}>

                            <h6>Howdy {state.fname}! We do not have any new or old notifications for you at the moment! You'll see them here as they arrive!</h6>

                        </div>
                    </div>

                    <DashboardFooter />
                </div>
            </div>

            <canvas />

        </>
    )
}

export default Notifications