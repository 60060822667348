import React from 'react'
import DashboardFooter from '../../layouts/dashboard_footer';
import DashboardHeader from '../../layouts/dashboard_header'
import SideMenu from "../../layouts/dashboard_menu";
import {Link} from "react-router-dom";

const PendingInvoices: React.FC = () => {

    return(
        <>
            <DashboardHeader />
            <SideMenu />

            <div className={"main-content"}>

                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-transparent">
                                    <h4 className="mb-sm-0">Invoice List</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Invoices</a></li>
                                            <li className="breadcrumb-item active">Invoice List</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card" id="invoiceList">
                                    <div className="card-header border-0">
                                        <div className="d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Pending Invoice List</h5>
                                            <div className="flex-shrink-0">
                                                <div className="d-flex gap-2 flex-wrap">
                                                    <button className="btn btn-danger" id="remove-actions">
                                                        <i className="ri-delete-bin-2-line"> </i>
                                                    </button>
                                                    <Link to={"/institution/accounting/create-invoice"} className="btn btn-primary">
                                                        <i className="ri-add-line align-bottom me-1"> </i>
                                                        Generate Invoice
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body bg-light-subtle border border-dashed border-start-0 border-end-0">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-md-3">
                                                    <div className="search-box">
                                                        <input type="text" className="form-control search bg-light border-light" placeholder="Search for customer, email, country, status or something..." />
                                                            <i className="ri-search-line search-icon"> </i>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <input type="text" className="form-control bg-light border-light flatpickr-input" id="datepicker-range" placeholder="Select date" />
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-light">
                                                        <div className="choices" data-type="select-one" tabIndex={0} role="listbox" aria-haspopup={true} aria-expanded={false}>
                                                            <div className="choices__inner">
                                                                <select className="form-control choices__input"  data-choice="active">
                                                                    <option value="all" >All</option>
                                                                </select>
                                                            </div>

                                                            <div className="choices__list choices__list--dropdown" aria-expanded="false">
                                                                <div className="choices__list" role="listbox">
                                                                    <div id="choices--idStatus-item-choice-5" className="choices__item choices__item--choice choices__placeholder choices__item--selectable is-highlighted" role="option" data-choice="" data-id="5" data-value="" data-select-text="Press to select" data-choice-selectable="" aria-selected="true">Status</div>
                                                                    <div id="choices--idStatus-item-choice-1" className="choices__item choices__item--choice is-selected choices__item--selectable" role="option" data-choice="" data-id="1" data-value="all" data-select-text="Press to select" data-choice-selectable="">All</div>
                                                                    <div id="choices--idStatus-item-choice-2" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="2" data-value="Cancel" data-select-text="Press to select" data-choice-selectable="">Cancel</div>
                                                                    <div id="choices--idStatus-item-choice-3" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="3" data-value="Paid" data-select-text="Press to select" data-choice-selectable="">Paid</div>
                                                                    <div id="choices--idStatus-item-choice-4" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="4" data-value="Refund" data-select-text="Press to select" data-choice-selectable="">Refund</div>
                                                                    <div id="choices--idStatus-item-choice-6" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="6" data-value="Unpaid" data-select-text="Press to select" data-choice-selectable="">Unpaid</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <button type="button" className="btn btn-primary w-100" >
                                                        <i className="ri-equalizer-fill me-1 align-bottom"> </i> Filters
                                                    </button>
                                                </div>

                                            </div>

                                        </form>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle table-nowrap" id="invoiceTable">
                                                    <thead className="text-muted">
                                                    <tr>
                                                        <th scope="col" style={{"width": "50px;"}}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort text-uppercase" data-sort="invoice_id">ID</th>
                                                        <th className="sort text-uppercase" data-sort="customer_name">Customer</th>
                                                        <th className="sort text-uppercase" data-sort="email">Email</th>
                                                        <th className="sort text-uppercase" data-sort="country">County</th>
                                                        <th className="sort text-uppercase" data-sort="date">Date</th>
                                                        <th className="sort text-uppercase" data-sort="invoice_amount">Amount</th>
                                                        <th className="sort text-uppercase" data-sort="status">Payment Status</th>
                                                        <th className="sort text-uppercase" data-sort="action">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all" id="invoice-list-data"><tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="chk_child" value="#VL25000351" />
                                                            </div>
                                                        </th>
                                                        <td className="id"><Link to="/institution/accounting/invoice"  data-id="25000351" className="fw-medium link-primary">#VL25000351</Link></td>
                                                        <td className="customer_name">
                                                            <div className="d-flex align-items-center">
                                                                <img src="assets/images/users/avatar-1.jpg" alt="" className="avatar-xs rounded-circle me-2" />Uzima User
                                                            </div>
                                                        </td>
                                                        <td className="email">user***@gmail.com</td>
                                                        <td className="country">Naairobi(047)</td>
                                                        <td className="date">23 Mar, 2023 <small className="text-muted">9:58 PM</small></td>
                                                        <td className="invoice_amount">KSH. 2943</td>
                                                        <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Paid</span>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button className="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="ri-more-fill align-middle"></i>
                                                                </button>
                                                                <ul className="dropdown-menu dropdown-menu-end">
                                                                    <li><button className="dropdown-item"  data-id="25000351"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                                                        View</button></li>
                                                                    <li><button className="dropdown-item"  data-id="25000351"><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                                        Edit</button></li>
                                                                    <li><a className="dropdown-item" ><i className="ri-download-2-line align-bottom me-2 text-muted"></i>
                                                                        Download</a></li>
                                                                    <li className="dropdown-divider"></li>
                                                                    <li>
                                                                        <a className="dropdown-item remove-item-btn" data-bs-toggle="modal" href="#deleteOrder">
                                                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                                                            Delete
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <div className="noresult" style={{"display": "none"}}>
                                                    <div className="text-center">
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">We've searched more than 150+ invoices We did not find any invoices for you search.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end mt-3">
                                                <div className="pagination-wrap hstack gap-2" style={{"display": "flex"}}>
                                                    <a className="page-item pagination-prev disabled" href="#">
                                                        Previous
                                                    </a>
                                                    <ul className="pagination listjs-pagination mb-0"><li className="active"><a className="page" href="#" data-i="1" data-page="8">1</a></li><li><a className="page" href="#" data-i="2" data-page="8">2</a></li><li><a className="page" href="#" data-i="3" data-page="8">3</a></li></ul>
                                                    <a className="page-item pagination-next" href="#">
                                                        Next
                                                    </a>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <DashboardFooter />

        </>
    )
}

export default PendingInvoices