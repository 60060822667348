import React, {useEffect, useReducer, useState} from "react";
import localforage from "localforage";
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import {useAppName} from "../../utils/utils";

const Messages: React.FC = () => {

    const [pageTitle, setPageTitle] = useState("Communication Center")
    const [clinicName, setClinicName] = useState<any>("Facility")

    const initialState = {
        clinicName: "",
        appName: useAppName(),
        fname: "",
        pageTitle: "Messaging Center"
    }



    const reducer = (state: any, action: any) =>{

        switch (action.type){

            case "SET_CLINIC_NAME":
                return {...state, clinicName: action.payload}

            case "SET_FIRST_NAME":
                return {...state, fname: action.payload}

            default:
                return state

        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(()=>{
        document.title = "Uzima - Communication Center"

        localforage.getItem("clinic_name").then((clini)=>{
            dispatch({type: "SET_CLINIC_NAME", payload: clini})
        })

        localforage.getItem("fname").then((fnm: any)=>{

            dispatch({type: "SET_FIRST_NAME", payload: fnm})

        })
    }, [])

    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        <div className="row mt-2">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0  ">{state.clinicName} - {state.pageTitle} &nbsp;
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href="javascript: void(0);">Dashboards</a></li>
                                            <li className="breadcrumb-item active">{state.pageTitle}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col text-center"}>


                            </div>

                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col text-center"}>

                                <h4>Messages <sup><span className="topbar-badge badge rounded-pill bg-primary">0 </span></sup></h4>

                            </div>

                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <p className={"alert alert-info"}>Howdy {state.fname}! We do not have any new or old messages for you at the moment! You'll see them here as they arrive!</p>
                            </div>
                        </div>


                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <canvas></canvas>
        </>
    )
}

export default Messages