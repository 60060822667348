import React from "react";
import Canvas from "./dahboard_canvas";
import DashboardFooter from "./dashboard_footer";
import SideMenu from "./dashboard_menu";
import DashboardHeader from "./dashboard_header";

const Contracts: React.FC = () => {
    return (
        <>
            <div id="layout-wrapper">
                <DashboardHeader></DashboardHeader>

                <SideMenu></SideMenu>

                <div className="vertical-overlay">

                </div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">

                            <div className={"alert alert-danger mt-4"}>
                                Not accessible by Guest accounts
                            </div>

                        </div>
                    </div>
                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <Canvas></Canvas>
        </>
    )
}

export default Contracts