import React, {useEffect, useReducer} from 'react'
import DashboardFooter from '../../layouts/dashboard_footer';
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import {useUserNames} from "../../../utils/utils";
import {useNavigate} from "react-router-dom";

const CommunicationHome: React.FC = () => {

    const navigate = useNavigate()

    let user_info: any = {first_name: "", last_name: "", clinic: "", staff_id: "", avatar: ""}

    useEffect(()=>{

        document.title = "Communications Center"

        const GetUsers = async () => {

            const user_info = useUserNames()

        }

        user_info = GetUsers()

    }, [])

    const initialState = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        header: "Uzima Communications Center",
        subHeader: "Manage all your communications from one place.",
        showHeadings: true
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case 'SET_STEP':
                return {...state, step: action.payload}

            case 'SET_PAGE_HEADING':
                return {...state, header: action.payload}

            case 'SET_SUB_HEADER':
                return {...state, subHeader: action.payload}

            case 'SET_SUB_STEP':
                return {...state, subStep: action.payload}

            case 'SET_CARD_STEP':
                return {...state, cardStep: action.payload}

            case 'SET_SHOW_HEADINGS':
                return {...state, showHeadings: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <>
            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />
            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={'container-fluid'}>

                        {state.showHeadings && (
                            <>
                                <div className={"row mt-4 text-center"}>
                                    <div className={"col-12"}>
                                        <h3>{state.header}</h3>
                                    </div>
                                </div>

                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <h6>{state.subHeader}</h6>
                                    </div>
                                </div>
                            </>
                        )}

                        {state.step === 0 && (
                            <>

                                <div className={"row mt-4"}>

                                    <div className={"col"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_SUB_HEADER", payload: "Non Specific Communication"})
                                            dispatch({type: "SET_STEP", payload: 1})
                                        }} >

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/community.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">General Communication</h5>

                                                <br />

                                            </div>

                                        </div>


                                    </div>

                                    <div className={"col"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_SUB_HEADER", payload: "View Communications specific to a partner or patient"})
                                            dispatch({type: "SET_STEP", payload: 2})
                                        }} >

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/community.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Targeted Communication</h5>

                                                <br />

                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </>
                        )}

                        {state.step === 1 && (
                            <>
                                {state.subStep === 0 && (
                                    <>
                                        <div className={"row mt-4"}>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima General Appointments"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 1})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/planners.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Appointments</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima General Meetings"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 2})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/meetings.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Meetings</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima General Chats"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 3})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/messaging.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Chats</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima General Notifications"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 4})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/notifications.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Notifications</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima General Messages"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 5})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/unopened.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Messaging</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima General Documents Manager"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 1})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/notes.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Document Manager</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Healthcare Communications Center"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 0})
                                                    dispatch({type: "SET_STEP", payload: 0})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/back.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Go Back</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </>
                                )}

                                {state.subStep === 1 && (
                                    <>

                                    </>
                                )}

                                {state.subStep === 2 && (
                                    <>
                                        <div className={"row mt-4"}>
                                                <div className={"col-md-3"}>

                                                    <div className={"card card-animate"} onClick={(e)=>{
                                                        dispatch({type: "SET_SUB_HEADER", payload: "Create an open / genera meeting"})
                                                        dispatch({type: "SET_STEP", payload: 3})
                                                    }} >

                                                        <div className="card-body p-4 text-center">
                                                            <br />
                                                            <br />
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/meetings.png`}
                                                                    alt=""
                                                                    className="img-fluid "
                                                                />
                                                            </div>
                                                            <br />
                                                            <h5 className="card-title mb-1">Create Meeting</h5>

                                                            <br />

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className={"col-md-3"}>

                                                    <div className={"card card-animate"} onClick={(e)=>{
                                                        dispatch({type: "SET_SUB_HEADER", payload: "Your upcoming meetings (allowed maximum: 15)"})
                                                        dispatch({type: "SET_STEP", payload: 4})
                                                    }} >

                                                        <div className="card-body p-4 text-center">
                                                            <br />
                                                            <br />
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/meeting.png`}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <br />
                                                            <h5 className="card-title mb-1">Upcoming Meetings</h5>

                                                            <br />

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className={"col-md-3"}>

                                                    <div className={"card card-animate"} onClick={(e)=>{
                                                        dispatch({type: "SET_SUB_HEADER", payload: "Your meeting history (showing for the last 15 days max)"})
                                                        dispatch({type: "SET_STEP", payload: 5})
                                                    }} >

                                                        <div className="card-body p-4 text-center">
                                                            <br />
                                                            <br />
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/api.png`}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <br />
                                                            <h5 className="card-title mb-1">Meeting History</h5>

                                                            <br />

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className={"col-md-3"}>

                                                    <div className={"card card-animate"} onClick={(e)=>{
                                                        dispatch({type: "SET_SUB_HEADER", payload: "Uzima Healthcare Communications Center"})
                                                        dispatch({type: "SET_SUB_STEP", payload: 0})
                                                        dispatch({type: "SET_STEP", payload: 0})
                                                    }} >

                                                        <div className="card-body p-4 text-center">
                                                            <br />
                                                            <br />
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/images/back.png`}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <br />
                                                            <h5 className="card-title mb-1">Go Back</h5>

                                                            <br />

                                                        </div>

                                                    </div>


                                                </div>

                                        </div>
                                    </>
                                )}


                            </>
                        )}

                        {state.step === 2 && (
                            <>
                                {state.subStep === 0 && (
                                    <>
                                        <div className={"row mt-4"}>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Targeted Appointments"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 1})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/planners.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Appointments</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Targeted Meetings"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 2})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/meetings.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Meetings</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Targeted Chats"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 3})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/messaging.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Chats</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Targeted Notifications"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 4})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/notifications.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Notifications</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Targeted Messages"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 5})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/unopened.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Messaging</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Targeted Documents Manager"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 1})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/notes.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Document Manager</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col-md-3"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Healthcare Communications Center"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 0})
                                                    dispatch({type: "SET_STEP", payload: 0})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/back.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Go Back</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>


                                        </div>
                                    </>
                                )}

                                {state.subStep === 1 && (
                                    <>
                                        <div className={"row mt-4"}>

                                            <div className={"col"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Patient Appointments"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 1})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/planners.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Patient Appointments</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                            <div className={"col"}>

                                                <div className={"card card-animate"} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_HEADER", payload: "Uzima Patient Appointments"})
                                                    dispatch({type: "SET_SUB_STEP", payload: 1})
                                                }} >

                                                    <div className="card-body p-4 text-center">
                                                        <br />
                                                        <br />
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/planners.png`}
                                                                alt=""
                                                                className="img-fluid rounded-circle"
                                                            />
                                                        </div>
                                                        <br />
                                                        <h5 className="card-title mb-1">Partner Appointments</h5>

                                                        <br />

                                                    </div>

                                                </div>


                                            </div>

                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {state.step === 3 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card"} style={{"transition": "none"}}>

                                            <div style={{backgroundColor: "#577BC4", color: "white", fontSize: "15px", fontWeight: "Bold", transform: "none"}} className={"card-header d-flex justify-content-between align-items-center"}>
                                                <span>
                                                    {state.cardStep === 0 && (
                                                        <>
                                                            Create a General Web Meeting
                                                        </>
                                                    )}

                                                    {state.cardStep === 1 && (
                                                        <>
                                                            Choose Meeting Date
                                                        </>
                                                    )}

                                                    {state.cardStep === 2 && (
                                                        <>
                                                            Enter Meeting Details
                                                        </>
                                                    )}

                                                    {state.cardStep === 3 && (
                                                        <>
                                                            Add meeting participants
                                                        </>
                                                    )}

                                                    {state.cardStep === 4 && (
                                                        <>
                                                            Verify and confirm Meeting
                                                        </>
                                                    )}
                                                </span>
                                                <img style={{maxHeight: "25px"}} onClick={(e)=>{
                                                    dispatch({type: "SET_SUB_STEP", payload: 0})
                                                    dispatch({type: "SET_STEP", payload: 0})

                                                }} src={`${process.env.PUBLIC_URL}/assets/images/close.png`} alt=""
                                                     className="img-fluid card-animate"/>

                                            </div>

                                            <div className={"card-body"}>

                                                {state.cardStep === 0 && (
                                                    <>
                                                        <p>Hello there!</p>
                                                        <p>I will help you set up a general meeting.</p>
                                                        <p>So what is a general meeting?</p>
                                                        <p>This kind of meeting is not tied to a specific patient, service or partner. Anyone you select can attend!</p>
                                                        <p>If this is the kind of meeting you want to set up then let's proceed!</p>
                                                    </>
                                                )}

                                                {state.cardStep === 1 && (

                                                    <>
                                                        <div className={"row mt-4"}>
                                                            <div className={"col"}>

                                                                <label>When do you want to have the meeting?</label>
                                                                <input type={"date"} onChange={(e)=>{
                                                                    dispatch({type:"SET_DATE", payload: e.currentTarget.value})
                                                                }} className={"form-control mt-3 mb-4"} />

                                                            </div>
                                                        </div>

                                                    </>
                                                )}

                                                {state.cardStep === 2 && (
                                                    <>
                                                        <div className={"row mt-4"}>
                                                            <div className={"col"}>

                                                                <label>What is the meeting title?</label>
                                                                <input type={"text"} onChange={(e)=>{
                                                                    dispatch({type:"SET_MEETING_TITLE", payload: e.currentTarget.value})
                                                                }} className={"form-control mt-3 mb-1"} />

                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"}>

                                                                <label>Describe the meeting</label>
                                                                <input type={"text"} onChange={(e)=>{
                                                                    dispatch({type:"SET_MEETING_DESC", payload: e.currentTarget.value})
                                                                }} className={"form-control mt-3 mb-1"} />

                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {state.cardStep === 3 && (
                                                    <>

                                                        <div className={"row mt-2"}>
                                                            <div className={"col"}>

                                                                <label>How many participants do you intend to have (<em>Apart from you.</em>)?</label>
                                                                <select className={"form-control"}>
                                                                    <option>Choose One</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                    <option>5</option>
                                                                    <option>6</option>
                                                                    <option>7</option>
                                                                    <option>8</option>
                                                                    <option>9</option>
                                                                    <option>10</option>
                                                                </select>

                                                            </div>
                                                        </div>

                                                    </>
                                                )}

                                            </div>

                                            <div className={"card-footer"}>

                                                {state.cardStep === 0 && (
                                                    <>
                                                        <div className={"row mt-2"}>
                                                            <div className={"col"}>

                                                                <button style={{width: "100%"}} onClick={(e)=>{
                                                                    dispatch({type:"SET_CARD_STEP", payload: 1})
                                                                }} className={"btn btn-primary"}>
                                                                    Okay, Let's Proceed.
                                                                </button>

                                                            </div>

                                                            <div className={"col"}>

                                                            </div>

                                                            <div className={"col"}>

                                                                <button className={"btn btn-primary"} style={{width: "100%"}}>
                                                                    I will do it later.
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {state.cardStep === 1 && (
                                                    <>

                                                        <div className={"row mt-2"}>
                                                            <div className={"col"}>

                                                                <button style={{width: "100%"}} onClick={(e)=>{
                                                                    dispatch({type:"SET_CARD_STEP", payload: 2})
                                                                }} className={"btn btn-primary"}>
                                                                    Next Step.
                                                                </button>

                                                            </div>

                                                            <div className={"col"}>

                                                                <button style={{width: "100%"}} onClick={(e)=>{
                                                                    dispatch({type:"SET_CARD_STEP", payload: state.cardStep-1})
                                                                }} className={"btn btn-primary"}>
                                                                    Previous Step.
                                                                </button>

                                                            </div>

                                                            <div className={"col"}>

                                                                <button className={"btn btn-primary"} onClick={(e)=>{
                                                                    dispatch({type:"SET_STEP", payload: 1})
                                                                }} style={{width: "100%"}}>
                                                                    Cancel Process.
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </>
                                                )}

                                                {state.cardStep === 2 && (
                                                    <>
                                                        <div className={"row mt-2"}>
                                                            <div className={"col"}>

                                                                <button style={{width: "100%"}} onClick={(e)=>{
                                                                    dispatch({type:"SET_CARD_STEP", payload: 3})
                                                                }} className={"btn btn-primary"}>
                                                                    Next Step.
                                                                </button>

                                                            </div>

                                                            <div className={"col"}>

                                                                <button style={{width: "100%"}} onClick={(e)=>{
                                                                    dispatch({type:"SET_CARD_STEP", payload: state.cardStep-1})
                                                                }} className={"btn btn-primary"}>
                                                                    Previous Step.
                                                                </button>

                                                            </div>

                                                            <div className={"col"}>

                                                                <button className={"btn btn-primary"} onClick={(e)=>{
                                                                    dispatch({type:"SET_STEP", payload: 1})
                                                                }} style={{width: "100%"}}>
                                                                    Cancel Process.
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        )}

                        {state.step === 4 && (
                            <>
                                {state.subStep === 0 && (
                                    <>
                                        <div className={"row mt-4"}>
                                            <div className={"col"}>

                                                <div className={"card card-animate"}>
                                                    <div className={"row mt-1 mb-1"}>
                                                        <div className={"col-md-2"}>

                                                        </div>

                                                        <div className={"col-md-8"}>

                                                        </div>

                                                        <div className={"col-md-2"}>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )}

                            </>
                        )}

                    </div>

                </div>

            </div>

            <DashboardFooter />

        </>
    )
}

export default CommunicationHome