import React from "react";
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import DashboardFooter from "../../layouts/dashboard_footer";
import {Link} from "react-router-dom";

const ServicesHome: React.FC = () => {

    return (
        <>

            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />
            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        <div className={"row mt-4 text-center"}>
                            <div className={"col-12"}>
                                <h3>Uzima Community Service Center</h3>
                            </div>
                        </div>

                        <div className={"row mt-4 text-center"}>
                            <div className={"col"}>
                                <h6>Service is our core service.</h6>
                            </div>
                        </div>


                        <div className={"row mt-4"}>

                            <div className={"col"}>

                                <div className={"card card-animate"}>

                                    <Link to={"/institution/services"} className="card-body p-4 text-center">
                                        <br />
                                        <br />
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/ls.png`}
                                                alt=""
                                                className="img-fluid rounded-circle"
                                            />
                                        </div>
                                        <br />
                                        <h5 className="card-title mb-1">General List</h5>

                                        <br />

                                    </Link>

                                </div>


                            </div>

                            <div className={"col"}>

                                <div className={"card card-animate"}>

                                    <div className="card-body p-4 text-center">
                                        <br />
                                        <br />
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/service.png`}
                                                alt=""
                                                className="img-fluid rounded-circle"
                                            />
                                        </div>
                                        <br />
                                        <h5 className="card-title mb-1">Our Services</h5>

                                        <br />

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col"}>

                                <Link to={"/institution/resources"} className={"card card-animate"}>

                                    <div className="card-body p-4 text-center">
                                        <br />
                                        <br />
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/rsource.png`}
                                                alt=""
                                                className="img-fluid rounded-circle"
                                            />
                                        </div>
                                        <br />
                                        <h5 className="card-title mb-1">Resources</h5>

                                        <br />

                                    </div>

                                </Link>


                            </div>

                            <div className={"col"}>

                                <Link to={"/institution/ehr"} className={"card card-animate"}>

                                    <div className="card-body p-4 text-center">
                                        <br />
                                        <br />
                                        <div className="mx-auto avatar-md mb-3">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/record.png`}
                                                alt=""
                                                className="img-fluid rounded-circle"
                                            />
                                        </div>
                                        <br />
                                        <h5 className="card-title mb-1">My Workspace</h5>

                                        <br />

                                    </div>

                                </Link>

                            </div>

                        </div>


                    </div>


                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </>
    )

}

export default ServicesHome