import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import localforage from "localforage";
import set = Reflect.set;
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import clinic_start from "./clinic_start";
import {Link} from "react-router-dom";
import {useBaseUrl} from "../../utils/utils";

interface counties  {
    county: string,
    county_code: number
}

interface services {
    service_title: string,
    service_id: number
}

const SearchClinic: React.FC = () => {


    useEffect(()=>{

        document.title = "Search Clinics/ Partners"

    }, [])

    useEffect(()=>{
        getAllServices();
        getAllCounties(countyId);

        document.title = "Find Service Providers";


    }, [])

    const [step, setStep] = useState(1)
    const [subStep, setSubStep] = useState(1)
    const [townReady, setTownReady] = useState<boolean>(true);
    const [countyId, setCountyId] = useState<number>();
    const [townId, setTownId] = useState<number>();
    const [categoryId, setCategoryId] = useState<number>(99999999);
    const [towns, setTowns] = useState<any []>([]);
    const [town, setTown] = useState(null);
    const [counties, setCounties] = useState<any []>([]);
    const [county, setCounty] = useState<any []>([]);
    const [categories, setCategories] = useState<any []>([]);
    const [applicationId, setApplicationId] = useState();
    const [serviceId, setServiceId] = useState();
    const [jobs, setJobs] = useState<any []>([]);
    const [job, setJob] = useState();
    const [jobCount, setJobCount] = useState<number>(0);
    const [partner, setPartner] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)

    const [cservice, setCservice] = useState<any>()
    const [cpartner, setCpartner] = useState<any>()

    const [vrecordNo, setVrecordNo] = useState<any>()
    const [patientCode, setPatientCode] = useState<any>()
    const [contractType, setContractType] = useState<any>()

    const [urgency, setUrgency] = useState<any>()
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [terms, setTerms] = useState<any>("Carefully describe your contract terms for this activity...")
    const [requirements, setRequirements] = useState<any>("Describe your needs and provide any other relevant information.")
    const [requestId, setRequestId] = useState<any>()

    const base_url = useBaseUrl()

    let staff_id: any = localforage.getItem("staff_id");
    let institution_id: any = localforage.getItem("institution_id");
    let clinic_id: any = localforage.getItem("clinic_id");



    const getAllTowns = async (town_id= townId) => {

        let town_url = base_url + "/facilities/get-towns";

        try{

            const response = await axios.post(town_url, {
                town_id: town_id,
                county_id: countyId,
                clinic_id: clinic_id,
                staff_id: staff_id,
                institution_id: institution_id
            });

            const {status, message, data} = response.data;

            if(status == "success"){
                if(Object.keys(data).length == 1){

                    setTown(data);

                    setTownReady(false)

                }else if(Object.keys(data).length > 1){

                    setTowns(data);

                    setTownReady(false);

                } else {

                    setTownReady(true)

                    await Swal.fire({
                        title: "Warning",
                        icon: "warning",
                        text: "The server returned zero(0) towns. Filter by towns will be unavailable"
                    })
                }
            }else if(status == "error"){

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                });
            }


        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Towns",
                text: "Unable to fetch towns. Filter by towns will be unavailable"
            })

        }
    }

    const getAllCounties = async (county_id = countyId) => {

        let county_url = base_url + "/facilities/get-counties";

        try{

            const response = await axios.post(county_url, {
                county_id: countyId,
                clinic_id: clinic_id,
                staff_id: staff_id,
                institution_id: institution_id
            });

            const {status, message, data} = response.data;

            if(status == "success"){
                if(Object.keys(data).length == 1){

                    const response_data: counties[] = data

                    setCounty(response_data);

                    setTownReady(false)

                }else if(Object.keys(data).length > 1){

                    const response_data: counties[] = data

                    setCounties(response_data);

                    setTownReady(false);

                } else {
                    setTownReady(true)

                    await Swal.fire({
                        title: "Warning",
                        icon: "warning",
                        text: "The server returned zero(0) counties. Filter by location will be unavailable"
                    })
                }
            }else if(status == "error"){
                setTownReady(true)
                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                });
            }


        }catch (e) {
            setTownReady(true)

            await Swal.fire({
                icon: "warning",
                title: "Towns",
                text: "Unable to fetch counties. Filter by towns will be unavailable"
            })

        }

    }

    const getAllServices = async ()=>{

        try{

            const response = await axios.get(base_url + '/facilities/service-list');

            const {status, message, data} = response.data;

            if(status == "success"){

                const sorted_data: services [] = data;

                setCategories(sorted_data);

            }else{
                await Swal.fire({
                    icon: "error",
                    title:"Error",
                    text: message
                });

                return;
            }

        }catch (e) {

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Server communication failure. Check your connection and try again."
            })

            return;

        }

    }

    const getServices = ()=>{
        let retries = 0;
        const maxRetries = 5;
        const delay = 4000;

        let service_url = base_url + "/facilities/clinics";

        const runTrySegment = async ()=>{

            try{

                const response = await axios.post(service_url, {
                    town_id: townId,
                    county_code: countyId,
                    application_id: applicationId,
                    service_id: serviceId
                });

                const {status, message, data} = response.data;

                if(status == "success"){

                    if(Object.keys(data).length==0){
                        await Swal.fire({
                            icon: "success",
                            text: "The query was successful but got no results. Expand your search parameters perhaps?",
                            title: "Zero results"
                        })

                        setJobCount(0)
                    }else if(Object.keys(data).length ==1){

                        setJobs(data);

                        setJobCount(Object.keys(data).length)

                    }else{
                        setJobs(data)

                        setJobCount(Object.keys(data).length)
                    }

                }else{

                    await Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: message
                    })
                }

            }catch (error) {

                if (retries < maxRetries) {
                    retries++;

                    await Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `Server unreachable. Retrying (${retries}/${maxRetries}) in ${delay / 2000} seconds...`
                    })

                    setTimeout(runTrySegment, delay);
                } else {
                    await Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `Exceeded maximum attempts (${maxRetries}). Unable to continue, please refresh manually.`
                    })
                }

            }

        };

        runTrySegment();

    }

    useEffect(()=> {
        if(countyId !=0){
            getAllTowns();
        }

        getServices();

    }, [countyId, townId, applicationId, serviceId]);

    const getPartner = async (partner: any) =>{

        const url = base_url + "/facilities/partner"

        try{

            let response = await axios.post(url, {
                "partner": partner
            })

            const {status, message, data} = response.data

            if(status == "success"){

                setPartner(data)
                setStep(2)

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message + partner
                })

                return

            }

        }catch{

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Server offline."
            })

            return

        }


    }

    const engageService = async (service: any, partner: any)=> {

        setCservice(service)
        setCpartner(partner)

        setStep(3)

    }

    const createServiceRequest = async () => {

        let url = base_url + "/collaborations/send-request"

        clinic_id = 1

        setIsLoading(true)

        try {

            const formData = new FormData()

            formData.append("clinic_id", clinic_id)
            formData.append("record_id", vrecordNo)
            formData.append("partner_id", cpartner)
            formData.append("patient_id", patientCode)
            formData.append("service_id", cservice)
            formData.append("contract_type", contractType)
            formData.append("urgency", urgency)
            formData.append("start_date", startDate)
            formData.append("end_date", endDate)
            formData.append("terms", terms)
            formData.append("requirements", requirements)

            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if(status=="success"){

                setRequestId(data.requestId)

                await Swal.fire({
                    icon: "success",
                    title: "Request Sent",
                    text: "You collaboration request has been sent to "+ partner.partner
                })

                return


            }else{

                await Swal.fire({
                    icon: "warning",
                    title: "Fail",
                    text: message
                })

                return

            }

        }catch{
            await Swal.fire({
                icon: "warning",
                title: "Disconnect",
                text: "You are disconnected from the server"
            })

            return
        }
    }

    return (
        <>
            <div className={"row mt-4"}>
                <div className={"col"}>
                    <div className={"container-fluid"}>

                        {step == 1 && (
                            <>
                                <div className={"row"}>
                                    <div className={"col"}>

                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search" placeholder="Type something" />
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className="col ms-auto">
                                                        <div>
                                                            <select className="form-control" value={categoryId} onChange={(e)=>{
                                                                const selectedCat = parseInt(e.currentTarget.value);
                                                                setCategoryId(selectedCat)
                                                            }}>

                                                                <option value="99999999">All</option>
                                                                {
                                                                    Object.values(categories).map((item)=>(
                                                                        <option key={item.service_id}>{item.service_title}</option>
                                                                    ))
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>
                                                        <select className="form-control" value={countyId}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const selectedCountyId = parseInt(e.currentTarget.value);
                                                                    setCountyId(selectedCountyId);

                                                                }}
                                                        >
                                                            <option >Select County</option>
                                                            {
                                                                Object.values(counties).map((item) => (
                                                                    <option  value={item.county_code}> {item.county_code} : {item.county} </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={townReady} onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>{ const selectedTown = parseInt(e.currentTarget.value); setTownId(selectedTown) }}>
                                                            <option >Town</option>
                                                            {
                                                                Object.values(towns).map((item) => (
                                                                    <option value={item.town_id} > {item.town} </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">
                                                            <button type="button" className="btn btn-danger"><i className="ri-equalizer-fill me-1 align-bottom"> </i> Filters </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className={"row"}>
                                    {
                                        jobs.map((item)=>(
                                            <>
                                                <div className={"col-md-3"}>

                                                    <div className="card">
                                                        <div className="card-body text-center p-4">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/companies/img-2.png`} alt="" height="45" />
                                                            <h5 className="mb-1 mt-4"><a onClick={(e:any)=>{e.preventDefault()}} className="link-primary">{item.clinic_name}</a>
                                                            </h5>
                                                            <p className="text-muted mb-4">{item.category.cat}</p>
                                                            <div className="row justify-content-center">
                                                                <div className="col-lg-8">

                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-lg-6 border-end-dashed border-end">
                                                                    <h5>2</h5>
                                                                    <span className="text-muted">Engaged</span>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <h5>{item.clinic_capacity}</h5>
                                                                    <span className="text-muted">Capacity</span>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <button className={"btn btn-rounded btn-outline-primary w-100"} onClick={(e: any)=>{getPartner(item.clinic_id)}}> Load Partner</button>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                        ))
                                    }
                                </div>
                            </>
                        )}
                        {step == 2 && (
                            <>
                                <div className="row">
                                    <div className="col-xxl-4">
                                        <div className="card">
                                            <div className="card-body p-4">
                                                <div>
                                                    <div className="flex-shrink-0 avatar-md mx-auto">
                                                        <div className="avatar-title bg-light rounded">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/companies/img-2.png`} alt="" height="50"/>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 text-center">
                                                        <h5 className="mb-1">{partner.partner}</h5>
                                                        <p className="text-muted">{partner.category.name}</p>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table mb-0 table-borderless">
                                                            <tbody>
                                                            <tr>
                                                                <th><span className="fw-medium">Institution:</span></th>
                                                                <td>Medi Group</td>
                                                            </tr>
                                                            <tr>
                                                                <th><span className="fw-medium">Clinic Type</span></th>
                                                                <td>{partner.category.name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th><span className="fw-medium">Email</span></th>
                                                                <td>{partner.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <th><span className="fw-medium">Website</span></th>
                                                                <td><a href="javascript:void(0);"
                                                                       className="link-primary">{partner.website}</a></td>
                                                            </tr>
                                                            <tr>
                                                                <th><span className="fw-medium">Phone: </span></th>
                                                                <td>{partner.phone}</td>
                                                            </tr>
                                                            <tr>
                                                                <th><span className="fw-medium">Capacity: </span></th>
                                                                <td>{partner.capacity}</td>
                                                            </tr>
                                                            <tr>
                                                                <th><span className="fw-medium">Location</span></th>
                                                                <td>{partner.location.county} County {partner.location.town} Town</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body border-top border-top-dashed p-4">
                                                <div>
                                                    <h6 className="text-muted text-uppercase fw-semibold mb-4">Patient
                                                        Reviews</h6>
                                                    <div>
                                                        <div>
                                                            <div className="bg-light px-3 py-2 rounded-2 mb-2">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1">
                                                                        <div className="fs-16 align-middle text-warning">
                                                                            <i className="ri-star-fill"></i>
                                                                            <i className="ri-star-fill"></i>
                                                                            <i className="ri-star-fill"></i>
                                                                            <i className="ri-star-fill"></i>
                                                                            <i className="ri-star-half-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-shrink-0">
                                                                        <h6 className="mb-0">4.5 out of 5</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <div className="text-muted">Total <span
                                                                    className="fw-medium">87</span>
                                                                    reviews
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mt-3">
                                                            <div className="row align-items-center g-2">
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0">5 star</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="p-1">
                                                                        <div
                                                                            className="progress animated-progress progress-sm">
                                                                            <div className="progress-bar bg-success"
                                                                                 role="progressbar" style={{width: "50.16%"}}
                                                                                 aria-valuenow={50.16} aria-valuemin={0}
                                                                                 aria-valuemax={100}> </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0 text-muted">44</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center g-2">
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0">4 star</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="p-1">
                                                                        <div
                                                                            className="progress animated-progress progress-sm">
                                                                            <div className="progress-bar bg-success"
                                                                                 role="progressbar" style={{width: "29.32%"}}
                                                                                 aria-valuenow={29.32} aria-valuemin={0}
                                                                                 aria-valuemax={100}> </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0 text-muted">21</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center g-2">
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0">3 star</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="p-1">
                                                                        <div
                                                                            className="progress animated-progress progress-sm">
                                                                            <div className="progress-bar bg-warning"
                                                                                 role="progressbar" style={{width: "18.12%"}}
                                                                                 aria-valuenow={18.12} aria-valuemin={0}
                                                                                 aria-valuemax={100}> </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0 text-muted">10</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center g-2">
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0">2 star</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="p-1">
                                                                        <div
                                                                            className="progress animated-progress progress-sm">
                                                                            <div className="progress-bar bg-success"
                                                                                 role="progressbar" style={{width: "4.98%"}}
                                                                                 aria-valuenow={4.98} aria-valuemin={0}
                                                                                 aria-valuemax={100}> </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0 text-muted">2</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row align-items-center g-2">
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0">1 star</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="p-1">
                                                                        <div
                                                                            className="progress animated-progress progress-sm">
                                                                            <div className="progress-bar bg-danger"
                                                                                 role="progressbar" style={{width: "7.42%"}}
                                                                                 aria-valuenow={7.42} aria-valuemin={0}
                                                                                 aria-valuemax={100}> </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="p-1">
                                                                        <h6 className="mb-0 text-muted">1</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body p-4 border-top border-top-dashed">
                                                <h6 className="text-muted text-uppercase fw-semibold mb-4">Patient
                                                    Feedback</h6>

                                                <div className="swiper vertical-swiper" style={{height: "242px"}}>
                                                    <div className="swiper-wrapper">
                                                        <div className="swiper-slide">
                                                            <div className="card border border-dashed shadow-none">
                                                                <div className="card-body">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-sm">
                                                                            <div className="avatar-title bg-light rounded">
                                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/companies/img-1.png`}
                                                                                     alt="" height="30" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <div>
                                                                                <p className="text-muted mb-1 fst-italic">"
                                                                                    Great great facility and extremely
                                                                                    great. "</p>
                                                                                <div
                                                                                    className="fs-11 align-middle text-warning">
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-end mb-0 text-muted">
                                                                                - by <cite title="Source Title">Clinic
                                                                                Facility</cite>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="swiper-slide">
                                                            <div className="card border border-dashed shadow-none">
                                                                <div className="card-body">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`}
                                                                                 alt="" className="avatar-sm rounded" />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <div>
                                                                                <p className="text-muted mb-1 fst-italic">"
                                                                                    Amazing amazing facility, just amazing.
                                                                                    "</p>
                                                                                <div
                                                                                    className="fs-11 align-middle text-warning">
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-half-fill"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-end mb-0 text-muted">
                                                                                - by <cite title="Source Title">User
                                                                                99</cite>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="swiper-slide">
                                                            <div className="card border border-dashed shadow-none">
                                                                <div className="card-body">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 avatar-sm">
                                                                            <div className="avatar-title bg-light rounded">
                                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/companies/img-8.png`}
                                                                                     alt="" height="30" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <div>
                                                                                <p className="text-muted mb-1 fst-italic">"Beautiful
                                                                                    beautiful facility, just beautiful."</p>
                                                                                <div
                                                                                    className="fs-11 align-middle text-warning">
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-line"></i>
                                                                                    <i className="ri-star-line"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-end mb-0 text-muted">
                                                                                - by <cite title="Source Title">User
                                                                                NinetyNine</cite>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="swiper-slide">
                                                            <div className="card border border-dashed shadow-none">
                                                                <div className="card-body">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0">
                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`}
                                                                                 alt="" className="avatar-sm rounded" />
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <div>
                                                                                <p className="text-muted mb-1 fst-italic">"
                                                                                    Great great facility, just great. "</p>
                                                                                <div
                                                                                    className="fs-11 align-middle text-warning">
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-fill"></i>
                                                                                    <i className="ri-star-half-fill"></i>
                                                                                    <i className="ri-star-line"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-end mb-0 text-muted">
                                                                                - by <cite title="Source Title">User
                                                                                Five</cite>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-3">
                                                    <a href="javascript:void(0)" className="link-primary">View All
                                                        Reviews <i
                                                            className="ri-arrow-right-line align-bottom ms-1"></i></a>
                                                </div>
                                            </div>
                                            <div className="card-body p-4 border-top border-top-dashed">

                                                <button className={"btn btn-success mt-2"} onClick={()=>{setStep(1)}}> Back to list</button>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-xxl-8">

                                        <div className="row g-4 mb-3">
                                            <div className="col-sm-auto">
                                                <div>
                                                    <Link to={"/collaborations/invite"} className="btn btn-success"><i className="ri-add-line align-bottom me-1"> </i> Collaboration Invite</Link>
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control" placeholder="Search Common..." />
                                                        <i className="ri-search-line search-icon"> </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-animate mt-4">
                                            <div className={"card-header"} style={{backgroundColor: "#553E93", color: "white"}}>Partner Services</div>
                                            <div className="card-body">

                                                {Object.keys(partner.services).length==0 && (
                                                    <div className={"alert alert-info"}>This partner has not shared their services. </div>
                                                )}

                                                {Object.keys(partner.services).length==1 && (
                                                    <>
                                                        <table className={"table table-striped table-hover"}>
                                                            <thead>
                                                                <tr>
                                                                    <th> ID</th>
                                                                    <th> Service</th>
                                                                    <th> Base(Ksh)</th>
                                                                    <th> Hourly(Ksh)</th>
                                                                    <th> Engage</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{partner.services.id}</td>
                                                                    <td>{partner.services.title}</td>
                                                                    <td>{partner.services.minimum_rate}</td>
                                                                    <td>{partner.services.hourly_rate}</td>
                                                                    <td><button className={"btn btn-primary"} onClick={(e: any)=>{e.preventDefault(); engageService(partner.service_id, partner.partner_id)}}>Engage</button></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}

                                                {Object.keys(partner.services).length >1 &&(
                                                    <>
                                                        <table className={"table table-striped table-hover"}>
                                                            <thead>
                                                                <tr>
                                                                    <th> ID</th>
                                                                    <th> Service</th>
                                                                    <th> Base(Ksh)</th>
                                                                    <th> Hourly(Kshs)</th>
                                                                    <th> Engage</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>

                                                            {partner.services.map((service: any)=>(
                                                                <tr>
                                                                    <td>{service.id}</td>
                                                                    <td>{service.title}</td>
                                                                    <td>{service.minimum_rate}</td>
                                                                    <td>{service.hourly_rate}</td>
                                                                    <td><button className={"btn btn-primary"} onClick={(e: any)=>{e.preventDefault(); engageService(service.title, partner.partner_id)}}>Engage</button></td>
                                                                </tr>
                                                            ))}

                                                            </tbody>
                                                        </table>

                                                    </>
                                                ) }

                                            </div>
                                        </div>

                                        <div className={"card card-animate mt-4"}>
                                            <div className={"card-header"} style={{backgroundColor: "#553E93", color: "white"}} >
                                                Activity In Common
                                            </div>

                                            <div className={"card-body"}>
                                                {Object.keys(partner.collaborations).length == 0 && (
                                                    <>
                                                        <div className={"alert alert-info"}>
                                                            You have no collaborations with this clinic. Select a service above to engage.
                                                        </div>
                                                    </>
                                                )}
                                                {Object.keys(partner.collaborations).length == 1 && (
                                                    <></>
                                                )}

                                                {Object.keys(partner.collaborations).length > 1 && (
                                                    <></>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </>
                        )}

                        {step == 3 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card mt-2"}>
                                            <div className={"card-header"} style={{backgroundColor: "#553E93", color: "white"}}>Request Partnership</div>

                                            <div className={"card-body"}>
                                                <p className="alert alert-info" style={{ display: 'inline', margin: '0' }}>
                                                    Caution! When you submit this form&nbsp;
                                                    <span style={{ display: 'inline-block' }}>
                                                    <h6 style={{ display: 'inline', margin: '0' }}>{partner.partner}</h6>
                                                  </span>
                                                    &nbsp;will be able to access your profile and view the patient record shared
                                                </p>

                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>
                                                        <label className={"form-label mt-3"}>Record Number</label>
                                                        <input className={"form-control mt-2"} value={vrecordNo} onChange={(e:any)=>{setVrecordNo(e.currentTarget.value)}} />
                                                    </div>
                                                    <div className={"col"}>
                                                        <label className={"form-label mt-3"}>Patient Code</label>
                                                        <input className={"form-control mt-2"} value={patientCode} type={"text"} onChange={(e:any)=>{setPatientCode(e.currentTarget.value)}} />
                                                    </div>
                                                </div>
                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>
                                                        <label className={"form-label"}>Contract Type</label>
                                                        <select value={contractType} onChange={(e:any)=>{setContractType(e.currentTarget.value)}} className={"form-control"}>
                                                            <option value={"Long Term"}>Long term</option>
                                                            <option value={"Short Term"} >Short term</option>
                                                            <option value={"One Off"}>One Off</option>
                                                            <option value={"Intermediate"}>Intermediate</option>
                                                        </select>

                                                        <label className={"form-label mt-4"}>Urgency/ Priority</label>
                                                        <select value={urgency} className={"form-control"} onChange={(e: any)=>{setUrgency(e.currentTarget.value)}}>
                                                            <option value={"high"} >High</option>
                                                            <option value={"moderate"} >Moderate</option>
                                                            <option value={"low"} >Low</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className = {"row mt-4"}>
                                                    <div className={"col"}>
                                                        <label className={"form-label"}>Start Date</label>
                                                        <input type={"datetime-local"} value={startDate} onChange={(e:any)=>{setStartDate(e.currentTarget.value)}} className={"form-control"}/>

                                                    </div>

                                                    <div className={"col"}>
                                                        <label className={"form-label"}>End Date</label>
                                                        <input type={"datetime-local"} value={endDate} onChange={(e:any)=>{setEndDate(e.currentTarget.value)}} className={"form-control"}/>

                                                    </div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>

                                                        <label className={"form-label"}>Your Contract Terms</label>
                                                        <CKEditor editor={ClassicEditor} data={terms} onChange={(e:any, editor:any)=>{setTerms(editor.getData)}}/>

                                                    </div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>

                                                        <label className={"form-label"}>Requirement Specifications</label>
                                                        <CKEditor editor={ClassicEditor} data={requirements} onChange={(e:any, editor:any)=>{setRequirements(editor.getData)}}/>

                                                    </div>
                                                </div>

                                                <div className={"row mt-4 justify-items-center"}>
                                                    <div className={"col"}>

                                                        <button className={"btn btn-primary"} onClick={(e:any)=>{setStep(2)}}>Go back</button>

                                                    </div>

                                                    <div className={"col"}>

                                                        <button className={"btn btn-warning"}>Clear Fields</button>
                                                    </div>

                                                    <div className={"col"}>
                                                        <button className={"btn btn-success"} disabled={isLoading} onClick={createServiceRequest}>Send Request</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchClinic;