import React, {useEffect, useRef, useState} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";
import Canvas from "./dahboard_canvas";
import axios from "axios";
import Swal from 'sweetalert';
import localforage from "localforage";
import {useBaseUrl} from "../../utils/utils";


const AddClinic: React.FC = () => {
    const [editorData, setEditorData] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [counties, setCounties] = useState<any[]>([]);
    const [towns, setTowns] = useState<any[]>([]);
    const [selectedCounty, setSelectedCounty] = useState('');
    const [institutionId, setInstitutionId] = useState<number | null>(null);

    const base_url = useBaseUrl()

    useEffect(() => {
        const fetchInstitute = async () => {
            try{
                const id = await localforage.getItem<number>("institution_id");
                setInstitutionId(id || null);
            } catch (error){
                await Swal('Critical Error!', 'We were unable to determine important data about your account. All functions deactivated', 'error');
            }
        }
    })

    useEffect(() => {
        FetchCounties();
    }, []);

    const FetchCounties = () => {
        axios
            .get(`${base_url}/counties`)
            .then(response => {
                const data = response.data;
                if (data.status === 'success') {
                    setCounties(data.data);
                } else {
                    showAlert('Error', data.message, 'error');
                }
            })
            .catch(error => {
                Swal("Error", 'An error occurred while fetching counties.', 'error');
            });
    };

    const FetchTowns = (countyCode: string) => {
        axios
            .post(`${base_url}/towns`, { county_code: countyCode })
            .then(response => {
                const data = response.data;
                if (data.status === 'success') {
                    setTowns(data.data);
                } else {
                    showAlert('Error', data.message, 'error');
                }
            })
            .catch(error => {
                Swal("Error", 'An error occurred while fetching towns .', 'error');
            });
    };

    const handleCountyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountyCode = event.target.value;
        setSelectedCounty(selectedCountyCode);
        FetchTowns(selectedCountyCode);
    };

    const showAlert = (title: string, message: string, type: string) => {

    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
        setPasswordsMatch(event.target.value === confirmPassword);
    };

    const handleConfirmPasswordChange = (event: any) => {
        setConfirmPassword(event.target.value);
        setPasswordsMatch(event.target.value === password);
    };

    const handleEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setEditorData(data);
    }
    const clearFields = (event: any) => {
        const formData = new FormData(event.target);
        event.target.reset();
    }

    const HandleSubmit = async (event: any) => {
        let url = `${base_url}/facilities/new-clinic`;
        event.preventDefault();

        if(password !== confirmPassword){
            setPasswordsMatch(false);
        }

        try{
            const formData = new FormData(event.target);
            const response = await axios.post(url, formData);

            if(response.data.status == 'success'){
                await Swal('Success', 'Clinic registration successfully submitted. Your admin will approve or reject.', 'success');

                event.target.reset();

            }else{
                await Swal('Error', response.data.message, 'error');
            }
        } catch (error) {
            await Swal('Denied', 'The API rejected your request to create a clinic because you are not authorized by your institution to add clinics on their behalf.', 'error');
        }
    }
    return(
        <>
            <div id="layout-wrapper">
                <DashboardHeader></DashboardHeader>

                <div id="removeNotificationModal" className="modal fade zoomIn" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                        id="NotificationModalbtn-close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mt-2 text-center">
                                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                        <h4>Are you sure ?</h4>
                                        <p className="text-muted mx-4 mb-0">Are you sure you want to remove this
                                            Notification ?</p>
                                    </div>
                                </div>
                                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                    <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="button" className="btn w-sm btn-danger" id="delete-notification">
                                        Yes, Delete It!
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <SideMenu></SideMenu>

                <div className="vertical-overlay">

                </div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid  ">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Clinics: Create New</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a
                                                    href="/institution/home">Clinic</a></li>
                                                <li className="breadcrumb-item active">List</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className={"alert alert-info"}>Soft Warning: The API classified you as a guest user automatically.</div>
                            <form>
                                <div className={"card card-animate"}>
                                    <div className="card-header d-flex align-items-center">
                                        <h5 className="card-title flex-grow-1 mb-0">Clinic details</h5>
                                        <div className="d-flex gap-1 flex-wrap">
                                            <button className="btn btn-soft-danger" onClick={clearFields} ><i className="ri-delete-bin-2-line"> </i> Clear Fields</button>
                                            <button  onClick={HandleSubmit} className="btn btn-primary create-btn" ><i className="ri-add-line align-bottom me-1"> </i> Add Clinic</button>
                                        </div>
                                    </div>
                                    <div className={"card-body"}>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <div className={"container-fluid"}>

                                                    <label className={"form-label"} htmlFor={"clinic_name"}>Clinic Name</label>
                                                    <input type={"text"} id={"clinic_name"} name={"clinic_name"} required={true} className={"form form-control"}/>

                                                    <label className={"form-label mt-3"} htmlFor={"county"}>Clinic County</label>
                                                    <select className={"form-control"} required={true} name={"county"} id={"county"} onChange={handleCountyChange} value={selectedCounty}>
                                                        <option>Select County</option>
                                                        {
                                                            counties.map(county =>  (
                                                                <option key={county.county_code} value={county.county_code}> {county.county} </option>
                                                            ))
                                                        }
                                                    </select>

                                                    <label className={"form-label mt-3"} htmlFor={"town"}>Clinic Town</label>
                                                    <select className={"form-control"} required={true} name={"town"} id={"town"} disabled={!selectedCounty}>
                                                        <option>Select Town</option>
                                                        {towns.map(town => (
                                                            <option key={town.town_id} value={town.town_id}>
                                                                {town.town}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <label className={"form-label mt-3"} htmlFor={"website"}>Clinic Website</label>
                                                    <input type={"text"} id={"website"} name={"website"} className={"form form-select"}/>

                                                    <label className={"form-label mt-3"} htmlFor={"logo"}>Clinic Logo</label>
                                                    <input className={"form-control "} id={"logo"} type={"file"} accept={"image/png, image/jpeg"} name={"logo"} />

                                                    <label className={"form-label mt-3"} htmlFor={"email"}>Clinic Email</label>
                                                    <input type={"email"} id={"email"} name={"email"} className={"form form-select"}/>

                                                    <label className={"form-label mt-3"} htmlFor={"postcode"}>Clinic Postcode</label>
                                                    <input type={"number"} name={"postcode"} id={"postcode"} className={"form-control"}/>

                                                </div>

                                            </div>
                                            <div className={"col"}>

                                                <input type={"hidden"} name={"institution_id"} value={institutionId ?? ""}/>
                                                <div className={"mt-4"}></div>
                                                <CKEditor editor={ClassicEditor} data="<p>Clinic Description goes here</p>" onChange={handleEditorChange} />

                                                <label className={"form-label mt-3"} htmlFor={"username"}>Clinic Username/Codename</label>
                                                <input type={"text"} name={"username"} id={"username"} className={"form-control"}/>

                                                <label className="form-label mt-3" htmlFor="password"> Clinic Password</label>
                                                <input type={"password"} name={"password"} id={"password"} className={"form-control"} value={password} onChange={handlePasswordChange}/>

                                                <label className="form-label mt-3" htmlFor="passwordc"> Confirm Password</label>
                                                <input type="password" name="passwordc" id="passwordc" className="form-control" value={confirmPassword} onChange={handleConfirmPasswordChange}/>

                                                {!passwordsMatch && (
                                                    <div className="alert alert-danger mt-3">
                                                        Passwords do not match.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>


                        </div>
                    </div>
                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <Canvas></Canvas>
        </>
    )
}

export default AddClinic;