import React, {useEffect, useState} from "react";
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import {usePostData, useGetData, useFacilityServices, useBaseUrl} from "../../utils/utils";
import {unsubscribe} from "diagnostics_channel";
import localforage from "localforage";

const ResourcesInit: React.FC = () => {

    const [step, setStep] = useState<number>(0)
    const [subStep, setSubStep] = useState(0)
    const [clinicName, setClinicName] = useState<any>("")
    const [title, setTitle] = useState("Medical Reports")
    const [reportSearch, setReportSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [sortField, setSortField] = useState('complaints.date');
    const [sortOrder, setSortOrder] = useState('asc');

    {/*Reports*/}
    const [treatment, setTreatment] = useState<any[]>([])

    useEffect(()=>{

        document.title = "Uzima - Medical Reports"

        localforage.getItem("clinic_name").then((clini)=>{
            setClinicName(clini)
        })

    }, [])

    const GetTreatmentReports = async (event: any) => {
        event.preventDefault()

        let url = `${useBaseUrl()}/reports/treatment-reports`

        const formData = new FormData

        const reports = await usePostData(url, formData)

        setTreatment(reports)
    }

    const downloadFile = () => {

        const fileUrl = `${process.env.PUBLIC_URL}/assets/docs/Open_clinic_methodology.pdf`;


        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'Uzima_downloads_open_clinic_methodology.pdf';


        link.click();


        link.remove();
    };

    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>

                <div className={"page-content"}>

                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0  ">{clinicName} - {title} &nbsp;
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href="javascript: void(0);">Dashboards</a></li>
                                            <li className="breadcrumb-item active">Resource Center</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {step === 0 && (
                            <>
                                <div className={"row mt-4"}>

                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                            setStep(1)
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/reports.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Reports</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                            setTitle("Resources Downloads")
                                            setStep(2)

                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/downloads.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Downloads</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                            setStep(3)
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/protocols.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Guidelines</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                            setStep(4)
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/notices.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Notices</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                            setStep(5)
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/webinars.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Webinars</h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className={"row"}>

                                    <div className={"col"}>
                                        <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                            setStep(6)
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/forum.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Forum</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col"}>
                                        <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                            setStep(6)
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/iot.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">IoT Integration</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col"}> &nbsp; </div>
                                    <div className={"col"}> &nbsp; </div>
                                    <div className={"col"}>
                                        &nbsp;
                                    </div>
                                </div>
                            </>
                        )}

                        {step === 1 && (
                            <section className={"reports"}>
                                {subStep===0 && (
                                    <section className={"report-types"}>

                                        <div className={"row"}>
                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(1)
                                                    setTitle("Treatment Reports")
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/treatment.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Treatment</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/consultation.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Consultation</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(3)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/diagnostic.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Diagnostic</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(4)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/operative.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Operative</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(5)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/radiology.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Radiology</h5>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(6)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/pathology.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Pathology</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(7)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/notes.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Progress</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(8)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/discharge.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Discharge</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(8)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/referral.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Referral</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setSubStep(0)
                                                    setStep(0)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/reports.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Go Back</h5>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </section>
                                )}

                                {subStep===1 && (
                                    <section className={"treatment-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Attendee</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={5} className={"text-danger"}>

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={5} className={"text-danger"}>
                                                                    There are currently no reports to be displayed. Generate one and it will appear here
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={5} className={"text-danger"}>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 2 && (
                                    <section className={"consultation-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Doctor</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 3 && (
                                    <section className={"diagnosis-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Diagnosis</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 4 && (
                                    <section className={"operative-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Operation</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 5 && (
                                    <section className={"radiology-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Radiologist</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 6  && (
                                    <section className={"pathology-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Pathologists</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 7 &&(
                                    <section className={"treatment-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Doctor</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 8 && (
                                    <section className={"treatment-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Discharged By</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}
                                {subStep === 9 && (
                                    <section className={"treatment-reports"}>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-9"}>
                                                <input value={reportSearch} placeholder={"Enter report code..."} className={"form-control"} onChange={(e)=>{
                                                    setReportSearch(e.currentTarget.value)
                                                }} />
                                            </div>

                                            <div className={"col-md-3"}>

                                                <button className={"btn btn-primary"} style={{width: "100%"}}> <i className={"bx bx-search"}> </i> &nbsp; Search Report</button>

                                            </div>
                                        </div>

                                        <div className={"row mt-3"}>

                                            <div className={"col"}>

                                                <div className={"card"}>
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <i className={"bx bx-image"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Avatar</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-user"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Patient</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-clinic"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Referee</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-calendar"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Date</h6>
                                                            </th>
                                                            <th>
                                                                <i className={"bx bx-file"}> </i>
                                                                &nbsp; &nbsp;
                                                                <h6 style={{display: "inline"}}>Action</h6>
                                                            </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>
                                                                There are currently no reports to be displayed. Generate one and it will appear here
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={"text-danger"}>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>

                                        <div className={"row mt-1 paginations"}>
                                            <div className={"col"} style={{width: "100%"}}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setTitle("Medical Reports")
                                                    setSubStep(0)
                                                }}>Back to Reports</button>
                                            </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                            <div className={"col"}> </div>

                                        </div>


                                    </section>
                                )}

                            </section>
                        )}

                        {step === 2 && (
                            <section>

                                <div className={"row mt-4"}>

                                    <div className={"col"}>

                                        <table className={"table table-striped table-dark"}>

                                            <thead>
                                                <tr>
                                                    <th>
                                                        <i className="bx bx-key"> </i>
                                                        Index
                                                    </th>
                                                    <th>
                                                        <i className="bx bx-file"> </i>
                                                        File
                                                    </th>
                                                    <th>
                                                        <i className={"bx bx-calendar"}> </i>
                                                        Created
                                                    </th>
                                                    <th>
                                                        <i className={"bx bx-bell-minus"}> </i>
                                                        Expiry
                                                    </th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><a href={"#"}> #009BCX</a></td>
                                                    <td><img style={{maxHeight: "20px"}} src={`${process.env.PUBLIC_URL}/assets/images/pdf.png`}/> Open Clinic Research Methodology</td>
                                                    <td>July 15, 2023</td>
                                                    <td>August 15, 2023</td>
                                                    <td><button onClick={()=>{
                                                        downloadFile()
                                                    }} className={"btn btn-primary"}>
                                                        Download
                                                    </button></td>

                                                </tr>

                                                <tr>
                                                    <td><a href={"#"}> #009BCX</a></td>
                                                    <td><img style={{maxHeight: "20px"}} src={`${process.env.PUBLIC_URL}/assets/images/pdf.png`}/> Open Research Methodology</td>
                                                    <td>July 15, 2023</td>
                                                    <td>August 15, 2023</td>
                                                    <td><button className={"btn btn-primary"}>
                                                        Download
                                                    </button></td>

                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                            </section>
                        )}

                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <canvas></canvas>

        </>
    )
}

export default  ResourcesInit