import React, {useEffect, useState} from "react";
import PartnerMenu from "../layouts/partner_sidemenu";
import PartneHeader from "../layouts/partner_header";
import DashboardFooter from "../../institutions/layouts/dashboard_footer";
import Canvas from "../../institutions/layouts/dahboard_canvas";
import Swal from "sweetalert2";
import axios from "axios";
import FormData from "form-data";
import localforage from "localforage";


const PartnerHome: React.FC = () => {

    const [partnerId, setPartnerID] = useState<any>("Undefined")
    const [partnerName, setPartnerName] = useState<any>("Undefined")
    const [partnerCat, setPartnerCat] = useState<any>("Undefined")
    const [partnerLogo, setPartnerLogo] = useState<any>("Undefined")
    const [segment, setSegment] = useState("Home")

    const [init, setInit] = useState("normal")

    const base_url = "http://localhost:8000"

    useEffect(()=>{
        document.title = "Uzima Partners"
    },[])

    const get_partner = async (partner_id: any) =>{

        try{

            const form_data = new FormData()
            form_data.append("partner_id", partner_id)

            let url = base_url + "/facilities/partners/get-partner"

            const partner = await axios.post(url, form_data)

            const {status, message, data} = partner.data

            if(status=="success"){

                await localforage.setItem("partner_name", data.partner.name)

            }else{

                setInit("fail")

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })


            }


        }catch (e){

            setInit("fail")

            Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "We were unable to fetch your details. please refresh this page"
            })


        }


    }

    const get_notis = async (partner_id: any) => {


    }

    return (
        <>
            <PartneHeader partner_name={partnerName} partner_id={partnerId} partner_cat={partnerCat} partner_logo={partnerLogo}></PartneHeader>
            <PartnerMenu></PartnerMenu>

            <div className={"vertical-overlay"}>

            </div>

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        {init=="normal" && (
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <div
                                            className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 className="mb-sm-0">Partners - {segment}</h4>

                                            <div className="page-title-right">
                                                <ol className="breadcrumb m-0">
                                                    <li className="breadcrumb-item"><a
                                                        href={"/institution/erh"}>Partners</a></li>
                                                    <li className="breadcrumb-item active">Dashboard</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card card-animate"}>

                                            <div className={"card-header"}>

                                            </div>

                                            <div className={"card-body"}>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card card-animate"}>

                                            <div className={"card-header"}>

                                            </div>

                                            <div className={"card-body"}>

                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col"}>

                                        <div className={"card card-animate"}>

                                            <div className={"card-header"}>

                                            </div>

                                            <div className={"card-body"}>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className={"card card-animate"}>

                                            <div className={"card-header"}>

                                            </div>

                                            <div className={"card-body"}>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {init=="fail" && (
                            <>
                                "You are seeing this page because we were unable to load your details thus you can't proceed to the next page. If refreshing the page fails please contact our technical team for help."
                            </>
                        )}

                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <Canvas></Canvas>
        </>
    )
}

export default PartnerHome