import React from 'react'

interface Props {
    mainStep: any
}

const EquipmentFind: React.FC<Props> = ({mainStep}) => {

    const changeParentStep = () => {
        mainStep(0)
    }
    return (
        <>

            

        </>
    )

}

export default EquipmentFind