import React, {useEffect, useReducer, useState} from "react"
import {Link, useNavigate} from "react-router-dom";
import {useBaseUrl, useDjangoToReactTime, usePostData} from "../../../utils/utils";
import Swal from "sweetalert2";
import PatientCollaborations from "./PatientCollaborations";



interface patientList {
    patient: any,
    step: number
}

interface InitialState {
    step: number,
    patientRecords: any[],
    parentStep: number,
    subStep: number
}


const PatientProfile: React.FC<patientList> = ({patient, step}) => {

    const [patientRecords, setPatientRecords] = useState<any[]>([])

    const navigate = useNavigate()

    const base_url = useBaseUrl()

    const Get_Patient_Records = async () => {

        let url = `${base_url}/ehr/patient-records`

        const formData = new FormData()

        formData.append("patient_code", patient.patient_code)

        const records = await usePostData(url, formData)

        if(records === undefined || records === "undefined"){

            await Swal.fire({
                icon: "success",
                title: "Zero records",
                text: "We found zero records related to this patient"
            })

        }else{



            setPatientRecords(records)

        }


    }

    useEffect(()=>{
        document.title = `${patient.first_name} ${patient.last_name}: ${patient.patient_code}`

        Get_Patient_Records()

        console.log(patientRecords)
    }, [])

    const initialState: InitialState = {
        step: 0,
        parentStep: step,
        subStep: 0,
        patientRecords: []

    }

    const reducer = (state: any, action: any) => {

        switch (action.type) {

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payoad}

            case "SET_PARENT_STEP":
                return {...state, parentStep: action.payload}

            case "SET_PATIENT_RECORDS":
                return {...state, patientRecords: action.payoad}

            default:
                return state

        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const TimeSwitcher = (djTime: any) => {

        return useDjangoToReactTime(djTime)

    }

    return(
        <>
            {state.step === 0 && (
                <>
                    <div className={"row mt-3"}>

                        <div className={"col-md-3"}>
                            <div className={"card card-animate"} onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 1})
                            }} >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/active.png`}
                                            alt=""
                                            className="img-fluid "
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Collaborations</h5>



                                </div>

                            </div>
                        </div>
                        <div className={"col-md-3"}>
                            <div className={"card card-animate"} onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 2})
                            }} >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/hystory.png`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Treatment History</h5>


                                </div>

                            </div>
                        </div>

                        <div className={"col-md-3"}>
                            <div className={"card card-animate"} onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 3})
                            }} >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/bylling.png`}
                                            alt=""
                                            className="img-fluid "
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Billing History</h5>


                                </div>

                            </div>
                        </div>

                        <div className={"col-md-3"}>
                            <div className={"card card-animate"} onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 4})
                            }} >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/invoice.png`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Invoices</h5>


                                </div>

                            </div>
                        </div>

                        <div className={"col-md-3"}>
                            <div className={"card card-animate"} onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 5})
                            }} >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/records.png`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Patient Records <span>10</span></h5>


                                </div>

                            </div>
                        </div>

                        <div className={"col-md-3"}>
                            <div className={"card card-animate"} onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 6})
                            }} >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/details.png`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Patient Details</h5>


                                </div>

                            </div>
                        </div>

                        <div className={"col-md-3"}>
                            <div className={"card card-animate"} onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 7})
                            }} >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/report.png`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Patient Reports</h5>


                                </div>

                            </div>
                        </div>

                        <div className={"col-md-3"}>
                            <Link to={"/institution/patients"} className={"card card-animate"}  >

                                <div className="card-body p-4 text-center">

                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/back.png`}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <br />
                                    <h5 className="card-title mb-1">Patient Center</h5>


                                </div>

                            </Link>
                        </div>

                    </div>
                </>
            )}

            {state.step === 1 && (
                <PatientCollaborations patient={patient}/>
            )}

            {state.step === 2 && (
                <>
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <h5>Patient's Treatment History Tree</h5>
                                    <div className="timeline">
                                        {Object.values(patientRecords).map((item: any, index)=>(
                                            <>
                                                <div className={
                                                    index % 2 === 0 ? "timeline-item right" : "timeline-item left"
                                                }>
                                                    <i className="icon ri-stack-line"> </i>
                                                    <div className="date">
                                                        {TimeSwitcher(item.date)}
                                                    </div>
                                                    <div className="content">
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt=""
                                                                     className="avatar-sm rounded" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15">{item.assesment}
                                                                </h5>
                                                                <p className="text-muted mb-2">Presctiption: None</p>
                                                                <p className="text-muted mb-2">Lab Test: Perfect</p>
                                                                <p className="text-muted mb-2">Assessment: Balanced</p>
                                                                <p className="text-muted mb-2">Physical: Balanced</p>
                                                                <p className="text-muted mb-2">Psychology: Perfect</p>

                                                                <div className="hstack gap-2">
                                                                    <a className="btn btn-sm btn-light"><span
                                                                        className="me-1">🔥</span> 19</a>
                                                                    <a className="btn btn-sm btn-light"><span
                                                                        className="me-1">🤩</span> 22</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={"row mt-4 mb-2"}>
                            <div className={"col"}>

                                <button className={"btn btn-primary"} onClick={(e)=>{
                                    Get_Patient_Records()
                                }} style={{width: "100%"}}>
                                    Refresh
                                </button>

                            </div>

                            <div className={"col"}>

                                <button className={"btn btn-success"} style={{width: "100%"}}>
                                    Cancel
                                </button>

                            </div>

                            <div className={"col"} >

                                <button className={"btn btn-danger"} onClick={(e)=>{
                                    dispatch({type: "SET_STEP", payload: 0})
                                }} style={{width: "100%"}}>
                                    Previous Step
                                </button>

                            </div>
                        </div>


                    </div>

                </>
            )}

            {state.step === 4 && (
                <>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card" id="invoiceList">
                                <div className="card-header border-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">{patient.first_name} {patient.last_name}' s Invoices</h5>
                                        <div className="flex-shrink-0">
                                            <div className="d-flex gap-2 flex-wrap">
                                                <button className="btn btn-danger" id="remove-actions">
                                                    <i className="ri-delete-bin-2-line"> </i>
                                                </button>
                                                <Link to={"/institution/accounting/create-invoice"} className="btn btn-primary">
                                                    <i className="ri-add-line align-bottom me-1"> </i>
                                                    Generate Invoice
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light-subtle border border-dashed border-start-0 border-end-0">
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-md-3">
                                                <div className="search-box">
                                                    <input type="text" className="form-control search bg-light border-light" placeholder="Search for customer, email, country, status or something..." />
                                                    <i className="ri-search-line search-icon"> </i>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <input type="text" className="form-control bg-light border-light flatpickr-input" id="datepicker-range" placeholder="Select date" />
                                            </div>
                                            <div className="col-md-4">
                                                <div className="input-light">
                                                    <div className="choices" data-type="select-one" tabIndex={0} role="listbox" aria-haspopup={true} aria-expanded={false}>
                                                        <div className="choices__inner">
                                                            <select className="form-control choices__input"  data-choice="active">
                                                                <option value="all" >All</option>
                                                            </select>
                                                        </div>

                                                        <div className="choices__list choices__list--dropdown" aria-expanded="false">
                                                            <div className="choices__list" role="listbox">
                                                                <div id="choices--idStatus-item-choice-5" className="choices__item choices__item--choice choices__placeholder choices__item--selectable is-highlighted" role="option" data-choice="" data-id="5" data-value="" data-select-text="Press to select" data-choice-selectable="" aria-selected="true">Status</div>
                                                                <div id="choices--idStatus-item-choice-1" className="choices__item choices__item--choice is-selected choices__item--selectable" role="option" data-choice="" data-id="1" data-value="all" data-select-text="Press to select" data-choice-selectable="">All</div>
                                                                <div id="choices--idStatus-item-choice-2" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="2" data-value="Cancel" data-select-text="Press to select" data-choice-selectable="">Cancel</div>
                                                                <div id="choices--idStatus-item-choice-3" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="3" data-value="Paid" data-select-text="Press to select" data-choice-selectable="">Paid</div>
                                                                <div id="choices--idStatus-item-choice-4" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="4" data-value="Refund" data-select-text="Press to select" data-choice-selectable="">Refund</div>
                                                                <div id="choices--idStatus-item-choice-6" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="6" data-value="Unpaid" data-select-text="Press to select" data-choice-selectable="">Unpaid</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <button type="button" className="btn btn-primary w-100" >
                                                    <i className="ri-equalizer-fill me-1 align-bottom"> </i> Filters
                                                </button>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap" id="invoiceTable">
                                                <thead className="text-muted">
                                                <tr>
                                                    <th scope="col" style={{"width": "50px;"}}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    <th className="sort text-uppercase" data-sort="invoice_id">ID</th>
                                                    <th className="sort text-uppercase" data-sort="customer_name">Customer</th>
                                                    <th className="sort text-uppercase" data-sort="email">Email</th>
                                                    <th className="sort text-uppercase" data-sort="country">County</th>
                                                    <th className="sort text-uppercase" data-sort="date">Date</th>
                                                    <th className="sort text-uppercase" data-sort="invoice_amount">Amount</th>
                                                    <th className="sort text-uppercase" data-sort="status">Payment Status</th>
                                                    <th className="sort text-uppercase" data-sort="action">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody className="list form-check-all" id="invoice-list-data"><tr>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="chk_child" value="#VL25000351" />
                                                        </div>
                                                    </th>
                                                    <td className="id"><Link to="/institution/accounting/invoice"  data-id="25000351" className="fw-medium link-primary">#VL25000351</Link></td>
                                                    <td className="customer_name">
                                                        <div className="d-flex align-items-center">
                                                            <img src="assets/images/users/avatar-1.jpg" alt="" className="avatar-xs rounded-circle me-2" />Uzima User
                                                        </div>
                                                    </td>
                                                    <td className="email">user***@gmail.com</td>
                                                    <td className="country">Naairobi(047)</td>
                                                    <td className="date">23 Mar, 2023 <small className="text-muted">9:58 PM</small></td>
                                                    <td className="invoice_amount">KSH. 2943</td>
                                                    <td className="status"><span className="badge bg-success-subtle text-success text-uppercase">Paid</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-more-fill align-middle"></i>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                <li><button className="dropdown-item"  data-id="25000351"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                                                    View</button></li>
                                                                <li><button className="dropdown-item"  data-id="25000351"><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                                    Edit</button></li>
                                                                <li><a className="dropdown-item" ><i className="ri-download-2-line align-bottom me-2 text-muted"></i>
                                                                    Download</a></li>
                                                                <li className="dropdown-divider"></li>
                                                                <li>
                                                                    <a className="dropdown-item remove-item-btn" data-bs-toggle="modal" href="#deleteOrder">
                                                                        <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                                                        Delete
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="noresult" style={{"display": "none"}}>
                                                <div className="text-center">
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ invoices We did not find any invoices for you search.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end mt-3">
                                            <div className="pagination-wrap hstack gap-2" style={{"display": "flex"}}>
                                                <a className="page-item pagination-prev disabled" href="#">
                                                    Previous
                                                </a>
                                                <ul className="pagination listjs-pagination mb-0"><li className="active"><a className="page" href="#" data-i="1" data-page="8">1</a></li><li><a className="page" href="#" data-i="2" data-page="8">2</a></li><li><a className="page" href="#" data-i="3" data-page="8">3</a></li></ul>
                                                <a className="page-item pagination-next" href="#">
                                                    Next
                                                </a>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )}

        </>
    )
}

export default PatientProfile