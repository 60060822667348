import React, {useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";

const FileManager: React.FC = () => {
    const [showButton, setShowButton] = useState(true);

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const ScrollFunction = () => {
        if (document.documentElement.scrollTop > 20) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };
    return(
        <>
            <div id="layout-wrapper">
                <DashboardHeader></DashboardHeader>

                <div id="removeNotificationModal" className="modal fade zoomIn" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                        id="NotificationModalbtn-close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mt-2 text-center">
                                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                        <h4>Are you sure ?</h4>
                                        <p className="text-muted mx-4 mb-0">Are you sure you want to remove this
                                            Notification ?</p>
                                    </div>
                                </div>
                                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                    <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="button" className="btn w-sm btn-danger" id="delete-notification">
                                        Yes, Delete It!
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <SideMenu></SideMenu>

                <div className="vertical-overlay">

                </div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">My File Manager - 28% Used</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a
                                                    href="javascript: void(0);">Clinic</a></li>
                                                <li className="breadcrumb-item active">My Files</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                                <div className="file-manager-sidebar">
                                    <div className="p-3 d-flex flex-column h-100">
                                        <div className="mb-3">
                                            <h5 className="mb-0 fw-bold">My Drive</h5>
                                        </div>
                                        <div className="search-box">
                                            <input type="text" className="form-control bg-light border-light"
                                                   placeholder="Search here..." />
                                                <i className="ri-search-2-line search-icon"></i>
                                        </div>
                                        <div className="mt-3 mx-n4 px-4 file-menu-sidebar-scroll" data-simplebar>
                                            <ul className="list-unstyled file-manager-menu">
                                                <li>
                                                    <a data-bs-toggle="collapse" href="#collapseExample" role="button"
                                                       aria-expanded="true" aria-controls="collapseExample">
                                                        <i className="ri-folder-2-line align-bottom me-2"></i> <span
                                                        className="file-list-link">My Drive</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#!"><i
                                                        className="ri-file-list-2-line align-bottom me-2"></i> <span
                                                        className="file-list-link">Documents</span></a>
                                                </li>
                                                <li>
                                                    <a href="#!"><i className="ri-image-2-line align-bottom me-2"></i>
                                                        <span className="file-list-link">Media</span></a>
                                                    <li>
                                                        <a href="#!"><i
                                                            className="ri-history-line align-bottom me-2"></i> <span
                                                            className="file-list-link">Recents</span></a>
                                                    </li>
                                                    <li>
                                                        <a href="#!"><i className="ri-star-line align-bottom me-2"></i>
                                                            <span className="file-list-link">Important</span></a>
                                                    </li>
                                                </li>
                                                <li>
                                                    <a href="#!"><i
                                                        className="ri-delete-bin-line align-bottom me-2"></i> <span
                                                        className="file-list-link">Deleted</span></a>
                                                </li>
                                            </ul>
                                        </div>


                                        <div className="mt-auto">
                                            <h6 className="fs-11 text-muted text-uppercase mb-3">Storage Status</h6>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <i className="ri-database-2-line fs-17"></i>
                                                </div>
                                                <div className="flex-grow-1 ms-3 overflow-hidden">
                                                    <div className="progress mb-2 progress-sm">
                                                        <div className="progress-bar bg-success" role="progressbar"
                                                             style={{width: "25%"}} aria-valuenow={25} aria-valuemin={0}
                                                             aria-valuemax={100}> </div>
                                                    </div>
                                                    <span
                                                        className="text-muted fs-12 d-block text-truncate"><b>47.52</b>GB used of <b>107</b>GB</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="file-manager-content w-100 p-3 py-0">
                                    <div className="mx-n3 pt-4 px-4 file-manager-content-scroll" data-simplebar>
                                        <div id="folder-list" className="mb-2">
                                            <div className="row justify-content-beetwen g-2 mb-3">

                                                <div className="col">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 me-2 d-block d-lg-none">
                                                            <button type="button"
                                                                    className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                                                                <i className="ri-menu-2-fill align-bottom"></i>
                                                            </button>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h5 className="fs-16 mb-0">Folders</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="d-flex gap-2 align-items-start">
                                                        <select className="form-control" data-choices
                                                                data-choices-search-false name="choices-single-default"
                                                                id="file-type">
                                                            <option value="">File Type</option>
                                                            <option value="All" selected>All</option>
                                                            <option value="Images">Images</option>
                                                            <option value="Documents">Documents</option>
                                                        </select>

                                                        <button
                                                            className="btn btn-success w-sm create-folder-modal flex-shrink-0"
                                                            data-bs-toggle="modal" data-bs-target="#createFolderModal">
                                                            <i className="ri-add-line align-bottom me-1"></i> Create
                                                            Folders
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row" id="folderlist-data">
                                                <div className="col-xxl-3 col-6 folder-card">
                                                    <div className="card bg-light shadow-none" id="folder-1">
                                                        <div className="card-body">
                                                            <div className="d-flex mb-1">
                                                                <div
                                                                    className="form-check form-check-danger mb-3 fs-15 flex-grow-1">
                                                                    <input className="form-check-input" type="checkbox"
                                                                           value="" id="folderlistCheckbox_1" checked />
                                                                        <label className="form-check-label"
                                                                               htmlFor="folderlistCheckbox_1"></label>
                                                                </div>
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-ghost-primary btn-icon btn-sm dropdown"
                                                                        type="button" data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="ri-more-2-fill fs-16 align-bottom"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                                        <li><a className="dropdown-item view-item-btn"
                                                                               href="javascript:void(0);">Open</a></li>
                                                                        <li><a
                                                                            className="dropdown-item edit-folder-list"
                                                                            href="#createFolderModal"
                                                                            data-bs-toggle="modal"
                                                                            role="button">Rename</a></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="#removeFolderModal"
                                                                               data-bs-toggle="modal"
                                                                               role="button">Delete</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="text-center">
                                                                <div className="mb-2">
                                                                    <i className="ri-folder-2-fill align-bottom text-warning display-5"></i>
                                                                </div>
                                                                <h6 className="fs-15 folder-name">Collaborations</h6>
                                                            </div>
                                                            <div className="hstack mt-4 text-muted">
                                                                <span className="me-auto"><b>349</b> Files</span>
                                                                <span><b>4.10</b>GB</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-6 folder-card">
                                                    <div className="card bg-light shadow-none" id="folder-2">
                                                        <div className="card-body">
                                                            <div className="d-flex mb-1">
                                                                <div
                                                                    className="form-check form-check-danger mb-3 fs-15 flex-grow-1">
                                                                    <input className="form-check-input" type="checkbox"
                                                                           value="" id="folderlistCheckbox_2" />
                                                                        <label className="form-check-label"
                                                                               htmlFor="folderlistCheckbox_2"></label>
                                                                </div>
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-ghost-primary btn-icon btn-sm dropdown"
                                                                        type="button" data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="ri-more-2-fill fs-16 align-bottom"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                                        <li><a className="dropdown-item view-item-btn"
                                                                               href="javascript:void(0);">Open</a></li>
                                                                        <li><a
                                                                            className="dropdown-item edit-folder-list"
                                                                            href="#createFolderModal"
                                                                            data-bs-toggle="modal"
                                                                            role="button">Rename</a></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="#removeFolderModal"
                                                                               data-bs-toggle="modal"
                                                                               role="button">Delete</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="text-center">
                                                                <div className="mb-2">
                                                                    <i className="ri-folder-2-fill align-bottom text-warning display-5"></i>
                                                                </div>
                                                                <h6 className="fs-15 folder-name">Documents</h6>
                                                            </div>
                                                            <div className="hstack mt-4 text-muted">
                                                                <span className="me-auto"><b>2348</b> Files</span>
                                                                <span><b>27.01</b>GB</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-6 folder-card">
                                                    <div className="card bg-light shadow-none" id="folder-3">
                                                        <div className="card-body">
                                                            <div className="d-flex mb-1">
                                                                <div
                                                                    className="form-check form-check-danger mb-3 fs-15 flex-grow-1">
                                                                    <input className="form-check-input" type="checkbox"
                                                                           value="" id="folderlistCheckbox_3" />
                                                                        <label className="form-check-label"
                                                                               htmlFor="folderlistCheckbox_3"></label>
                                                                </div>
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-ghost-primary btn-icon btn-sm dropdown"
                                                                        type="button" data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="ri-more-2-fill fs-16 align-bottom"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                                        <li><a className="dropdown-item view-item-btn"
                                                                               href="javascript:void(0);">Open</a></li>
                                                                        <li><a
                                                                            className="dropdown-item edit-folder-list"
                                                                            href="#createFolderModal"
                                                                            data-bs-toggle="modal"
                                                                            role="button">Rename</a></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="#removeFolderModal"
                                                                               data-bs-toggle="modal"
                                                                               role="button">Delete</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="text-center">
                                                                <div className="mb-2">
                                                                    <i className="ri-folder-2-fill align-bottom text-warning display-5"></i>
                                                                </div>
                                                                <h6 className="fs-15 folder-name">Media</h6>
                                                            </div>
                                                            <div className="hstack mt-4 text-muted">
                                                                <span className="me-auto"><b>12480</b> Files</span>
                                                                <span><b>20.87</b>GB</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3 col-6 folder-card">
                                                    <div className="card bg-light shadow-none" id="folder-4">
                                                        <div className="card-body">
                                                            <div className="d-flex mb-1">
                                                                <div
                                                                    className="form-check form-check-danger mb-3 fs-15 flex-grow-1">
                                                                    <input className="form-check-input" type="checkbox"
                                                                           value="" id="folderlistCheckbox_4" checked />
                                                                        <label className="form-check-label"
                                                                               htmlFor="folderlistCheckbox_4"></label>
                                                                </div>
                                                                <div className="dropdown">
                                                                    <button
                                                                        className="btn btn-ghost-primary btn-icon btn-sm dropdown"
                                                                        type="button" data-bs-toggle="dropdown"
                                                                        aria-expanded="false">
                                                                        <i className="ri-more-2-fill fs-16 align-bottom"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                                        <li><a className="dropdown-item view-item-btn"
                                                                               href="javascript:void(0);">Open</a></li>
                                                                        <li><a
                                                                            className="dropdown-item edit-folder-list"
                                                                            href="#createFolderModal"
                                                                            data-bs-toggle="modal"
                                                                            role="button">Rename</a></li>
                                                                        <li><a className="dropdown-item"
                                                                               href="#removeFolderModal"
                                                                               data-bs-toggle="modal"
                                                                               role="button">Delete</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="text-center">
                                                                <div className="mb-2">
                                                                    <i className="ri-folder-2-fill align-bottom text-warning display-5"></i>
                                                                </div>
                                                                <h6 className="fs-15 folder-name">Uzima v1.7.0</h6>
                                                            </div>
                                                            <div className="hstack mt-4 text-muted">
                                                                <span className="me-auto"><b>180</b> Files</span>
                                                                <span><b>478.65</b>MB</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center mb-3">
                                                <h5 className="flex-grow-1 fs-16 mb-0" id="filetype-title">Recent
                                                    File</h5>
                                                <div className="flex-shrink-0">
                                                    <button className="btn btn-success createFile-modal"
                                                            data-bs-toggle="modal" data-bs-target="#createFileModal"><i
                                                        className="ri-add-line align-bottom me-1"></i> Create File
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap mb-0">
                                                    <thead className="table-active">
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">File Item</th>
                                                        <th scope="col">File Size</th>
                                                        <th scope="col">Recent Date</th>
                                                        <th scope="col" className="text-center">Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody id="file-list"></tbody>
                                                </table>
                                            </div>
                                            <ul id="pagination" className="pagination pagination-lg"></ul>
                                            <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
                                                <div className="col-sm">
                                                    <div className="text-muted">Showing<span
                                                        className="fw-semibold">4</span> of <span
                                                        className="fw-semibold">125</span> Results
                                                    </div>
                                                </div>
                                                <div className="col-sm-auto">
                                                    <ul className="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
                                                        <li className="page-item disabled">
                                                            <a href="#" className="page-link">←</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="#" className="page-link">1</a>
                                                        </li>
                                                        <li className="page-item active">
                                                            <a href="#" className="page-link">2</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="#" className="page-link">3</a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a href="#" className="page-link">→</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="file-manager-detail-content p-3 py-0">
                                    <div className="mx-n3 pt-3 px-3 file-detail-content-scroll" data-simplebar>
                                        <div id="folder-overview">
                                            <div
                                                className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed">
                                                <h5 className="flex-grow-1 fw-bold mb-0">Overview</h5>
                                                <div>
                                                    <button type="button"
                                                            className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-overview">
                                                        <i className="ri-close-fill align-bottom"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="simple_dount_chart"
                                                 data-colors='["--vz-info", "--vz-danger", "--vz-primary", "--vz-success"]'
                                                 className="apex-charts mt-3" dir="ltr"></div>
                                            <div className="mt-4">
                                                <ul className="list-unstyled vstack gap-4">
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <div className="avatar-xs">
                                                                    <div
                                                                        className="avatar-title rounded bg-soft-secondary text-secondary">
                                                                        <i className="ri-file-text-line fs-17"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="mb-1 fs-15">Documents</h5>
                                                                <p className="mb-0 fs-12 text-muted">2348 files</p>
                                                            </div>
                                                            <b>27.01 GB</b>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <div className="avatar-xs">
                                                                    <div
                                                                        className="avatar-title rounded bg-soft-success text-success">
                                                                        <i className="ri-gallery-line fs-17"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="mb-1 fs-15">Media</h5>
                                                                <p className="mb-0 fs-12 text-muted">12480 files</p>
                                                            </div>
                                                            <b>20.87 GB</b>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <div className="avatar-xs">
                                                                    <div
                                                                        className="avatar-title rounded bg-soft-warning text-warning">
                                                                        <i className="ri-folder-2-line fs-17"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="mb-1 fs-15">Collaborations</h5>
                                                                <p className="mb-0 fs-12 text-muted">349 files</p>
                                                            </div>
                                                            <b>4.10 GB</b>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0">
                                                                <div className="avatar-xs">
                                                                    <div
                                                                        className="avatar-title rounded bg-soft-primary text-primary">
                                                                        <i className="ri-error-warning-line fs-17"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="mb-1 fs-15">Others</h5>
                                                                <p className="mb-0 fs-12 text-muted">9873 files</p>
                                                            </div>
                                                            <b>33.54 GB</b>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div id="file-overview" className="h-100">
                                            <div className="d-flex h-100 flex-column">
                                                <div
                                                    className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2">
                                                    <h5 className="flex-grow-1 fw-bold mb-0">File Preview</h5>
                                                    <div>
                                                        <button type="button"
                                                                className="btn btn-ghost-primary btn-icon btn-sm fs-16 favourite-btn">
                                                            <i className="ri-star-fill align-bottom"></i>
                                                        </button>
                                                        <button type="button"
                                                                className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-overview">
                                                            <i className="ri-close-fill align-bottom"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="pb-3 border-bottom border-bottom-dashed mb-3">
                                                    <div
                                                        className="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3">
                                                        <div className="display-4 file-icon">
                                                            <i className="ri-file-text-fill text-secondary"></i>
                                                        </div>
                                                    </div>
                                                    <button type="button"
                                                            className="btn btn-icon btn-sm btn-ghost-success float-end fs-16">
                                                        <i className="ri-share-forward-line"></i></button>
                                                    <h5 className="fs-16 mb-1 file-name">html.docx</h5>
                                                    <p className="text-muted mb-0 fs-12"><span className="file-size">0.3 KB</span>, <span
                                                        className="create-date">19 Apr, 2022</span></p>
                                                </div>
                                                <div>
                                                    <h5 className="fs-12 text-uppercase text-muted mb-3">File
                                                        Description :</h5>

                                                    <div className="table-responsive">
                                                        <table className="table table-borderless table-nowrap table-sm">
                                                            <tbody>
                                                            <tr>
                                                                <th scope="row" style={{width: "35%"}}>File Name :</th>
                                                                <td className="file-name">html.docx</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">File Type :</th>
                                                                <td className="file-type">Documents</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Size :</th>
                                                                <td className="file-size">0.3 KB</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Created :</th>
                                                                <td className="create-date">19 Apr, 2022</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Path :</th>
                                                                <td className="file-path">
                                                                    <div
                                                                        className="user-select-all text-truncate">ftp:\projects\src\assets\images
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div>
                                                        <h5 className="fs-12 text-uppercase text-muted mb-3">Share
                                                            Information:</h5>
                                                        <div className="table-responsive">
                                                            <table
                                                                className="table table-borderless table-nowrap table-sm">
                                                                <tbody>
                                                                <tr>
                                                                    <th scope="row" style={{width: "35%"}}>Share Name :
                                                                    </th>
                                                                    <td className="share-name">\\*\Projects</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Share Path :</th>
                                                                    <td className="share-path">ftp:\Documents\</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-auto border-top border-top-dashed py-3">
                                                    <div className="hstack gap-2">
                                                        <button type="button" className="btn btn-soft-primary w-100"><i
                                                            className="ri-download-2-line align-bottom me-1"></i> Download
                                                        </button>
                                                        <button type="button"
                                                                className="btn btn-soft-danger w-100 remove-file-overview"
                                                                data-remove-id="" data-bs-toggle="modal"
                                                                data-bs-target="#removeFileItemModal"><i
                                                            className="ri-close-fill align-bottom me-1"></i> Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade zoomIn" id="createFolderModal" tabIndex={-1}
                                 aria-labelledby="createFolderModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content border-0">
                                        <div className="modal-header p-3 bg-soft-success">
                                            <h5 className="modal-title" id="createFolderModalLabel">Create Folder</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                    id="addFolderBtn-close" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <form autoComplete="off" className="needs-validation createfolder-form"
                                                  id="createfolder-form" noValidate>
                                                <div className="mb-4">
                                                    <label htmlFor="foldername-input" className="form-label">Folder
                                                        Name</label>
                                                    <input type="text" className="form-control" id="foldername-input"
                                                           required placeholder="Enter folder name" />
                                                        <div className="invalid-feedback">Please enter a folder name.
                                                        </div>
                                                        <input type="hidden" className="form-control"
                                                               id="folderid-input" value="" />
                                                </div>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-ghost-success"
                                                            data-bs-dismiss="modal"><i
                                                        className="ri-close-line align-bottom"></i> Close
                                                    </button>
                                                    <button type="submit" className="btn btn-primary"
                                                            id="addNewFolder">Add Folder
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade zoomIn" id="createFileModal" tabIndex={-1}
                                 aria-labelledby="createFileModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content border-0">
                                        <div className="modal-header p-3 bg-soft-success">
                                            <h5 className="modal-title" id="createFileModalLabel">Create File</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                    id="addFileBtn-close" aria-label="Close"> </button>
                                        </div>
                                        <div className="modal-body">
                                            <form autoComplete="off" className="needs-validation createfile-form"
                                                  id="createfile-form" noValidate>
                                                <div className="mb-4">
                                                    <label htmlFor="filename-input" className="form-label">File
                                                        Name</label>
                                                    <input type="text" className="form-control" id="filename-input"
                                                           value="" required placeholder="Enter file name"/>
                                                    <div className="invalid-feedback">Please enter a file name.</div>
                                                    <input type="hidden" className="form-control" id="fileid-input"
                                                           value="" placeholder="Enter file name" />
                                                </div>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-ghost-success"
                                                            data-bs-dismiss="modal"><i
                                                        className="ri-close-line align-bottom"></i> Close
                                                    </button>
                                                    <button type="submit" className="btn btn-primary"
                                                            id="addNewFile">Create
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="removeFileItemModal" className="modal fade zoomIn" tabIndex={-1}
                                 aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close" id="close-removefilemodal"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mt-2 text-center">

                                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                                    <h4>Are you sure ?</h4>
                                                    <p className="text-muted mx-4 mb-0">Are you sure you want to remove
                                                        this item ?</p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                                <button type="button" className="btn w-sm btn-light"
                                                        data-bs-dismiss="modal">Close
                                                </button>
                                                <button type="button" className="btn w-sm btn-danger"
                                                        id="remove-fileitem">Yes, Delete It!
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div id="removeFolderModal" className="modal fade zoomIn" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close" id="close-removeFoldermodal"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mt-2 text-center">

                                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                                    <h4>Are you sure ?</h4>
                                                    <p className="text-muted mx-4 mb-0">Are you sure you want to remove
                                                        this folder ?</p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                                <button type="button" className="btn w-sm btn-light"
                                                        data-bs-dismiss="modal">Close
                                                </button>
                                                <button type="button" className="btn w-sm btn-danger"
                                                        id="remove-folderList">Yes, Delete It!
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <button onClick={topFunction} className="btn btn-danger btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>

            <div id="preloader">
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

            <div className="customizer-setting d-none d-md-block">
                <div className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
                     data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <i className='mdi mdi-spin mdi-cog-outline fs-22'></i>
                </div>
            </div>

            <div className="offcanvas offcanvas-end border-0" tabIndex={-1} id="theme-settings-offcanvas">
                <div className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
                    <h5 className="m-0 me-2 text-white">Uzima Customizer</h5>

                    <button type="button" className="btn-close btn-close-white ms-auto" id="customizerclose-btn"
                            data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-0">
                    <div data-simplebar className="h-100">
                        <div className="p-4">
                            <h6 className="mb-0 fw-bold text-uppercase">Layout</h6>
                            <p className="text-muted">Choose your layout</p>

                            <div className="row gy-3">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout01" name="data-layout" type="radio" value="vertical"
                                               className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout01">
                                    <span className="d-flex gap-1 h-100">
                                        <span className="flex-shrink-0">
                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                            </span>
                                        </span>
                                        <span className="flex-grow-1">
                                            <span className="d-flex h-100 flex-column">
                                                <span className="bg-light d-block p-1"> </span>
                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                            </span>
                                        </span>
                                    </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Vertical</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout02" name="data-layout" type="radio"
                                               value="horizontal" className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout02">
                                                <span className="d-flex h-100 flex-column gap-1">
                                                    <span className="bg-light d-flex p-1 gap-1 align-items-center">
                                                        <span
                                                            className="d-block p-1 bg-soft-primary rounded me-1"> </span>
                                                        <span
                                                            className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"> </span>
                                                        <span className="d-block p-1 pb-0 px-2 bg-soft-primary"> </span>
                                                    </span>
                                                    <span className="bg-light d-block p-1"> </span>
                                                    <span className="bg-light d-block p-1 mt-auto"> </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Horizontal</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout03" name="data-layout" type="radio"
                                               value="twocolumn" className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout03">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1">
                                                            <span className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Two Column</h5>
                                </div>
                                {/* end col */}

                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout04" name="data-layout" type="radio" value="semibox"
                                               className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout04">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0 p-1">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column pt-1 pe-2">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Semi Box</h5>
                                </div>
                                {/* end col */}
                            </div>

                            <h6 className="mt-4 mb-0 fw-bold text-uppercase">Color Scheme</h6>
                            <p className="text-muted">Choose Light or Dark Scheme.</p>

                            <div className="colorscheme-cardradio">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-mode"
                                                   id="layout-mode-light" value="light"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="layout-mode-light">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check card-radio dark">
                                            <input className="form-check-input" type="radio" name="data-layout-mode"
                                                   id="layout-mode-dark" value="dark"/>
                                            <label className="form-check-label p-0 avatar-md w-100 bg-dark"
                                                   htmlFor="layout-mode-dark">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-soft-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-soft-light d-block p-1"> </span>
                                                                <span
                                                                    className="bg-soft-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-visibility">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Visibility</h6>
                                <p className="text-muted">Choose show or Hidden sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio"
                                                   name="data-sidebar-visibility" id="sidebar-visibility-show"
                                                   value="show"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-visibility-show">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0 p-1">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column pt-1 pe-2">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Show</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio"
                                                   name="data-sidebar-visibility" id="sidebar-visibility-hidden"
                                                   value="hidden"/>
                                            <label className="form-check-label p-0 avatar-md w-100 px-2"
                                                   htmlFor="sidebar-visibility-hidden">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column pt-1 px-2">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Hidden</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="layout-width">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Layout Width</h6>
                                <p className="text-muted">Choose Fluid or Boxed layout.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-width"
                                                   id="layout-width-fluid" value="fluid"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="layout-width-fluid">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Fluid</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-width"
                                                   id="layout-width-boxed" value="boxed"/>
                                            <label className="form-check-label p-0 avatar-md w-100 px-2"
                                                   htmlFor="layout-width-boxed">
                                                    <span className="d-flex gap-1 h-100 border-start border-end">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Boxed</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="layout-position">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Layout Position</h6>
                                <p className="text-muted">Choose Fixed or Scrollable Layout Position.</p>

                                <div className="btn-group radio" role="group">
                                    <input type="radio" className="btn-check" name="data-layout-position"
                                           id="layout-position-fixed" value="fixed"/>
                                    <label className="btn btn-light w-sm" htmlFor="layout-position-fixed">Fixed</label>

                                    <input type="radio" className="btn-check" name="data-layout-position"
                                           id="layout-position-scrollable" value="scrollable"/>
                                    <label className="btn btn-light w-sm ms-0"
                                           htmlFor="layout-position-scrollable">Scrollable</label>
                                </div>
                            </div>
                            <h6 className="mt-4 mb-0 fw-bold text-uppercase">Topbar Color</h6>
                            <p className="text-muted">Choose Light or Dark Topbar Color.</p>

                            <div className="row">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar"
                                               id="topbar-color-light" value="light"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="topbar-color-light">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Light</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar"
                                               id="topbar-color-dark" value="dark"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="topbar-color-dark">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-primary d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Dark</h5>
                                </div>
                            </div>

                            <div id="sidebar-size">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Size</h6>
                                <p className="text-muted">Choose a size of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-default" value="lg"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-default">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-compact" value="md"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-compact">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Compact</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-small" value="sm"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-small">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span
                                                                    className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small (Icon View)</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-small-hover" value="sm-hover"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-small-hover">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span
                                                                    className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small Hover View</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-view">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar View</h6>
                                <p className="text-muted">Choose Default or Detached Sidebar view.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style"
                                                   id="sidebar-view-default" value="default"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-view-default">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style"
                                                   id="sidebar-view-detached" value="detached"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-view-detached">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span
                                                            className="bg-light d-flex p-1 gap-1 align-items-center px-2">
                                                            <span
                                                                className="d-block p-1 bg-soft-primary rounded me-1"> </span>
                                                            <span
                                                                className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"> </span>
                                                            <span
                                                                className="d-block p-1 pb-0 px-2 bg-soft-primary"> </span>
                                                        </span>
                                                        <span className="d-flex gap-1 h-100 p-1 px-2">
                                                            <span className="flex-shrink-0">
                                                                <span
                                                                    className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                    <span
                                                                        className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                    <span
                                                                        className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                    <span
                                                                        className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span className="bg-light d-block p-1 mt-auto px-2"> </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Detached</h5>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar-color">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Color</h6>
                                <p className="text-muted">Choose a color of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse"
                                             data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-light" value="light"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-color-light">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary">  </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse"
                                             data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-dark" value="dark"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-color-dark">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-primary d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                    <div className="col-4">
                                        <button
                                            className="btn btn-link avatar-md w-100 p-0 overflow-hidden border collapsed"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient"
                                            aria-expanded="false" aria-controls="collapseBgGradient">
                                             <span className="d-flex gap-1 h-100">
                                                <span className="flex-shrink-0">
                                                    <span
                                                        className="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                                                        <span
                                                            className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                    </span>
                                                </span>
                                                <span className="flex-grow-1">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span className="bg-light d-block p-1"> </span>
                                                        <span className="bg-light d-block p-1 mt-auto"> </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                        <h5 className="fs-13 text-center mt-2">Gradient</h5>
                                    </div>
                                </div>
                                {/* end row */}

                                <div className="collapse" id="collapseBgGradient">
                                    <div className="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">

                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient" value="gradient"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient"> </span>
                                            </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient-2" value="gradient-2"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient-2">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-2"> </span>
                                            </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient-3" value="gradient-3"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient-3">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-3"> </span>
                                            </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient-4" value="gradient-4"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient-4">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-4"> </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-img">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Images</h6>
                                <p className="text-muted">Choose a image of Sidebar.</p>

                                <div className="d-flex gap-2 flex-wrap img-switch">
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-none" value="none"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-none">
                                    <span
                                        className="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
                                        <i className="ri-close-fill fs-20"> </i>
                                    </span>
                                        </label>
                                    </div>

                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-01" value="img-1"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-01">
                                            <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-1.jpg"} alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>

                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-02" value="img-2"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-02">
                                            <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-2.jpg"} alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-03" value="img-3"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-03">
                                            <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-3.jpg"} alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-04" value="img-4"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-04">
                                            <img src="assets/images/sidebar/img-4.jpg" alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div id="preloader-menu">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Preloader</h6>
                                <p className="text-muted">Choose a preloader.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-preloader"
                                                   id="preloader-view-custom" value="enable"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="preloader-view-custom">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                {/* <div id="preloader"> */}
                                                <div id="status"
                                                     className="d-flex align-items-center justify-content-center">
                                                    <div className="spinner-border text-primary avatar-xxs m-auto"
                                                         role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Enable</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-preloader"
                                                   id="preloader-view-none" value="disable"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="preloader-view-none">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Disable</h5>
                                    </div>
                                </div>

                            </div>
                            {/* end preloader-menu */}

                        </div>
                    </div>

                </div>
                <div className="offcanvas-footer border-top p-3 text-center">
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn btn-light w-100" id="reset-layout">Defaults</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FileManager;