import React, {useEffect, useState} from "react";
import logo from './logo-light.png';
import logo_dark from './logo-dark.png';
import logo_sm from './logo-sm.png'
import localforage from "localforage";
import { Link } from "react-router-dom";

const DashboardHeader: React.FC = () => {
    const [fname, setFname] = useState<any>("")
    const [lname, setLname] = useState<any>("")
    const [clinicName, setClinicName] = useState<any>("")
    const [staffId, setStaffId] = useState<any>("")
    const [avatar, setAvatar] = useState<any>("avatar.png")
    const [wallet, setWallet] = useState<any>(0)
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            document.documentElement.requestFullscreen();
        } else {

            document.exitFullscreen();
        }

        setIsFullscreen(!isFullscreen);

    }

    const [currentMode, setCurrentMode] = useState<string>("system")

    const SwitchMode = (currentMode: string) => {

        if(currentMode === 'light'){

            document.documentElement.setAttribute('data-layout-mode', 'dark')

        }else if(currentMode==="dark"){

            document.documentElement.setAttribute('data-layout-mode', 'light')

        }

    }




    useEffect(()=>{

        localforage.getItem("fname").then((first_name)=>{
            setFname(first_name)
        })

        localforage.getItem("lname").then((last_name)=>{
            setLname(last_name)
        })

        localforage.getItem("clinic_name").then((clinic)=>{
            setClinicName(clinic)
        })

        localforage.getItem("staff_id").then((usr)=>{
            setStaffId(usr)
        })

        localforage.getItem("user_avatar").then((avtr)=>{
            if(avtr === "" || avtr === null){
                setAvatar("avatar.png")
            }else{

                setAvatar(avtr)
            }
        })

    }, [])

    return(
        <div>
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            {/* Logo */}
                            <div className="navbar-brand-box horizontal-logo">
                                <a href={"/institutions/home"} className="logo logo-dark" >
                                <span className="logo-sm">
                                    <img src={logo_sm} alt="" height="22" />
                                </span>
                                    <span className="logo-lg">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`} alt="" height="17" />
                                </span>
                                </a>

                                <a href={"/institution/home"} className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logo_sm} alt="" height="22" />
                                </span>
                                    <span className="logo-lg">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/flags/us.svg`} alt="" height="17" />
                                </span>
                                </a>
                            </div>

                            <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
                            <span className="hamburger-icon">
                                <span> </span>
                                <span> </span>
                                <span> </span>
                            </span>
                            </button>

                            <form className="app-search d-none d-md-block">
                                <div className="position-relative">
                                    <input type="text" className="form-control" placeholder="Search..." autoComplete="off" id="search-options" value="" />
                                        <span className="mdi mdi-magnify search-widget-icon"> </span>
                                        <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"> </span>
                                </div>
                            </form>
                        </div>

                        <div className="d-flex align-items-center">

                            <div className="dropdown d-md-none topbar-head-dropdown header-item">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="bx bx-search fs-22">  </i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                     aria-labelledby="page-header-search-dropdown">
                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"> </i> </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="dropdown ms-1 topbar-head-dropdown header-item">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img id="header-lang-img" src={ `${process.env.PUBLIC_URL}/assets/images/flags/us.svg`} alt="Header Language" height="20" className="rounded" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">


                                    <a href="javascript:void(0);" className="dropdown-item notify-item language py-2" data-lang="en" title="English">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flags/us.svg`} alt="user-image" className="me-2 rounded" height="18" />
                                            <span className="align-middle">English</span>
                                    </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item language" data-lang="sp" title="Spanish">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flags/spain.svg`} alt="user-image" className="me-2 rounded" height="18" />
                                            <span className="align-middle">Española</span>
                                    </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item language"
                                       data-lang="gr" title="German">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flags/germany.svg`} alt="user-image" className="me-2 rounded" height="18" />
                                        <span className="align-middle">Deutsche</span>
                                    </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item language"
                                       data-lang="it" title="Italian">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flags/italy.svg`}alt="user-image" className="me-2 rounded" height="18" />
                                            <span className="align-middle">Italiana</span>
                                    </a>

                                    <a href="javascript:void(0);" className="dropdown-item notify-item language"
                                       data-lang="ch" title="Chinese">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flags/china.svg`} alt="user-image" className="me-2 rounded" height="18" />
                                            <span className="align-middle">中国人</span>
                                    </a>


                                    <a href="javascript:void(0);" className="dropdown-item notify-item language"
                                       data-lang="fr" title="French">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/flags/french.svg`} alt="user-image" className="me-2 rounded" height="18" />
                                            <span className="align-middle">Français</span>
                                    </a>
                                </div>
                            </div>


                            <div className="ms-1 header-item d-none d-sm-flex">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" onClick={toggleFullscreen}>
                                    <i className='bx bx-fullscreen fs-22'> </i>
                                </button>
                            </div>

                            <div className="ms-1 header-item d-none d-sm-flex">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                                    <i className='bx bx-moon fs-22'> </i>
                                </button>
                            </div>

                            <div className="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                                    <i className='bx bx-bell fs-22'> </i>
                                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">3
                                        <span className="visually-hidden">unread messages</span></span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                     aria-labelledby="page-header-notifications-dropdown">

                                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                        <div className="p-3">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                                </div>
                                                <div className="col-auto dropdown-tabs">
                                                    <span className="badge badge-soft-light fs-13"> 0 New</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="px-2 pt-2">
                                            <ul className="nav nav-tabs dropdown-tabs nav-tabs-custom"
                                                data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">
                                                <li className="nav-item waves-effect waves-light">
                                                    <a className="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="true">
                                                        All (0)
                                                    </a>
                                                </li>
                                                <li className="nav-item waves-effect waves-light">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="false">
                                                        Messages
                                                    </a>
                                                </li>
                                                <li className="nav-item waves-effect waves-light">
                                                    <a className="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab" aria-selected="false">
                                                        Alerts
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div className="tab-content position-relative" id="notificationItemsTabContent">
                                        <div className="tab-pane fade show active py-2 ps-2" id="all-noti-tab"
                                             role="tabpanel">
                                            <div data-simplebar style={{maxHeight: '300px'}} className="pe-2">
                                                <div className="text-reset notification-item d-block dropdown-item position-relative">

                                                </div>

                                                <div className="text-reset notification-item d-block dropdown-item position-relative">

                                                </div>

                                                <div className="text-reset notification-item d-block dropdown-item position-relative">

                                                </div>

                                                <div className="text-reset notification-item d-block dropdown-item position-relative">

                                                </div>

                                                <div className="my-3 text-center view-all">
                                                    <button type="button"className="btn btn-soft-success waves-effect waves-light">
                                                        View All Notifications <i className="ri-arrow-right-line align-middle"> </i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel"
                                             aria-labelledby="messages-tab">
                                            <div data-simplebar style={{maxHeight: '300px'}} className="pe-2">
                                                <div className="text-reset notification-item d-block dropdown-item">
                                                    <div className="d-flex">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                            <div className="flex-1">
                                                                <a href="#!" className="stretched-link">
                                                                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">James Lemire</h6>
                                                                </a>
                                                                <div className="fs-13 text-muted">
                                                                    <p className="mb-1">We talked about CT-Scan services.</p>
                                                                </div>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i className="mdi mdi-clock-outline"> </i> 30 min ago</span>
                                                                </p>
                                                            </div>
                                                            <div className="px-2 fs-15">
                                                                <div className="form-check notification-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="messages-notification-check01" />
                                                                        <label className="form-check-label" htmlFor="messages-notification-check01"> </label>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>

                                                <div className="text-reset notification-item d-block dropdown-item">
                                                    <div className="d-flex">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                            <div className="flex-1">
                                                                <a href="#!" className="stretched-link">
                                                                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">Angela Bernier</h6>
                                                                </a>
                                                                <div className="fs-13 text-muted">
                                                                    <p className="mb-1">Answered to your comment on the cash flow forecast's graph 🔔.</p>
                                                                </div>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i className="mdi mdi-clock-outline"> </i> 2 hrs ago</span>
                                                                </p>
                                                            </div>
                                                            <div className="px-2 fs-15">
                                                                <div className="form-check notification-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="messages-notification-check02" />
                                                                        <label className="form-check-label" htmlFor="messages-notification-check02"> </label>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>

                                                <div className="text-reset notification-item d-block dropdown-item">
                                                    <div className="d-flex">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-6.jpg`} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                            <div className="flex-1">
                                                                <a href="#!" className="stretched-link">
                                                                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">Admi Brown</h6>
                                                                </a>
                                                                <div className="fs-13 text-muted">
                                                                    <p className="mb-1">
                                                                        Mentioned you in his comment on 📃 invoice #12501.
                                                                    </p>
                                                                </div>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i className="mdi mdi-clock-outline"> </i> 10 hrs ago</span>
                                                                </p>
                                                            </div>
                                                            <div className="px-2 fs-15">
                                                                <div className="form-check notification-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="messages-notification-check03" />
                                                                        <label className="form-check-label" htmlFor="messages-notification-check03"> </label>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>

                                                <div className="text-reset notification-item d-block dropdown-item">
                                                    <div className="d-flex">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-8.jpg`} className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                            <div className="flex-1">
                                                                <a href="#!" className="stretched-link">
                                                                    <h6 className="mt-0 mb-1 fs-13 fw-semibold">Maureen Gibson</h6>
                                                                </a>
                                                                <div className="fs-13 text-muted">
                                                                    <p className="mb-1">We talked about a CT Imaging services.</p>
                                                                </div>
                                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                    <span><i className="mdi mdi-clock-outline"> </i> 3 days ago</span>
                                                                </p>
                                                            </div>
                                                            <div className="px-2 fs-15">
                                                                <div className="form-check notification-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="messages-notification-check04" />
                                                                        <label className="form-check-label" htmlFor="messages-notification-check04"> </label>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>

                                                <div className="my-3 text-center view-all">
                                                    <button type="button" className="btn btn-soft-success waves-effect waves-light">
                                                        View All Messages <i className="ri-arrow-right-line align-middle"> </i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab"> </div>

                                        <div className="notification-actions" id="notification-actions">
                                            <div className="d-flex text-muted justify-content-center">
                                                Select <div id="select-content" className="text-body fw-semibold px-1">0</div>
                                                Result <button type="button" className="btn btn-link link-danger p-0 ms-3" data-bs-toggle="modal" data-bs-target="#removeNotificationModal">Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="dropdown ms-sm-3 header-item topbar-user">
                                <button type="button" className="btn" id="page-header-user-dropdown"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="d-flex align-items-center">
                                    <img className="rounded-circle header-profile-user" src={`${process.env.PUBLIC_URL}/assets/images/users/${avatar}`} alt="Header Avatar" />
                                    <span className="text-start ms-xl-2">
                                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{fname} {lname}</span>
                                        <span
                                            className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{clinicName}
                                        </span>
                                    </span>
                                </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">

                                    <h6 className="dropdown-header">Hi {lname}!</h6>
                                    <a className="dropdown-item" href="/institutions/profile">
                                        <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle">Profile</span>
                                    </a>
                                    <a className="dropdown-item" href="/institutions/messages">
                                        <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle">Messages</span>
                                    </a>
                                    <a className="dropdown-item" href="/institutions/taskboard">
                                        <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle">Taskboard</span>
                                    </a>
                                    <a className="dropdown-item" href="/institutions/faqs">
                                        <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle">Help</span>
                                    </a>
                                    <div className="dropdown-divider">

                                    </div>
                                    <Link className="dropdown-item" to={"/institution/billing"}>
                                        <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle">Balance : <b>Kes. {wallet}</b></span>
                                    </Link>
                                    <Link className="dropdown-item" to={"/institution/settings"}>
                                        <span className="badge bg-soft-success text-success mt-1 float-end">New</span>
                                        <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle">Settings</span>
                                    </Link>
                                    <Link className="dropdown-item" to={"/institution/lock"}><i className="mdi mdi-lock text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle">Lock Account</span>
                                    </Link>
                                    <Link className="dropdown-item" to={"/institution/login"}>
                                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"> </i>
                                        <span className="align-middle" data-key="t-logout">Logout</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}

export default DashboardHeader;