import React from "react";

const DashboardFooter: React.FC = () => {

    let currentYear;
    return(
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4">
                            <script type="text/javascript">{`document.write(${currentYear})`}</script>
                            © Uzima.
                        </div>
                        <div className="col-sm-8">
                            <div className="text-sm-end d-none d-sm-block">
                                &copy; Uzima Health Care <sup> <em><span className={"text-danger"}> Beta - 1 </span></em> </sup>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )

}

export default DashboardFooter;