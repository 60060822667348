import React, {useEffect, useReducer} from 'react'
import DashboardFooter from '../../layouts/dashboard_footer'
import DashboardHeader from '../../layouts/dashboard_header'
import SideMenu from '../../layouts/dashboard_menu'

const InvoiceDetails: React.FC = () => {

    const initialState = {
        showHeader: true,
        step: 0,
        subStep: 0,
        cardStep: 0,
        heading: "Uzima Healthcare Invoicing",
        subHeader: "View and manage your invoices",
        address: "Upper Hill Nairobi",
        postCode: "00100",
        invoiceNo: "UZNPTPAT-0001#",
        invoiceDate: "01/12/2023 13:00",
        paymentStatus: "PAID",
        totalAmount: 8500,
        currency: "Kshs.",
        billingName: "Uzima Person",
        billingAddress: "Plot Infinity, Westlands Nairobi",
        phone: "+254123 456 789",
        billingPhone: "+254 000 111 222",
        email: "user@domail.org",
        billingEmail: "client@domain.org",
        items: [
            {
                itemId: "#1",
                billingItem: "Consultation & Diagnosis",
                desc: "Pre-Treatment Diagnosis",
                rate: 400,
                discount: "5%",
                totalAmount: 380
            },

            {
                itemId: "#2",
                billingItem: "Laboratory Tests",
                rate: 2000,
                desc: "Basic Lab tests for common infections",
                discount: "10%",
                totalAmount: 1800
            }

        ],
        totals: {
            subTotal: 2180,
            discount: 220 ,
            tax: "15%",
            taxAmount: 327,
            uzimaPercentage: "20%",
            uzimaCharge: 436,
            totalAmount: 2943
        }
    }

    const reducer = (state: any, action: any) => {

        switch (action.type){

            case "SET_SHOW_HEADER":
                return {...state, showHeader: action.payload}

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_CARD_STEP":
                return {...state, cardStep: action.payload}

            case "SET_HEADING":
                return {...state, heading: action.payload}

            case "SET_SUB_HEADING":
                return {...state, subHeader: action.payoad}

            case "SET_ADDRESS":
                return {...state, address: action.payload}

            case "SET_POST_CODE":
                return {...state, postCode: action.payload}

            case "SET_INVOICE_NO":
                return {...state, invoiceNo: action.payload}

            case "SET_INVOICE_DATE":
                return {...state, invoiceDate: action.payload}

            case "SET_PAYMENT_STATUS":
                return {...state, paymentStatus: action.payload}

            case "SET_CURRENCY":
                return {...state, currency: action.payload}

            case "SET_BILLING_NAME":
                return {...state, billingName: action.payload}

            case "SET_BILLING_ADDRESS":
                return {...state, billingAddress: action.payload}

            case "SET_PHONE":
                return {...state, phone: action.payload}

            case "SET_ITEMS":
                return {...state, items: action.payload}

            case "SET_TOTALS":
                return {...state, totals: action.payload}

            case "SET_BILLING_PHONE":
                return {...state, billingPhone: action.payload}

            case "SET_BILLING_EMAIL":
                return {...state, billingEmail: action.payload}

            case "SET_EMAIL":
                return {...state, email: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer,  initialState)

    return (
        <>
            <DashboardHeader />
            <SideMenu />

            <div className={"main-content"}>
                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Invoice Details</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Invoices</a></li>
                                            <li className="breadcrumb-item active">Invoice Details</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xxl-9">
                                <div className="card" style={{"transform": "none"}}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card-header border-bottom-dashed p-4">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`} className="card-logo card-logo-dark" alt="logo dark" height="17" />
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`} className="card-logo card-logo-light" alt="logo light" height="17" />
                                                                <div className="mt-sm-5 mt-4">
                                                                    <h6 className="text-muted text-uppercase fw-semibold">Address</h6>
                                                                    <p className="text-muted mb-1" id="address-details">Address: {state.address}</p>
                                                                    <p className="text-muted mb-0" id="post-code"><span>Post-code:</span> {state.postCode}</p>
                                                                    <p className="text-muted mb-1" id="phone">Phone: {state.phone}</p>
                                                                    <p className="text-muted mb-0" id="phone">Email: {state.email}</p>
                                                                </div>
                                                    </div>
                                                    <div className="flex-shrink-0 mt-sm-0 mt-3">
                                                        <h6><span className="text-muted fw-normal">Uzima ID:</span><span id="legal-register-no">5687PT</span></h6>
                                                        <h6><span className="text-muted fw-normal">Email:</span><span id="email">user@uzimahc.com</span></h6>
                                                        <h6><span className="text-muted fw-normal">Website:</span> <a href="https://uzimahealthcare.com/" className="link-primary" target="_blank" id="website">www.uzimahealthcare.com</a></h6>
                                                        <h6 className="mb-0"><span className="text-muted fw-normal">Contact No: </span><span id="contact-no"> +254729 517 425</span></h6>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card-body p-4">
                                                <div className="row g-3">
                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">Invoice No</p>
                                                        <h5 className="fs-14 mb-0"><span id="invoice-no">{state.invoiceNo}</span></h5>
                                                    </div>

                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                                                        <h5 className="fs-14 mb-0"><span id="invoice-date">{state.invoiceDate}</span> </h5>
                                                    </div>

                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">Payment Status</p>
                                                        <span className="badge bg-success-subtle text-success fs-11" id="payment-status">{state.paymentStatus}</span>
                                                    </div>

                                                    <div className="col-lg-3 col-6">
                                                        <p className="text-muted mb-2 text-uppercase fw-semibold">Total Amount</p>
                                                        <h5 className="fs-14 mb-0">{state.currency}<span id="total-amount">{state.totals.totalAmount}</span></h5>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card-body p-4 border-top border-top-dashed">
                                                <div className="row g-3">
                                                    <div className="col-10">
                                                        <h6 className="text-muted text-uppercase fw-semibold mb-3">Billing Address</h6>
                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                <p className="fw-medium mb-2" id="billing-name">Name: {state.billingName}</p>
                                                                <p className="fw-medium mb-1" id="billing-address-line-1">Address: {state.billingAddress}</p>
                                                            </div>

                                                            <div className={"col"}>

                                                                <p className="fw-medium mb-1"><span>Phone: </span><span id="billing-phone-no">{state.billingPhone}</span></p>
                                                                <p className="fw-medium mb-0" id="billing-email">Email: {state.billingEmail}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="col-lg-12">
                                            <div className="card-body p-4">
                                                <div className="table-responsive">
                                                    <table className="table table-borderless text-center table-nowrap align-middle mb-0">
                                                        <thead>
                                                        <tr className="table-active">
                                                            <th scope="col" style={{"width": "50px"}}>#</th>
                                                            <th scope="col">Details</th>
                                                            <th scope="col">Rate</th>
                                                            <th scope="col">Discount</th>
                                                            <th scope="col" className="text-end">Amount</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id="products-list">

                                                        {Object.values(state.items).map((item: any)=>(
                                                            <tr>
                                                                <th scope="row">{item.itemId}</th>
                                                                <td className="text-start">
                                                                    <span className="fw-medium">{item.billingItem}</span>
                                                                    <p className="text-muted mb-0">{item.default}</p>
                                                                </td>
                                                                <td>{item.rate}</td>
                                                                <td>{item.discount}</td>
                                                                <td className="text-end">{item.totalAmount}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="border-top border-top-dashed mt-2">
                                                    <table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{"width":"250px"}}>
                                                        <tbody>
                                                        <tr>
                                                            <td>Sub Total</td>
                                                            <td className="text-end">{state.currency} {state.totals.subTotal}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Estimated Tax ({state.totals.tax})</td>
                                                            <td className="text-end">{state.currency} {state.totals.taxAmount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Discount <small className="text-muted">(Uzima HC)</small></td>
                                                            <td className="text-end">{state.currency} {state.totals.discount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Platform Charge ({state.totals.uzimaPercentage})</td>
                                                            <td className="text-end">{state.currency} {state.totals.uzimaCharge}</td>
                                                        </tr>
                                                        <tr className="border-top border-top-dashed fs-15">
                                                            <th scope="row">Total Amount</th>
                                                            <th className="text-end">{state.currency} {state.totals.totalAmount}</th>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                                <div className="mt-3">
                                                    <h6 className="text-muted text-uppercase fw-semibold mb-3">Payment Details:</h6>
                                                    <p className="text-muted mb-1">Payment Method: <span className="fw-medium" id="payment-method">Visa Card</span></p>
                                                    <p className="text-muted mb-1">Currency Deducted: <span className="fw-medium" id="payment-method">{state.currency}</span></p>
                                                    <p className="text-muted mb-1">Card Holder: <span className="fw-medium" id="card-holder-name">{state.billingName}</span></p>
                                                    <p className="text-muted mb-1">Card Number: <span className="fw-medium" id="card-number">xxx xxxx xxxx 1234</span></p>
                                                    <p className="text-muted">Total Amount: <span className="fw-medium" id="">{state.currency}  </span><span id="card-total-amount">{state.totals.totalAmount}</span></p>
                                                </div>
                                                <div className="mt-4">
                                                    <div className="alert alert-info">
                                                        <p className="mb-0"><span className="fw-semibold">NOTES:</span>
                                                            <span id="note">All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or
                                                            credit card or direct payment online. If account is not paid within 7
                                                            days the credits details supplied as confirmation of work undertaken
                                                            will be charged the agreed quoted fee noted above.
                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                                    <a href="javascript:window.print()" className="btn btn-success"><i className="ri-printer-line align-bottom me-1"></i> Print</a>
                                                    <a href="javascript:void(0);" className="btn btn-primary"><i className="ri-download-2-line align-bottom me-1"></i> Download</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>

                <DashboardFooter />
            </div>

        </>
    )
}

export default InvoiceDetails