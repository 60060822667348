import React, {useEffect, useState} from "react";
import {useBaseUrl, usePostData} from "../../utils/utils";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import Collaborators from "./Collaborators";

interface Props {
    service: any,
    codex: string
}

const CreateCollaborationComponent: React.FC<Props> = ({service, codex}) => {

    useEffect(()=>{

        GetPartner()

    }, [service])

    const [partners, setPartners] = useState<any []>([])
    const [step, setStep] = useState(1)
    const [selectedPartner, setSelectedPartner] = useState<any>([])
    const [collaborationType, setCollaborationType] = useState<any>()
    const [request, setRequest] = useState<any>()
    const [priority, setPriority] = useState<any>()
    const [requestId, setRequestId] = useState<any>()
    const [fname, setFname] = useState<any>("")
    const [lname, setLname] = useState<any>("")
    const [county, setCounty] = useState<any>("")
    const [town, setTown] = useState<any>("")
    const [spec, setSpec] = useState<any>("")
    const [ver, setVer] = useState<any>("")
    const [years, setYears] = useState("Not Submitted")
    const [image, setImage] = useState("")

    const GetPartner = async () => {

        const url = `${useBaseUrl()}/facilities/select-partner`

        const formData = new FormData()

        formData.append("service_id", service)

        const server_partners = await usePostData(url, formData)

        if(!isNaN(server_partners) && server_partners != "undefined"){
            setPartners(server_partners)
        }

    }



    const CreateRequest = async () => {

        const url = `${useBaseUrl()}/facilities/collaboration-request`

        const formData = new FormData()

        const send_request = await usePostData(url, formData)

        if (typeof send_request === 'undefined' || isNaN(send_request)){

            await Swal.fire({
                icon: "info",
                title: "Request failed",
                text: "The server response is not consistent. Please resend your request."
            })

            return


        }

        setRequestId(send_request.request_id)

    }


    return(
        <section>
            {step === 1 && (
                <section>

                    <div className={"row mt-4"}>

                        {Object.values(partners).map((facility: any) => (

                            <div className={"col-md-4"}>
                                <div className="card">
                                    <div className="card-body text-center p-4">
                                        <img src={`${process.env.PUBLIC_URL}${facility.logo}`} alt="" height="45" />
                                        <h5 className="mb-1 mt-4"><a href="#" className="link-primary">{facility.name}</a>
                                        </h5>
                                        <p className="text-muted mb-4">{facility.location.county} County</p>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">

                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-lg-6 border-end-dashed border-end">
                                                <h5>{facility.collaborations}</h5>
                                                <span className="text-muted">Collaborations</span>
                                            </div>
                                            <div className="col-lg-6">
                                                <h5>{facility.capacity}</h5>
                                                <span className="text-muted">Capacity</span>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <form onSubmit={(e)=>{
                                                e.preventDefault()
                                                setSelectedPartner(facility)
                                                setStep(2)
                                            }}>
                                                <input type="hidden" name="clinic_id" value={3}/>
                                                <input type="submit" value="Partner" className="btn btn-rounded btn-outline-primary w-100" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {Object.keys(partners).length ===0 && (
                            <section>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <div className={"card card-animate"}>
                                            <div className={"row"}>
                                                <div className={"col-md-2"}>
                                                    <div className={"card-body"}>
                                                        <button style={{width: "100%"}} className={"btn btn-success"}>Refresh</button>
                                                    </div>
                                                </div>

                                                <div className={"col-md-2"}>
                                                    <div className={"card-body"}>
                                                        <Link to={"/institution/home"} style={{width: "100%"}} className={"btn btn-success"}>Go Back</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row mt-4"}>
                                    {service === 6 && (
                                        <>
                                            <div className={"col-md-4"}>

                                                <div className={"card card-animate"}>
                                                    <div className={"card-header"} style={{backgroundColor: "#29b0ea" ,color: "white",  border: "None"}}>
                                                        {codex}
                                                    </div>

                                                    <div className={"card-body"}>

                                                        <div className={"row"}>
                                                            <div className={"col"}>

                                                            </div>
                                                            <div className={"col"}>
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} className="me-3 rounded-circle avatar-md" alt="user-pic" />
                                                            </div>
                                                            <div className={"col"}>

                                                            </div>
                                                        </div>

                                                        <div className={"row mt-3"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-file"}> </i> &nbsp;
                                                                First Name
                                                            </div>

                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-file"}> </i> &nbsp;
                                                                Last Name
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Jackson
                                                            </div>

                                                            <div className={"col"}>
                                                                Siemper
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-current-location"}> </i> &nbsp;
                                                                County
                                                            </div>

                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-current-location"}> </i> &nbsp;
                                                                Town
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Nairobi
                                                            </div>

                                                            <div className={"col"}>
                                                                Westlands
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-briefcase"}> </i> &nbsp;
                                                                Specialization
                                                            </div>
                                                        </div>


                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Ortho<em> (Something weird) </em> paedriatics
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-badge-check"}> </i> &nbsp;
                                                                Verification
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Jackson Siemper is <em style={{fontWeight: "Bold"}} className={"text-success"}> Verified! </em>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={"card-footer"} style={{border: "None"}}>
                                                        <div className={"container-fluid"}>
                                                            <button onClick={
                                                                ()=>{
                                                                    setFname("Jackson")
                                                                    setLname("Siemper")
                                                                    setCounty("Nairobi")
                                                                    setTown("Westlands")
                                                                    setSpec("Ortho(something)paedriatics")
                                                                    setVer(true)
                                                                    setStep(4)
                                                                    setImage("avatar-3.jpg")

                                                                }
                                                            } style={{width: "100%"}} className={"btn btn-outline-primary"}>
                                                                <i className={"bx bx-user"}> </i> &nbsp; &nbsp;
                                                                View Profile
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className={"col-md-4"}>

                                                <div className={"card card-animate"}>
                                                    <div className={"card-header"} style={{backgroundColor: "#29b0ea" ,color: "white",  border: "None"}}>
                                                        {codex}
                                                    </div>

                                                    <div className={"card-body"}>

                                                        <div className={"row"}>
                                                            <div className={"col"}>

                                                            </div>
                                                            <div className={"col"}>
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`} className="me-3 rounded-circle avatar-md" alt="user-pic" />
                                                            </div>
                                                            <div className={"col"}>

                                                            </div>
                                                        </div>

                                                        <div className={"row mt-3"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-file"}> </i> &nbsp;
                                                                First Name
                                                            </div>

                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-file"}> </i> &nbsp;
                                                                Last Name
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Bunsen
                                                            </div>

                                                            <div className={"col"}>
                                                                Burner
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-current-location"}> </i> &nbsp;
                                                                County
                                                            </div>

                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-current-location"}> </i> &nbsp;
                                                                Town
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Narok
                                                            </div>

                                                            <div className={"col"}>
                                                                Narik
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-briefcase"}> </i> &nbsp;
                                                                Specialization
                                                            </div>
                                                        </div>


                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Phyisiotherapy
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-badge"}> </i> &nbsp;
                                                                Verification
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Bunsen Burner is <em style={{fontWeight: "Bold"}} className={"text-warning"}> Unverified! </em>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={"card-footer"} style={{border: "None"}}>
                                                        <div className={"container-fluid"}>
                                                            <button style={{width: "100%"}} className={"btn btn-outline-primary"}>
                                                                <i className={"bx bx-user"}> </i> &nbsp; &nbsp;
                                                                View Profile
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className={"col-md-4"}>

                                                <div className={"card card-animate"}>
                                                    <div className={"card-header"} style={{backgroundColor: "#29b0ea" ,color: "white",  border: "None"}}>
                                                        {codex}
                                                    </div>

                                                    <div className={"card-body"}>

                                                        <div className={"row"}>
                                                            <div className={"col"}>

                                                            </div>
                                                            <div className={"col"}>
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} className="me-3 rounded-circle avatar-md" alt="user-pic" />
                                                            </div>
                                                            <div className={"col"}>

                                                            </div>
                                                        </div>

                                                        <div className={"row mt-3"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-file"}> </i> &nbsp;
                                                                First Name
                                                            </div>

                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-file"}> </i> &nbsp;
                                                                Last Name
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Luna
                                                            </div>

                                                            <div className={"col"}>
                                                                Moonsurface
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-current-location"}> </i> &nbsp;
                                                                County
                                                            </div>

                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-current-location"}> </i> &nbsp;
                                                                Town
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Kajiado
                                                            </div>

                                                            <div className={"col"}>
                                                                Kitengela
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className={"bx bx-briefcase"}> </i> &nbsp;
                                                                Specialization
                                                            </div>
                                                        </div>


                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Ortho<em> (Something weird) </em> paedriatics
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-4"}>
                                                            <div className={"col"} style={{fontWeight: "Bold"}}>
                                                                <i className="bx bx-badge-check"> </i> &nbsp;
                                                                Verification
                                                            </div>
                                                        </div>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                Luna Moonsurface is <em style={{fontWeight: "Bold"}} className={"text-success"}> Verified! </em>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={"card-footer"} style={{border: "None"}}>
                                                        <div className={"container-fluid"}>
                                                            <button style={{width: "100%"}} className={"btn btn-outline-primary"}>
                                                                <i className={"bx bx-user"}> </i> &nbsp; &nbsp;
                                                                View Profile
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>



                                        </>
                                    )}
                                </div>
                            </section>
                        )}

                    </div>

                </section>
            )}

            {step === 2 &&(
                <section>
                    <div className={"card"}>

                        <div className={"card-body"}>
                            <p className={"alert alert-info"}>Caution: You cannot send a request on behalf of another user or facility!</p>
                            <p className={"alert alert-info"}>Medical Record carries all required info!</p>
                            <div className={"row mt-3"}>
                                <div className={"col"}>
                                    <label className={"form-label"}>
                                        <i className={"bx bx-box"}> </i> &nbsp;
                                        Collaboration Type
                                    </label>

                                    <select className={"form-control"} onChange={(e)=>{

                                        setCollaborationType(e.currentTarget.value)
                                    }}>
                                        <option value={"1"}>One Off</option>
                                        <option value={"2"}>Short Term</option>
                                        <option value={"1"}>Long Term</option>
                                    </select>
                                </div>

                                <div className={"col"}>
                                    <label><i className={"bx bx-clinic"}> &nbsp; </i>Selected Facility</label>
                                    <input disabled={true} className={"form-control"} value={selectedPartner.name} />
                                </div>
                            </div>

                            <div className={'row mt-3'}>
                                <div className={"col"}>
                                    <label className={"form-label"}>
                                        <i className={"bx bx-server"}> </i> &nbsp;

                                        Medical Record
                                    </label>

                                    <input type={"text"} className={"form-control"} placeholder={"Enter medical record id"}/>


                                </div>

                                <div className={"col"}>
                                    <label className={"form-label"}>
                                        <i className={"bx bx-alarm-exclamation"}> </i> &nbsp;
                                        Priority
                                    </label>

                                    <select value={priority} className={"form-control"} onChange={(e)=>{
                                        setPriority(e.currentTarget.value)
                                    }}>
                                        <option value={1}>Low</option>
                                        <option value={2}>Normal</option>
                                        <option value={3}>High</option>
                                    </select>

                                </div>
                            </div>

                            <div className={"row mt-3"}>
                                <div className={"col"}>

                                    <label className={"form-label"}>
                                        <i className={"bx bx-file"}> </i> &nbsp;
                                        Describe your request
                                    </label>

                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={request}
                                        onChange={(d, e)=>{
                                            setRequest(e.getData())
                                        }}
                                    />

                                </div>
                            </div>

                            <div className={"row mt-3"}>
                                <div className={"col-md-2"}>

                                    <button className={"btn btn-danger"} onClick={(e)=>{
                                        e.preventDefault()
                                        setRequest("")
                                        setSelectedPartner("")
                                        setStep(1)
                                    }}>Back</button>

                                </div>
                                <div className={"col-md-2"}>

                                    <button className={"btn btn-success"} onClick={(e)=>{

                                        e.preventDefault()

                                        CreateRequest()

                                    }}>Next</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {step === 4 &&(
                <Collaborators fname={fname} lname={lname} county={county} town={town} specialization={spec} verified={ver} years={years} image={image}/>


            )}


        </section>
    )
}

export default CreateCollaborationComponent