import React, {useEffect, useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";
import Canvas from "./dahboard_canvas";
import axios from "axios";
import Swal from "sweetalert2";
import {useBaseUrl} from "../../utils/utils";

interface UserData {
    fname: string;
    lname: string;
    avatar: string;
    email: string;
    status: boolean;
    clinic: string;
    clinic_id: number;
    user_id: number;
}

const ClinicProfessionals: React.FC = () =>{
    const [data, setData] = useState<any []>([]);
    const [loading, setLoading] = useState(true);

    const base_url = useBaseUrl()

        useEffect(() => {
            FetchData();
        }, []);

        const FetchData = async () => {
            try {
                const response = await axios.post(`${base_url}/facilities/clinic-lisst`, {institution_id: 21});

                if (response.data.status === 'success') {
                    const responseData: UserData[] = response.data.data;

                    setData(responseData);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    });
                }

                setLoading(false);
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'The system denied you access to this data..',
                });

                setLoading(false);
            }
        };

        const handleDeactivate = (userId: number) => {
            // Logic to deactivate user
        };

        const handleActivate = (userId: number) => {
            // Logic to activate user
        };

    return (
        <>
            <div id="layout-wrapper">
                <DashboardHeader></DashboardHeader>

                <div id="removeNotificationModal" className="modal fade zoomIn" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                        id="NotificationModalbtn-close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mt-2 text-center">
                                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                        <h4>Are you sure ?</h4>
                                        <p className="text-muted mx-4 mb-0">Are you sure you want to remove this
                                            Notification ?</p>
                                    </div>
                                </div>
                                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                    <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="button" className="btn w-sm btn-danger" id="delete-notification">
                                        Yes, Delete It!
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <SideMenu></SideMenu>

                <div className="vertical-overlay">

                </div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row mt-4">
                                <div className="col-12">
                                    <div
                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Institution Staff</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a
                                                    href="/institution/home">Clinic</a></li>
                                                <li className="breadcrumb-item active">Staff</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={"row mt-4"}>

                                <div className={"col"}>

                                    <div className={"card"}>
                                        <div className="card-header border-0">
                                            <div className="d-flex align-items-center">
                                                <h5 className="card-title mb-0 flex-grow-1">All Institution Staff Members</h5>
                                                <div className="flex-shrink-0">
                                                    <div className="d-flex flex-wrap gap-2">
                                                        <button className="btn btn-danger add-btn"><i className="ri-add-line align-bottom me-1"></i> Add Staff</button>
                                                        <button className="btn btn-soft-secondary" ><i className="ri-delete-bin-2-line"></i> Deactivate Staff</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"card-body border border-dashed border-end-0 border-start-0"}>
                                            {loading ? (
                                                <p>Loading...</p>
                                            ) : (
                                                <table className={"table table-nowrap table-striped-columns mb-0"}>
                                                    <thead className={"table-light"} style={{fontWeight: "bold !important" }}>
                                                    <tr>
                                                        <th>Check</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Avatar</th>
                                                        <th>Status</th>
                                                        <th>Clinic</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {data.map((item) => (
                                                        <tr key={item.user_id}>
                                                            <td> <input type={"checkbox"} /> </td>
                                                            <td>{item.fname}</td>
                                                            <td>{item.lname}</td>
                                                            <td>{item.email}</td>
                                                            <td>
                                                                <img id={"avatar"} className="avatar-xs rounded-3 me-2" src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`} alt="Avatar"  />
                                                            </td>
                                                            <td>{item.status ? 'Active' : 'Inactive'}</td>
                                                            <td>{item.clinic}</td>
                                                            <td>
                                                                {item.status ? (
                                                                    <button className={"btn btn-danger"} onClick={() => handleDeactivate(item.user_id)}>Deactivate</button>
                                                                ) : (
                                                                    <button className={"btn btn-success"} onClick={() => handleActivate(item.user_id)}>Activate</button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <Canvas></Canvas>
        </>
    )
}

export default ClinicProfessionals;