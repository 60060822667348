import React from 'react'

const SuspendStaff: React.FC = () =>{

    return (
        <>
        </>
    )
}

export default SuspendStaff