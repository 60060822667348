import React, {useReducer} from 'react'
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import DashboardFooter from "../../layouts/dashboard_footer";
import {useBreadcrumbs, useCounties, useTowns} from "../../../utils/utils";
import BillingStats from "./BillingStats";

const BillingHome: React.FC = () => {

    const initialState = {

        step: 0,
        subStep: 0,
        cardStep: 0,
        header: "Uzima Billing Center",
        subHeader: "View and manage your bills",
        showHeadings: true,

    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case 'SET_STEP':
                return {...state, step: action.payload}

            case 'SET_PAGE_HEADING':
                return {...state, header: action.payload}

            case 'SET_SUB_HEADER':
                return {...state, subHeader: action.payload}

            case 'SET_SUB_STEP':
                return {...state, subStep: action.payload}

            case 'SET_CARD_STEP':
                return {...state, cardStep: action.payload}

            case 'SET_SHOW_HEADINGS':
                return {...state, showHeadings: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <>
            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />
            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={'container-fluid'}>

                        {state.showHeadings && (
                            <>
                                <div className={"row mt-4 text-center"}>
                                    <div className={"col-12"}>
                                        <h3>{state.header}</h3>
                                    </div>
                                </div>

                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <h6>View and Manage your received partnership requests.</h6>
                                    </div>
                                </div>
                            </>
                        )}

                        <BillingStats />

                    </div>

                </div>

            </div>

            <DashboardFooter />

        </>
    )
}

export default BillingHome