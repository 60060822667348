import React, {useEffect} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import Canvas from "./dahboard_canvas";

const Applicants: React.FC =() => {
    useEffect(()=>{
        document.title = "Applicants"
    }, [])

    return(
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>
            <div className={"vertical-overlay"}>

            </div>
            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>

                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Service Providers: Applicants</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/institution/services"}>Services</a></li>
                                            <li className="breadcrumb-item active">Applicants</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-3"}>
                            <div className={"col"}>
                                <div className={"card"}>
                                    <div className={"card-body"}>
                                        <div className={"alert alert-danger"}>
                                            When you post a collaboration request and someone responds, they will appear here. Post one now?.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Canvas></Canvas>
            </div>
        </>
    )
}

export default Applicants;