import React, {useEffect, useReducer} from "react"
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import DashboardFooter from "../../layouts/dashboard_footer";
import {Link, useNavigate} from "react-router-dom";
import {useBaseUrl, usePostData} from "../../../utils/utils";
import Swal from "sweetalert2";
import PatientProfile from "./PatientProfile";

interface PatientOwner {

}

const PatientList: React.FC = () => {

    const navigate = useNavigate()

    useEffect(()=>{

        Get_Patients()

    }, [])

    const initialState = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        heading: "My Uzima Patients",
        subHeading: "A list of all the patients under your facility.",
        patients: [],
        selectedPatient: [],
        showHeadings: true,
        clinicId: 1,
        showId: false
    }

    const reducer = (state: any, action: any) => {

        switch (action.type) {

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_CARD_STEP":
                return {...state, cardStep: action.payload}

            case "SET_HEADING":
                return {...state, heading: action.payload}

            case "SET_SUB_HEADING":
                return {...state, subHeading: action.payload}

            case "SET_SHOW_HEADINGS":
                return {...state, showHeadings: action.payload}

            case "SET_PATIENTS":
                return {...state, patients: action.payload}

            case "SET_SELECTED_PATIENT":
                return {...state, selectedPatient: action.payload}

            case "SET_SHOW_ID":
                return {...state, showId: action.payload}

            default:
                return state
        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const Get_Patients = async () => {

        const url = `${useBaseUrl()}/facilities/patient-list`

        const formData = new FormData()

        formData.append(
            "clinic_id", state.clinicId
        )

        const patients = await usePostData(url, formData)
        

        if(patients === undefined || patients === "undefined"){

            await Swal.fire({
                icon: "success",
                title: "Operation Complete",
                text: "We fetched your patients and got a null result. You can refresh the page if this is wrong."
            })

        }

        dispatch({type: "SET_PATIENTS", payload: patients})
    }

    return (

        <>
            <DashboardHeader />
            <SideMenu />

            <div className={"main-content"}>
                <div className="page-content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Team</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Patients</a>
                                            </li>
                                            <li className="breadcrumb-item active">My patients</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {state.showHeadings && (
                            <>
                                <div className={"row mt-2"}>
                                    <div className={"col text-center"}>
                                        <h3>
                                            {state.heading}
                                        </h3>
                                    </div>
                                </div>

                                <div className={"row mt-1 mb-2"}>
                                    <div className={"col text-center"}>
                                        <h6>
                                            {state.subHeading}
                                        </h6>
                                    </div>
                                </div>
                            </>
                        )}


                        {state.step === 0 && (
                            <>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-2">
                                            <div className="col-sm-4">
                                                <div className="search-box">
                                                    <input type="text" className="form-control" id="searchMemberList"
                                                           placeholder="Enter Patient Code" />
                                                    <i className="ri-search-line search-icon"> </i>
                                                </div>
                                            </div>
                                            <div className="col-sm-auto ms-auto">
                                                <div className="list-grid-nav hstack gap-1">
                                                    <button type="button" id="grid-view-button"
                                                            className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button">
                                                        <i className="ri-grid-fill"> </i></button>
                                                    <button type="button" id="list-view-button"
                                                            className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button">
                                                        <i className="ri-list-unordered"> </i></button>
                                                    <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown"
                                                            aria-expanded="false" className="btn btn-soft-info btn-icon fs-14">
                                                        <i className="ri-more-2-fill"> </i></button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                                        <li><a className="dropdown-item" href="#">All</a></li>
                                                        <li><a className="dropdown-item" href="#">Last Week</a></li>
                                                        <li><a className="dropdown-item" href="#">Last Month</a></li>
                                                        <li><a className="dropdown-item" href="#">Last Year</a></li>
                                                    </ul>
                                                    <button className="btn btn-success addMembers-modal" ><i
                                                        className="ri-search-line search-icon"> </i> Find Patient
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div>

                                            <div id="teamlist">
                                                <div className="team-list grid-view-filter row" id="team-member-list">
                                                    {Object.values(state.patients).map((patient: any)=>(
                                                        <div className="col-md-4">
                                                            <div className="card team-box">
                                                                <div className="team-cover"><img src={`${process.env.PUBLIC_URL}${patient.cover}`}
                                                                                                 alt="" className="img-fluid" /></div>
                                                                <div className="card-body p-4">
                                                                    <div className="row align-items-center team-row">
                                                                        <div className="col team-settings">
                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <div className="flex-shrink-0 me-2">
                                                                                        <button type="button"
                                                                                                className="btn btn-light btn-icon rounded-circle btn-sm favourite-btn ">
                                                                                            <i className="ri-star-fill fs-14"> </i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col text-end dropdown"><a
                                                                                    href="javascript:void(0);"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <i className="ri-more-fill fs-17"> </i> </a>
                                                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                                                        <li>
                                                                                            <a className="dropdown-item edit-list">
                                                                                                <i className="ri-pencil-line me-2 align-bottom text-muted"> </i>Edit
                                                                                            </a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a className="dropdown-item remove-list">
                                                                                                <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"> </i>Remove
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col">
                                                                            <div className="team-profile-img">
                                                                                <div
                                                                                    className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                                                    <img src={`${process.env.PUBLIC_URL}${patient.avatar}`}
                                                                                         alt=""
                                                                                         className="member-img img-fluid d-block rounded-circle" />
                                                                                </div>
                                                                                <div className="team-content">
                                                                                    <a className="member-name" data-bs-toggle="offcanvas" href="#member-overview" aria-controls="member-overview">
                                                                                        <h5 className="fs-16 mb-1">{patient.first_name} {patient.last_name}</h5>
                                                                                    </a>
                                                                                    <p className="text-muted member-designation mb-0">Uzima ID: {state.showId && (
                                                                                        <>
                                                                                            {patient.patient_code}
                                                                                        </>
                                                                                    ) || (
                                                                                        <>
                                                                                            ****
                                                                                        </>
                                                                                    )} &nbsp; &nbsp;

                                                                                        <a>
                                                                                            <i className="bx bx-block" onClick={(e)=>{
                                                                                                dispatch({type: "SET_SHOW_ID", payload: !state.showId})
                                                                                            }}> </i>
                                                                                        </a>

                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col">
                                                                            <div className="row text-muted text-center">
                                                                                <div className="col-6 border-end border-end-dashed">
                                                                                    <h5 className="mb-1 projects-num">0</h5>
                                                                                    <p className="text-muted mb-0">Treatments</p>
                                                                                </div>
                                                                                <div className="col-6"><h5
                                                                                    className="mb-1 tasks-num">0</h5>
                                                                                    <p className="text-muted mb-0">Records</p></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col">
                                                                            <div className="text-end">
                                                                                <button className="btn btn-light view-btn" onClick={(e)=>{
                                                                                    dispatch({type: "SET_SUB_HEADING", payload: `Patient's profile for ${patient.first_name} ${patient.last_name} of Uzima ID: ${patient.patient_code}`})
                                                                                    dispatch({type: "SET_SELECTED_PATIENT", payload: patient})
                                                                                    dispatch({type: "SET_STEP", payload: 1})
                                                                                }}>
                                                                                    View Profile
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="text-center mb-3">
                                                    <Link to="javascript:void(0);" className="text-success"><i
                                                        className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"> </i> Load
                                                        More </Link>
                                                </div>
                                            </div>
                                            <div className="py-4 mt-4 text-center" id="noresult" style={{display: "none"}}>

                                                <h5 className="mt-4">Sorry! No Result Found</h5>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </>
                        )}

                        {state.step === 1 && (
                            <>
                                <PatientProfile patient={state.selectedPatient} step={state.step} />
                            </>
                        )}

                    </div>

                </div>

                <DashboardFooter />
            </div>

        </>

    )
}

export default PatientList