import React, {useEffect, useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import Canvas from "./dahboard_canvas";
import localforage from "localforage";
import Swal from "sweetalert2";
import axios from "axios";
import Datatable from "./components/datatable";
import {useBaseUrl} from "../../utils/utils";

const AllRecords: React.FC = () =>{
    let clinic_id: any = localforage.getItem("clinic_id")
    const [step, setStep] = useState<number>(1);
    const [segment, setSegment] = useState("All Records")
    const [records, setRecords] = useState<any[]>([])

    const base_url = useBaseUrl()
    useEffect(()=>{
        document.title = "Clinic Medical Records";
        get_clinic_records()
    }, [])

    const get_clinic_records = async () => {

        clinic_id = 1

        let url = base_url + "/ehr/clinic-records"

        try{
            const formData = new FormData()

            formData.append("clinic_id", clinic_id)

            const response = await axios.post(url, formData)

            const {status, message, data} = response.data

            if (status != "success"){

                await Swal.fire({
                    icon: "error",
                    title:"Error",
                    text: message
                })

                return true

            }

            setRecords(data)

            return true
        }catch{
            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to communicate with the server. Hit the refresh button to retry."
            })
        }

    }
    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>
            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Electronic Health Records(EHR) - {segment}</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/institution/erh"}>EHR</a></li>
                                            <li className="breadcrumb-item active">List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <div className={"card card-animate"}>
                                    <div className={"card-header"}>

                                    </div>
                                    <div className={"card-body"}>

                                        <Datatable data={records} count={Object.keys(records).length}></Datatable>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Canvas></Canvas>

        </>
    )
}

export default AllRecords;