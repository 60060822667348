import React, {useEffect} from "react";
import DashboardFooter from "../layouts/dashboard_footer";
import Swal from "sweetalert2";

const PartnerStart: React.FC = () => {

    useEffect(()=> {
        document.title = "Project Uzima - 3rd Party"

    }, []);

    const divStyle = {
        borderRadius: "3% "
    }

    return(
        <>

            <div className={"container-fluid"}>

                <div className={"row"}>

                    <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                    <div className={"col-xxl-6 col-xl-6 col-lg-6 col-md-8"}>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4 text-center"}> <h3>Get Started - Third Party Service Providers</h3> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>



                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <div className="card card-animate" style={divStyle}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/start_login.png`} alt="" className="img-fluid" />
                                        </div>
                                        <h5 className="card-title mb-1">Login</h5>

                                        <p><a className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                              href={"/partner/login"}>Login</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className={"col"}>
                                <div className="card card-animate" style={divStyle}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/patient_register.png`} alt="" className="img-fluid" />
                                        </div>
                                        <h5 className="card-title mb-1">Create Account</h5>

                                        <p><a className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                              href={"/partner/register"}>Register</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className={"col-xxl-3 col-xl-3 col-lg-3 col-md-2"}> </div>

                </div>

            </div>

            <DashboardFooter></DashboardFooter>


        </>
    )
}

export default PartnerStart;