import React, {useEffect, useState} from 'react'
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import {useAppName, useBaseUrl, useBreadcrumbs} from "../../utils/utils";
import localforage from "localforage";
import {Link} from "react-router-dom";

const AddStaff: React.FC = () => {
    const app_name = useAppName()
    const base_url = useBaseUrl()
    const [clinicName, setClinicName] = useState<any>("Facilities")
    const [understood, setUnderstood] = useState<boolean>(false)
    const [step, setStep] = useState(0)

    const [counties, setCounties] = useState([])
    const [towns, setTowns] = useState([])
    const [categories, setCategories] = useState([])
    const [roles, setRoles] = useState([])


    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [selectedTown, setSelectedTown] = useState("")
    const [selectedCat, setSelectedCat] = useState("")
    const [role, setRole] = useState("")
    const [selectedCounty, setSelectedCounty] = useState("")

    useEffect(()=>{
        document.title = `${app_name} - Add Staff User`

        localforage.getItem("clinic_name").then((name: any)=>{
            setClinicName(name)
        })
    }, [])

    const [titleText, setTitleText] = useState("Add New Staff Member")


    return (
        <>
            <DashboardHeader />
            <SideMenu />

            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    {useBreadcrumbs(`${clinicName}`, "Add Users", "Users", "Adding")}

                    <div className={"row mt-4"}>
                        <div className={"col text-center"}>

                            <h3>{titleText}</h3>

                        </div>
                    </div>

                    {!understood && (
                        <div className={"row mt-4"} style={{fontSize: "14px"}}>
                            <div className={"col"}>

                                <p className={"alert alert-danger"}>
                                    <em>Adding a staff member allows them to <span style={{fontWeight: "Bold"}}>operate</span> and <span style={{fontWeight: "Bold"}}>transact</span> under your facility and your facility is <span style={{fontWeight: "Bold"}}>responsible</span> for their <span style={{fontWeight: "Bold"}}>actions</span> on this platform.</em>
                                </p>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <button onClick={(e)=>{
                                            setUnderstood(true)
                                        }} className={"btn btn-primary"} style={{width: "80%"}}>I understand</button>

                                    </div>

                                    <div className={"col"}>

                                        <Link to={"/institution/clinics/launch"} className={"btn btn-primary"} style={{width: "80%"}}>I'll Add Later</Link>

                                    </div>
                                </div>

                            </div>
                        </div>
                    )}

                    {understood && (
                        <div style={{fontSize: "14px"}} className={"row mt-4"}>
                            <div className={"col"}>

                                <div className={"card"}>

                                    <div className={"card-header"} style={{backgroundColor: "#38534F", color: "white"}}>

                                        {step === 0 && (
                                            <>
                                                Personal Information
                                            </>
                                        )}


                                    </div>

                                    <div className={"card-body"}>

                                        {step ===0 && (
                                            <>
                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>

                                                        <label>First Name:</label>
                                                        <input className={"form-control mt-1"} value={firstName} onChange={(e)=>{
                                                            setFirstName(e.currentTarget.value)
                                                        }} />

                                                    </div>

                                                    <div className={"col"}>

                                                        <label>Last Name:</label>
                                                        <input className={"form-control mt-1"} value={lastName} onChange={(e)=>{
                                                            setLastName(e.currentTarget.value)
                                                        }} />

                                                    </div>
                                                </div>

                                                <div className={"row mt-2"}>
                                                    <div className={"col"}>

                                                        <label>User Email:</label>
                                                        <input className={"form-control"} type={"email"} value={email} onChange={(e)=>{
                                                            setEmail(e.currentTarget.value)
                                                        }} />

                                                    </div>
                                                </div>
                                            </>
                                        )}


                                    </div>

                                    <div className={"card-footer"}>

                                        {step === 0 && (
                                            <>
                                                <div className={"row mt-3 mb-3"}>
                                                    <div className={"col"}>
                                                        <button style={{width: "100%"}} className={"btn btn-primary"}>
                                                            Next Step
                                                        </button>

                                                    </div>

                                                    <div className={"col"}>
                                                        <button style={{width: "100%"}} className={"btn btn-primary"}>
                                                            Clear Fields
                                                        </button>

                                                    </div>

                                                    <div className={"col"}>
                                                        <button style={{width: "100%"}} className={"btn btn-primary"}>
                                                            Cancel Process
                                                        </button>

                                                    </div>
                                                </div>
                                            </>
                                        )}



                                    </div>

                                </div>

                            </div>
                        </div>
                    )}





                </div>

                <DashboardFooter />
            </div>

            <canvas />

        </>
    )

}

export default AddStaff