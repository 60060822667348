import React, {useEffect, useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import Canvas from "./dahboard_canvas";
import axios from "axios";
import Swal from "sweetalert2";
import {useBaseUrl} from "../../utils/utils";

const FindCollaborators: React.FC = ()=>{
    const [request, setRequest] = useState<any[]>([])
    const [steps, setSteps] = useState(1)
    const [display, setDisplay] = useState<any>()

    const base_url = useBaseUrl()

    const get_invites = async ()=>{

        let url = base_url + "/collaborations/invitos"

        try{

            let my_id = 1

            const response = await axios.post(url, {
                "clinic_id": my_id
            })

            const {status, message, data} = response.data

            if(status == "success"){

                console.log(data)

                setRequest(data)

                return

            }else{

                await Swal.fire({
                    icon: "warning",
                    title: "Error",
                    text: message
                })

                return

            }


        }catch (e){

            await Swal.fire({
                icon: "warning",
                title: "Conn-Fail",
                text: "Server connection not established"
            })

            return

        }

    }

    useEffect(()=>{
        document.title = "Find Partners"

        get_invites()

    }, [request])

    const loadPartner = (request: any) => {
        setDisplay(request)
        setSteps(2)
    }

    return(
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>
            <div className={"vertical-overlay"}>

            </div>
            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>

                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Service Launcher: Received Requests</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/institution/services"}>Services</a></li>
                                            <li className="breadcrumb-item active">Providers</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-3"}>
                            <div className={"col"}>
                                {steps==1 &&(
                                    <>
                                        <table className={"table table-striped mt-2"}>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Requester
                                                    </th>
                                                    <th>
                                                        Urgency
                                                    </th>
                                                    <th>
                                                        Issue
                                                    </th>
                                                    <th>
                                                        Due Date
                                                    </th>
                                                    <th>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </>
                                )}

                                {steps==2 &&(

                                    <></>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Canvas></Canvas>
            </div>


        </>
    )
}

export default FindCollaborators;
