import React, {useEffect, useState} from 'react'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {Link} from "react-router-dom";

interface Props {
    fname: any,
    lname: any,
    county: any,
    town: any,
    specialization: any,
    verified: boolean,
    years: any,
    image: any
}

const Collaborators: React.FC<Props> = ({fname, lname, county, town, specialization, verified, years, image}) => {
    useEffect(()=>{
        document.title = `Dr. ${fname} ${lname}`
    }, [])
    const [step, setStep] = useState(1)
    const [contract, setContract] = useState<any>("")
    const [role, setRole] = useState<any>("")
    const [proposition, setPropostion] = useState<any>("")
    const [start, setStart] = useState<any>()
    const [end, setEnd] = useState<any>()

    return(
        <>
            <div className={"card"}>
                <div className={"card-header"} style={{backgroundColor: "#29b0ea" ,color: "white",  border: "None"}}>

                    <span style={{fontWeight: "Bold"}}>Dr. {fname} {lname}</span>

                </div>

                <div className={"card-body"}>

                    <div className={"row mt-3 text-center"}>
                        <div className={"col"}>

                        </div>

                        <div className={"col"}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/${image}`} className="me-3 rounded-circle avatar-xl" alt="user-pic" />
                        </div>

                        <div className={"col"}>



                        </div>
                    </div>

                </div>

                <div className={"row mt-3"}>

                    <div className={"col"}>
                        <div className={"container-fluid"}>
                            <div className={"row"}>
                                <div className={"col-md-3"}>

                                    <div className={"row mt-3"}>
                                        <div className={"col"}>

                                            <button style={{width: "100%"}} onClick={()=>{
                                                setStep(1)
                                            }} className={"btn btn-outline-success"}>Summary</button>

                                        </div>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className={"col"}>

                                            <button style={{width: "100%"}} onClick={()=>{
                                                setStep(2)
                                            }} className={"btn btn-outline-success"}>Documents</button>

                                        </div>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className={"col"}>

                                            <button style={{width: "100%"}} onClick={()=>{
                                                setStep(3)
                                            }} className={"btn btn-outline-success"}>History</button>

                                        </div>
                                    </div>

                                    <div className={"row mt-3"}>
                                        <div className={"col"}>

                                            <button style={{width: "100%"}} onClick={()=>{
                                                setStep(4)
                                            }} className={"btn btn-outline-success"}>Engage</button>

                                        </div>
                                    </div>

                                </div>

                                <div className={"col-md-9"}>

                                    {step === 1 && (
                                        <>
                                            <div className={"row"}>
                                                <div className={"col"}>

                                                    <div className={"row"}>
                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className={"bx bx-briefcase"}> </i> &nbsp;
                                                            Title
                                                        </div>

                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className={"bx bx-user-pin"}> </i> &nbsp;
                                                            First Name
                                                        </div>

                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className={"bx bx-user-pin"}> </i> &nbsp;
                                                            Last Name
                                                        </div>
                                                    </div>

                                                    <div className={"row"}>
                                                        <div className={"col"} >
                                                            Dr.
                                                        </div>

                                                        <div className={"col"} >
                                                            {fname}
                                                        </div>

                                                        <div className={"col"} >
                                                            {lname}
                                                        </div>
                                                    </div>

                                                    <div className={"row mt-4"}>
                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className={"bx bx-file"}> </i> &nbsp;
                                                            Professional Specialization
                                                        </div>
                                                    </div>

                                                    <div className={"row"}>
                                                        <div className={"col"}>
                                                            {specialization}
                                                        </div>
                                                    </div>

                                                    <div className={"row mt-4"}>
                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className="bx bx-location-plus"> </i> &nbsp;
                                                            County
                                                        </div>

                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className={"bx bx-location-plus"}> </i> &nbsp;
                                                            Town
                                                        </div>
                                                    </div>

                                                    <div className={"row"}>
                                                        <div className={"col"}>
                                                            {county}
                                                        </div>

                                                        <div className={"col"}>
                                                            {town}
                                                        </div>


                                                    </div>

                                                    <div className={"row mt-4"}>
                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className={"bx bx-badge-check"}> </i> &nbsp;
                                                            Verification status
                                                        </div>

                                                        <div className={"col"} style={{fontWeight: "Bold", fontSize: "15px"}}>
                                                            <i className={"bx bx-time"}> </i> &nbsp;
                                                            Years Experience
                                                        </div>
                                                    </div>

                                                    <div className={"row"}>
                                                        <div className={"col"}>
                                                            {verified && (
                                                                <>Verified</>
                                                            )}

                                                            {!verified && (
                                                                <>Unverified</>
                                                            )}
                                                        </div>

                                                        <div className={"col"}>
                                                            {years}
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {step === 2 && (
                                        <p className={"row mt-4 alert alert-info"}>
                                            Dr. {fname} {lname} has not uploaded any professional documents or made any available
                                        </p>
                                    )}

                                    {step === 3 && (
                                        <p className={"row mt-4 alert alert-info"}>
                                            When Dr. {fname} {lname} first shares their work history, it will appear here.
                                        </p>
                                    )}

                                    {step === 4 && (
                                        <>
                                            <p className={"mt-2 alert alert-success"}>You are about to send a collaboration request to Dr. {fname} {lname}</p>

                                            <div className={"row mt-2"}>
                                                <div className={"col"}>
                                                    <label style={{fontSize: "14px", fontWeight: "Bold"}}>
                                                        <i className="bx bx-pen"> </i> &nbsp;
                                                        Contract Type
                                                    </label>
                                                    <select className={"form-control"} value={contract} onChange={(e)=>{setContract(e.currentTarget.value)}}>
                                                        <option value={"once"}>Uzima One Time</option>
                                                        <option value={"short"}>Uzima Short Term</option>
                                                        <option value={"medium"}>Uzima Medium Term</option>
                                                        <option value={"long"}>Uzima Long Term</option>
                                                        <option value={"life"}>Uzima Life Long</option>
                                                    </select>

                                                    <div className={"row"}>
                                                        <div className={"col"}>
                                                            <label className={"mt-2"} style={{fontSize: "14px", fontWeight: "Bold"}}>
                                                                <i className="bx bx-calendar-plus"> </i> &nbsp;
                                                                Start Date
                                                            </label>

                                                            <input className={"form-control"} type={"datetime-local"} onChange={(e)=>{
                                                                setStart(e.currentTarget.value)
                                                            }}/>
                                                        </div>

                                                        <div className={"col"}>
                                                            <label className={"mt-2"} style={{fontSize: "14px", fontWeight: "Bold"}}>
                                                                <i className="bx bx-calendar-minus"> </i> &nbsp;
                                                                End Date
                                                            </label>

                                                            <input className={"form-control"} type={"datetime-local"} onChange={(e)=>{
                                                                setEnd(e.currentTarget.value)
                                                            }}/>
                                                        </div>
                                                    </div>

                                                    <div className={"row mt-2 mb-4"}>
                                                        <div className={"col"}>
                                                            <label style={{fontSize: "14px", fontWeight: "Bold"}}>
                                                                <i className="bx bx-notepad"> </i> &nbsp;
                                                                Add Info
                                                            </label>
                                                            <CKEditor editor={ClassicEditor} data={proposition} onChange={(e, d)=>{
                                                                setPropostion(d.getData)
                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className={"row"}>
                                                        <div className={"col-md-4"}>

                                                            <button style={{width: "100%"}} className={"btn btn-success"}>Send Request</button>

                                                        </div>

                                                        <div className={"col-md-4"}>

                                                            <button style={{width: "100%"}} className={"btn btn-warning"}>Clear Fields</button>

                                                        </div>

                                                        <div className={"col-md-4"}>

                                                            <Link to={"/institution/home"} style={{width: "100%"}} className={"btn btn-danger"}>Cancel Request</Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={"card-footer"}>

                </div>
            </div>
        </>
    )
}

export default Collaborators