import React, {useCallback, useEffect, useState} from 'react'
import {usePostData, useGetData, useFacilityServices, useBaseUrl} from "../../utils/utils";
import Swal from "sweetalert2";
import axios from "axios";
import localforage from "localforage";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import localForage from "localforage";
import FormData from "form-data";

const RegisterInstitution: React.FC = () => {

    const hideEmail = (email: any) => {
        return email

    }

    {/*common states*/}
    const base_url = useBaseUrl()
    const [pageTitle, setPageTitle] = useState("Registration - Institutional Partners")
    const [step, setStep] = useState(1)
    const [subStep, setSubStep] = useState(1)
    const [counties, setCounties] = useState<any[]>([])
    const [towns, setTowns] = useState<any[]>([])
    const [titles, setTitles] = useState<any[]>([])
    const [clinics, setClinics] = useState<any[]>([])
    const [checkedItems, setCheckedItems] = useState<any[]>([])
    const [clinicServices, setClinicServices] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [allData, setAllData] = useState<boolean>(false)
    const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false)

    {/*institution states*/}
    const [selectedCounty, setSelectedCounty] = useState("")
    const [selectedTown, setSelectedTown] = useState("")
    const [institutionName, setInstitutionName] = useState("")
    const [institutionWebsite, setInstitutionWebsite] = useState("")
    const [institutionLogo, setInstitutionLogo] = useState<any>(null)
    const [institutionEmail, setInstitutionEmail] = useState("")
    const [institutionPassword, setInstitutionPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [institution, setInstitution] = useState<any>("")


    {/*clinic states*/}
    const [clinic, setClinic] = useState("")
    const [clinicName, setClinicName] = useState("")
    const [clinicWebsite, setClinicWebsite] = useState("")
    const [clinicPostCode, setClinicPostCode] = useState("")
    const [clinicLogo, setClinicLogo] = useState<any>()
    const [clinicEmail, setClinicEmail] = useState("")
    const [clinicDescription, setClinicDescription] = useState("Introduce your facility to patients and other partners in your own words.")
    const [clinicCategories, setClinicCategories] = useState<any[]>([])
    const [clinicCapacity, setClinicCapacity] = useState("")
    const [clinicPhone, setClinicPhone] = useState<any>("")
    const [clinicPassword, setClinicPassword] = useState("")
    const [selectedCategory, setselectedCategory] = useState("")


    {/*staff states*/}
    const [staff, setStaff] = useState("")
    const [staffTitle, setStaffTitle] = useState("")
    const [staffFname, setStaffFname] = useState("")
    const [staffMname, setStaffMname] = useState("")
    const [staffLname, setStaffLname] = useState("")
    const [staffAvatar, setStaffAvatar] = useState()
    const [staffEmail, setStaffEmail] = useState("")
    const [staffpassword, setStaffPassword] = useState("")
    const [staffPhone, setStaffPhone] = useState("")

    {/*services*/}
    const [servicesForm, setServicesForm] = useState<any[]>([])
    const [baseRateValues, setBaseRateValues] = useState<Record<string, string>>({});
    const [hourlyValues, setHourlyValues] = useState<Record<string, string>>({});
    const [hiddenInstitutionEmail, setHiddenInstitutionEmail] = useState<any>(hideEmail(institutionEmail))
    const [hiddenClinicEmail, setHiddenClinicEmail] = useState<any>(hideEmail(clinicEmail))
    const [hiddenStaffEmail, setHiddenStaffEmail] = useState<any>(hideEmail(staffEmail))


    {/*verifications*/}
    const [verificationId, setVerificationId] = useState<any>()
    const [institutionCode, setInstitutionCode] = useState<any>()
    const [facilityCode, setFacilityCode] = useState<any>()
    const [staffCode, setStaffCode] = useState<any>()



    useEffect(()=>{
        document.title = pageTitle
        let institute = ""
        let fecility = ""
        let staffy = ""

        localforage.getItem("institution_id").then((institute: any)=>{
            console.log(staffEmail + clinicEmail + institutionEmail)
                if(institution !== "" && institution !== null){

                    localforage.getItem("clinic_id").then((cliny: any)=>{

                        if(cliny !== "" && cliny !== null){

                            localforage.getItem("staff_id").then((starf: any)=>{

                                if(starf !=="" && starf !== null){

                                    //clinic + inst + staff are set
                                    setStaff(starf)
                                    setStep(4)

                                    if(allData){
                                        setStep(5)

                                    }
                                }else{

                                    //clonic + inst are set
                                    setInstitution(institute)
                                    setClinic(cliny)
                                    setStep(3)
                                }

                            })


                        }else{
                            //facility not submitted
                            setInstitution(institute)
                            setStep(2)

                        }

                    })

                }
        })

        Prefetch_counties()
        Prefetch_categories("")
        Prefetch_titles()
        GetServices()
    }, [selectedTown])

    const Register_institution = async(e: any) =>{

        e.preventDefault()

        let url = `${useBaseUrl()}/facilities/create-institution`

        try{

            const formData = new FormData()
            formData.append("town_id", selectedTown)
            formData.append("institution_name", institutionName)
            formData.append("website", institutionWebsite)
            formData.append("logo", institutionLogo)
            formData.append("email", institutionEmail)
            formData.append("password", institutionPassword)

            const response = await axios.post(url, formData)

            const {status, message, data} = response.data

            if(status === "success"){

                setInstitution(data.institution)
                setInstitutionPassword("")


                await localforage.setItem("institution_id", data.institution)

                setStep(2)
                setPageTitle("Registration - Add Clinic to Institution: "+ institutionName)

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Data Error",
                    text: "Server says: " + message + ". Please check your info and try again"
                })

                return
            }

        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Fail",
                text: "Token mismatch. Are you offline?"
            })

            return

        }

    }

    const Add_facility = async(e: any) =>{
        e.preventDefault()

        let url = `${useBaseUrl()}/facilities/new-clinic`

        try{

            const formData = new FormData()
            formData.append("clinic_name", clinicName)
            formData.append("town", selectedTown)
            formData.append("website", clinicWebsite)
            formData.append("postcode", clinicPostCode)
            formData.append("logo", clinicLogo)
            formData.append("description", clinicDescription)
            formData.append("category", selectedCategory)
            formData.append("capacity", clinicCapacity)
            formData.append("email", clinicEmail)
            formData.append("phone", clinicPhone)
            formData.append("password", clinicPassword)
            formData.append("institution_id", institution)

            const response = await axios.post(url, formData)

            const {status, message, data} = response.data

            if(status==="success"){

                setClinic(data.clinic_id)
                await localforage.setItem("clinic_id", data.clinic_id)
                setClinicPassword("")
                setClinicPhone("")
                GetFacilityList()
                setStep(3)

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Server says: " + message + "."
                })

                return

            }

        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Connection Fail",
                text: "Server refused your connection request. are you offline?"
            })

            return

        }
    }

    const GetFacilityList = async ()=>{

        let url = `${useBaseUrl()}/facilities/institution-facilities`

        const formData = new FormData()
        formData.append("institution_id", institution)

        const resp = await usePostData(url, formData)

        setClinics(resp)

    }

    const Add_facility_staff = async (e: any) => {

        e.preventDefault()

        let url = `${useBaseUrl()}/facilities/add-staff`

        try{

            let formData =new FormData()


            formData.append("title", staffTitle)
            formData.append("clinic_id", clinic)
            formData.append("first_name", staffFname)
            formData.append("middle_name", staffMname)
            formData.append("last_name", staffLname)
            formData.append("town_id", selectedTown)
            formData.append("email", staffEmail)
            formData.append("password", staffpassword)
            formData.append("avatar", staffAvatar)
            formData.append("phone", staffPhone)

            const resp = await axios.post(url, formData)

            const {status, message, data} = resp.data

            if(status=="success"){

                await localforage.setItem("staff_id", data.staff_id)

                setStaffPassword("")

                setStaffPhone("")

                setStaff(data.staff_id)

                setStep(4)

                setPageTitle("Select your services")

                return
            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Server says: " + message
                })

                return

            }


        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Unable to initiate communication with the server"
            })

            return

        }

    }

    const Prefetch_counties = async () => {

        let url = `${useBaseUrl()}/facilities/get-counties`

        try{

            const response = await axios.post(url, {
                "county_id": 0
            })

            const {status, message, data} = response.data

            if(status === "success"){

                setCounties(data)

                return

            }else{

                await Swal.fire({
                    icon: "error",
                    text: "Error fetching counties because: " + message,
                    title: "Can't fetch counties"

                })

                return

            }

        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "unable to fetch counties. Please refresh this page. If the issue persists then contact us."
            })

            return

        }
    }

    const Fetch_towns = async (county: any) => {
        let url = `${useBaseUrl()}/facilities/get-towns`

        try{

            const response = await axios.post(url, {
                "county_id": county
            })

            const {status, message, data} = response.data

            if(status==="success"){

                setTowns(data)

                return

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Fetch town failed: " + message
                })

                return

            }

        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Unable to load towns. Please check your connection and try again."
            })

            return

        }
    }

    const Prefetch_categories = async (category: any) => {

        let url = `${useBaseUrl()}/facilities/categories`

        try{
            let formData = new FormData()
            formData.append("category_id", category)

            let response =await axios.post(url, formData)

            const {status, message, data} = response.data

            if(status == "success"){

                setClinicCategories(data)

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Unable to fetch categories. Serverside error: " + message
                })

                return

            }
        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title : "Warning",
                text: "Unable to fetch categories!"
            })

            return

        }

    }

    const ServiceSelectors = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value

        const isChecked = event.currentTarget.checked

        if(isChecked){

            setCheckedItems((prevCheckedItems) => [...prevCheckedItems, val])

        }else{

            setCheckedItems((prevCheckedItems)=>prevCheckedItems.filter((item)=> item !== val))

            setBaseRateValues((prevBaseRateValues) => {
                const updatedVals = {...prevBaseRateValues}
                delete prevBaseRateValues[val]
                return updatedVals
            })

            setHourlyValues((prevHourlyValues)=>{

                const updatedVals = {...prevHourlyValues}
                delete updatedVals[val]
                return updatedVals

            })

        }
    }

    const SaveSelected = async (event: React.FormEvent) => {
        event.preventDefault()



        const formData = checkedItems.map((id: any)=>{
            setIsLoading(true)
            const baseRate = baseRateValues[id] || ""
            const hourly = hourlyValues[id] || ""

            return{
                id,
                hourly,
                baseRate,
                staff,
                clinic
            }

        })

        console.log(formData)

        const url = `${useBaseUrl()}/facilities/add-services`

        try{

            const resp = await axios.post(url, formData).finally(()=>{
                    setIsLoading(false)
                }
            )

            const {status, message, data} = resp.data

            if(status=="success"){

                await Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Your services have been added to your account. For the final step, your credentials will be verified"
                })

                setAllData(true)
                setStep(5)

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Client Error",
                    text: "Server says: " + message
                })

                return

            }

        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text:"Unable to initiate client-server communication. Are you offline?"
            })

            return

        }
    }

    const RateChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) =>{

        const {value} = e.currentTarget

        setBaseRateValues((prevValues)=>({...prevValues, [id]: value}))

    }

    const HourlyChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {

        const {value} = e.currentTarget

        setHourlyValues((prevValues)=>({...prevValues, [id]: value}))
    }

    const Prefetch_titles = async () => {

        let url = `${useBaseUrl()}/facilities/titles`

        try{

            const resp = await axios.get(url)

            const {status, message, data} = resp.data

            if(status === "success"){

                setTitles(data)

                return

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Server says: " + message
                })

                return

            }

        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Unable to inititate server communication. Cannot fetch titles"
            })

            return

        }

    }

    const GetServices = async () => {

        /**const data: any = await useFacilityServices()


        const serviceIdToNameMap: {[key: number]: string} = {}
        const serviceIdToImgMap: {[key: number]: string} = {}

        data.forEach((facil: any) => {
            serviceIdToNameMap[facil.service_id] = facil.service_name
            serviceIdToImgMap[facil.service_id] = facil.service_logo
        })

        setClinicServices(data)**/

        const data: any = await useFacilityServices()

        //const vals = Object.values(data)

        setClinicServices(data)


    }

    const Add_services = async () => {

    }

    const VerifyCodes = async (e: any) => {

        e.preventDefault()

        let url = `${useBaseUrl()}/facilities/verify-codes`

        let formData = new FormData()

        formData.append("staff_email", staffEmail)
        formData.append("facility_email", clinicEmail)
        formData.append("institution_email", institutionEmail)

        const resp = await usePostData(url, formData)

        setVerificationId(resp.verification_id)

    }

    const reset_fields = useCallback(()=>{

    }, [])

    const goback = useCallback(()=>{

        setStep(step - 1)

    },[])



    return (
        <>
            <div className={"container-fluid"}>
                <div className={"row mt-4"}> </div>

                <div className={"row mt-4"}> </div>

                <div className={"row mt-4 text-center"}> <h3>{pageTitle}</h3> </div>

                <div className={"row mt-4"}> </div>

                <div className={"row mt-4"}> </div>

                <div className={"row mt-4"}>

                    <div className={"col-md-1"}> </div>
                    <div className={"col-md-10"}>

                        <div className={"card card-animate"}>
                            <div className={"card-header"}>

                                <div className={"row"}>

                                    <div className={"col"}>
                                        {step===1 && (
                                            <div className={"text-center"}>
                                                <img style={{maxHeight: "45px"}} alt={"step-1"} src={`${process.env.PUBLIC_URL}/assets/images/1.png`} />
                                                <br />
                                                <h6>Step 1: Institution <i className="bx bx-clinic"></i> </h6>
                                            </div>
                                        )}
                                    </div>
                                    <div className={"col"}>
                                        {step===2 && (
                                            <div className={"text-center"}>
                                                <img style={{maxHeight: "45px"}} alt={"step-2"} src={`${process.env.PUBLIC_URL}/assets/images/2.png`} />
                                                <br />
                                                <h6>Step 2: Add Facility </h6>
                                            </div>
                                        )}
                                    </div>
                                    <div className={"col"}>
                                        {step===3 && (
                                            <div className={"text-center"}>
                                                <img style={{maxHeight: "45px"}} alt={"step-3"} src={`${process.env.PUBLIC_URL}/assets/images/3.png`} />
                                                <br />
                                                <h6>Step 3: Add User </h6>
                                            </div>
                                        )}
                                    </div>
                                    <div className={"col"}>
                                        {step===4 &&(
                                            <div className={"text-center"}>
                                                <img style={{maxHeight: "45px"}} alt={"step-4"} src={`${process.env.PUBLIC_URL}/assets/images/4.png`} />
                                                <br />
                                                <h6>Step 4: Add services </h6>
                                            </div>
                                        )}
                                    </div>
                                    <div className={"col"}>
                                        {step===5 &&(
                                            <div className={"text-center"}>
                                                <img style={{maxHeight: "45px"}} alt={"step-5"} src={`${process.env.PUBLIC_URL}/assets/images/5.png`} />
                                                <br />
                                                <h6>Step 5: Verify & Activate </h6>
                                            </div>
                                        )}
                                    </div>

                                </div>

                            </div>

                            <div className={"card-body"}>
                                {step===1 &&(
                                    <form onSubmit={Register_institution}>


                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-globe"}> </i> &nbsp;
                                                    Institution County
                                                </label>
                                                <select value={selectedCounty} className={"form-control mt-2"} onChange={(e)=>{setSelectedCounty(e.currentTarget.value); Fetch_towns(e.currentTarget.value)}}>
                                                    <option value={47}>Choose County</option>
                                                    {Object.values(counties).map((county)=>(

                                                        <option value={county.county_code}>{county.county}</option>

                                                    ))}
                                                </select>
                                            </div>

                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-current-location"}> </i> &nbsp;
                                                    Institution Town
                                                </label>

                                                <select className={"mt-2 form-control"} value={selectedTown} disabled={selectedCounty===""} onChange={(e)=>{setSelectedTown(e.currentTarget.value)}}>
                                                    <option value={"9999"}>Choose A Town</option>
                                                    {Object.values(towns).map((town: any)=>(
                                                        <option value={town.town_id}>{town.town}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>





                                        <label className={"mt-3 form-label"}>
                                            <i className={"bx bx-file-blank"}> </i> &nbsp;
                                            Institution Logo - <em>Optional Field</em>
                                        </label>
                                        <input type={"file"} className={"form-control mt-2"} accept={".jpg, .png"}  onChange={(e: any)=>{

                                            setInstitutionLogo(e.currentTarget.files[0])
                                        }}/>

                                        <label className={"mt-3 form-label"}>
                                            <i className={"bx bx-clinic"}> </i> &nbsp;
                                            Institution Name
                                        </label>

                                        <input className={"form-control mt-2"} placeholder={"Institution Name"} type={"text"} value={institutionName} onChange={(e)=>{setInstitutionName(e.currentTarget.value)}} />


                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-server"}> </i> &nbsp;
                                                    Website
                                                </label>
                                                <input type={"text"} className={"form-control mt-2"} placeholder={"Institution Website"} value={institutionWebsite} onChange={(e)=>{setInstitutionWebsite(e.currentTarget.value)}} />

                                            </div>

                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-envelope"}> </i> &nbsp;
                                                    Email
                                                </label>
                                                <input type={"email"} className={"form-control mt-2"} placeholder={"Institution Email"} value={institutionEmail} onChange={(e)=>{ setInstitutionEmail(e.currentTarget.value)} } />

                                            </div>
                                        </div>


                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-key"}> </i> &nbsp;
                                                    Password
                                                </label>
                                                <input type={"password"} className={"form-control mt-2"} placeholder={"Password"} value={institutionPassword} onChange={(e)=>{setInstitutionPassword(e.currentTarget.value)}} />

                                            </div>

                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-key"}> </i> &nbsp;
                                                    Confirm Password {institutionPassword !== confirmPassword &&(<span className={"text-danger"}>Passwords do not match</span>)} {institutionPassword !== "" && institutionPassword === confirmPassword &&(<span className={"text-success"}>Passwords match!</span>)}
                                                </label>
                                                <input type={"password"} className={"form-control mt-2"} placeholder={"Confirm Password"} value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.currentTarget.value)}} />

                                            </div>
                                        </div>

                                        <div className={"row mt-3 text-center"}>
                                            <div className={"col"}>
                                                <button className={"btn btn-success"} disabled={confirmPassword !== institutionPassword || selectedCounty==="" || selectedTown === "" || institutionEmail==="" || institutionName ===""} ><i className={"bx bx-check"}> </i> &nbsp; Register</button>
                                            </div>
                                            <div className={"col"}>
                                                <button className={"btn btn-primary"} onClick={reset_fields} ><i className={"bx bx-reset"}> </i> &nbsp; Clear Fields</button>
                                            </div>
                                            <div className={"col"}>
                                                <button className={"btn btn-danger"} onClick={goback}><i className={"bx bx-comment-x"}> </i> &nbsp; Cancel Process</button>
                                            </div>

                                        </div>
                                    </form>
                                )}

                                {step===2 &&(

                                    <form onSubmit={Add_facility}>

                                        <p className={"alert alert-info"}>
                                            Add facilities to institution: <strong>{institutionName}</strong>. You can add multiple facilities under one institution.
                                        </p>
                                        {institution === "" && (<p className={"text-danger"}>
                                            Warning! Institution info missing from this request as such you cannot proceed. Please go one step back and ensure it was successfully created.
                                        </p>)}

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-globe"}> </i> &nbsp;
                                                    Facility County <sup>*</sup>
                                                </label>
                                                <select value={selectedCounty} className={"form-control mt-2"} onChange={(e)=>{setSelectedCounty(e.currentTarget.value); Fetch_towns(e.currentTarget.value)}}>
                                                    <option value={47}>Choose County </option>
                                                    {Object.values(counties).map((county)=>(

                                                        <option value={county.county_code}>{county.county}</option>

                                                    ))}
                                                </select>
                                            </div>

                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-current-location"}> </i> &nbsp;
                                                    Facility Town <sup>*</sup>
                                                </label>

                                                <select className={"mt-2 form-control"} value={selectedTown} disabled={selectedCounty===""} onChange={(e)=>{setSelectedTown(e.currentTarget.value)}}>
                                                    <option value={999}>Choose Town</option>
                                                    {Object.values(towns).map((town: any)=>(
                                                        <option value={town.town_id}>{town.town}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className={"row"}>

                                            <div className={"col"}>

                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-clinic"}> </i> &nbsp;
                                                    Facility Name <sup>*</sup>
                                                </label>

                                                <input className={"form-control mt-2"} placeholder={"Facility Name"} type={"text"} value={clinicName} onChange={(e)=>{setClinicName(e.currentTarget.value)}} />


                                            </div>

                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-book"}> </i> &nbsp;
                                                    Facility Category <sup>*</sup>
                                                </label>
                                                <select value={selectedCategory} className={"form-control mt-2"} onChange={(e)=>{
                                                    setselectedCategory(e.currentTarget.value)}}>
                                                    <option >Choose Category </option>
                                                    {Object.values(clinicCategories).map((cat)=>(

                                                        <option value={cat.id}>{cat.name}</option>

                                                    ))}
                                                </select>
                                            </div>

                                        </div>

                                        <label className={"mt-3 form-label"}>
                                            <i className={"bx bx-file-blank"}> </i> &nbsp;
                                            Facility Logo - <em>Optional Field</em>
                                        </label>
                                        <input type={"file"} className={"form-control mt-2"} accept={".jpg, .png"}  onChange={(e: any)=>{

                                            setClinicLogo(e.currentTarget.files[0])
                                        }}/>

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-at"}> </i> &nbsp;
                                                    Facility Email <sup>*</sup>
                                                </label>
                                                <input type={"email"} className={"form-control mt-2"} placeholder={"Facility Email"} value={clinicEmail} onChange={(e)=>{ setClinicEmail(e.currentTarget.value)} } />

                                            </div>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-server"}> </i> &nbsp;
                                                    Facility Website - <em>Optional</em>
                                                </label>
                                                <input type={"text"} className={"form-control mt-2"} placeholder={"Facility Website"} value={clinicWebsite} onChange={(e)=>{setClinicWebsite(e.currentTarget.value)}} />

                                            </div>
                                        </div>

                                        <label className={"mt-3 form-label"}>
                                            <i className={"bx bx-chart"}> </i> &nbsp;
                                            Your Facility Capacity
                                        </label>

                                        <input type={"number"} className={"form-control mt-2"} placeholder={"How many patients can your facility handle at a given time?"} value={clinicCapacity} onChange={(e)=>{setClinicCapacity(e.currentTarget.value)}}/>

                                        <label className={"mt-3 form-label"}>
                                            <i className={"bx bx-detail"}> </i> &nbsp;
                                            About Facility
                                        </label>
                                        <CKEditor editor={ClassicEditor} data={clinicDescription} onChange={(d , e )=>{setClinicDescription(e.getData())}} />

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-key"}> </i> &nbsp;
                                                    Facility Password
                                                </label>
                                                <input type={"password"} className={"form-control mt-2"} placeholder={"Password"} value={clinicPassword} onChange={(e)=>{setClinicPassword(e.currentTarget.value)}} />
                                            </div>
                                            <div className={"col"}>
                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-key"}> </i> &nbsp;
                                                    Confirm Password {clinicPassword !== confirmPassword &&(<span className={"text-danger"}>Passwords do not match</span>)} {clinicPassword !== "" && clinicPassword === confirmPassword &&(<span className={"text-success"}>Passwords match!</span>)}
                                                </label>
                                                <input type={"password"} className={"form-control mt-2"} placeholder={"Confirm Password"} value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.currentTarget.value)}} />
                                            </div>
                                        </div>

                                        <div className={"row mt-3 text-center"}>
                                            <div className={"col"}>
                                                <button className={"btn btn-success"} disabled={confirmPassword !== clinicPassword || selectedCounty==="" || selectedTown === "" || clinicEmail==="" || clinicName ===""} ><i className={"bx bx-check"}> </i> &nbsp; Add Facility</button>
                                            </div>
                                            <div className={"col"}>
                                                <button className={"btn btn-primary"} onClick={reset_fields} ><i className={"bx bx-reset"}> </i> &nbsp; Clear Fields</button>
                                            </div>
                                            <div className={"col"}>
                                                <button className={"btn btn-danger"} onClick={goback}><i className={"bx bx-comment-x"}> </i> &nbsp; Go Back</button>
                                            </div>
                                        </div>

                                    </form>
                                )}
                                {step===3 &&(
                                    <form onSubmit={Add_facility_staff}>

                                        <label className={"form-label mt-3"}>
                                            <i className="bx bx-album"> </i> &nbsp;
                                            Title
                                        </label>
                                        <select required={true} value={staffTitle} className={"mt-1 form-control"} onChange={(e)=>{setStaffTitle(e.currentTarget.value)}}>
                                            <option value={0}>No title</option>
                                            {Object.values(titles).map((obj:any)=>(
                                                <option value={obj.id}>{obj.title}</option>
                                            ))}
                                        </select>

                                        <label className={"form-label mt-3"}>
                                            <i className="bx bx-image-add"> </i> &nbsp;
                                            User Photo - <em>Optional</em>
                                        </label>

                                        <input type={"file"} className={"form-control mt-1"} onChange={(e: any)=>{setStaffAvatar(e.currentTarget.files[0])}}/>

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>
                                                    <i className="bx bx-user"> </i> &nbsp;
                                                    First Name
                                                </label>
                                                <input required={true} className={"form-control mt-1"} type={"text"} placeholder={"User's First Name"} value={staffFname} onChange={(e)=>{setStaffFname(e.currentTarget.value)}} />

                                            </div>
                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>
                                                    <i className="bx bx-user"> </i> &nbsp;
                                                    Middle Name - <em>Optional</em>
                                                </label>
                                                <input required={true} className={"form-control mt-1"} type={"text"} placeholder={"User's Middle Name"} value={staffMname}  onChange={(e)=>{setStaffMname(e.currentTarget.value)}} />

                                            </div>
                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>
                                                    <i className="bx bx-user"> </i> &nbsp;
                                                    Last Name
                                                </label>
                                                <input required={true} className={"form-control mt-1"} type={"text"} placeholder={"User's Last Name"} value={staffLname} onChange={(e)=>{setStaffLname(e.currentTarget.value)}} />

                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col"}>

                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-globe"}> </i> &nbsp;
                                                    User County <sup>*</sup>
                                                </label>
                                                <select value={selectedCounty} className={"form-control mt-2"} onChange={(e)=>{setSelectedCounty(e.currentTarget.value); Fetch_towns(e.currentTarget.value)}}>
                                                    <option value={47}>Choose County </option>
                                                    {Object.values(counties).map((county)=>(

                                                        <option value={county.county_code}>{county.county}</option>

                                                    ))}
                                                </select>

                                            </div>

                                            <div className={"col"}>

                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-current-location"}> </i> &nbsp;
                                                    User Town <sup>*</sup>
                                                </label>

                                                <select required={true} className={"mt-2 form-control"} value={selectedTown} disabled={selectedCounty===""} onChange={(e)=>{setSelectedTown(e.currentTarget.value)}}>
                                                    <option value={999}>Choose Town</option>
                                                    {Object.values(towns).map((town: any)=>(
                                                        <option value={town.town_id}>{town.town}</option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>
                                                    <i className="bx bx-at"> </i> &nbsp;
                                                    Email
                                                </label>
                                                <input className={"form-control mt-1"} type={"text"} placeholder={"User's Email Address"} value={staffEmail} onChange={(e)=>{setStaffEmail(e.currentTarget.value)}} />

                                            </div>

                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>
                                                    <i className="bx bx-phone"> </i> &nbsp;
                                                    Phone <em>Optional</em>
                                                </label>
                                                <input className={"form-control mt-1"} type={"text"} placeholder={"User's Phone Number"} value={staffPhone} onChange={(e)=>{setStaffPhone(e.currentTarget.value)}} />

                                            </div>

                                        </div>

                                        <div className={"row"}>
                                            <div className={"col"}>

                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-key"}> </i> &nbsp;
                                                    Set Password <sup>*</sup>
                                                </label>
                                                <input type={"password"} required={true} className={"form-control mt-2"} placeholder={"Password"} value={staffpassword} onChange={(e)=>{setStaffPassword(e.currentTarget.value)}} />

                                            </div>
                                            <div className={"col"}>

                                                <label className={"mt-3 form-label"}>
                                                    <i className={"bx bx-key"}> </i> &nbsp;
                                                    Confirm Password {staffpassword !== confirmPassword &&(<span className={"text-danger"}>Passwords do not match</span>)} {staffpassword !== "" && staffpassword === confirmPassword &&(<span className={"text-success"}>Passwords match!</span>)}
                                                </label>
                                                <input type={"password"} className={"form-control mt-2"} placeholder={"Confirm Password"} value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.currentTarget.value)}} />

                                            </div>
                                        </div>



                                        <div className={"row mt-3 text-center"}>
                                            <div className={"col"}>
                                                <button className={"btn btn-success"} disabled={confirmPassword !== staffpassword || selectedCounty==="" || selectedTown === "" || staffEmail==="" } ><i className={"bx bx-check"}> </i> &nbsp; Register</button>
                                            </div>
                                            <div className={"col"}>
                                                <button className={"btn btn-primary"}><i className={"bx bx-reset"} onClick={reset_fields}> </i> &nbsp; Clear Fields</button>
                                            </div>
                                            <div className={"col"}>
                                                <button className={"btn btn-danger"}><i className={"bx bx-comment-x"} onClick={goback}> </i> &nbsp; Go Back</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                                {step===4 &&(
                                    <form onSubmit={Add_services}>

                                        <label className={"form-label mt-3"}>
                                            <i className="bx bx-abacus"> </i> &nbsp;
                                            Select The Services You Offer
                                        </label>

                                        <div className="row mt-1" style={{border: "1px dashed #a4b5c1"}}>
                                            {
                                                Object.values(clinicServices).map((facil:any)=>(
                                                    <div className="col" key={facil.service_id}>
                                                        <input type="checkbox" className={"checkbox"} value={facil.service_id} onChange={ServiceSelectors} />
                                                        <label>{facil.service_name} facility</label>
                                                    </div>

                                                ))
                                            }
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col">
                                                <h6>Add Services & Your Charge: </h6>
                                                <div className={"row mt-2"}>
                                                    {checkedItems.map((item, index) => (
                                                        <div className={"col"}>
                                                            <div className="card mt-2 card-animate" key={item} style={{borderRadius: 0}}>
                                                                <div className="card-header">
                                                                    <h6>{item}. &nbsp; {clinicServices[item-1].service_name}</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    <form>
                                                                        <label className="form-label mt-2">Minimum Rate</label>
                                                                        <input
                                                                            type="number"
                                                                            required={true}
                                                                            className="form-control mt-1"
                                                                            value={baseRateValues[item] || ''}
                                                                            onChange={(e) => RateChange(e, item)}
                                                                        />

                                                                        <label className="form-label mt-2">Per Task Rate</label>
                                                                        <input
                                                                            type="number"
                                                                            required={true}
                                                                            className="form-control mt-1"
                                                                            value={hourlyValues[item] || ''}
                                                                            onChange={(e) => HourlyChange(e, item)}
                                                                        />
                                                                    </form>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <button className="btn btn-primary mt-3" onClick={SaveSelected}>
                                            Add Selected Service(s)
                                        </button>

                                    </form>

                                )}

                                {step===5 && (
                                    <div className={"container-fluid"}>

                                        <h5 className={"text-center"}>Verify Your Registration Info & Email Accounts</h5>

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>Institution Email</label>
                                                <input type={"email"} disabled={true} value={institutionEmail} className={"form-control mt-1"}/>

                                                {verificationId !== "" && verificationId !== null && (
                                                    <>
                                                        <label className={"form-label mt-2"}>
                                                            <i className={"bx bx-key"}> </i>
                                                            Institution Code
                                                        </label>
                                                        <input type={"text"} className={"form-control"} value={institutionCode} onChange={(e)=>{
                                                            setInstitutionCode(e.currentTarget.value)
                                                        }}> </input>
                                                    </>
                                                )}

                                            </div>
                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>Facility Email</label>
                                                <input type={"email"} disabled={true} value={clinicEmail} className={"form-control mt-1"}/>

                                                {verificationId !== "" && verificationId !== null && (
                                                    <>
                                                        <label className={"form-label mt-2"}>
                                                            <i className={"bx bx-key"}> </i>
                                                            Facility Code
                                                        </label>
                                                        <input type={"text"} className={"form-control"} value={facilityCode} onChange={(e)=>{
                                                            setFacilityCode(e.currentTarget.value)
                                                        }}> </input>
                                                    </>
                                                )}
                                            </div>


                                            <div className={"col"}>
                                                <label className={"form-label mt-3"}>User's Email</label>
                                                <input type={"email"} disabled={true} value={staffEmail} className={"form-control mt-1"}/>

                                                {verificationId !== "" && verificationId !== null && (
                                                    <>
                                                        <label className={"form-label mt-2"}>
                                                            <i className={"bx bx-key"}> </i>
                                                            User Code
                                                        </label>
                                                        <input type={"text"} className={"form-control"} value={staffCode} onChange={(e)=>{
                                                            setStaffCode(e.currentTarget.value)
                                                        }}> </input>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        {verificationId === "" && verificationId === null && (
                                            <div className={"row mt-4"}>

                                                <div className={"col"}>
                                                    <input className={"checkbox"} type={"checkbox"} onChange={(e)=>{
                                                        setIsAcknowledged(e.currentTarget.checked)
                                                    }} /> &nbsp;
                                                    <label className={"form-label"}> <em>I acknowledge these are registered emails.</em></label>
                                                </div>

                                            </div>
                                        )}

                                        <div className={"row"}>
                                            <div className={"col"}>

                                                {
                                                    verificationId === "" && verificationId === null && (
                                                        <button className={"btn btn-primary"} disabled={isAcknowledged} onClick={VerifyCodes}>Get codes</button>
                                                    )
                                                }

                                                {verificationId !== "" && verificationId !== null && (
                                                    <button className={"btn btn-primary"} disabled={isAcknowledged} onClick={VerifyCodes}>Get codes</button>
                                                )}


                                            </div>
                                        </div>

                                    </div>
                                )}

                            </div>
                        </div>

                    </div>
                    <div className={"col-md-1"}> </div>

                </div>

            </div>

        </>
    )
}

export default RegisterInstitution