import React, {useEffect, useReducer, useState} from 'react'
import {useBaseUrl, useCounties, usePostData, useTowns} from "../../utils/utils";
import Swal from "sweetalert2";
import SearchResullts from "./SearchResullts";
import {Link} from "react-router-dom";

const SearchEl: React.FC = () => {
    const countyList: any = []


    const LoadTowns = async ( county: any) => {

        const towns = await useTowns(county)

        dispatch({type: "SET_TOWNS", payload: towns})

    }



    const initialState = {
        pageHeading: "Uzima Service Finder App",
        subHeading: "Uzima Health Care is here to connect you to health service providers.",
        textSelector: "Kindly tell us what is your health care role",
        user: "professionals",
        userType : "guest",
        userId: 877,
        counties: [],
        selectedCounty: 47,
        towns: [],
        selectedTown: 1
    }

    const reducer = (state: any, action: any) => {
        console.log(state.counties)

        switch(action.type){

            case "SET_PAGE_HEADING":
                return {...state, pageHeading: action.payload}

            case "SET_SUB_HEADING":
                return {...state, subHeading: action.payload}

            case "SET_TEXT_SELECTOR":
                return {...state, textSelector: action.payload}

            case "SET_USER":
                return {...state, user: action.payload}

            case "SET_USER_TYPE":
                return {...state, userType: action.payload}

            case "SET_USER_ID":
                return {...state, userId: action.payload}

            case "SET_COUNTIES":
                return {...state, counties: action.payload}

            case "SET_SELECTED_COUNTY":
                return {...state, selectedCounty: action.payload}

            case "SET_TOWNS":
                return {...state, towns: action.payload}

            default:
                return state

        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=>{
        document.title = "Initiate Search"

        const  LoadCounties = async () => {

            dispatch({type: "SET_COUNTIES", payload: await useCounties()})

        }

        LoadCounties()



    }, [])

    const [step, setStep] = useState(0)
    const [subStep, setSubstep] = useState(0)
    const [results, setResults] = useState<any>([])
    const [selectedCat, setSelectedCat] = useState<any>()
    const [partners, setPartners] = useState<any>([])
    const [selectedProfessional, setSelectedProfessional] = useState<any>(0)
    const [pageHeading, setPageHeading] = useState("Service App")
    const [categories, setCategories] = useState<any>([])
    const [selectedCounty, setSelectedCounty] = useState<any>("999")
    const [selectedTown, setSelectedTown] = useState<any>("999")

    const base_url = useBaseUrl()

    const InstitutionSearch = async (e: any, id: any) => {

        e.preventDefault()

        let url = `${useBaseUrl()}/facilities/get_partners_cat`

        const formData = new FormData()

        formData.append("category", id)

        const resp: any = await usePostData(url, formData)

        if (resp !== undefined){

            setPartners(resp)

            setSubstep(2)

        }else{

            await Swal.fire({
                icon: "info",
                title: "No Data",
                text: "The server found no data related to your search request. Expand your search params and try again"
            })

        }

    }



    const PatientSearch = async (e: any, id: any) => {
        e.preventDefault()

        let url = `${useBaseUrl()}/facilities/get_professionals`

        const formData = new FormData()

        formData.append("category", id)

        const resp: any = await usePostData(url, formData)

        if(resp !== undefined){

            setResults(resp)


            setSubstep(1)

        }else{

            await Swal.fire({
                icon: "info",
                title: "No Data",
                text: "The server found no data related to your search request. Expand your search params and try again"
            })

        }

    }

    const Randy: any = () =>{
        return Math.random()
    }
    return(
        <section>
            <div className={"row"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4 text-center"}> <h3>{state.pageHeading}</h3> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4 text-center"}><h5>{state.subHeading}</h5></div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}>
                <div className={"col-md-1"}>

                </div>

                <div className={"col-md-10"}>

                    <div className={"container-fluid"}>

                        {step===0 && (
                            <>
                                <div className={"row"}>

                                    <div className={"col-md-12"}>
                                        <div className="card card-animate" >
                                            <div className="card-body p-4 text-center">
                                                <div  className={"row mt-1 mb-1"}>
                                                    <div className={"col-md-3"}>
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/patient.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">I'm a Patient</h5>
                                                    </div>

                                                    <div className={"col-md-6"}>

                                                        <div className={"row"}>
                                                            <div className={"col"}>

                                                                <h6>Patients</h6>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={"col-md-3"}>


                                                        <div className={"row "}>
                                                            <div className={"col"}>
                                                                <button onClick={(e)=>{
                                                                    dispatch({type: "SET_SUB_HEADING", payload: "We connect patients to a raft of industry stakeholders"})
                                                                    setStep(1)
                                                                }} style={{width: "100%"}} className={"btn btn-primary"}>Get Started as a Patient</button>
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-3"}>
                                                            <div className={"col"}>
                                                                <Link to={"/"} style={{width: "100%"}} className={"btn btn-primary"}>Go to Previous Page</Link>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-md-12"}>
                                        <div className="card card-animate">
                                            <div className="card-body p-4 text-center">

                                                <div  className={"row mt-1 mb-1"}>
                                                    <div className={"col-md-3"}>
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/professional.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">I'm a Professional</h5>
                                                    </div>

                                                    <div className={"col-md-6"}>

                                                        <div className={"row"}>
                                                            <div className={"col"}>

                                                                <h6>Professionals</h6>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className={"col-md-3"}>


                                                        <div className={"row "}>
                                                            <div className={"col"}>
                                                                <button onClick={(e)=>{
                                                                    setStep(2)
                                                                }} style={{width: "100%"}} className={"btn btn-primary"}>Get Started as a Professional</button>
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-3"}>
                                                            <div className={"col"}>
                                                                <Link to={"/"} style={{width: "100%"}} className={"btn btn-primary"}>Go to Previous Page</Link>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-md-12"}>
                                        <div className="card card-animate">
                                            <div className="card-body p-4 text-center">
                                                <div  className={"row mt-1 mb-1"}>
                                                    <div className={"col-md-3"}>
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/stakeholder.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Stakeholder Organization</h5>
                                                    </div>

                                                    <div className={"col-md-6"}>

                                                        <div className={"row"}>
                                                            <div className={"col"}>

                                                                <h6>Stakeholder Organizations</h6>

                                                            </div>
                                                        </div>



                                                    </div>

                                                    <div className={"col-md-3"}>


                                                        <div className={"row "}>
                                                            <div className={"col"}>
                                                                <button onClick={(e)=>{
                                                                    dispatch({type: "SET_SUB_HEADING", payload: "Uzima health care is here to connect you to a wide variety of stakeholder organizations"})
                                                                    setStep(4)
                                                                }} style={{width: "100%"}} className={"btn btn-primary"}>Get Started as a Stakeholder</button>
                                                            </div>
                                                        </div>

                                                        <div className={"row mt-3"}>
                                                            <div className={"col"}>
                                                                <Link to={"/"} style={{width: "100%"}} className={"btn btn-primary"}>Go to Previous Page</Link>
                                                            </div>
                                                        </div>



                                                    </div>

                                                </div>



                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {step===1 && (
                            <>
                                {subStep===0 && (
                                    <>
                                        <div className={"card"}>

                                            <div className={"card-body"}>

                                                <div className={"row mt-2"}>
                                                    <div className={"col"}>
                                                        <h6 className={"text-center"}> Who would you like to connect with? </h6>
                                                    </div>
                                                </div>

                                                <div className={"row mt-4"}>

                                                    <div className={"col-md-4"}>
                                                        <div onClick={(e)=>{
                                                            PatientSearch(e, 1)
                                                        }} className="card card-animate" >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/doctor.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Doctors</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <div onClick={(e)=>{
                                                            InstitutionSearch(e, 4)
                                                        }} className="card card-animate" >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/clinic.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Clinics</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <div onClick={(e)=>{
                                                            InstitutionSearch(e, 2)
                                                        }} className="card card-animate" >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/hospitals.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Hospital</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div onClick={(e)=>{
                                                        InstitutionSearch(e, 3)
                                                    }} className={"col-md-4"}>
                                                        <div className="card card-animate" >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/laboratories.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Labs</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <div onClick={(e)=>{
                                                            InstitutionSearch(e, 12)
                                                        }} className="card card-animate">
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/pharmaceuticals.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Pharmacies</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <div onClick={(e)=>{
                                                            dispatch({type: "SET_SUB_HEADING", payload: "Uzima Health Care is here to connect you to health service providers."});
                                                            setStep(0);
                                                            setSubstep(0);

                                                        }} className="card card-animate" >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/go_back.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Go Back</h5>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </>
                                )}

                                {subStep===1 && (
                                    <div className={"row mt-4"}>

                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search"
                                                                   placeholder="Type something"/>
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className="col ms-auto">
                                                        <div>
                                                            <select className="form-control" value={selectedCat}
                                                                    onChange={
                                                                        (e) => {
                                                                            setSelectedCat(e.currentTarget.value);
                                                                        }}>

                                                                <option value={"1"}>All</option>

                                                                {Object.values(categories).map((cats: any) => (
                                                                    <option value={cats.id}>
                                                                        {cats.cat}
                                                                    </option>
                                                                ))}


                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>


                                                        <select className="form-control" value={state.selectedCounty}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const selectedCountyId = parseInt(e.currentTarget.value);
                                                                    dispatch({type: "SET_SELECTED_COUNTY", payload: selectedCountyId})
                                                                    LoadTowns(selectedCountyId)
                                                                }}
                                                        >

                                                                <>
                                                                    {Object.values(state.counties).map((cty:any)=>(

                                                                        <option value={cty.county_code}>{cty.county}</option>
                                                                    ))}
                                                                </>

                                                            <option value={"999"}>All Counties</option>


                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={false}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const selectedTown = parseInt(e.currentTarget.value);
                                                                    dispatch({type: "SET_SELECTED_TOWN", payload: selectedTown})
                                                                }}>
                                                            <option value={"999"}>All Towns</option>

                                                            {Object.values(state.towns).map((tn: any)=> (
                                                                <option value={tn.town_id}>{tn.town}</option>
                                                            ))}


                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">
                                                            <button type="button" onClick={(e)=>{
                                                                setSubstep(0)
                                                                setStep(0)}} className="btn btn-danger"><i
                                                                className="ri-arrow-go-back-fill me-1 align-bottom"> </i> Back
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {Object.values(results).map((pro: any)=>(
                                            <>

                                                <div className={"col-md-4"}>

                                                    <div className="card">
                                                        <div className="card-body text-center p-4">
                                                            <img src={`${process.env.PUBLIC_URL} ${pro.image}`} className="me-3 rounded-circle avatar-sm" alt={`${pro.title + " " + pro.fname}`} />

                                                            <h5 className="mb-1 mt-4"><a href="#"
                                                                                         className="link-primary">{pro.title} {pro.fname} {pro.lname}</a>
                                                            </h5>
                                                            <p className="text-muted mb-4">Doctors: ({pro.title})</p>

                                                            <p className="text-muted mb-4">{pro.county} {pro.town}</p>

                                                            <div className="row mt-4">
                                                                <div
                                                                    className="col-lg-6 border-end-dashed border-end">
                                                                    <h5>{Randy}</h5>
                                                                    <span className="text-muted">Engagements</span>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <h5>{Randy}</h5>
                                                                    <span className="text-muted">Capacity</span>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <form method="POST" onSubmit={(e) => {
                                                                    setSelectedProfessional(pro)
                                                                    setStep(3)
                                                                    setPageHeading(`${pro.fname}  ${pro.lname}'s Pofile `)
                                                                }}>

                                                                    <input type="hidden" name="clinic_id"
                                                                           value={2}/>
                                                                    <input type="submit" value="View details"
                                                                           className="btn btn-rounded btn-outline-primary w-100"/>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                        ))}
                                        <div className={"col-md-4"}>
                                            <div onClick={(e)=>{
                                                setSubstep(0)
                                                setStep(0)
                                            }} className="card card-animate" style={{borderRadius: "3%"}}>
                                                <div className="card-body p-4 text-center">

                                                    <h5 className="card-title mb-1">Go Back</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {subStep===2 &&(
                                    <div className={"row mt-4"}>
                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search"
                                                                   placeholder="Type something"/>
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className="col ms-auto">
                                                        <div>
                                                            <select className="form-control" value={selectedCat}
                                                                    onChange={
                                                                        (e) => {
                                                                            setSelectedCat(e.currentTarget.value);
                                                                        }}>

                                                                <option value={"1"}>All</option>

                                                                {Object.values(categories).map((cats: any) => (
                                                                    <option value={cats.id}>
                                                                        {cats.cat}
                                                                    </option>
                                                                ))}


                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>
                                                        <select className="form-control" value={selectedCounty}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const selectedCountyId = parseInt(e.currentTarget.value);
                                                                    setSelectedCounty(selectedCountyId)
                                                                }}
                                                        >
                                                            <option value={"999"}>All Counties</option>


                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={false}
                                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    const selectedTown = parseInt(e.currentTarget.value);
                                                                    setSelectedTown(selectedTown)
                                                                }}>
                                                            <option value={"999"}>All Towns</option>


                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">
                                                            <button type="button" onClick={(e)=>{
                                                                setSubstep(0)
                                                                setStep(0)}} className="btn btn-danger"><i
                                                                className="ri-arrow-go-back-fill me-1 align-bottom"> </i> Back
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {Object.values(partners).map((pro: any)=>(
                                            <>
                                                <div className={"col-md-3"}>

                                                    <div className="card">
                                                        <div className="card-body text-center p-4">
                                                            <img src={`${process.env.PUBLIC_URL} ${pro.logo}`} className="me-3 rounded-circle avatar-sm" alt={`${pro.name}`} />

                                                            <h5 className="mb-1 mt-4"><a href="#"
                                                                                         className="link-primary">{pro.name} </a>
                                                            </h5>
                                                            <p className="text-muted mb-4">Category: {pro.category}</p>

                                                            <p className="text-muted mb-4" style={{fontWeight: "Bold"}}>{pro.county} {pro.town}</p>

                                                            <div className="row mt-4">
                                                                <div
                                                                    className="col-lg-6 border-end-dashed border-end">
                                                                    <h5>0</h5>
                                                                    <span className="text-muted">Engagements</span>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <h5>{pro.capacity}</h5>
                                                                    <span className="text-muted">Capacity</span>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <form method="POST" onSubmit={(e) => {
                                                                    setSelectedProfessional(pro)
                                                                    dispatch({type: "SET_USER", payload: "facility"})
                                                                    setStep(3)
                                                                    setPageHeading(`${pro.name}'s Profile `)
                                                                }}>

                                                                    <input type="hidden" name="clinic_id"
                                                                           value={2}/>
                                                                    <input type="submit" value="View details"
                                                                           className="btn btn-rounded btn-outline-primary w-100"/>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                        ))}

                                    </div>
                                )}


                            </>
                        )}

                        {step===2 && (
                            <>
                                {subStep===0 &&(
                                    <div className={"card"}>

                                        <div className={"card-body"}>

                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <h6 className={"text-center"}>Choose a service you're looking for: </h6>
                                                </div>
                                            </div>

                                            <div className={"row"}>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/doctor.png`} alt="" className="img-fluid" />
                                                            </div>
                                                            <h5 className="card-title mb-1">A professional</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-4"}>
                                                    <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/clinic.png`} alt="" className="img-fluid" />
                                                            </div>
                                                            <h5 className="card-title mb-1">Specific Professional</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-3"}>
                                                    <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/pill.png`} alt="" className="img-fluid" />
                                                            </div>
                                                            <h5 className="card-title mb-1">Locum</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-md-2"}>
                                                    <div onClick={(e)=>{setStep(0); setSubstep(0)}} className="card card-animate" style={{borderRadius: "3%"}}>
                                                        <div className="card-body p-4 text-center">
                                                            <div className="mx-auto avatar-md mb-3">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/professional.png`} alt="" className="img-fluid" />
                                                            </div>
                                                            <h5 className="card-title mb-1">Go Back</h5>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                )}

                                {subStep===1 && (
                                    <>

                                    </>
                                )}
                            </>
                        )}

                        {step === 3 && (
                            <SearchResullts data={selectedProfessional} user={state.user} />
                        )}

                        {step=== 4 && (
                            <>
                                {state.userType !== "stakeholder" && (
                                    <>
                                        <div className={"card card-animate"}>
                                            <div className={"card-body text-center"}>

                                                <h5>Hold On!</h5>
                                                <br />

                                                <img src={`${process.env.PUBLIC_URL}/assets/images/warning.gif`} alt="" className="img-fluid" />

                                                <br />
                                                <br />

                                                <h6>You are currently not logged in as a stakeholder organization or not in a user group allowed to access this resource. </h6>
                                                <h6>To protect the data integrity of registered stakeholder organization, we do require you to be logged in</h6>
                                                <p style={{fontWeight: "Bold"}}>Your user class: {state.userType}</p>

                                            </div>

                                            <div className={"card-footer"}>
                                                <div className={"row mt-1 mb-1"}>
                                                    <div className={"col"}>
                                                        <Link to={"/institution/newreg"} style={{width: "100%"}} className={"btn btn-primary"}>

                                                            Take me to the Login / Register page

                                                        </Link>
                                                    </div>

                                                    <div className={"col"}>
                                                        <Link to={"/search/info"} style={{width: "100%"}} className={"btn btn-primary"}>

                                                            Why should I login first?

                                                        </Link>
                                                    </div>

                                                    <div className={"col"}>
                                                        <button onClick={(e)=>{
                                                            dispatch({type: "SET_SUB_HEADING", payload: "Uzima Health Care is here to connect you to health service providers."})
                                                            setSubstep(0)
                                                            setStep(0)
                                                        }} style={{width: "100%"}} className={"btn btn-primary"}>

                                                            I'll do it later!

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                )}

                            </>
                        )}



                    </div>

                </div>

                <div className={"col-md-1"}>

                </div>
            </div>
        </section>
    )
}

export default SearchEl