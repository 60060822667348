import React, {useEffect, useState} from 'react'
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import localforage from "localforage";

const Settings: React.FC = () => {

    const [selectedTitle, setSelectedTitle] = useState("Settings")
    const [selected, setSelected] = useState("none")
    const [step, setStep] = useState("init")
    const [token, setToken] = useState("")

    useEffect(()=>{
        localforage.getItem("token").then((tok: any)=>{
            setToken(tok)
        })
    }, [])


    const divStyle = {borderRadius: "3%"}

    useEffect(()=>{
        document.title = "My Uzima Account"
    }, [])

    return (
        <>
            <DashboardHeader />
            <SideMenu />

            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"row mt-3"}>
                        <div className={"col text-center"}>
                            <h3>My Uzima - {selectedTitle}</h3>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-md-3 col-sm-2"}>
                            <div className="card card-animate" onClick={(e)=>{
                                setSelected("profile")
                            }} style={divStyle}>
                                <div className="card-body p-4 text-center">
                                    <div className="mx-auto avatar-md mb-3">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/profile.png`} alt=""
                                             className="img-fluid"/>
                                    </div>
                                    <h5 className="card-title mb-1">My Profile</h5>
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-3 col-sm-2"}>
                            <div className="card card-animate" onClick={(e)=>{
                                setSelected("api")
                            }} style={divStyle}>
                                <div className="card-body p-4 text-center">
                                    <div className="mx-auto avatar-md mb-3">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/api.png`} alt=""
                                             className="img-fluid"/>
                                    </div>
                                    <h5 className="card-title mb-1">Api Key</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    {selected === "profile" && (
                        <>
                            <div className={"row mt-4"}>
                                <div className={"col"}>

                                    <div className="card card-drop">
                                        <div className={"row"}>
                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/update.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Update</h5>

                                            </div>

                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/facility.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Add Facility</h5>

                                            </div>

                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/add_user.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Add User</h5>

                                            </div>

                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"} onClick={(e)=>{
                                                setSelected("none")
                                            }}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/back.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Back</h5>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    )}

                    {selected === "api" && (
                        <>
                            <div className={"row mt-4"}>
                                <div className={"col"}>

                                    <div className="card card-drop">
                                        <div className={"row"}>
                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/update.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Refresh & Info</h5>

                                            </div>

                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/key.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">New Key</h5>

                                            </div>

                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/renew.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Renew Key</h5>

                                            </div>

                                            <div className={"col-md-3 col-sm-2 text-center mb-4 mt-4"} onClick={(e)=>{
                                                setSelected("none")
                                            }}>

                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/back.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Back</h5>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className={"row mt-4"}>
                                <div className={"col"}>
                                    <div className="card" id="tasksList">
                                        <div className="card-header border-0">
                                            <div className="d-flex align-items-center">
                                                <h5 className="card-title mb-0 flex-grow-1">All Tasks</h5>
                                                <div className="flex-shrink-0">
                                                    <div className="d-flex flex-wrap gap-2">
                                                        <button className="btn btn-danger add-btn" data-bs-toggle="modal" data-bs-target="#showModal">
                                                            <i className="ri-add-line align-bottom me-1"> </i> Create Task
                                                        </button>
                                                        <button className="btn btn-soft-danger" id="remove-actions"><i className="ri-delete-bin-2-line"> </i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body border border-dashed border-end-0 border-start-0">
                                            <form>
                                                <div className="row g-3">
                                                    <div className="col-xxl-5 col-sm-12">
                                                        <div className="search-box">
                                                            <input type="text"
                                                                   className="form-control search bg-light border-light"
                                                                   placeholder="Search for tasks or something..." />
                                                                <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>


                                                    <div className="col-xxl-3 col-sm-4">
                                                        <input type="text"
                                                               className="form-control bg-light border-light flatpickr-input"
                                                               id="demo-datepicker" data-provider="flatpickr"
                                                               data-date-format="d M, Y" data-range-date="true"
                                                               placeholder="Select date range" readOnly={true}/>
                                                    </div>

                                                    <div className="col-xxl-3 col-sm-4">
                                                        <div className="input-light">
                                                            <div className="choices" data-type="select-one" tabIndex={0}
                                                                 role="listbox" aria-haspopup="true"
                                                                 aria-expanded="false">
                                                                <div className="choices__inner">
                                                                    <select
                                                                            className="form-control choices__input"
                                                                            data-choices="" data-choices-search-false=""
                                                                            name="choices-single-default" id="idStatus"
                                                                            hidden={true} tabIndex={-1} data-choice="active">
                                                                        <option value="all"
                                                                                data-custom-properties="[object Object]">All
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xxl-1 col-sm-4">
                                                        <button type="button" className="btn btn-primary w-100">
                                                            <i className="ri-equalizer-fill me-1 align-bottom"> </i>
                                                            Filters
                                                        </button>
                                                    </div>

                                                </div>

                                            </form>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-responsive table-card mb-4">
                                                <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                                    <thead className="table-light text-muted">
                                                    <tr>
                                                        <th scope="col" style={{width: "40px"}}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                       id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort desc" data-sort="id">ID</th>
                                                        <th className="sort" >API-Key</th>
                                                        <th className="sort" >Created</th>
                                                        <th className="sort" >Updated</th>
                                                        <th className="sort" >Assigned To</th>
                                                        <th className="sort" >Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">

                                                    </tbody>
                                                </table>
                                                <div className="noresult" style={{display: "none"}}>
                                                    <div className="text-center">

                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">We've searched more than 200k+
                                                            tasks We did not find any tasks for you search.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end mt-2">
                                                <div className="pagination-wrap hstack gap-2" style={{display: "flex"}}>
                                                    <a className="page-item pagination-prev disabled" href="#">
                                                        Previous
                                                    </a>
                                                    <ul className="pagination listjs-pagination mb-0">
                                                        <li className="active"><a className="page" href="#" data-i="1"
                                                                                  data-page="8">1</a></li>
                                                        <li><a className="page" href="#" data-i="2" data-page="8">2</a>
                                                        </li>
                                                    </ul>
                                                    <a className="page-item pagination-next" href="#">
                                                        Next
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                </div>

                <DashboardFooter />
            </div>

            <canvas />
        </>
    )
}

export default Settings