import React, {useEffect, useState} from 'react'
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import { useAppName, useBaseUrl, usePostData} from "../../utils/utils";
import localforage from "localforage";
import {Link} from "react-router-dom";
import CreateCollaborationComponent from "./CreateCollaborationComponent";
import Swal from "sweetalert2";
import Canvas from "../layouts/dahboard_canvas";
import Collaboration from "./Collaborations/Collaboration";



const CollaborationLauncher: React.FC = () => {
    const [step, setStep] = useState<number>(0)
    const [subStep, setSubStep] = useState(0)
    const [clinicName, setClinicName] = useState<any>("")
    const [title, setTitle] = useState("Collaborations Center")
    const [service, setService] = useState(0)
    const [selectedStaff, setSelectedStaff] = useState<any>()
    const [shortcuts, setShortcuts] = useState<boolean>(true)
    const [clinicId, setClinicId] = useState<any>(null)


    const [invites, setInvites] = useState<any>([])
    const [collaborations, setCollaborations] = useState<any>([])

    const appName = useAppName()
    const base_url = useBaseUrl()

    useEffect(()=>{

        document.title = `${appName} - Collaborations Launcher`

        localforage.getItem("clinic_name").then((clini)=>{
            setClinicName(clini)
        })

        localforage.getItem("clinicId").then((clin)=>{
            setClinicId(clin)
        })

    }, [])

    const GetCollaborationInvites = async () => {

        let url = `${base_url}/collaborations/invites`

        const formdata = new FormData()

        formdata.append("partner", clinicId)

        const resp = await usePostData(url, formdata)

        if(resp === "undefined" || resp === undefined){

            return await Swal.fire({
                icon: "success",
                text: "We found no collaboration invites related to your account",
                title: "No invites"
            })

        }else{

            setInvites(resp)

        }

    }     


    return (
        <>
            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0  ">{clinicName} - {title} &nbsp;
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <Link to="/institutions/home">Dashboards</Link>
                                            </li>
                                            <li className="breadcrumb-item active">{title}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col"}>

                                {step===0 && (
                                    <div className={"container-fluid"}>
                                        <div className={"row mt-4 mb-4"}>
                                            <div className={"col text-center"}>
                                                <h4>Uzima Collaborations Center</h4>
                                            </div>
                                        </div>

                                        <div className={"row mt-4 mb-4"}>
                                            <div className={"col text-center"}>
                                                <h6>Collaborations you are involved in, create collaborations and manage collaboration activity here.</h6>
                                            </div>
                                        </div>
                                        <div className={"row mt-2"}>
                                            <div className={"col-md-4"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setStep(1)
                                                    setTitle("Create Collaboration")
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/create.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Create Private Request</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col-md-4"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setStep(3)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/list.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Active Collaborations</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col-md-4"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setStep(3)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/happy.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Completed Collaborations</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col-md-4"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setStep(4)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/private.png`} alt="Requests"
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Private Requests</h5>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                )}

                                {step === 1 && (
                                    <>
                                        <section className={"row mt-2"}>
                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Doctors")
                                                    setService(6)
                                                    setStep(2)

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/doctor.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Doctors</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Clinicians")
                                                    setService(4)
                                                    setStep(2)

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/clinician.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Clinics</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Technologists")
                                                    setService(7)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/technician.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Technologists</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Pharmaceutical Manufacturers")
                                                    setService(8)
                                                    setStep(2)

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/pharmaceuticals.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Manufacturers</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Laboratories")
                                                    setService(3)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/laboratories.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Laboratories</h5>
                                                    </div>
                                                </div>

                                            </div>

                                        </section>

                                        <section className={"row mt-2"}>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Laboratories")
                                                    setService(3)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/laboratories.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Laboratories</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Find Ambulances")
                                                    setService(5)
                                                    setStep(2)

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/ambulance.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Ambulances</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Health Care Providers")
                                                    setService(9)
                                                    setStep(2)

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/nurse.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">HCP</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Clinics")
                                                    setService(4)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/clinic.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Clinics</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Engage Morgues")
                                                    setService(10)
                                                    setStep(2)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/morgues.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Morgues</h5>
                                                    </div>
                                                </div>

                                            </div>



                                        </section>

                                        <div className={"row mt-2"}>
                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/hospitals.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Hospitals</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/regulators.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Regulators</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/others.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Others</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{

                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/tools.gif`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Tools</h5>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col"}>

                                                <div className="card card-animate" style={{borderRadius: "0%"}} onClick={()=>{
                                                    setTitle("Collaborations Launcher")
                                                    setStep(0)
                                                }}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/create.png`} alt=""
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <h5 className="card-title mb-1">Go back</h5>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </>

                                )}

                                {step === 2 && (
                                    <CreateCollaborationComponent service={service} codex={title} />
                                )}

                                {step === 3 && (

                                    <>
                                        {shortcuts && (
                                            <>
                                                <div className={"row mt-3"}>
                                                    <h6 onClick={(e)=>{
                                                        setShortcuts(!shortcuts)
                                                    }}> <i className={"bx bx-reset"}> </i> My Shortcuts</h6>
                                                    <div className={"col-md-3"}>
                                                        <Link to={"/institution/home"} className="card card-animate" style={{borderRadius: "0%"}} >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/dashboard.png`} alt=""
                                                                         className="img-fluid"/>
                                                                </div>
                                                                <h5 className="card-title mb-1">Dashboard</h5>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <Link to={"/institution/resources"} className="card card-animate" style={{borderRadius: "0%"}} >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/resources.png`} alt=""
                                                                         className="img-fluid"/>
                                                                </div>
                                                                <h5 className="card-title mb-1">Resources</h5>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <div  className="card card-animate" onClick={(e)=>{
                                                            setStep(0)
                                                        }} style={{borderRadius: "0%"}} >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/collaborations.png`} alt=""
                                                                         className="img-fluid"/>
                                                                </div>
                                                                <h5 className="card-title mb-1">Collaborations</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={"col-md-3"}>
                                                        <Link to={"/institution/meetings"} className="card card-animate" style={{borderRadius: "0%"}} >
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/meetings.png`} alt=""
                                                                         className="img-fluid"/>
                                                                </div>
                                                                <h5 className="card-title mb-1">Meetings</h5>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {subStep === 0 && (
                                            <div className={"row mt-2"}>
                                                <div className={"col-md-4"}>
                                                    <div className="card card-height-100">
                                                        <div className="card-body">
                                                            <div className="d-flex flex-column h-100">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1">
                                                                        <p className="text-muted mb-4">Last update : yester</p>
                                                                    </div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-1 align-items-center">
                                                                            <button type="button" className="btn avatar-xs mt-n1 p-0 favourite-btn active">
                                                                                        <span className="avatar-title bg-transparent fs-15">
                                                                                            <i className="ri-star-fill"> </i>
                                                                                        </span>
                                                                            </button>
                                                                            <div className="dropdown">
                                                                                <button
                                                                                    className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                                                    aria-expanded="true">
                                                                                    <i data-feather="more-horizontal" className="icon-sm"> </i>
                                                                                </button>

                                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                                    <a className="dropdown-item"
                                                                                       href="a1_collaboration_summary.html"><i
                                                                                        className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                                                                        View</a>
                                                                                    <a className="dropdown-item"
                                                                                       href="apps-projects-create.html"><i
                                                                                        className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                                                        Edit</a>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <a className="dropdown-item" href="#"
                                                                                       data-bs-toggle="modal"
                                                                                       data-bs-target="#removeProjectModal"><i
                                                                                        className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                                                                        Remove</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mb-2">
                                                                    <div className="flex-shrink-0 me-3">
                                                                        <div className="avatar-sm">
                                                    <span className="avatar-title bg-soft-danger rounded p-2">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/brands/dribbble.png`} alt=""
                                                             className="img-fluid p-1" />
                                                    </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h5 className="mb-1 fs-14"><a
                                                                            href="#"
                                                                            onClick={(e)=>{
                                                                                setStep(4)
                                                                            }}
                                                                            className="text-dark">Patient Nursing</a></h5>
                                                                        <p className="text-muted text-truncate-two-lines mb-3">Monitor
                                                                            patient vitals for 56 Days.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-auto">
                                                                    <div className="d-flex mb-2">
                                                                        <div className="flex-grow-1">
                                                                            <div>Tasks</div>
                                                                        </div>
                                                                        <div className="flex-shrink-0">
                                                                            <div><i
                                                                                className="ri-list-check align-bottom me-1 text-muted"></i>
                                                                                22/56
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="progress progress-sm animated-progress">
                                                                        <div className="progress-bar bg-success" role="progressbar"
                                                                             aria-valuenow={54} aria-valuemin={0}
                                                                             aria-valuemax={100} style={{width: "54%;"}}>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="card-footer bg-transparent border-top-dashed py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <div className="avatar-group">
                                                                        <a href="javascript: void(0);" className="avatar-group-item"
                                                                           data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                           data-bs-placement="top" title="Phil Collins">
                                                                            <div className="avatar-xxs">
                                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} alt=""
                                                                                     className="rounded-circle img-fluid" />
                                                                            </div>
                                                                        </a>
                                                                        <a href="javascript: void(0);" className="avatar-group-item"
                                                                           data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                           data-bs-placement="top" title="Kyllian Mbappe">
                                                                            <div className="avatar-xxs">
                                                                                <div
                                                                                    className="avatar-title rounded-circle bg-secondary">
                                                                                    S
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <a href="javascript: void(0);" className="avatar-group-item"
                                                                           data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                           data-bs-placement="top" title="RDJ Junior">
                                                                            <div className="avatar-xxs">
                                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-4.jpg`}alt=""
                                                                                     className="rounded-circle img-fluid" />
                                                                            </div>
                                                                        </a>
                                                                        <a href="javascript: void(0);" className="avatar-group-item"
                                                                           data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                           data-bs-placement="top" title="Add Members">
                                                                            <div className="avatar-xxs">
                                                                                <div
                                                                                    className="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                                                    +
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <div className="text-muted">
                                                                        <i className="ri-calendar-event-fill me-1 align-bottom"></i> 18
                                                                        May, 2023
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                                {Object.values(invites).map((invite: any)=>(
                                                    <>
                                                        <div className="card card-height-100">
                                                            <div className="card-body">
                                                                <div className="d-flex flex-column h-100">
                                                                    <div className="d-flex">
                                                                        <div className="flex-grow-1">
                                                                            <p className="text-muted mb-4">Last update : {invite.updated}</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0">
                                                                            <div className="d-flex gap-1 align-items-center">
                                                                                <button type="button" className="btn avatar-xs mt-n1 p-0 favourite-btn active">
                                                                                        <span className="avatar-title bg-transparent fs-15">
                                                                                            <i className="ri-star-fill"> </i>
                                                                                        </span>
                                                                                </button>
                                                                                <div className="dropdown">
                                                                                    <button
                                                                                        className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                                                        aria-expanded="true">
                                                                                        <i data-feather="more-horizontal" className="icon-sm"> </i>
                                                                                    </button>

                                                                                    <div className="dropdown-menu dropdown-menu-end">
                                                                                        <a className="dropdown-item"
                                                                                           href="a1_collaboration_summary.html"><i
                                                                                            className="ri-eye-fill align-bottom me-2 text-muted"></i>
                                                                                            View</a>
                                                                                        <a className="dropdown-item"
                                                                                           href="apps-projects-create.html"><i
                                                                                            className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                                                            Edit</a>
                                                                                        <div className="dropdown-divider"></div>
                                                                                        <a className="dropdown-item" href="#"
                                                                                           data-bs-toggle="modal"
                                                                                           data-bs-target="#removeProjectModal"><i
                                                                                            className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                                                                            Remove</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex mb-2">
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div className="avatar-sm">
                                                    <span className="avatar-title bg-soft-danger rounded p-2">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/brands/dribbble.png`} alt=""
                                                             className="img-fluid p-1" />
                                                    </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <h5 className="mb-1 fs-14"><a
                                                                                href="/institution/collaboration-details"
                                                                                className="text-dark">Patient Nursing</a></h5>
                                                                            <p className="text-muted text-truncate-two-lines mb-3">Monitor
                                                                                patient vitals for 56 Days.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-auto">
                                                                        <div className="d-flex mb-2">
                                                                            <div className="flex-grow-1">
                                                                                <div>Tasks</div>
                                                                            </div>
                                                                            <div className="flex-shrink-0">
                                                                                <div><i
                                                                                    className="ri-list-check align-bottom me-1 text-muted"></i>
                                                                                    22/56
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress progress-sm animated-progress">
                                                                            <div className="progress-bar bg-success" role="progressbar"
                                                                                 aria-valuenow={54} aria-valuemin={0}
                                                                                 aria-valuemax={100} style={{width: "54%;"}}>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="card-footer bg-transparent border-top-dashed py-2">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1">
                                                                        <div className="avatar-group">
                                                                            <a href="javascript: void(0);" className="avatar-group-item"
                                                                               data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                               data-bs-placement="top" title="Phil Collins">
                                                                                <div className="avatar-xxs">
                                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} alt=""
                                                                                         className="rounded-circle img-fluid" />
                                                                                </div>
                                                                            </a>
                                                                            <a href="javascript: void(0);" className="avatar-group-item"
                                                                               data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                               data-bs-placement="top" title="Kyllian Mbappe">
                                                                                <div className="avatar-xxs">
                                                                                    <div
                                                                                        className="avatar-title rounded-circle bg-secondary">
                                                                                        S
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                            <a href="javascript: void(0);" className="avatar-group-item"
                                                                               data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                               data-bs-placement="top" title="RDJ Junior">
                                                                                <div className="avatar-xxs">
                                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-4.jpg`}alt=""
                                                                                         className="rounded-circle img-fluid" />
                                                                                </div>
                                                                            </a>
                                                                            <a href="javascript: void(0);" className="avatar-group-item"
                                                                               data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                                               data-bs-placement="top" title="Add Members">
                                                                                <div className="avatar-xxs">
                                                                                    <div
                                                                                        className="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                                                        +
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="text-muted">
                                                                            <i className="ri-calendar-event-fill me-1 align-bottom"></i> 18
                                                                            May, 2023
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                                ))}

                                            </div>
                                        )}




                                    </>
                                )}

                                {step === 4 && (
                                    <Collaboration collaboration={"any"} parentPage={1} />
                                )}

                            </div>

                        </div>

                    </div>
                </div>

                <DashboardFooter />
            </div>
            <Canvas />
        </>
    )
}

export default CollaborationLauncher;