import React, {useEffect, useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import Canvas from "./dahboard_canvas";

const ShareRecords: React.FC = () =>{
    const [step, setStep] = useState<number>(1);
    const [recepient, setRecepient] = useState<any>()
    const [record, setRecord] = useState<any>()

    useEffect(()=>{
        document.title = "Share Medical Records";
    }, [])
    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>
            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Electronic Health Records(EHR) - Share Records</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/institution/ehr"}>EHR</a></li>
                                            <li className="breadcrumb-item active">Sharing</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col"}></div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col"}>

                                <div className={"card card-animate"}>
                                    <div className={"card-header"} style={{backgroundColor: "#29B0EA", color: "white"}}>
                                        You
                                    </div>
                                    <div className={"card-body"}>
                                        <label className={"form-label mt-4"}>Record ID</label>
                                        <input className={"form-control mt-2"} value={record} onChange={(e:any)=>{setRecord(e.currentTarget.value)}} />
                                    </div>
                                </div>

                            </div>

                            <div className={"col"}>
                                <div className={"row"}>
                                    <div className={"col"}></div>
                                    <div className={"col"}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/transfer.png`} />
                                    </div>
                                    <div className={"col"}>



                                    </div>
                                </div>

                            </div>

                            <div className={"col"}>
                                <div className={"card card-animate"}>
                                    <div className={"card-header"} style={{backgroundColor: "#29B0EA", color: "white"}}>
                                        Recepients
                                    </div>
                                    <div className={"card-body"}></div>
                                </div>
                            </div>

                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <button className={"btn btn-primary"} style={{width: "100%"}}>Share record </button>
                                <br />
                                <button className={"btn btn-warning mt-4"} style={{width: "100%"}} onClick={(e:any)=>{setStep(1)}}>Back  </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Canvas></Canvas>

        </>
    )
}

export default ShareRecords;