import React, {useEffect, useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";
import axios from "axios";
import localforage from "localforage";
import Swal from "sweetalert2";
import {useBaseUrl} from "../../utils/utils";

interface counties  {
    county: string,
    county_code: number
}

interface services {
    service_title: string,
    service_id: number
}


const SearchServices: React.FC = () => {
    useEffect(()=>{
        getAllServices();
        getAllCounties(countyId);

        document.title = "Uzima Create Public Request";

    }, [])

    const base_url = useBaseUrl();

    const [townReady, setTownReady] = useState<boolean>(true);
    const [countyId, setCountyId] = useState<number>(47);
    const [townId, setTownId] = useState<number>();
    const [categoryId, setCategoryId] = useState<number>(99999999);
    const [towns, setTowns] = useState<any []>([]);
    const [town, setTown] = useState<any>(null);
    const [counties, setCounties] = useState<any []>([]);
    const [county, setCounty] = useState<any []>([]);
    const [categories, setCategories] = useState<any []>([]);
    const [applicationId, setApplicationId] = useState();
    const [serviceId, setServiceId] = useState();
    const [jobs, setJobs] = useState<any []>([]);
    const [job, setJob] = useState();
    const [jobCount, setJobCount] = useState<number>(0);
    const [page, setPage] = useState("")
    const [selectedJob, setSelectedJob] = useState<any>()
    const [step, setStep] = useState(0)

    const staff_id = localforage.getItem("staff_id");
    const institution_id = localforage.getItem("institution_id");
    const clinic_id = localforage.getItem("clinic_id");



    const getAllTowns = async (town_id= townId) => {

        let town_url = base_url + "/facilities/get-towns";

        try{

            const response = await axios.post(town_url, {
                town_id: town_id,
                county_id: countyId,
                clinic_id: clinic_id,
                staff_id: staff_id,
                institution_id: institution_id
            });

            const {status, message, data} = response.data;

            if(status == "success"){
                if(Object.keys(data).length == 1){

                    setTown(data);

                    setTownReady(false)

                }else if(Object.keys(data).length > 1){

                    setTowns(data);

                    setTownReady(false);

                } else {

                    setTownReady(true)

                    await Swal.fire({
                        title: "Warning",
                        icon: "warning",
                        text: "The server returned zero(0) towns. Filter by towns will be unavailable"
                    })
                }
            }else if(status == "error"){

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                });
            }


        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Towns",
                text: "Unable to fetch towns. Filter by towns will be unavailable"
            })

            return

        }
    }

    const getAllCounties = async (county_id = countyId) => {

        let county_url = base_url + "/facilities/get-counties";

        try{

            const response = await axios.post(county_url, {
                county_id: countyId,
                clinic_id: clinic_id,
                staff_id: staff_id,
                institution_id: institution_id
            });

            const {status, message, data} = response.data;

            if(status == "success"){
                if(Object.keys(data).length == 1){

                    const response_data: counties[] = data

                    setCounty(response_data);

                    setTownReady(false)

                }else if(Object.keys(data).length > 1){

                    const response_data: counties[] = data

                    setCounties(response_data);

                    setTownReady(false);

                } else {
                    setTownReady(true)

                    await Swal.fire({
                        title: "Warning",
                        icon: "warning",
                        text: "The server returned zero(0) counties. Filter by location will be unavailable"
                    })
                }
            }else if(status == "error"){
                setTownReady(true)
                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                });
            }


        }catch (e) {
            setTownReady(true)

            await Swal.fire({
                icon: "warning",
                title: "Towns",
                text: "Unable to fetch counties. Filter by towns will be unavailable"
            })

        }

    }

    const getAllServices = async ()=>{

        try{

            const response = await axios.get(base_url + '/facilities/service-list');

            const {status, message, data} = response.data;

            if(status === "success"){

                const sorted_data: services [] = data;

                setCategories(sorted_data);

            }else{
                await Swal.fire({
                    icon: "error",
                    title:"Error",
                    text: message
                });

                return;
            }

        }catch (e) {

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Server communication failure. Check your connection and try again."
            })

            return;

        }

    }

    const getServices = ()=>{
        let retries = 0;
        const maxRetries = 3;
        const delay = 3000;

        let service_url = base_url + "/collaborations/jobs";

        const runTrySegment = async ()=>{

            try{

                const response = await axios.post(service_url, {
                    town_id: townId,
                    county_code: countyId,
                    application_id: applicationId,
                    service_id: serviceId
                });

                const {status, message, data} = response.data;

                if(status === "success"){

                    if(Object.keys(data).length==0){
                        await Swal.fire({
                            icon: "success",
                            text: "The query was successful but got no results. Expand your search parameters perhaps?",
                            title: "Zero results"
                        })

                        setJobCount(0)
                    }else if(Object.keys(data).length==1){

                        setJob(data);

                        setJobCount(Object.keys(data).length)

                    }else{
                        setJobs(data)

                        setJobCount(Object.keys(data).length)
                    }

                }else{

                    await Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: message
                    })
                }

            }catch (error) {

                if (retries < maxRetries) {
                    retries++;

                    await Swal.fire({
                        icon: "info",
                        title: "Retry",
                        text: `Server unreachable. Retrying (${retries}/${maxRetries}) in ${delay / 2000} seconds...`
                    })

                    setTimeout(runTrySegment, delay);
                } else {
                    await Swal.fire({
                        icon: "warning",
                        title: "Warning",
                        text: `Exceeded maximum attempts (${maxRetries}). Unable to continue, please reload this page manually.`
                    })
                }

            }

        };

        runTrySegment();

    }

    useEffect(()=> {
        if(countyId !=0){
            getAllTowns();
        }

        getServices();

    }, [countyId, townId, applicationId, serviceId, categoryId]);
    return(
        <>
            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />
            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Service Providers: Create a Public Request</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/institution/services"}>Public Request</a></li>
                                            <li className="breadcrumb-item active">Create</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {step === 0 && (
                            <>
                                <div className="card">
                                    <div className="card-header border-0 rounded">
                                        <div className="row g-2">
                                            <div className="col-xl-3">
                                                <div className="search-box">
                                                    <input type="text" className="form-control search" placeholder="Type something" />
                                                    <i className="ri-search-line search-icon"> </i>
                                                </div>
                                            </div>

                                            <div className={"col ms-auto"}>
                                                <div>
                                                    <select className="form-control" value={categoryId} onChange={(e: any)=>{const selectedCat = e.currentTarget.value; setCategoryId(selectedCat)}}>

                                                        <option value={"99999999"}>All</option>
                                                        {
                                                            Object.values(categories).map((item)=>(
                                                                <option key={item.service_id}>{item.service_title}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className={"col ms-auto"}>
                                                <select className="form-control" value={countyId}
                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                            const selectedCountyId = parseInt(e.currentTarget.value);
                                                            setCountyId(selectedCountyId);

                                                        }}
                                                >
                                                    <option >Select County</option>
                                                    {
                                                        Object.values(counties).map((item) => (
                                                            <option  value={item.county_code}>{item.county} County </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className={"col ms-auto"}>

                                                <select className="form-control" disabled={townReady} onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>{
                                                    const selectedTown = parseInt(e.currentTarget.value);
                                                    setTownId(selectedTown)
                                                }}>
                                                    <option >Town</option>
                                                    {Object.keys(towns).length > 1 &&
                                                    (Object.values(towns).map((item) => (
                                                        <option value={item.id} > {item.town} Town</option>
                                                    )))
                                                    }
                                                    { town === 1 &&
                                                    <option value={town.town_id}> {town.town}</option>
                                                    }
                                                    {
                                                        <option>Choose Town</option>
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-lg-auto">
                                                <div className="hstack gap-2">
                                                    <button type="button" className="btn btn-danger"><i className="ri-equalizer-fill me-1 align-bottom"> </i> Filters </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col"}>
                                        {
                                            jobCount === 0 && (
                                                <div className={"alert alert-info"}>
                                                    No collaborations requests posted by others.
                                                    Check back later or expand your selection.
                                                </div>
                                            )
                                        }
                                        {
                                            jobCount >0 && (
                                                <div className={"row mt-2"}>
                                                    {
                                                        Object.values(jobs).map((item: any) =>(
                                                            <>

                                                                <div className={"col-md-3"}>
                                                                    <div className={"card card-animate"}>
                                                                        <div className={"card-body"}>
                                                                            <p>Title {item.title}</p>
                                                                            <p>Apply By: {item.deadline}</p>
                                                                            <p>Requester: {item.requester}</p>
                                                                            <p>Applicants: {item.applicants}</p>
                                                                            <p><button className={"btn btn-primary"}>Apply</button></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </>
                        )}


                    </div>
                </div>
            </div>
            <DashboardFooter />
        </>
    )
}

export default SearchServices;