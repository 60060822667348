import React, {useEffect, useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import Canvas from "./dahboard_canvas";

const SharedRecords: React.FC = () =>{
    const [step, setStep] = useState<number>(1);

    useEffect(()=>{
        document.title = "Received Medical Records";
    }, [])
    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>
            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Electronic Health Records(EHR) - Shared Records</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/institution/home"}>Clinic</a></li>
                                            <li className="breadcrumb-item active">List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Canvas></Canvas>

        </>
    )
}

export default SharedRecords;