import React from "react";

const PartnerMenu: React.FC = () => {
    return (
        <>
            <div className="app-menu navbar-menu">

                <div className="navbar-brand-box">

                    <a href={"/partner/home"} className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="48" />
                    </span>
                        <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`} alt="" height="17" />
                    </span>
                    </a>

                    <a href={"/partner/home"} className="logo logo-light">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="22" />
                    </span>
                        <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`} alt="" height="44" />
                    </span>
                    </a>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                        <i className="ri-record-circle-line"> </i>
                    </button>
                </div>

                <div id="scrollbar">
                    <div className="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                                    <i className="bx bxs-dashboard"> </i> <span data-key="t-dashboards">Dashboards</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarDashboards">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href={"/partner/home"} className="nav-link" data-key="t-projects"> Facility </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href={"/partner/home"} className="nav-link" data-key="t-projects"> Facility Admin </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className={"nav-item"}>
                                <a className="nav-link menu-link" href="#sidebarServices" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarServices">
                                    <i className="bx bx-server"> </i> <span data-key="t-apps">Services</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarServices">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href={"/partner/services"} className="nav-link" data-key="t-calendar"> Request Provider </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href={"/partner/fnd-service"} className="nav-link" data-key="t-chat">Find Requests</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href={"/partner/applicants"} className="nav-link" data-key="t-chat">Applications</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
                                    <i className="bx bx-layer"> </i> <span data-key="t-apps">Uzima - Apps</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarApps">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href={"/calendar"} className="nav-link" data-key="t-calendar"> Calendar </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/chat" className="nav-link" data-key="t-chat"> Chat </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarProjects" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarProjects" data-key="t-projects">
                                                Collaborations
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarProjects">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/collaborations" className="nav-link"
                                                           data-key="t-list"> List </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/collaboration-overview" className="nav-link"
                                                           data-key="t-overview"> Overview </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/create-collaboration" className="nav-link"
                                                           data-key="t-create-project"> New </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarTasks" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarTasks"
                                               data-key="t-tasks"> Document Exchange
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarTasks">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/collaboration_tasks" className="nav-link" data-key="t-kanbanboard"> Tasks </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/document_list" className="nav-link"
                                                           data-key="t-list-view"> List View </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/task-details" className="nav-link" data-key="t-task-details"> Task Details </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarInvoices" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarInvoices" data-key="t-invoices"> Billing Account
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarInvoices">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/invoice_list" className="nav-link"
                                                           data-key="t-list-view"> Invoices </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/invoice_details" className="nav-link"
                                                           data-key="t-details"> Details </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/manual_invoice" className="nav-link"
                                                           data-key="t-create-invoice"> Create Invoice </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarTickets" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarTickets"
                                               data-key="t-supprt-tickets"> Support Tickets
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarTickets">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/ticket-list" className="nav-link"
                                                           data-key="t-list-view"> List View </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/open-ticket" className="nav-link"
                                                           data-key="t-ticket-details"> Ticket Details </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/file-manager" className="nav-link"> <span
                                                data-key="t-file-manager">File Manager</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/contracts" className="nav-link"> <span
                                                data-key="t-to-do">Contract</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/api-key" className="nav-link">
                                                <span data-key="t-api-key">API </span>
                                                <span className="badge badge-pill bg-success" data-key="t-new">Core</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>



                            <li className="menu-title"><i className="ri-more-fill"> </i> <span  data-key="t-pages">Account</span></li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarAuth" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAuth">
                                    <i className="bx bx-user-circle"> </i>
                                    <span data-key="t-authentication">My Account</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarAuth">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href={"#sidebarSignIn"} className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarSignIn" data-key="t-signin"> Sign In
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarSignIn">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/staff-login" className="nav-link" data-key="t-basic"> Staff Login </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/manager-login" className="nav-link" data-key="t-cover"> Manager Login </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarSignUp" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarSignUp" data-key="t-signup"> Sign Up
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarSignUp">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/create-institution" className="nav-link" data-key="t-basic"> Create Facility </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/create-clinic" className="nav-link" data-key="t-cover"> Add Clinic </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/create-staff" className="nav-link" data-key="t-cover"> Add Staff </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <a href="/partner/recover-institution-password" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarResetPass" data-key="t-password-reset">
                                                Password Reset
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarResetPass">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/recover-clinic-password" className="nav-link" data-key="t-basic"> Clinic</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/recover-staff-password" className="nav-link" data-key="t-cover"> Staff </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#sidebarchangePass" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarchangePass" data-key="t-password-create">
                                                Change Password
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarchangePass">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/clinic-change-pass" className="nav-link" data-key="t-basic">
                                                            Clinic </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/staff-change-pass" className="nav-link" data-key="t-cover">
                                                            Staff </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#sidebarLockScreen" className="nav-link" data-bs-toggle="collapse"
                                               role="button" aria-expanded="false" aria-controls="sidebarLockScreen"
                                               data-key="t-lock-screen"> Lock Screen
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarLockScreen">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/clinic-lock" className="nav-link" data-key="t-basic"> Lock Clinic </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/staff-lock" className="nav-link" data-key="t-cover"> Lock Staff </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#sidebarLogout" className="nav-link" data-bs-toggle="collapse"
                                               role="button" aria-expanded="false" aria-controls="sidebarLogout"
                                               data-key="t-logout"> Logout
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarLogout">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/clinic-logout" className="nav-link" data-key="t-basic"> Clinic </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/staff-logout" className="nav-link" data-key="t-cover"> Staff </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarSuccessMsg" className="nav-link" data-bs-toggle="collapse"
                                               role="button" aria-expanded="false" aria-controls="sidebarSuccessMsg"
                                               data-key="t-success-message"> Success Message
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarSuccessMsg">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="#" className="nav-link" data-key="t-basic"> Basic </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="#" className="nav-link" data-key="t-cover"> Cover </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarTwoStep" className="nav-link" data-bs-toggle="collapse"
                                               role="button" aria-expanded="false" aria-controls="sidebarTwoStep"
                                               data-key="t-two-step-verification"> 2FA Authenticator
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarTwoStep">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/clinic2fa" className="nav-link" data-key="t-basic"> Clinic </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/staff2fa" className="nav-link" data-key="t-cover"> Staff </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#sidebarErrors" className="nav-link" data-bs-toggle="collapse"
                                               role="button" aria-expanded="false" aria-controls="sidebarErrors"
                                               data-key="t-errors"> Errors
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarErrors">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="#" className="nav-link" data-key="t-404-basic"> 404
                                                            Basic </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="#" className="nav-link" data-key="t-404-cover"> 404
                                                            Cover </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="#" className="nav-link" data-key="t-404-alt"> 404
                                                            Alt </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="#" className="nav-link" data-key="t-500"> 500 </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="#" className="nav-link"
                                                           data-key="t-offline-page"> Offline Page </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarPages" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPages">
                                    <i className="bx bx-file"> </i>
                                    <span data-key="t-pages">Help & Support</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarPages">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href="/partner/faqs" className="nav-link" data-key="t-starter"> FAQs</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/ticket-list" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarProfile" data-key="t-profile">
                                                Tickets
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarProfile">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <a href="/partner/create-ticket" className="nav-link"
                                                           data-key="t-simple-page"> Create Ticket </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a href="/partner/ticket-list" className="nav-link"
                                                           data-key="t-settings"> My Tickets </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/notices" className="nav-link" data-key="t-team"> Notices</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/timelines" className="nav-link" data-key="t-timeline"> Timeline </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link" data-key="t-maintenance"> Maintenance </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/help" className="nav-link" data-key="t-coming-soon"> Account Help </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/documentation" className="nav-link" data-key="t-sitemap"> Documentation </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/billing-help" className="nav-link" data-key="t-search-results"> Billing
                                                help </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/privacy" className="nav-link"><span data-key="t-privacy-policy">Privacy Policy</span>
                                                <span className="badge badge-pill bg-success"
                                                      data-key="t-new">New</span></a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/partner/terms" className="nav-link"><span data-key="t-term-conditions">Term & Conditions</span>
                                                <span className="badge badge-pill bg-success"
                                                      data-key="t-new">New</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            <li className="menu-title"> <i className="ri-more-fill"></i> <span data-key="t-components">Uzima -  System</span>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse"
                                   role="button" aria-expanded="false" aria-controls="sidebarUI">
                                    <i className="bx bx-palette"></i> <span data-key="t-base-ui">Patient Records</span>
                                </a>
                                <div className="collapse menu-dropdown mega-dropdown-menu" id="sidebarUI">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="nav nav-sm flex-column">
                                                <li className="nav-item">
                                                    <a href={"/partner/ehr"} className="nav-link" data-key="t-alerts">Launch</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href={"/partner/ehr/list"} className="nav-link" data-key="t-badges">Clinic Records</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href={"/partner/ehr/settings"} className="nav-link" data-key="t-carousel">Settings</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                        </div>
                                        <div className="col-lg-4">
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarAdvanceUI" data-bs-toggle="collapse"
                                   role="button" aria-expanded="false" aria-controls="sidebarAdvanceUI">
                                    <i className="bx bx-briefcase-alt"></i> <span data-key="t-advance-ui">Partner</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarAdvanceUI">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <a href={"/partner/clinics/launch"} className="nav-link"
                                               data-key="t-sweet-alerts">Launch</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/partner/resources" data-bs-toggle="collapse"
                                   role="button" aria-expanded="false" aria-controls="sidebarForms">
                                    <i className="bx bx-receipt"></i> <span data-key="t-forms">Resources</span>
                                </a>

                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="/partner/rnp" data-bs-toggle="collapse"
                                   role="button" aria-expanded="false" aria-controls="sidebarTables">
                                    <i className="bx bx-table"></i> <span data-key="t-tables">Research & Partners</span>
                                </a>

                            </li>

                            <li className={"nav-item"}>
                                <a className={"nav-link menu-link"} href={"#sidebarBackups"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={"false"} aria-controls={"sidebarBackups"}>
                                    <i className="bx bx-bot"></i>
                                    <span data-key={"t-backups"}>Easy Backup</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarBackups">
                                    <ul className="nav nav-sm flex-column">

                                        <li className="nav-item">
                                            <a href={"/partner/create-backup"} className="nav-link" data-key="t-backups">Create Backup</a>
                                        </li>

                                        <li className="nav-item">
                                            <a href={"/partner/restore-backup"} className="nav-link" data-key="t-backups">Restore Backup</a>
                                        </li>

                                        <li className="nav-item">
                                            <a href={"/partner/request-backup"} className="nav-link" data-key="t-backups">My Backups</a>
                                        </li>

                                        <li className="nav-item">
                                            <a href={"/partner/request-backup"} className="nav-link" data-key="t-backups">Request Access</a>
                                        </li>

                                    </ul>

                                </div>

                            </li>


                        </ul>
                    </div>
                </div>

                <div className="sidebar-background"> </div>
            </div>
        </>
    )
}

export default PartnerMenu