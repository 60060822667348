import React from "react";

interface Props {
    heading: any,
    start: any,
    fini: any
}

const BreadCrumb: React.FC<Props> = ({heading, start, fini}) => {

    return(
        <div className="row">
            <div className="col-12">
                <div
                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">{heading}</h4>

                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a
                                href={"/institution/services"}>{start}</a></li>
                            <li className="breadcrumb-item active">{fini}</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BreadCrumb