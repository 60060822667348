import React, {useState} from 'react'
import {useBaseUrl, usePostData} from "../../utils/utils";
import localForage from "localforage";
import {Link} from "react-router-dom";


const NewLogin: React.FC =() => {

    const [step, setStep] = useState(0)
    const [subStep, setSubStep] = useState(0)


    const [email, setEmail] = useState<any>("user@email.com")
    const [password, setPassword] = useState<any>("")

    const PartnerLogin = async(e: any) => {
        e.preventDefault()

        const formData = new FormData()

        formData.append("email", email)
        formData.append("password", password)

        const url = `${useBaseUrl()}/facilities/newlog`

        const resp: any = await usePostData(url, formData)

        if(resp !== undefined){
            if(resp.status==="success"){

                setSubStep(1)

                await localForage.setItem("fname", resp.fname)
                await localForage.setItem("lname", resp.lname)
                await localForage.setItem("clinic_id", resp.clinic_id)
                await localForage.setItem("clinic_name", resp.institution)
                await localForage.setItem("staff_id", resp.staff_id)
                await localForage.setItem("institution_id", resp.institution_id)

            }else{
                setSubStep(2)
            }

        }
    }


    return (
        <>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4 text-center"}> <h3>Get Started - Login Access</h3> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}>
                <div className={"col-md-3"}>

                </div>

                <div className={"col-md-6"}>

                    <div className={"container-fluid"}>

                        <div className={"card"}>

                            <div className={"card-header"} style={{backgroundColor: "#29b0ea", fontWeight: "Bold" ,color: "white",  border: "None"}}>
                                {step===0 && (
                                    <>
                                        Let's get started!
                                    </>
                                )}

                                {step===2 && (
                                    <section>

                                        Patient Login
                                    </section>
                                )}

                                {step===3 &&(
                                    <section>
                                        Partner Login
                                    </section>
                                )}

                            </div>

                            <div className={"card-body"}>
                                {step===0 && (
                                    <>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/patient.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Patient</h5>

                                                        <p><button onClick={()=>{
                                                            setStep(2)
                                                        }} className="btn btn-rounded btn-outline-primary btn-rounded btn-lg mt-4"
                                                        >I'm a Patient</button></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/partners.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Partner</h5>

                                                        <p><button onClick={()=>{
                                                            setStep(3)
                                                        }} className="btn btn-rounded btn-outline-primary btn-rounded btn-lg mt-4">I'm a Partner</button></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {step===2 && (
                                    <>
                                        {subStep===0 && (
                                            <>

                                                <label>
                                                    <i className={"bx bx-at"}> </i> &nbsp;
                                                    Email Address
                                                </label>

                                                <input type={"email"} className={"form-control"} onChange={(e)=>{setEmail(e.currentTarget.value)}}/>

                                                <label>
                                                    <i className={"bx bx-key mt-4"}> </i> &nbsp;
                                                    Password
                                                </label>

                                                <input type={"password"} className={"form-control"} onChange={(e)=>{setPassword(e.currentTarget.value)}}/>

                                                <input type={"submit"} className={"btn btn-success mt-4"} onSubmit={(e)=>{
                                                    e.preventDefault()
                                                }} value={"Login Now"}/>

                                            </>
                                        )}

                                        {subStep===1 && (
                                            <>

                                            </>
                                        )}

                                        {subStep===2 &&(
                                            <>


                                            </>
                                        )}

                                        {subStep===3 && (
                                            <>


                                            </>
                                        )}

                                    </>
                                )}

                                {step===3 && (
                                    <>
                                        {subStep===0 && (
                                            <>

                                                <label>
                                                    <i className={"bx bx-at"}> </i> &nbsp;
                                                    Email Address
                                                </label>

                                                <input type={"email"} className={"form-control"} onChange={(e)=>{setEmail(e.currentTarget.value)}}/>

                                                <label>
                                                    <i className={"bx bx-key mt-4"}> </i> &nbsp;
                                                    Password
                                                </label>

                                                <input type={"password"} className={"form-control"} onChange={(e)=>{setPassword(e.currentTarget.value)}}/>

                                                <input type={"submit"} className={"btn btn-success mt-4"} onClick={(e)=>{
                                                    PartnerLogin(e)
                                                }} value={"Login Now"}/>

                                            </>
                                        )}

                                        {subStep===1 && (
                                            <>
                                                <p>
                                                    Your login was successful. Click the button below to access the Dashboard
                                                </p>

                                                <p>
                                                    This step is necessary to prevent bots and attackers from accessing our dashboard
                                                </p>

                                                <Link to={"/institution/home"} className={"btn btn-warning mt-4"} >Launch Dashboard</Link>

                                            </>
                                        )}

                                        {subStep===2 &&(
                                            <>


                                            </>
                                        )}

                                        {subStep===3 && (
                                            <>


                                            </>
                                        )}
                                    </>
                                )}

                            </div>

                            <div className={"card-footer"}>

                                {step===2 &&(
                                    <>
                                        <div className={"row mt-2 mb-2"}>
                                            <div className={"col"}>
                                                <button className={"btn btn-primary"} onClick={()=>{
                                                    setSubStep(subStep+1)
                                                }}>{subStep===3 && (<>Finish & Submit</>)} {subStep !==3 &&(<>Next Step</>)}</button>
                                            </div>

                                            <div className={"col"}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setStep(0)
                                                    setSubStep(0)
                                                }}>Go back</button>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>

                        </div>

                    </div>

                </div>

                <div className={"col-md-3"}>

                </div>
            </div>

        </>
    )

}

export default NewLogin