import React, {useEffect} from 'react'

const SaaSApi: React.FC = () =>{

    useEffect(()=>{
        document.title = "Uzima API - Management"
    }, [])

    return (
        <>

        </>
    )
}

export default SaaSApi