import React, {useEffect} from "react";
import DashboardFooter from "../layouts/dashboard_footer";
import Swal from "sweetalert2";
import {Link} from "react-router-dom";

const ClinicStart: React.FC = () => {

    useEffect(()=> {
        document.title = "Project Uzima - Institutions"

    }, []);

    const divStyle = {
        borderRadius: "3% "
    }

    return(
        <>


        </>
    )
}

export default ClinicStart;