import React, {useEffect} from "react";
import Swal from "sweetalert2";
import axios from "axios";
import {useState} from "react";
import localforage from "localforage";
import set = Reflect.set;
import {Link} from "react-router-dom";
import {useBaseUrl} from "../../utils/utils";

const LoginClinic: React.FC = () => {

    const [step, setStep] = useState(1);
    const [staffEmail, setStaffEmail] = useState('');
    const [staffPassword, setStaffPassword] = useState('');
    const [clinicEmail, setClinicEmail] = useState('');
    const [clinicPassword, setClinicPassword] = useState('');
    const [clinicName, setClinicName] = useState('');
    const [staffFirstName, setStaffFirstName] = useState('');
    const [staffLastName, setStaffLastName] = useState('');
    const [verificationCode, setVerificationCode] = useState<any>();
    const [institutionId, setInstitutionId] = useState('');
    const [clinicId, setClinicId] = useState('');
    const [staffId, setStaffId] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [done, setDone] = useState<boolean>(false)

    const base_url = useBaseUrl()

    useEffect(() => {
        document.title = "Project Uzima - Login";
    }, [])

    const HandleSubmitStep1 = async (e: any) => {
        e.preventDefault();

        setIsLoading(true)

        try {
            const response = await axios.post(`${base_url}/facilities/prequalify-staff`, {
                staff_email: staffEmail,
                staff_password: staffPassword,
                clinic_email: clinicEmail,
            }).finally(()=>{
                setIsLoading(false)
            });

            const { status, message, data } = response.data;

            if (status === 'success') {

                await localforage.setItem('fname', data.fname);
                setStaffFirstName(data.fname)
                await localforage.setItem('lname', data.lname);
                setStaffLastName(data.lname);
                await localforage.setItem('staff_id', data.staff_id);
                await localforage.setItem('clinic_name', data.clinic_name);
                setClinicName(data.clinic_name);
                await localforage.setItem('clinic_id', data.clinic_id);
                await localforage.setItem('institution_id', data.institution_id);


                setClinicId(data.clinic_id);
                setInstitutionId(data.institution_id);
                setStaffId(data.staff_id);
                setStep(2);

                return
            } else {

                await Swal.fire('Error', message, 'error');

                return

            }
        } catch (error) {
            setIsLoading(false)
            await Swal.fire('Error', 'Login failed. If this error persists contacts us.', 'error');
            return
        }
    };

    const HandleSubmitStep2 = async (e: any) => {
        setIsLoading(true)
        e.preventDefault();

        try {
            const institution_id = await localforage.getItem('institution_id');

            if (!institution_id) {
                await localforage.clear();
                await Swal.fire('Error', 'Login data not found. Please try again.', 'error');
                setStep(1);
                return;
            }

            const formData = new FormData(e.target)

            const response = await axios.post(`${base_url}/facilities/facility-login`, {
                clinic_id: clinicId,
                institution_id: institutionId,
                staff_id: staffId,
                clinic_email: clinicEmail,
                clinic_password: clinicPassword,
            }).finally(()=>{
                setIsLoading(false)
            });

            const { status, message, data } = response.data;

            if (status === 'success') {
                setToken(data.token);
                setStep(3);

                return
            } else {

                await localforage.clear();
                await Swal.fire('Error', message, 'error');
                setStep(1)

                return
            }
        } catch (error) {
            setIsLoading(false)
            await Swal.fire('Error', 'An error occurred during login.', 'error');
        }
    };

    const HandleSubmitStep3 = async (e: any) => {
        setIsLoading(true)
        e.preventDefault();

        try {

            if (token == '') {

                await localforage.clear();
                await Swal.fire('Error', 'Login data not found. Please try again.', 'error');
                setStep(1);
                return;
            }

            const response = await axios.post(`${base_url}/facilities/verify2facode`, {
                clinic_id: clinicId,
                staff_id: staffId,
                verification_code: parseInt(verificationCode),
            }).finally(()=>{
                setIsLoading(false)
            });

            const { status, message, data } = response.data;

            if (status === 'success') {

                await localforage.setItem('clinic_token', token);

                setVerificationCode("")
                setStaffPassword("")
                setClinicPassword("")
                setDone(true)

                return

            } else {
                await Swal.fire('Error', message, 'error');


            }
        } catch (error) {
            setIsLoading(false)
            await Swal.fire('Error', 'An error occurred during login.', 'error');
            return
        }
    };

    return (
        <>
            <div className="auth-page-wrapper pt-5">

                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                    <div className="bg-overlay"> </div>

                    <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"> </path>
                        </svg>
                    </div>
                </div>

                <div className="auth-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <a href={"/"} className="d-inline-block auth-logo">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`} alt="" height="20" />
                                        </a>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">You will be taken through 3 log in steps.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-4">

                                    <div className="card-body p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Get Started</h5>
                                            <p className="text-muted">Verify your identity.</p>
                                        </div>

                                        <div className={"p-2 mt-4"}>
                                            {step === 1 && (
                                                <form onSubmit={HandleSubmitStep1}>
                                                    <input
                                                        type="text"
                                                        className={"form-control mt-4"}
                                                        value={staffEmail}
                                                        onChange={(e) => setStaffEmail(e.target.value)}
                                                        placeholder="Staff Email"
                                                        required
                                                    />
                                                    <input
                                                        type="password"
                                                        className={"form-control mt-4"}
                                                        value={staffPassword}
                                                        onChange={(e) => setStaffPassword(e.target.value)}
                                                        placeholder="Staff Password"
                                                        required
                                                    />
                                                    <input
                                                        type="text"
                                                        className={"form-control mt-4"}
                                                        value={clinicEmail}
                                                        onChange={(e) => setClinicEmail(e.target.value)}
                                                        placeholder="Clinic Email"
                                                        required
                                                    />
                                                    <button className={"btn btn-primary mt-4"} disabled={isLoading} type="submit"> Next </button>
                                                </form>
                                            )}

                                            {step === 2 && (
                                                <form onSubmit={HandleSubmitStep2}>
                                                    <p>Hi! <strong>{staffFirstName} {staffLastName}</strong>. You're logging in to - <strong>{clinicName}</strong></p>

                                                    <input
                                                        type="text"
                                                        className={"form-control mt-4"}
                                                        value={clinicEmail}
                                                        onChange={(e) => setClinicEmail(e.target.value)}
                                                        placeholder="Clinic Email"
                                                        required
                                                    />
                                                    <input
                                                        type="password"
                                                        className={"form-control mt-4"}
                                                        value={clinicPassword}
                                                        onChange={(e) => setClinicPassword(e.target.value)}
                                                        placeholder="Clinic Password"
                                                        required
                                                    />
                                                    <button className={"btn btn-warning mt-4"} disabled={isLoading} type="submit">Next</button>
                                                </form>
                                            )}

                                            {step === 3 && (
                                                <form onSubmit={HandleSubmitStep3}>
                                                    <p>Final Step! Enter the 2FA Code sent to your email.</p>
                                                    <input
                                                        type="text"
                                                        className={"form-control mt-4"}
                                                        value={verificationCode}
                                                        onChange={(e) => setVerificationCode(e.target.value)}
                                                        placeholder="Verification Code"
                                                        required
                                                        disabled={done}
                                                    />
                                                    <div className={"row mt-4"}>
                                                        {!done && (
                                                            <>
                                                                <div className={"col"}>
                                                                    <button className={"btn btn-danger "} disabled={isLoading} onClick={()=>{
                                                                        setStep(1)
                                                                    }}> Restart Process</button>
                                                                </div>

                                                                <div className={"col"}>
                                                                    <button className={"btn btn-success "} disabled={isLoading} type="submit">Verify Code</button>

                                                                </div>
                                                            </>
                                                        )}

                                                        {done === true && (
                                                            <div className={"col"}>
                                                                <Link to={"/institution/home"} className={"btn btn-warning"}>
                                                                    Finish Up
                                                                </Link>
                                                            </div>
                                                        )}

                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <a href={"/institution/register"} className={"fw-semibold text-primary text-decoration-underline"}> Signup </a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">&copy;
                                        <script>document.write(new Date().getFullYear())</script>
                                        Project Uzima
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default LoginClinic;