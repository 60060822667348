import React, {useReducer} from 'react'
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import DashboardFooter from "../../layouts/dashboard_footer";
import {useCounties, useTowns} from "../../../utils/utils";

const ReceivedRequests: React.FC = () =>{

    let selectedCounty: any = 47
    let townId: any = 1

    const initialState = {

        step: 0,
        subStep: 0,
        cardStep: 0,
        header: "Uzima Community Service Center - Received Requests",
        subHeader: "View and manage Partnership Requests sent to you",
        showHeadings: true,
        counties:useCounties(),
        towns: useTowns(selectedCounty),
        categories: [],
        selectedCategory: "",
        townReady: true

    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case 'SET_STEP':
                return {...state, step: action.payload}

            case 'SET_PAGE_HEADING':
                return {...state, header: action.payload}

            case 'SET_SUB_HEADER':
                return {...state, subHeader: action.payload}

            case 'SET_SUB_STEP':
                return {...state, subStep: action.payload}

            case 'SET_CARD_STEP':
                return {...state, cardStep: action.payload}

            case 'SET_SHOW_HEADINGS':
                return {...state, showHeadings: action.payload}

            case 'SET_SELECTED_CATEGORY':
                return {...state, selectedCategory: action.payload}

            case "SET_TOWN_READY":
                return {...state, townReady: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)



    return (
        <>
            <DashboardHeader />
            <SideMenu />
            <div className={"vertical-overlay"} />
            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={'container-fluid'}>

                        {state.showHeadings && (
                            <>
                                <div className={"row mt-4 text-center"}>
                                    <div className={"col-12"}>
                                        <h3>{state.header}</h3>
                                    </div>
                                </div>

                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <h6>View and Manage your received partnership requests.</h6>
                                    </div>
                                </div>
                            </>
                        )}


                        {state.step === 0 && (
                            <>
                                <div className={"row mt-4"}>

                                    <div className={"col"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_SUB_HEADER", payload: "View Community Requests"})
                                            dispatch({type: "SET_STEP", payload: 1})
                                        }} >

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/community.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Community Requests</h5>

                                                <br />

                                            </div>

                                        </div>


                                    </div>

                                    <div className={"col"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_SUB_HEADER", payload: "View Targeted Requests"})
                                            dispatch({type: "SET_STEP", payload: 2})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/targeted.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Targeted Requests</h5>

                                                <br />

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className={"row mt-4"}>

                                    <div className={"col"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_SUB_HEADER", payload: "My opened requests"})
                                            dispatch({type: "SET_STEP", payload: 3})
                                        }} >

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/opened.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Opened Requests</h5>

                                                <br />

                                            </div>

                                        </div>


                                    </div>

                                    <div className={"col"}>

                                        <div className={"card card-animate"} onClick={(e)=>{
                                            dispatch({type: "SET_SUB_HEADER", payload: "My unopened requests"})
                                            dispatch({type: "SET_STEP", payload: 4})
                                        }}>

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/unopened.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Unopened Requests</h5>

                                                <br />

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </>
                        )}


                        {state.step === 1 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search" placeholder="Type something" />
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className={"col ms-auto"}>
                                                        <div>
                                                            <select className="form-control" value={state.categoryId} onChange={(e: any)=>{
                                                                const selectedCat = e.currentTarget.value;
                                                                dispatch({type: "SET_SELECTED_CATEGORY", payload: selectedCat})
                                                            }}>

                                                                <option value={"99999999"}>All</option>
                                                                {Object.values(state.categories) !== undefined &&
                                                                    Object.values(state.categories).map((item: any)=>(
                                                                        <option key={item.service_id}>{item.service_title}</option>
                                                                    ))
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>
                                                        <select className="form-control" value={state.countyId}
                                                                onChange={(e) => {

                                                                    selectedCounty = parseInt(e.currentTarget.value)

                                                                }}
                                                        >
                                                            <option >Select County</option>

                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={state.townReady} onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>{
                                                            townId = parseInt(e.currentTarget.value);
                                                        }}>
                                                            <option >Town</option>

                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">
                                                            <button type="button" className="btn btn-danger" onClick={(e)=>{
                                                                dispatch({type:"SET_STEP", payload: 0})
                                                            }}><i className="ri-arrow-go-back-fill me-1 align-bottom"> </i> Back </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card card-animate"}>
                                            <div className={"card-body text-center"}>
                                                <div className={"row mt-4"}>
                                                    <div className={"col"}></div>
                                                    <div className={"col"}>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/not_found.png`}/>
                                                    </div>
                                                    <div className={"col"}></div>
                                                </div>

                                                <div className={"row mt-4"}>

                                                    <div className={"col"}>
                                                        <h4>
                                                            Sorry! You do not have any approved community requests at the moment.
                                                        </h4>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )}

                        {state.step === 2 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search" placeholder="Type something" />
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className={"col ms-auto"}>
                                                        <div>
                                                            <select className="form-control" value={state.categoryId} onChange={(e: any)=>{
                                                                const selectedCat = e.currentTarget.value;
                                                                dispatch({type: "SET_SELECTED_CATEGORY", payload: selectedCat})
                                                            }}>

                                                                <option value={"99999999"}>All</option>
                                                                {Object.values(state.categories) !== undefined &&
                                                                Object.values(state.categories).map((item: any)=>(
                                                                    <option key={item.service_id}>{item.service_title}</option>
                                                                ))
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>
                                                        <select className="form-control" value={state.countyId}
                                                                onChange={(e) => {

                                                                    selectedCounty = parseInt(e.currentTarget.value)

                                                                }}
                                                        >
                                                            <option >Select County</option>

                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={state.townReady} onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>{
                                                            townId = parseInt(e.currentTarget.value);
                                                        }}>
                                                            <option >Town</option>

                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">

                                                            <button type="button" className="btn btn-danger" onClick={(e)=>{
                                                                dispatch({type:"SET_STEP", payload: 0})
                                                            }}><i className="ri-arrow-go-back-fill me-1 align-bottom"> </i> Back </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card card-animate"}>
                                            <div className={"card-body text-center"}>
                                                <div className={"row mt-4"}>
                                                    <div className={"col"}></div>
                                                    <div className={"col"}>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/not_found.png`}/>
                                                    </div>
                                                    <div className={"col"}></div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>
                                                        <h4>
                                                            Sorry! You have not received any approved targeted requests at the moment.
                                                        </h4>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )}

                        {state.step === 3 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search" placeholder="Type something" />
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className={"col ms-auto"}>
                                                        <div>
                                                            <select className="form-control" value={state.categoryId} onChange={(e: any)=>{
                                                                const selectedCat = e.currentTarget.value;
                                                                dispatch({type: "SET_SELECTED_CATEGORY", payload: selectedCat})
                                                            }}>

                                                                <option value={"99999999"}>All</option>
                                                                {Object.values(state.categories) !== undefined &&
                                                                Object.values(state.categories).map((item: any)=>(
                                                                    <option key={item.service_id}>{item.service_title}</option>
                                                                ))
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>
                                                        <select className="form-control" value={state.countyId}
                                                                onChange={(e) => {

                                                                    selectedCounty = parseInt(e.currentTarget.value)

                                                                }}
                                                        >
                                                            <option >Select County</option>

                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={state.townReady} onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>{
                                                            townId = parseInt(e.currentTarget.value);
                                                        }}>
                                                            <option >Town</option>

                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">

                                                            <button type="button" className="btn btn-danger" onClick={(e)=>{
                                                                dispatch({type:"SET_STEP", payload: 0})
                                                            }}><i className="ri-arrow-go-back-fill me-1 align-bottom"> </i> Back </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card card-animate"}>
                                            <div className={"card-body text-center"}>
                                                <div className={"row mt-4"}>
                                                    <div className={"col"}></div>
                                                    <div className={"col"}>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty.png`}/>
                                                    </div>
                                                    <div className={"col"}></div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>
                                                        <h4>
                                                            Sorry! Your opened request inbox is currently empty!.
                                                        </h4>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )}

                        {state.step === 4 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col"}>
                                        <div className="card">
                                            <div className="card-header border-0 rounded">
                                                <div className="row g-2">
                                                    <div className="col-xl-3">
                                                        <div className="search-box">
                                                            <input type="text" className="form-control search" placeholder="Type something" />
                                                            <i className="ri-search-line search-icon"> </i>
                                                        </div>
                                                    </div>

                                                    <div className={"col ms-auto"}>
                                                        <div>
                                                            <select className="form-control" value={state.categoryId} onChange={(e: any)=>{
                                                                const selectedCat = e.currentTarget.value;
                                                                dispatch({type: "SET_SELECTED_CATEGORY", payload: selectedCat})
                                                            }}>

                                                                <option value={"99999999"}>All</option>
                                                                {Object.values(state.categories) !== undefined &&
                                                                Object.values(state.categories).map((item: any)=>(
                                                                    <option key={item.service_id}>{item.service_title}</option>
                                                                ))
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"col ms-auto"}>
                                                        <select className="form-control" value={state.countyId}
                                                                onChange={(e) => {

                                                                    selectedCounty = parseInt(e.currentTarget.value)

                                                                }}
                                                        >
                                                            <option >Select County</option>

                                                        </select>
                                                    </div>
                                                    <div className={"col ms-auto"}>

                                                        <select className="form-control" disabled={state.townReady} onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>{
                                                            townId = parseInt(e.currentTarget.value);
                                                        }}>
                                                            <option >Town</option>

                                                        </select>
                                                    </div>

                                                    <div className="col-lg-auto">
                                                        <div className="hstack gap-2">

                                                            <button type="button" className="btn btn-danger" onClick={(e)=>{
                                                                dispatch({type:"SET_STEP", payload: 0})
                                                            }}><i className="ri-arrow-go-back-fill me-1 align-bottom"> </i> Back </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}>

                                        <div className={"card card-animate"}>
                                            <div className={"card-body text-center"}>
                                                <div className={"row mt-4"}>
                                                    <div className={"col"}></div>
                                                    <div className={"col"}>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty.png`}/>
                                                    </div>
                                                    <div className={"col"}></div>
                                                </div>

                                                <div className={"row mt-4"}>
                                                    <div className={"col"}>
                                                        <h4>
                                                            Sorry! You do not have any unopened requests!.
                                                        </h4>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                </div>
            </div>

            <DashboardFooter />

        </>
    )
}

export default ReceivedRequests