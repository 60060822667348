import React, {useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";

const ClinicInfo: React.FC = () => {
    const [showButton, setShowButton] = useState(true);

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const ScrollFunction = () => {
        if (document.documentElement.scrollTop > 20) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };
    return(
        <>
            <div id="layout-wrapper">
                <DashboardHeader></DashboardHeader>

                <div id="removeNotificationModal" className="modal fade zoomIn" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                        id="NotificationModalbtn-close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mt-2 text-center">
                                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                        <h4>Are you sure ?</h4>
                                        <p className="text-muted mx-4 mb-0">Are you sure you want to remove this
                                            Notification ?</p>
                                    </div>
                                </div>
                                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                    <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="button" className="btn w-sm btn-danger" id="delete-notification">
                                        Yes, Delete It!
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <SideMenu></SideMenu>

                <div className="vertical-overlay">

                </div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Partner Details</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a
                                                    href="/institution/home">Clinic</a></li>
                                                <li className="breadcrumb-item active">Clinic Basic</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xxl-3">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <div>
                                                <div className="flex-shrink-0 avatar-md mx-auto">
                                                    <div className="avatar-title bg-light rounded">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/companies/img-2.png`} alt=""
                                                             height="50"/>
                                                    </div>
                                                </div>
                                                <div className="mt-4 text-center">
                                                    <h5 className="mb-1">Clinic Facility</h5>
                                                    <p className="text-muted">Main category</p>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table mb-0 table-borderless">
                                                        <tbody>
                                                        <tr>
                                                            <th><span className="fw-medium">Institution Name</span></th>
                                                            <td>Medi Group LTD</td>
                                                        </tr>
                                                        <tr>
                                                            <th><span className="fw-medium">Clinic Type</span></th>
                                                            <td>Mobile</td>
                                                        </tr>
                                                        <tr>
                                                            <th><span className="fw-medium">Email</span></th>
                                                            <td>clinic-one@gamil.com</td>
                                                        </tr>
                                                        <tr>
                                                            <th><span className="fw-medium">Website</span></th>
                                                            <td><a href="javascript:void(0);"
                                                                   className="link-primary">www.clinic-one.com</a></td>
                                                        </tr>
                                                        <tr>
                                                            <th><span className="fw-medium">Contact No.</span></th>
                                                            <td>+(254) 7876 654 32</td>
                                                        </tr>
                                                        <tr>
                                                            <th><span className="fw-medium">Main Contact</span></th>
                                                            <td>+254 776 543229</td>
                                                        </tr>
                                                        <tr>
                                                            <th><span className="fw-medium">Location</span></th>
                                                            <td>Town Two</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body border-top border-top-dashed p-4">
                                            <div>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-4">Patient
                                                    Reviews</h6>
                                                <div>
                                                    <div>
                                                        <div className="bg-light px-3 py-2 rounded-2 mb-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <div className="fs-16 align-middle text-warning">
                                                                        <i className="ri-star-fill"></i>
                                                                        <i className="ri-star-fill"></i>
                                                                        <i className="ri-star-fill"></i>
                                                                        <i className="ri-star-fill"></i>
                                                                        <i className="ri-star-half-fill"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <h6 className="mb-0">4.5 out of 5</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center">
                                                            <div className="text-muted">Total <span
                                                                className="fw-medium">87</span>
                                                                reviews
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <div className="row align-items-center g-2">
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0">5 star</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="p-1">
                                                                    <div
                                                                        className="progress animated-progress progress-sm">
                                                                        <div className="progress-bar bg-success"
                                                                             role="progressbar" style={{width: "50.16%"}}
                                                                             aria-valuenow={50.16} aria-valuemin={0}
                                                                             aria-valuemax={100}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0 text-muted">44</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-center g-2">
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0">4 star</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="p-1">
                                                                    <div
                                                                        className="progress animated-progress progress-sm">
                                                                        <div className="progress-bar bg-success"
                                                                             role="progressbar" style={{width: "29.32%"}}
                                                                             aria-valuenow={29.32} aria-valuemin={0}
                                                                             aria-valuemax={100}> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0 text-muted">21</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-center g-2">
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0">3 star</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="p-1">
                                                                    <div
                                                                        className="progress animated-progress progress-sm">
                                                                        <div className="progress-bar bg-warning"
                                                                             role="progressbar" style={{width: "18.12%"}}
                                                                             aria-valuenow={18.12} aria-valuemin={0}
                                                                             aria-valuemax={100}> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0 text-muted">10</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-center g-2">
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0">2 star</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="p-1">
                                                                    <div
                                                                        className="progress animated-progress progress-sm">
                                                                        <div className="progress-bar bg-success"
                                                                             role="progressbar" style={{width: "4.98%"}}
                                                                             aria-valuenow={4.98} aria-valuemin={0}
                                                                             aria-valuemax={100}> </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0 text-muted">2</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-center g-2">
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0">1 star</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="p-1">
                                                                    <div
                                                                        className="progress animated-progress progress-sm">
                                                                        <div className="progress-bar bg-danger"
                                                                             role="progressbar" style={{width: "7.42%"}}
                                                                             aria-valuenow={7.42} aria-valuemin={0}
                                                                             aria-valuemax={100}> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="p-1">
                                                                    <h6 className="mb-0 text-muted">1</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body p-4 border-top border-top-dashed">
                                            <h6 className="text-muted text-uppercase fw-semibold mb-4">Patient
                                                Feedback</h6>

                                            <div className="swiper vertical-swiper" style={{height: "242px"}}>
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <div className="card border border-dashed shadow-none">
                                                            <div className="card-body">
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 avatar-sm">
                                                                        <div className="avatar-title bg-light rounded">
                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/companies/img-1.png`}
                                                                                 alt="" height="30" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <div>
                                                                            <p className="text-muted mb-1 fst-italic">"
                                                                                Great great facility and extremely
                                                                                great. "</p>
                                                                            <div
                                                                                className="fs-11 align-middle text-warning">
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-end mb-0 text-muted">
                                                                            - by <cite title="Source Title">Clinic
                                                                            Facility</cite>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="card border border-dashed shadow-none">
                                                            <div className="card-body">
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`}
                                                                             alt="" className="avatar-sm rounded" />
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <div>
                                                                            <p className="text-muted mb-1 fst-italic">"
                                                                                Amazing amazing facility, just amazing.
                                                                                "</p>
                                                                            <div
                                                                                className="fs-11 align-middle text-warning">
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-half-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-end mb-0 text-muted">
                                                                            - by <cite title="Source Title">User
                                                                            99</cite>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="card border border-dashed shadow-none">
                                                            <div className="card-body">
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 avatar-sm">
                                                                        <div className="avatar-title bg-light rounded">
                                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/companies/img-8.png`}
                                                                                 alt="" height="30" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <div>
                                                                            <p className="text-muted mb-1 fst-italic">"Beautiful
                                                                                beautiful facility, just beautiful."</p>
                                                                            <div
                                                                                className="fs-11 align-middle text-warning">
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-line"></i>
                                                                                <i className="ri-star-line"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-end mb-0 text-muted">
                                                                            - by <cite title="Source Title">User
                                                                            NinetyNine</cite>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <div className="card border border-dashed shadow-none">
                                                            <div className="card-body">
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`}
                                                                             alt="" className="avatar-sm rounded" />
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <div>
                                                                            <p className="text-muted mb-1 fst-italic">"
                                                                                Great great facility, just great. "</p>
                                                                            <div
                                                                                className="fs-11 align-middle text-warning">
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-fill"></i>
                                                                                <i className="ri-star-half-fill"></i>
                                                                                <i className="ri-star-line"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-end mb-0 text-muted">
                                                                            - by <cite title="Source Title">User
                                                                            Five</cite>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3">
                                                <a href="javascript:void(0)" className="link-primary">View All
                                                    Reviews <i
                                                        className="ri-arrow-right-line align-bottom ms-1"></i></a>
                                            </div>
                                        </div>
                                        <div className="card-body p-4 border-top border-top-dashed">
                                            <h6 className="text-muted text-uppercase fw-semibold mb-4">Message This
                                                Facility</h6>
                                            <form action="#">
                                                <div className="mb-3">
                                                    <textarea className="form-control" id="exampleFormControlTextarea1"
                                                               placeholder="Enter your messages..."> </textarea>
                                                </div>
                                                <div className="text-end">
                                                    <button type="submit" className="btn btn-primary"><i
                                                        className="ri-mail-send-line align-bottom me-1"></i> Send
                                                        Message
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                </div>


                                <div className="col-xxl-9">

                                    <div className="row g-4 mb-3">
                                        <div className="col-sm-auto">
                                            <div>
                                                <a href="/collaborations/invite" className="btn btn-success"><i
                                                    className="ri-add-line align-bottom me-1"> </i> Collaboration Invite</a>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control"
                                                           placeholder="Search Common..." />
                                                        <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="alert alert-info">
                                                You do not have any collaborations in common with this facility.
                                            </div>
                                            <div id="table-product-list-all"
                                                 className="table-card gridjs-border-none"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <button onClick={topFunction} className="btn btn-danger btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>

            <div id="preloader">
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

            <div className="customizer-setting d-none d-md-block">
                <div className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
                     data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <i className='mdi mdi-spin mdi-cog-outline fs-22'></i>
                </div>
            </div>

            <div className="offcanvas offcanvas-end border-0" tabIndex={-1} id="theme-settings-offcanvas">
                <div className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
                    <h5 className="m-0 me-2 text-white">Uzima Customizer</h5>

                    <button type="button" className="btn-close btn-close-white ms-auto" id="customizerclose-btn"
                            data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-0">
                    <div data-simplebar className="h-100">
                        <div className="p-4">
                            <h6 className="mb-0 fw-bold text-uppercase">Layout</h6>
                            <p className="text-muted">Choose your layout</p>

                            <div className="row gy-3">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout01" name="data-layout" type="radio" value="vertical"
                                               className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout01">
                                    <span className="d-flex gap-1 h-100">
                                        <span className="flex-shrink-0">
                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                            </span>
                                        </span>
                                        <span className="flex-grow-1">
                                            <span className="d-flex h-100 flex-column">
                                                <span className="bg-light d-block p-1"> </span>
                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                            </span>
                                        </span>
                                    </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Vertical</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout02" name="data-layout" type="radio"
                                               value="horizontal" className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout02">
                                                <span className="d-flex h-100 flex-column gap-1">
                                                    <span className="bg-light d-flex p-1 gap-1 align-items-center">
                                                        <span
                                                            className="d-block p-1 bg-soft-primary rounded me-1"> </span>
                                                        <span
                                                            className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"> </span>
                                                        <span className="d-block p-1 pb-0 px-2 bg-soft-primary"> </span>
                                                    </span>
                                                    <span className="bg-light d-block p-1"> </span>
                                                    <span className="bg-light d-block p-1 mt-auto"> </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Horizontal</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout03" name="data-layout" type="radio"
                                               value="twocolumn" className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout03">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1">
                                                            <span className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Two Column</h5>
                                </div>
                                {/* end col */}

                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout04" name="data-layout" type="radio" value="semibox"
                                               className="form-check-input"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="customizer-layout04">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0 p-1">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column pt-1 pe-2">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Semi Box</h5>
                                </div>
                                {/* end col */}
                            </div>

                            <h6 className="mt-4 mb-0 fw-bold text-uppercase">Color Scheme</h6>
                            <p className="text-muted">Choose Light or Dark Scheme.</p>

                            <div className="colorscheme-cardradio">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-mode"
                                                   id="layout-mode-light" value="light"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="layout-mode-light">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check card-radio dark">
                                            <input className="form-check-input" type="radio" name="data-layout-mode"
                                                   id="layout-mode-dark" value="dark"/>
                                            <label className="form-check-label p-0 avatar-md w-100 bg-dark"
                                                   htmlFor="layout-mode-dark">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-soft-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-soft-light d-block p-1"> </span>
                                                                <span
                                                                    className="bg-soft-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-visibility">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Visibility</h6>
                                <p className="text-muted">Choose show or Hidden sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio"
                                                   name="data-sidebar-visibility" id="sidebar-visibility-show"
                                                   value="show"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-visibility-show">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0 p-1">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column pt-1 pe-2">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Show</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio"
                                                   name="data-sidebar-visibility" id="sidebar-visibility-hidden"
                                                   value="hidden"/>
                                            <label className="form-check-label p-0 avatar-md w-100 px-2"
                                                   htmlFor="sidebar-visibility-hidden">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column pt-1 px-2">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Hidden</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="layout-width">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Layout Width</h6>
                                <p className="text-muted">Choose Fluid or Boxed layout.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-width"
                                                   id="layout-width-fluid" value="fluid"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="layout-width-fluid">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Fluid</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-width"
                                                   id="layout-width-boxed" value="boxed"/>
                                            <label className="form-check-label p-0 avatar-md w-100 px-2"
                                                   htmlFor="layout-width-boxed">
                                                    <span className="d-flex gap-1 h-100 border-start border-end">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Boxed</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="layout-position">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Layout Position</h6>
                                <p className="text-muted">Choose Fixed or Scrollable Layout Position.</p>

                                <div className="btn-group radio" role="group">
                                    <input type="radio" className="btn-check" name="data-layout-position"
                                           id="layout-position-fixed" value="fixed"/>
                                    <label className="btn btn-light w-sm" htmlFor="layout-position-fixed">Fixed</label>

                                    <input type="radio" className="btn-check" name="data-layout-position"
                                           id="layout-position-scrollable" value="scrollable"/>
                                    <label className="btn btn-light w-sm ms-0"
                                           htmlFor="layout-position-scrollable">Scrollable</label>
                                </div>
                            </div>
                            <h6 className="mt-4 mb-0 fw-bold text-uppercase">Topbar Color</h6>
                            <p className="text-muted">Choose Light or Dark Topbar Color.</p>

                            <div className="row">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar"
                                               id="topbar-color-light" value="light"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="topbar-color-light">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Light</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar"
                                               id="topbar-color-dark" value="dark"/>
                                        <label className="form-check-label p-0 avatar-md w-100"
                                               htmlFor="topbar-color-dark">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span
                                                                className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span
                                                                className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-primary d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                        </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Dark</h5>
                                </div>
                            </div>

                            <div id="sidebar-size">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Size</h6>
                                <p className="text-muted">Choose a size of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-default" value="lg"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-default">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-compact" value="md"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-compact">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Compact</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-small" value="sm"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-small">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span
                                                                    className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small (Icon View)</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size"
                                                   id="sidebar-size-small-hover" value="sm-hover"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-size-small-hover">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span
                                                                    className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small Hover View</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-view">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar View</h6>
                                <p className="text-muted">Choose Default or Detached Sidebar view.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style"
                                                   id="sidebar-view-default" value="default"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-view-default">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style"
                                                   id="sidebar-view-detached" value="detached"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-view-detached">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span
                                                            className="bg-light d-flex p-1 gap-1 align-items-center px-2">
                                                            <span
                                                                className="d-block p-1 bg-soft-primary rounded me-1"> </span>
                                                            <span
                                                                className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"> </span>
                                                            <span
                                                                className="d-block p-1 pb-0 px-2 bg-soft-primary"> </span>
                                                        </span>
                                                        <span className="d-flex gap-1 h-100 p-1 px-2">
                                                            <span className="flex-shrink-0">
                                                                <span
                                                                    className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                    <span
                                                                        className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                    <span
                                                                        className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                    <span
                                                                        className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span className="bg-light d-block p-1 mt-auto px-2"> </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Detached</h5>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar-color">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Color</h6>
                                <p className="text-muted">Choose a color of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse"
                                             data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-light" value="light"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-color-light">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary">  </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse"
                                             data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-dark" value="dark"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="sidebar-color-dark">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-primary d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                    <div className="col-4">
                                        <button
                                            className="btn btn-link avatar-md w-100 p-0 overflow-hidden border collapsed"
                                            type="button" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient"
                                            aria-expanded="false" aria-controls="collapseBgGradient">
                                             <span className="d-flex gap-1 h-100">
                                                <span className="flex-shrink-0">
                                                    <span
                                                        className="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                                                        <span
                                                            className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                    </span>
                                                </span>
                                                <span className="flex-grow-1">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span className="bg-light d-block p-1"> </span>
                                                        <span className="bg-light d-block p-1 mt-auto"> </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                        <h5 className="fs-13 text-center mt-2">Gradient</h5>
                                    </div>
                                </div>
                                {/* end row */}

                                <div className="collapse" id="collapseBgGradient">
                                    <div className="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">

                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient" value="gradient"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient"> </span>
                                            </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient-2" value="gradient-2"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient-2">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-2"> </span>
                                            </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient-3" value="gradient-3"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient-3">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-3"> </span>
                                            </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar"
                                                   id="sidebar-color-gradient-4" value="gradient-4"/>
                                            <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                   htmlFor="sidebar-color-gradient-4">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-4"> </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-img">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Images</h6>
                                <p className="text-muted">Choose a image of Sidebar.</p>

                                <div className="d-flex gap-2 flex-wrap img-switch">
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-none" value="none"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-none">
                                    <span
                                        className="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
                                        <i className="ri-close-fill fs-20"> </i>
                                    </span>
                                        </label>
                                    </div>

                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-01" value="img-1"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-01">
                                            <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-1.jpg"} alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>

                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-02" value="img-2"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-02">
                                            <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-2.jpg"} alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-03" value="img-3"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-03">
                                            <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-3.jpg"} alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image"
                                               id="sidebarimg-04" value="img-4"/>
                                        <label className="form-check-label p-0 avatar-sm h-auto"
                                               htmlFor="sidebarimg-04">
                                            <img src="assets/images/sidebar/img-4.jpg" alt=""
                                                 className="avatar-md w-auto object-cover"/>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div id="preloader-menu">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Preloader</h6>
                                <p className="text-muted">Choose a preloader.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-preloader"
                                                   id="preloader-view-custom" value="enable"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="preloader-view-custom">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                {/* <div id="preloader"> */}
                                                <div id="status"
                                                     className="d-flex align-items-center justify-content-center">
                                                    <div className="spinner-border text-primary avatar-xxs m-auto"
                                                         role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Enable</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-preloader"
                                                   id="preloader-view-none" value="disable"/>
                                            <label className="form-check-label p-0 avatar-md w-100"
                                                   htmlFor="preloader-view-none">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span
                                                                    className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Disable</h5>
                                    </div>
                                </div>

                            </div>
                            {/* end preloader-menu */}

                        </div>
                    </div>

                </div>
                <div className="offcanvas-footer border-top p-3 text-center">
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn btn-light w-100" id="reset-layout">Defaults</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClinicInfo;