import React, {createContext, useEffect, useState} from "react";
import localForage from 'localforage';
import {useBaseUrl} from "../../utils/utils";

interface HomeContentProps {

    content: React.ReactNode;

}

export const HomeContext = createContext<HomeContentProps>({
    content: null,
});

export const HomeProvider: React.FC = () => {
    const base_url = useBaseUrl()

    const [content, setContent] = useState<React.ReactNode>(null);

    useEffect(() => {
        FetchContent().then((data) => {
            setContent("This is"+data)
        });
    }, []);



    return (

        <HomeContext.Provider value={{content}}>
            <div> Hello world! </div>
        </HomeContext.Provider>


    );
};

async function FetchContent(): Promise<React.ReactNode> {

    const base_url = useBaseUrl()

    try {
        let token = await localForage.getItem<string>('token');
        let clinic_id = await localForage.getItem('clinic_id');
        let staff_id = await localForage.getItem('staff_id');
        let institution_id = await localForage.getItem('institution_id');

        if (!token || !clinic_id || !staff_id || !institution_id) {
            window.location.href = './logout';
            return null;
        }

        // @ts-ignore
        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                "Api-key": token,
                "Content-Type": "application.json",
            },
            body: JSON.stringify({
                "clinic_id": clinic_id,
                "staff_id": staff_id,
                "institution_id": institution_id
            })
        }

        let dash_url = `${base_url}/institutions/dashboard`;
        const response = await fetch(dash_url, requestOptions);
        const data = await response.json();

        if(data.status === "error"){

            //load error module


        }else if(data.status === "success"){

            return data.data

        }else{

            //conn-fail error module


        }
    } catch (error) {
        // failed to fetch
    }
}