import React, {useEffect, useState} from 'react';
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import Canvas from "./dahboard_canvas";
import DashboardFooter from "./dashboard_footer";
import Swal from "sweetalert2";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {stringify} from "querystring";
import localforage from "localforage";
import {useBaseUrl} from "../../utils/utils";


const CreateMedicalRecord: React.FC = () => {
    const clinic_id: any = 1
    const staff_id: any = 1
    const staff_fname = localforage.getItem("staff_fname")
    const staff_lname = localforage.getItem("staff_lname")

    const [step, setStep] = useState<number>(1)
    const [subStep, setSubstep] = useState<number>(1)
    const [pageGuide, setPageGuide] = useState("(EHR)")

    const [fname, setFname] = useState("")
    const [mname, setMname] = useState<any>(null)
    const [lname, setLname] = useState("")
    const [otherName, setOtherName] = useState<any>(null)
    const [password, setPassword] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [patientEmail, setPatientEmail] = useState("")
    const [dob, setDob] = useState("")
    const [gender, setGender] = useState("")
    const [pic, setPic] = useState<any>(null)

    const [admNo, setAdmNo] = useState<any>(null)
    const [title, setTitle] = useState<any>(null)
    const [chiefComplaint, setChiefComplaint] = useState<any>("Describe the patient's complaint...")
    const [medicalHistory, setMedicalHistory] = useState("Describe the patient's medical history...")
    const [familyHistory, setFamilyHistory] = useState("Describe the patient's family history if applicable...")
    const [socialHistory, setSocialHistory] = useState("Patient's social history")
    const [physical, setPhysical] = useState("Patient's phyisical...")
    const [assesment, setAssesment] = useState("Patient's assesment...")
    const [treatmentPlan,setTreatmentPlan] = useState("Patient's treatment plan...")
    const [recordId, setRecordId] = useState<any>()

    const [temp, setTemp] = useState<any>(0.00)
    const [heart, setHeart] = useState<any>(0)
    const [bp, setBp] = useState("")
    const [bg, setBg] = useState("")
    const [bmi, setBmi] = useState<any>()
    const [height, setHeight] = useState<any>()
    const [weight, setWeight] = useState<any>()
    const [resp, setResp] = useState("")
    const [oxygen, setOxygen] = useState("")
    const [vitalNotes, setVitalNotes] = useState("")

    const [labTest, setLabTest] = useState("")
    const [labResult, setLabResult] = useState("")
    const [labRef, setLabRef] = useState("")
    const [image, setImage] = useState()

    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [isChecked2, setIsChecked2] = useState(false)
    const [labDisabled, setLabDisabled] = useState(true)
    const [imaging, setImaging] = useState(true)

    const base_url = useBaseUrl()

    useEffect(()=>{
        document.title = "Create Medical Record";
    }, [])

    useEffect(()=>{

        const handleBeforeRefresh = (event: any) => {

            event.preventDefault();
            event.returnValue = "";

            Swal.fire({
                icon: "warning",
                title: "Warning - HOT!",
                text: "Are you sure you want to leave this page? Any unsaved progress made will be lost when you leave.",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, leave',
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.cancel) {
                    event.returnValue = false;
                }
            });
        }

        window.addEventListener('beforeunload', handleBeforeRefresh);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeRefresh);
        }
    }, [])

    const handleEditorChange = (event: any, editor: any) => {
        const data = editor.getData();
        setChiefComplaint(data);
    }

    const divStyle = {
        borderRadius: "3% "
    }

    const handleStartZero = () => {

        setSubstep(2)

    }

    const handleStartExists = () => {
        setStep(2)
    }

    const createPatientAccount = async (e: any) => {
        e.preventDefault();

        const this_url = base_url + '/users/create-patient';

        setIsLoading(true)
        try{

            const formData = new FormData();

            formData.append("clinic_id", clinic_id)
            formData.append("staff_id", staff_id)
            formData.append("first_name", fname)
            formData.append("middle_name", mname)
            formData.append("last_name", lname)
            formData.append("other_name", otherName)
            formData.append("password", password)
            formData.append("email", patientEmail)
            formData.append("dob", dob)
            formData.append("gender", gender)
            formData.append("pic", pic)

            const response = await axios.post(this_url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if(status == "success"){

                setStep(2)
                setSubstep(1)
                setFname("")
                setMname("")
                setLname("")
                setOtherName("")
                setPassword("")
                setPatientEmail("")
                setDob("")
                setGender("")
                setPic("")
                setPageGuide("Create New Record")

                await Swal.fire({
                    icon: "success",
                    title: "Added",
                    text: "Patient Created, loading next..."
                })

            }else{
                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })
            }

        }catch (e){
            await Swal.fire({
                title: "Error",
                icon: "error",
                text: "Unable to establish server connection. Check your internet status."
            })

            return;
        }
    }

    const createMedicalRecord = async (e: any) => {
        e.preventDefault()

        setIsLoading(true)

        const url = base_url + "/ehr/create-record";

        try{

            const formData = new FormData()

            formData.append("staff_id", staff_id)
            formData.append("clinic_id", clinic_id)
            formData.append("patient_id", admNo)
            formData.append("title", title)
            formData.append("chief_complaint", chiefComplaint)
            formData.append("medical_history", medicalHistory)
            formData.append("family_history", familyHistory)
            formData.append("social_history", socialHistory)
            formData.append("phyisical", physical)
            formData.append("treatment_plan", treatmentPlan)
            formData.append("assessment", assesment)


            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data } = response.data;

            if(status == "success"){

                setSubstep(2)
                setRecordId(data.record_id)
                setChiefComplaint("")
                setMedicalHistory("")
                setFamilyHistory("")
                setSocialHistory("")
                setPhysical("")
                setTreatmentPlan("")
                setAssesment("")
                setPageGuide("Add Patient Vitals")

                await Swal.fire({
                    icon: "success",
                    title: "Add medication",
                    text: "You've created a medical record for patient:"+ admNo +"Now add more supporting info to the record in the next 3 easy steps"
                })

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })

                return;

            }

        }catch (e: any) {

            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Unable to initiate communication with the server. Try again."
            })

            return;

        }

    }

    const createVitals = async (e: any) => {

        e.preventDefault();

        setIsLoading(true)

        let url = base_url + "/ehr/create-vitals"

        try{
            const formData = new FormData()
            formData.append("record_id", recordId)
            formData.append("temperature", temp)
            formData.append("heart_rate", heart)
            formData.append("blood_pressure", bp)
            formData.append("blood_glucose", bg)
            formData.append("bmi", bmi)
            formData.append("height", height)
            formData.append("weight", weight)
            formData.append("respiratory", resp)
            formData.append("oxygen", oxygen)
            formData.append("vital_notes", vitalNotes)


            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if(status == "success"){

                setSubstep(3)
                setPageGuide("Add Test Results: Leave unchecked if not applicable")


            }else{
                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })

                return
            }

        }catch (e) {

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Unable to initiate communication with the Server"
            })

        }
    }

    const submitLabResults = async (e: any) => {

    }

    const submitLabTest = async(e: any) => {
        e.preventDefault()

        setIsLoading(true)

        const url = base_url+"/ehr/add-lab-test"

        try{

            const formData = new FormData()
            formData.append("record_id", recordId)
            formData.append("lab_test", labTest)
            formData.append("lab_result", labResult)
            formData.append("lab_ref", labRef)

            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(false)
            })

            const {status, message, data} = response.data

            if(status == "success"){

                setIsChecked(!isChecked)

                setLabTest("")
                setLabResult("")
                setLabRef("")

                await Swal.fire({
                    icon: "success",
                    title: "Lab Results Added!",
                    text: "Proceed to the next page or check any section to add more records"
                })

                return

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })

                return

            }

        }catch (e) {

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Unable to initiate server communication. Check your connection and try again"
            })

            return

        }

    }

    const submitImagingTest = async (e: any) => {
        e.preventDefault()

        const url = base_url+"/add-new-imaging"

        try{

        }catch (e) {

        }
    }

    const check_one = (e: any)=> {

        setIsChecked(!isChecked)

        if(!isChecked){

            setLabDisabled(false)

        }else{

            setLabDisabled(true)

        }

    }

    const check_two = () => {
        setIsChecked2(!isChecked2)

        if(!isChecked2){

            setImaging(false)

        }else{

            setImaging(true)

        }
    }

    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>

                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Electronic Health Records - {pageGuide}</h4>

                                    <div className={"page-title-right"}>
                                        <ol className={"breadcrumb m-0"}>
                                            <li className={"breadcrumb-item"}><a href={"/institution/ehr"}>EHR</a></li>
                                            <li className={"breadcrumb-item active"}>Add New</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                {step == 1 && (
                                    <>
                                        <div className={"row mt-3"}>
                                            {subStep==1 && (
                                                <>
                                                    <div className={"col"}> </div>
                                                    <div className={"col"}>

                                                        <div className="card card-animate" style={divStyle}>
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/new.png`} alt=""
                                                                         className="img-fluid"/>
                                                                </div>
                                                                <h5 className="card-title mb-1">Create Patient</h5>

                                                                <p><a className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                                      onClick={handleStartZero}>Create</a></p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className={"col"}> </div>
                                                    <div className={"col"}>

                                                        <div className="card card-animate" style={divStyle}>
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/existing.png`} alt=""
                                                                         className="img-fluid"/>
                                                                </div>
                                                                <h5 className="card-title mb-1">Use Existing</h5>

                                                                <p><a className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                                      onClick={handleStartExists}>Next Step</a></p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                            )}
                                            {subStep == 2 &&(
                                                <>
                                                    <div className={"row mt-4 project-wrapper"}>
                                                        <div className={"col-md-1"}> </div>
                                                        <div className={"col-md-10"}>
                                                            <div className={"card"}>
                                                                <div className={"card-body"}>

                                                                    <h4 className={"text-center"}>Create Clinic Patient</h4>

                                                                    <form onSubmit={createPatientAccount}>

                                                                        <label className={"form-label mt-4"}>First Name</label>
                                                                        <input className={"form-control mt 2"} type={"text"} value={fname} placeholder={"Patient First Name"} required={true} onChange={(e)=>setFname(e.currentTarget.value)}/>

                                                                        <label className={"form-label mt-4"}>Middle Name <em>(Optional)</em> </label>
                                                                        <input className={"form-control mt 2"} type={"text"} value={mname} placeholder={"Patient Last Name"} onChange={(e)=>setMname(e.currentTarget.value)}/>

                                                                        <label className={"form-label mt-4"}>Last Name  </label>
                                                                        <input className={"form-control mt 2"} type={"text"} value={lname} placeholder={"Patient Middle Name"} required={true} onChange={(e)=>setLname(e.currentTarget.value)}/>

                                                                        <label className={"form-label mt-4"}>Other Name(s) <em>(Optional)</em> </label>
                                                                        <input className={"form-control mt 2"} type={"text"} value={otherName} placeholder={"Other Name"} onChange={(e)=>setOtherName(e.currentTarget.value)}/>


                                                                        <label className={"form-label mt-4"}>Patient Picture <em>(Optional)</em></label>
                                                                        <input type={"file"} className={"form-control mt-2"} onChange={(e: any)=>{
                                                                            let patient_pic = e.currentTarget.files[0]

                                                                            if(patient_pic){
                                                                                setPic(patient_pic)
                                                                            }

                                                                        }}/>

                                                                        <label className={"form-label mt-4"}>Date of Birth </label>
                                                                        <input className={"form-control mt 2"} value={dob} type={"date"} required={true} onChange={(e)=>setDob(e.currentTarget.value)}/>

                                                                        <label className={"form-label mt-4"}>Gender</label>
                                                                        <select className={"form-control mt-2"} value={gender} onChange={(e)=>{setGender(e.currentTarget.value)}}>
                                                                            <option>Choose patient gender...</option>
                                                                            <option value={"male"}>Male</option>
                                                                            <option value={"female"}>Female</option>
                                                                            <option value={"other"}>Other</option>
                                                                        </select>

                                                                        <label className={"form-label mt-4"}>Email </label>
                                                                        <input className={"form-control mt 2"} type={"email"} value={patientEmail} placeholder={"Patient Email"} required={true} onChange={(e)=>setPatientEmail(e.currentTarget.value)}/>

                                                                        <label className={"form-label mt-4"}>Password</label>
                                                                        <input className={"form-control mt 2"} value={password} required={true} type={"password"} onChange={(e)=>setPassword(e.currentTarget.value)}/>

                                                                        <input className={"form-control btn btn-success mt-4"} disabled={isLoading} value={"Create Patient Account"} type={"submit"}/>
                                                                    </form>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className={"col-md-1"}> </div>
                                                    </div>

                                                </>
                                            )}
                                        </div>
                                    </>
                                )}

                                {step==2 && (
                                    <>
                                        <div className={"row mt-3"}>
                                            <div className={"col"}>{subStep==1&&(<div>Step 1</div>)}</div>
                                            <div className={"col"}>{subStep==2&&(<div>Step 2</div>)}</div>
                                            <div className={"col"}>{subStep==3&&(<div>Step 3</div>)}</div>
                                            <div className={"col"}>{subStep==4&&(<div>Step 4</div>)}</div>
                                        </div>
                                            {subStep == 1 &&(
                                                <>
                                                    <div className={"row mt-4"}>
                                                        <div className={"col"}>

                                                            <div className={"card card-animate"}>
                                                                <div className={"card-header"}>
                                                                    Medical Record Primary Details
                                                                </div>
                                                                <div className={"card-body"}>
                                                                    <div className={"container-fluid"}>

                                                                        <form onSubmit={createMedicalRecord}>

                                                                            <label className={"form-label"}>Patient Admission Number</label>
                                                                            <input className={"form-control mt-2"} type={"text"} value={admNo} onChange={(e:any)=>{setAdmNo(e.currentTarget.value)}} placeholder={"PT01--XXX"}/>

                                                                            <label className={"form-label mt-3"}>Record Title</label>
                                                                            <input className={"form-control mt-2"} type={"text"} value={title} onChange={(e)=>{setTitle(e.currentTarget.value)}} placeholder={"User's medical record"} />

                                                                            <label className={"form-label mt-3"}>Chief Complaint</label>
                                                                            <CKEditor editor={ClassicEditor} data={chiefComplaint} onChange={handleEditorChange} />

                                                                            <label className={"mt-3"}>Medical history</label>
                                                                            <CKEditor editor={ClassicEditor} data={medicalHistory} onChange={(d: any, e: any)=>{const data= e.getData(); setMedicalHistory(data)}} />

                                                                            <label className={"form-label mt-3"}>Family History</label>
                                                                            <CKEditor editor={ClassicEditor} data={familyHistory} onChange={(d:any, e:any)=>{const data = e.getData(); setFamilyHistory(data)}} />

                                                                            <label className={"form-label mt-3"}>Social History</label>
                                                                            <CKEditor editor={ClassicEditor} data={socialHistory} onChange={(d: any, e:any)=> {const data=e.getData(); setSocialHistory(data)}} />

                                                                            <label className={"form-label mt-3"}>Physical Examination</label>
                                                                            <CKEditor editor={ClassicEditor} data={physical} onChange={(d: any, e:any)=>{const data=e.getData(); setPhysical(data)}} />

                                                                            <label className={"form-label mt-3"}>Assessment</label>
                                                                            <CKEditor editor={ClassicEditor} data={assesment} onChange={(d: any, e: any)=>{const data=e.getData(); setAssesment(data)}} />

                                                                            <label className={"form-label mt-3"}>Treatment Plan</label>
                                                                            <CKEditor editor={ClassicEditor} data={treatmentPlan} onChange={(d: any, e: any)=> {const data = e.getData(); setTreatmentPlan(data)}} />

                                                                            <div className={"row mt-3"}>
                                                                                <div className={"col"}> </div>
                                                                                <div className={"col"}> </div>
                                                                                <div className={"col"}>
                                                                                    <input type={"submit"} className={"btn btn-primary mt-3"} disabled={isLoading} value={"      Next Step      "}/>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {subStep == 2 && (
                                                <>
                                                    <div className={"row mt-4"}>

                                                        <div className={"col"}>

                                                            <div className={"card card-animate"}>

                                                                <div className={"card-header"}>

                                                                </div>

                                                                <div className={"card-body"}>

                                                                    <div className={"container-fluid"}>

                                                                        <form onSubmit={createVitals}>
                                                                            <label className={"form-label mt-3"}>Temperature</label>
                                                                            <input className={"form-control"} type={"number"} value={temp} step={"0.01"} onChange={(e:any)=>{setTemp(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>Heart rate</label>
                                                                            <input className={"form-control"} type={"number"} value={heart} onChange={(e:any)=>{setHeart(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>Blood Pressure</label>
                                                                            <input className={"form-control"} type={"text"} value={bp} onChange={(e)=>{setBp(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>Blood Glucose</label>
                                                                            <input className={"form-control"} type={"text"} value={bg} onChange={(e)=>{setBg(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>BMI</label>
                                                                            <input type={"number"} className={"form-control"} value={bmi} onChange={(e:any)=>{setBmi(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>Height</label>
                                                                            <input type={"number"} className={"form-control"} value={height} onChange={(e:any)=>{setHeight(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>BMI</label>
                                                                            <input type={"number"} className={"form-control"} value={weight} onChange={(e:any)=>{setWeight(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>Respiratory Rate</label>
                                                                            <input type={"text"} className={"form-control"} value={resp} onChange={(e:any)=>{setResp(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>Oxygen Saturation</label>
                                                                            <input type={"text"} className={"form-control"} value={oxygen} onChange={(e:any)=>{setOxygen(e.currentTarget.value)}} />

                                                                            <label className={"form-label mt-3"}>Additional Notes</label>
                                                                            <CKEditor editor={ClassicEditor} data={vitalNotes} onChange={(d:any, e:any)=>{const data = e.getData(); setVitalNotes(data)}} />

                                                                            <div className={"row mt-3"}>
                                                                                <div className={"col"}>

                                                                                    <button className={"btn btn-success"} onClick={(e)=>{Swal.fire({icon:"warning", title:"attempted reverse navigation", text: "You must complete updating this record before creating another"})}}>Go Back</button>
                                                                                </div>
                                                                                <div className={"col"}>
                                                                                    <button className={"btn btn-danger"} onClick={(e)=>{setSubstep(3)}}>Skip Step</button>
                                                                                </div>
                                                                                <div className={"col"}>
                                                                                    <input type={"submit"} className={"btn btn-primary"} disabled={isLoading} value={"      Next Step      "}/>
                                                                                </div>
                                                                            </div>
                                                                        </form>

                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </>
                                            )}

                                            {subStep == 3 && (
                                                <>
                                                    <form onSubmit={submitLabResults}>
                                                        <div className={"row mt-4"}>
                                                            <div className={"col"}>
                                                                <div className={"card card-animate"}>
                                                                    <div className={"card-header"}> Lab results <input type={"checkbox"} checked={isChecked} onChange={check_one} /> </div>
                                                                    <div className={"card-body"}>
                                                                        <label className={"form-label mt-3"}>Test</label>
                                                                        <input type={"text"} value={labTest} className={"form-control"} disabled={labDisabled} onChange={(e:any)=>{setLabTest(e.currentTarget.value)}} />

                                                                        <label className={"form-label mt-3"}>Result</label>
                                                                        <textarea value={labResult} className={"form-control"} disabled={labDisabled} onChange={(e:any)=>{setLabResult(e.currentTarget.value)}} />

                                                                        <label className={"form-label mt-3"}>Reference Range</label>
                                                                        <textarea value={labRef} className={"form-control"} disabled={labDisabled} onChange={(e:any)=>{setLabRef(e.currentTarget.value)}} />

                                                                        <button className={"btn btn-success mt-4"} disabled={labDisabled} onClick={submitLabTest}>Submit Result</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"col"}>
                                                                <div className={"card card-animate"}>
                                                                    <div className={"card-header"}> Imaging Results <input type={"checkbox"} checked={isChecked2} onChange={check_two} /></div>
                                                                    <div className={"card-body"}>

                                                                        <label className={"form-label mt-3"}>Image</label>
                                                                        <input value={labResult} type={"file"} className={"form-control"} disabled={labDisabled} onChange={(e:any)=>{setLabResult(e.currentTarget.value)}} />

                                                                        <label className={"form-label mt-3"}>Description</label>
                                                                        <textarea value={labRef} className={"form-control"} disabled={labDisabled} onChange={(e:any)=>{setLabRef(e.currentTarget.value)}} />

                                                                        <button className={"btn btn-success mt-4"} disabled={labDisabled} onClick={submitImagingTest}>Submit Result</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </>
                                            )}
                                            {subStep==4 &&(

                                                <>
                                                    <div className={"row"}>
                                                        <div className={"col"}>
                                                            <div className={"card"}>
                                                                <div className={"card-header"} style={{backgroundColor: "", color:"white"}}>Medication</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            )}
                                    </>

                                )}

                            </div>
                        </div>

                    </div>
                </div>
                <DashboardFooter></DashboardFooter>
            </div>

            <Canvas></Canvas>
        </>
    )
}

export default CreateMedicalRecord;