import React, {useEffect, useReducer} from 'react'
import DashboardFooter from '../institutions/layouts/dashboard_footer';
import PatientDashboardHeader from "./PatientDashboardHeader";
import PatientDashboardSideMenu from "./PatientDashboardSideMenu";
import localforage from "localforage";
import {useAppName} from "../utils/utils";
import {Link, useNavigate} from "react-router-dom";


const PatientDashboard: React.FC = () => {

    useEffect(()=>{
        document.title = "Uzima Healthcare Patients"
    }, [])

    useEffect(() => {

        const fetchTime = async () => {
            try {

                const response = await fetch('http://worldtimeapi.org/api/timezone/Africa/Nairobi')

                const data = await response.json();

                dispatch({type: "SET_TYPE", payload: data.datetime})

                const currentHour: any = new Date(data.datetime).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', hour12: false, hour: 'numeric' });

                if (currentHour >= 0 && currentHour < 12) {
                    dispatch({type: "SET_GREETING", payload: "Good Morning"})
                } else if (currentHour >= 12 && currentHour < 18) {
                    dispatch({type: "SET_GREETING", payload: "Good Afternoon"})
                } else {
                    dispatch({type: "SET_GREETING", payload: "Good Evening"})
                }

            } catch (error) {

                console.error('Error fetching time:', error);
            }
        };

        fetchTime();
    }, []);

    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            // Change the data-layout attribute
            rootElement.setAttribute('data-layout', 'semi-box');
        }

        document.title = appName

        localforage.getItem("clinic_name").then((clin: any)=>{
            dispatch({type: "SET_CLINIC", payload: clin})
        })

        localforage.getItem("fname").then((frs: any)=>{
            dispatch({type: "SET_FNAME", payload: frs})
        })

        localforage.getItem("lname").then((lrs: any)=>{
            dispatch({type: "SET_LNAME", payload: lrs})
        })

        localforage.getItem("category").then((cat: any)=> {
            dispatch({type: "SET_CATEGORY", payload: cat})
        })
    }, []);

    const appName= useAppName()

    const navigate = useNavigate()

    const initialState = {
        category: "",
        fName: "",
        lName: "",
        clinic: "",
        greeting: "",
        currentTime: ""
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case "SET_CATEGORY":
                return {...state, category: action.payload}

            case "SET_FNAME":
                return {...state, fName: action.payload}

            case "SET_LNAME":
                return {...state, lName: action.payload}

            case "SET_CLINIC":
                return {...state, clinic: action.payload}

            case "SET_GREETING":
                return {...state, greeting: action.payload}

            case "SET_TIME":
                return {...state, currentTime: action.payload}

            default:
                return state
        }


    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (

        <>
            <PatientDashboardHeader />
            <PatientDashboardSideMenu />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className="row">
                        <div className="col-12">
                            <div
                                className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0  ">{state.fName} {state.lName} - Patient Access &nbsp;
                                </h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a
                                            href="javascript: void(0);">Dashboards</a></li>
                                        <li className="breadcrumb-item active">Facility</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4 text-center"}>
                        <div className={"col-12"}>
                            <h3>{state.greeting} - {state.fName}</h3>
                        </div>
                    </div>

                    <div className={"row mt-4 text-center"}>
                        <div className={"col"}>
                            <h6>Welcome to uzima Healthcare! Here you have access to various tools to connect you to industry stakeholders and professionals.</h6>
                        </div>
                    </div>

                    <div className={"row mt-4"}>

                    </div>

                    <div className={"row mt-4 mb-2"}>

                        <div className={"col"}>
                            <Link to={"/patient/dashboard/ehr"} className="card card-animate">
                                <div className="card-body p-4 text-center">
                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/record.png`}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <h6 className="card-title mb-1">My EHRs</h6>

                                </div>
                            </Link>

                        </div>

                        <div className={"col"}>
                            <Link to={"/patient/dashboard/treatments"} className="card card-animate">
                                <div className="card-body p-4 text-center">
                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/clinic.png`}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <h6 className="card-title mb-1">My Treatments</h6>

                                </div>
                            </Link>

                        </div>

                        <div className={"col"}>
                            <Link to={"/patient/dashboard/resources"} className="card card-animate">
                                <div className="card-body p-4 text-center">
                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/resources.png`}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <h6 className="card-title mb-1">Resources</h6>

                                </div>
                            </Link>

                        </div>

                        <div className={"col"}>
                            <Link to={"/patient/dashboard/healthtracker"} className="card card-animate">
                                <div className="card-body p-4 text-center">
                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/collaborations.png`}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <h6 className="card-title mb-1">Health Tracker</h6>

                                </div>
                            </Link>

                        </div>

                        <div className={"col"}>
                            <Link to={"/patient/dashboard/appointments"} className="card card-animate">
                                <div className="card-body p-4 text-center">
                                    <div className="mx-auto avatar-md mb-3">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/notifications.png`}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                    <h6 className="card-title mb-1">Appointments</h6>

                                </div>
                            </Link>

                        </div>

                    </div>


                </div>

                <DashboardFooter />
            </div>
        </>
    )

}

export default PatientDashboard