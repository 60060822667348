import React, {useEffect, useState} from "react";
import {useBaseUrl, usePostData} from "../../utils/utils";
import Swal from "sweetalert2";
import axios from "axios";
import * as Process from "process";
import {initMetric} from "web-vitals/dist/modules/lib/initMetric";
import 'boxicons'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";

interface Props{
    category: any,
    subcat: any,
    partnerId: any,
    county: any,
    town: any
}


const PartnerProcessor: React.FC<Props> =({category, subcat, partnerId, county, town})=>{

    const[isLoading, setIsLoading] = useState<boolean>(false)
    const[attachDebugger, setAttachDebugger] = useState<any>(true)
    const[partners, setPartners] = useState<any[]>([])
    const[step, setStep] = useState<number>(1)
    const[subStep, setSubStep] = useState<number>(1)
    const[displayItem, setDisplayItem] = useState<any>()
    const[cateTitle, setCateTitle] = useState("Basic Info")
    const[hoverData, setHoverData] = useState<any>("<p></p>")
    const[selectedService, setSelectedService] = useState<any[]>([])

    const[request, setRequest] = useState<any>("Replace this text with a description of your request. The medical record bears all the patient information you have provided...")
    const[btnText, setBtnText] = useState("Validate Record")
    const[record, setRecord] = useState<any>()
    const[priority, setPriority] = useState("low")
    const[isValidated, setisValidated] = useState<boolean>(true)


    const base_url = useBaseUrl()

    const getPartners = async () =>{

        let url = base_url + "/partners/fetch"

        setIsLoading(!isLoading)

        try{

            const formData = new FormData()

            formData.append("category", category)
            formData.append("partner_id", partnerId)
            formData.append("attach_debugger", attachDebugger)
            formData.append("town", town)
            formData.append("county", county)

            const response = await axios.post(url, formData).finally(()=>{
                setIsLoading(!isLoading)
            })

            const {status, message, data} = response.data

            if(status=="success"){

                if(Object.keys(data).length == 0){

                    await Swal.fire({
                        icon: "info",
                        title: "Success",
                        text: "The query executed successfully but we found no partners from your parameters"
                    })

                    return

                }else{

                    setPartners(data)

                    return

                }

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Request Error",
                    text: message
                })

                return

            }

        }

        catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Fail",
                text: "Connection to server failed. Please Refresh."
            })

            return

        }

    }

    useEffect(()=>{
        document.title="Processing Partner"
    }, [])

    useEffect(()=>{

        getPartners();

    }, [category, subcat, partnerId])

    const ValidateMedicalRecord = async() => {
        let url = base_url

        const formData = new FormData()

        const resp: any = usePostData(url, formData)

        if(resp.data.validater === "validated"){

            setBtnText("Re-Validate Record")

        }else{

            await Swal.fire({
                icon: "warning",
                title: "Failed to validate record",
                text: "The patient record could not be validated. Please ensure the patient record belongs to your facility and try again"
            })

        }

    }

    const ChooseItems = (e: React.ChangeEvent<HTMLInputElement>, item: any) =>{

        const isChecked = e.currentTarget.checked;

        if(isChecked){

            setSelectedService(prevSelectedService => [...prevSelectedService, item])

        }else{

            setSelectedService(prevSelectedService => prevSelectedService.filter(selectedService => selectedService !== item))

        }

    }
    return (
        <>
            {step==1 && (
                <>

                    {Object.keys(partners).length==0 &&(
                        <div className={"card card-animate"}>
                            <div className={"card-body text-center"}>
                                <div className={"row mt-4"}>
                                    <div className={"col"}></div>
                                    <div className={"col"}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/not_found.png`}/>
                                    </div>
                                    <div className={"col"}></div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col"}></div>
                                    <div className={"col"}>
                                        <h4>
                                            We found no partners.
                                        </h4>
                                    </div>
                                    <div className={"col"}></div>
                                </div>



                            </div>
                        </div>
                    )}


                    <div className={"row mt-4"}>
                        {Object.values(partners).map((items)=>(
                            <div className={"col"}>
                                <div className="card card-animate" >
                                    <div className={"card-header"}>
                                        {items.pricing.categories.category}
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}`+`${items.logo}`} alt="" className="img-fluid" />
                                        </div>
                                        <div className={"row mt-1"}>
                                            <div className={"col"}>
                                                <label><i className="bx bx-archive"></i>Partner ID:</label>
                                                <h6>{items.title} {items.name}</h6>
                                            </div>
                                        </div>
                                        <div className={"row mt-1"}>
                                            <div className={"col"}>
                                                <label><i className="bx bx-file"></i>Partner Category:</label>
                                                <h6>{items.pricing.categories.category} : {items.pricing.categories.sub_category}</h6>
                                            </div>
                                        </div>

                                        <div className={"row mt-1"}>
                                            <div className={"col"}>
                                                <label><i className="bx bx-current-location"></i>Partner Location:</label>
                                                <h6>County: {items.location.county}<br /> Town: {items.location.town}</h6>
                                            </div>
                                        </div>

                                        <p><button className="btn btn-outline-primary btn-rounded btn-lg mt-4" style={{width: "100%"}} onClick={()=>{
                                            setDisplayItem(items)
                                            setStep(2)
                                            setSubStep(1)
                                        }}>Open</button></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {step===2 && (

                <>

                    <div className="card mb-3" style={{maxWidth: "540px;", display: "block"}}>
                        <div className={"card-header"}>
                            <h5 className={"text-center"}>{cateTitle}</h5>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-3">
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <div className={"container-fluid"}>
                                            <div className={"mt-4"}>
                                                <button className={"btn btn-outline-primary"} onClick={()=>{
                                                    setSubStep(1)
                                                    setCateTitle(displayItem.name+"'s Basic Information")
                                                }} style={{width: "100%"}}>
                                                    Basic Info
                                                </button>

                                            </div>

                                            <div className={"mt-2"}>
                                                <button className={"btn btn-outline-primary"} onClick={()=>{
                                                    setSubStep(2)
                                                    setCateTitle("Partner's Availability Information")
                                                }} style={{width: "100%"}}>
                                                    Availability
                                                </button>

                                            </div>

                                            <div className={"mt-2"}>
                                                <button className={"btn btn-outline-primary"} onClick={()=>{
                                                    setSubStep(3)
                                                    setCateTitle("Partner's Location Info")
                                                }} style={{width: "100%"}}>
                                                    Location
                                                </button>
                                            </div>

                                            <div className={"mt-2"}>
                                                <button className={"btn btn-outline-primary"} onClick={()=>{
                                                    setSubStep(4)
                                                    setCateTitle("Partner's Services Offered")
                                                }} style={{width: "100%"}}>
                                                    Services
                                                </button>

                                            </div>

                                            <div className={"mt-2"}>
                                                <button className={"btn btn-outline-primary"} onClick={()=>{
                                                    setSubStep(5)
                                                    setCateTitle("Activity in common with "+ displayItem.name)
                                                }} style={{width: "100%"}}>

                                                    Common Activity
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card-body">

                                        {displayItem && (
                                            <>
                                                {
                                                    subStep==1 && (

                                                        <>
                                                            <div className={"mt-4"}>
                                                                <img className={"mx-auto avatar-md mb-3"} src={displayItem.logo}/>
                                                            </div>
                                                            <label className={"form-label mt-2"}><i className="bx bx-user"> </i> Partner Name: </label>
                                                            <h6 className={"mt-1"}>{displayItem.title} {displayItem.name}</h6>

                                                            <div className={"row"}>
                                                                <div className={"col"}>
                                                                    <label className={"form-label mt-3"}><i className="bx bx-file"> </i> Partner Category:</label>
                                                                    <h6 className={"mt-1"}>{displayItem.pricing.categories.category}</h6>
                                                                </div>

                                                                <div className={"col"}>
                                                                    <label className={"form-label mt-3"}><i className="bx bx-file"> </i> Partner Sub - Category:</label>
                                                                    <h6 className={"mt-1"}>{displayItem.pricing.categories.sub_category}</h6>
                                                                </div>
                                                            </div>

                                                            <label className={"form-label mt-2"}><i className="bx bx-globe"> </i> Partner Website: </label>
                                                            <h6 className={"mt-1"}>{displayItem.website}</h6>
                                                        </>
                                                    )
                                                }

                                                {
                                                    subStep==2 &&(
                                                        <>
                                                            <div className={"alert alert-info mt-3"}>{displayItem.name+"'s shared availability info"}</div>

                                                            <table className={"table table-striped mt-2"}>
                                                                <thead>

                                                                    <tr>
                                                                        <th><i className={"bx bx-server"}> </i> Capacity</th>
                                                                        <th><i className={"bx bx-list-minus"}> </i> Ongoing</th>
                                                                        <th><i className={"bx bx-loader"}> </i> Pending</th>
                                                                    </tr>

                                                                </thead>

                                                                <tbody>

                                                                    <tr>
                                                                        <td>{displayItem.availability.capacity} Patients</td>
                                                                        <td>{displayItem.availability.ongoing} Patients</td>
                                                                        <td>{displayItem.availability.pending} Patients</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3}>{displayItem.name} has indicated they are <h6>Available</h6></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )
                                                }

                                                {
                                                    subStep==3 && (
                                                        <>
                                                            <div className={"alert alert-info mt-3"}>{displayItem.name+"'s shared location info"}</div>

                                                            <table className={"table table-striped mt-2"}>
                                                                <thead>

                                                                <tr>
                                                                    <th><i className={"bx bx-server"}> </i> County</th>
                                                                    <th><i className={"bx bx-list-minus"}> </i> Town</th>
                                                                </tr>

                                                                </thead>

                                                                <tbody>

                                                                <tr>
                                                                    <td>{displayItem.location.county} County</td>
                                                                    <td>{displayItem.location.town} Town</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}>{displayItem.name} has indicated they are available in multiple locations</td>
                                                                </tr>

                                                                </tbody>
                                                            </table>

                                                        </>
                                                    )
                                                }

                                                {
                                                    subStep==4 && (
                                                        <>
                                                            <div className={"hover-info"}>
                                                                {displayItem.pricing.services.other_description!="" &&
                                                                    <div className={'text-center'}>
                                                                        <img className='avatar mt-2 text-center'
                                                                             src={`${process.env.PUBLIC_URL}/assets/images/info.png`}/>
                                                                        <p className='mt-2'>Partner Says 'Other'
                                                                            means: {displayItem.pricing.services.other_description}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <table className={"table table-striped mt-2"}>
                                                                <thead>

                                                                <tr>
                                                                    <th> </th>
                                                                    <th><i className={"bx bx-server"}> </i> Service</th>
                                                                    <th><i className={"bx bx-list-minus"}> </i> Base Charge</th>
                                                                    <th><i className={"bx bx-timer"}> </i> Hourly Charge</th>
                                                                    <th><i className={"bx bx-info-circle"}> </i> Other Charge</th>
                                                                </tr>

                                                                </thead>

                                                                <tbody>

                                                                <tr>
                                                                    {Object.keys(displayItem.pricing.services).length===0 && (
                                                                        <td colSpan={4}>This partner has not listed any services</td>
                                                                    )}
                                                                    {Object.keys(displayItem.pricing.services).length > 0 && (
                                                                        <>
                                                                            {Object.values(displayItem.pricing.services).map((item:any)=>(
                                                                                <>
                                                                                    <td>
                                                                                        <input
                                                                                            type={"checkbox"}
                                                                                            onChange={(e)=>{
                                                                                                ChooseItems(e, item)
                                                                                                console.log(selectedService)
                                                                                            }}
                                                                                            checked = {selectedService.includes(item)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>{item.name}</td>
                                                                                    <td>{item.base} Kes</td>
                                                                                    <td>{item.hourly} Kes</td>
                                                                                    <td>{item.other} Kes</td>
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={3}></td>
                                                                </tr>

                                                                </tbody>
                                                            </table>

                                                        </>

                                                    )
                                                }

                                                {subStep===5 &&(
                                                    <>
                                                        <div className={"alert alert-info mt-3"}>{displayItem.name+"'s activity you're involved in"}</div>

                                                        <table className={"table table-striped mt-2"}>
                                                            <thead>

                                                            <tr>
                                                                <th><i className={"bx bx-server"}> </i> Activity</th>
                                                                <th><i className={"bx bx-list-minus"}> </i> Patient</th>
                                                                <th><i className={"bx bx-calendar-x"}> </i> Start</th>
                                                                <th><i className={"bx bx-calendar-check"}> </i> End</th>
                                                            </tr>

                                                            </thead>

                                                            <tbody>

                                                            <tr>
                                                                <td colSpan={4}></td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={4}>{displayItem.name} has indicated they are available for a collaboration with you</td>
                                                            </tr>

                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}

                                            </>
                                        )}
                                </div>

                                <div className={"card-footer"}>
                                    <div className={"row mt-4"}>
                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-warning"} onClick={()=>{
                                                setStep(1)
                                                setSubStep(1)
                                                setCateTitle("Basic Information")
                                            }}>Go Back</button>
                                        </div>
                                        <div className={"col"}>
                                            <button style={{width: "100%"}} className={"btn btn-secondary"} onClick={()=>{
                                                setStep(3)
                                                setSubStep(1)
                                            }}>Engage</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            )}

            {step===3 && (
                <section>

                    <div className={"card"}>

                        <div className={"card-header"}>

                            <h6>Engage: {displayItem.title} {displayItem.name} </h6>
                            <h6> </h6>

                        </div>

                        <div className={"card-body"}>

                            <div className={"row mt-1"}>
                                <div className={"col"}>

                                    <div className={"container-fluid"}>

                                        <p className={"alert alert-info"}>

                                            <span style={{fontWeight: "bold"}}>{displayItem.title} {displayItem.name}</span>'s rates: Minimum - Khs. {displayItem['pricing']["services"][0]["base"]}/=, Hourly - Ksh. {displayItem['pricing']["services"][0]["hourly"]}/=, Other - Ksh. {displayItem['pricing']["services"][0]["base"]}/=

                                        </p>

                                        <label className={"mt-4"}> <i className={"bx bx-user"}> </i> Patient's Medical Record ID</label>

                                        <div className={"row mt-2"}>
                                            <div className={"col-md-8"}>
                                                <input type={"text"} className={"form-control"} value={record} onChange={(e)=>{
                                                    setRecord(e.currentTarget.value)
                                                }}/>
                                            </div>

                                            <div className={"col-md-4"}>
                                                <button style={{width: "100%"}} className={"btn btn-outline-primary"}>{btnText}</button>
                                            </div>
                                        </div>

                                        <label className={"mt-3"}><i className={"bx bx-file"}> </i>Priority</label>
                                        <select className={"form-control"}  onChange={(e)=>{
                                            setPriority(e.currentTarget.value)
                                        }}>
                                            <option value={"none"}> Choose One </option>
                                            <option value={"low"}>Low</option>
                                            <option value={"normal"}>Normal</option>
                                            <option value={"high"}>High</option>

                                        </select>

                                        <label className={"mt-3"}><i className={"bx bx-chip"}> </i> Description</label>


                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={request}
                                            onChange={(d, e)=>{
                                                setRequest(e.getData())
                                            }}
                                        />

                                        <div className={"row mt-4 mb-3"}>
                                            <div className={"col"}>
                                                <button className={"btn btn-primary"} disabled={!isValidated} style={{width: "100%"}}><i className="bx bx-check"> </i>  Request {displayItem.title} {displayItem.name}  </button>
                                            </div>

                                            <div className={"col"}>
                                                <button className={"btn btn-warning"} style={{width: "100%"}} onClick={(e)=>{
                                                    setRecord("")
                                                    setPriority("low")
                                                    setisValidated(false)
                                                    setBtnText("Validate Record")
                                                    setRequest("Describe your request here")
                                                }}><i className="bx bx-cross"> </i>  Clear Fields</button>

                                            </div>

                                            <div className={"col"}>
                                                <button className={"btn btn-danger"} onClick={(e)=>{
                                                    setStep(1)
                                                    setSubStep(1)
                                                }} style={{width: "100%"}}><i className="bx bx-checkbox-minus"> </i> Cancel Request  </button>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </section>
            )}


        </>
    )
}

export default PartnerProcessor;