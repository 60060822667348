import React, {useEffect, useState} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";
import Canvas from "./dahboard_canvas";
import SearchInput from "./components/search";
import localforage from "localforage";
import axios from "axios";
import Swal from "sweetalert2";
import {useTable} from 'react-table'
import Datatable from "./components/datatable";
import {Link} from "react-router-dom";



const PatientRecords: React.FC = () => {
    const [searchValue, setSearchValue] = useState("");
    const [isWorking, setIsWorking] = useState<boolean>(false);
    const [isShowingResults, setIsShowingResults] = useState<number>( 1);
    const [step, setStep] = useState<number>(1)
    const [recordCount, setRecordCount] = useState<number>(0)
    const [records, setRecords] = useState<any[]>([])



    let single_data = [];

    useEffect(()=>{
        document.title = "Electronic Health Records - Uzima"
        const clinic_id = localforage.getItem("clinic_id");
    })

    const handleInputChange = (event: any) => {
        setSearchValue(event.target.value);
    }

    const handleSearch = async () =>{
        setIsWorking(true)
        try{

            const response = await axios.post("https://ckolaty.pythonanywhere.com/ehr/search", {
                clinic_id: await localforage.getItem("clinic_id"),
                staff_id: await localforage.getItem("staff_id"),
                admission_number: searchValue
            }).finally(()=>{
                setIsWorking(false)
            })

            const {status, message, data} = response.data

            if(status == "success"){

                setStep(2);

                const record_count = Object.keys(data).length;

                setRecordCount(record_count);

                if(record_count == 1){
                    setRecords(data)

                    setStep(2)

                }else if(record_count > 1){
                    setRecords(data)

                    setStep(2)
                }else{
                    setStep(2)
                }

            }else{
                Swal.fire({
                    icon: "warning",
                    title: "Fail",
                    text: message
                })
                return;
            }

        }catch (e) {
            setIsWorking(false);

            Swal.fire({
                icon: "error",
                title: "Disconnect",
                text: "Could not initiate communication with the server. Check your connection and try again."
            })

            return;
        }
    }

    const divStyle = {
        borderRadius: "3% "
    }
    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>
            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Electronic Health Records - (EHR)</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/institution/home"}>Clinic</a></li>
                                            <li className="breadcrumb-item active">List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {step==1 &&
                        (
                            <>
                            <div className={"row mt-4"}>
                                <div className={"col"}>
                                    <div className="search-container">
                                        <div className={"row"}>
                                            <div className={"col-md-9"}>

                                                <input
                                                    type={"text"}
                                                    className={"search-input form-control"}
                                                    placeholder={"Patient Admission Number"}
                                                    value={searchValue}
                                                    onChange={(e:any)=>{setSearchValue(e.currentTarget.value)}}
                                                />< br/>
                                            </div>
                                            <div className={"col-md-3"}>
                                                <button disabled={isWorking} className="btn btn-secondary"
                                                        onClick={handleSearch}>
                                                    <img className="avatar-xxs"
                                                         src={`${process.env.PUBLIC_URL}/assets/images/search.png`}
                                                         alt="Search"/> Search Records
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className={"row mt-4"}>
                                <div className={"col"}>
                                    <div className="card card-animate" style={divStyle}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/create.png`} alt=""
                                                     className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">New Record</h5>

                                            <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                  to={"/institution/ehr/new"}>Create</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col"}>
                                    <div className="card card-animate" style={divStyle}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/list.png`} alt=""
                                                     className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Load Records</h5>

                                            <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                  to={"/institution/ehr/list"}>Open</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col"}>
                                    <div className="card card-animate" style={divStyle}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/share.png`} alt=""
                                                     className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Share Record</h5>

                                            <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                  to={"/institution/ehr/share"}>Export</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col"}>
                                    <div className="card card-animate" style={divStyle}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/share_2.png`} alt=""
                                                     className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Shared EHRs</h5>

                                            <p><Link className="btn btn-outline-primary btn-rounded btn-lg mt-4"
                                                  to={"/institution/ehr/shared"}>Load</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)}
                        {step==2 &&(
                            <>
                                {recordCount==0 ? (
                                    <>
                                        <div className={"alert alert-info"}>
                                            There are no records related to the patient you requested.
                                        </div>
                                        <br />
                                        <button className={"btn btn-danger"} onClick={()=> {setStep(1)}}>Back To Search</button>
                                    </>
                                ):(
                                    <>
                                        <Datatable data={records} count={recordCount}></Datatable>
                                        <div className={"row mt-4"}>
                                            <div className={"col"}>
                                                <button className={"btn btn-primary"} onClick={()=>{setStep(1)}}>EHR Home</button>
                                            </div>
                                        </div>

                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <DashboardFooter></DashboardFooter>
            </div>
            <Canvas></Canvas>
        </>
    )
}

export default PatientRecords;
