import React, {useEffect, useReducer} from 'react'
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import {useBreadcrumbs} from "../../utils/utils";
import {Link} from "react-router-dom";
import EquipmentFind from "./EquipmentFind";


const UzimaEquipment: React.FC = () => {

    useEffect(()=>{
        document.title = "Uzima Healthcare Equipment"
    })

    const initialState = {
        step: 0,
        pageHeading: "Equipment Collaboration App",
        subHeading: "Our platform facilitates sharing of medical equipment easily"
    }

    const reducer = (state: any, action: any) => {
        switch(action.type){

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_HEADING":
                return {...state, pageHeading: action.payload}

            case "SUB_HEADING":
                return {...state, subHeading: action.payoad}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const childStepControl = (step: any) => {

        dispatch({type: "SET_STEP", payload: step})
        
    }

    return (
        <>
            <DashboardHeader />
            <SideMenu />

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        {useBreadcrumbs("Medical Equipment", "App", "Equipment", "Launch")}

                        <div className={"row mt-4"}>
                            <div className={"col text-center"}>
                                <h3>{state.pageHeading}</h3>

                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col text-center"}>
                                <h6>{state.subHeading}</h6>

                            </div>
                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col-md-3"}>
                                <div className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/equipment.png`} alt=""
                                                 className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Find Equipment</h5>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-3"}>
                                <div className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/add.png`} alt=""
                                                 className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">My Equipment</h5>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-3"}>
                                <div className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/requesty.png`} alt=""
                                                 className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Equipment Requests</h5>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-md-3"}>
                                <Link to={"/dashboard/home"} className={"card card-animate"}>
                                    <div className="card-body p-4 text-center">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/dashboard.png`} alt=""
                                                 className="img-fluid"/>
                                        </div>
                                        <h5 className="card-title mb-1">Dashboard Home</h5>
                                    </div>
                                </Link>
                            </div>

                        </div>



                        {state.step === 0 && (
                            <>

                            </>
                        )}

                        {state.step === 1 && (
                            <>
                                <EquipmentFind mainStep={childStepControl} />
                            </>
                        )}


                    </div>
                </div>

                <DashboardFooter />
            </div>

            <canvas />
        </>
    )
}

export default UzimaEquipment