import React, { useState, useEffect, useContext} from "react"
import {useBaseUrl} from "../../utils/utils";
import axios from "axios";
import Swal from "sweetalert2";

const CreateClinic: React.FC = () => {
    useEffect(()=>{

        document.title = "Create Institution & Clinic"

    },[])

    const base_url = useBaseUrl()

    const [stage, setStage] = useState(1)
    const [step, setStep] = useState(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [is2FA, setIs2FA] = useState<boolean>(false)
    const [institutionId, setInstitutionId] = useState()
    const [file, setFile] = useState<any>()
    const [email, setEmail] = useState<any>()
    const [institutionName, setInstitutionName] = useState<any>()
    const [postCode, setPostCode] = useState<any>()
    const [website, setWebsite] = useState<any>()
    const [password, setPassword] = useState<any>()
    const [town, setTown] = useState<any>()

    const step1 = async (e: any) => {

        e.preventDefault()

        setIsLoading(true)

        let url = base_url + "/facilities/create-institution"

        try{

            const formData = new FormData
            formData.append("logo", file)
            formData.append("email", email)
            formData.append("institution_name", institutionName)
            formData.append("postcode", postCode)
            formData.append("website", website)
            formData.append("password", password)
            formData.append("town", town)

            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).finally(()=>{
                setIsLoading(false)
            });

            const {status, message, data} = response.data

            if(status=="success"){

                setInstitutionId(data.institution)

                setEmail(null)
                setPassword(null)
                setFile(null)
                setTown(null)

                await Swal.fire({
                    icon: "success",
                    title: "Registered",
                    text: "Your account has been created. Now we verify the email provided before proceeding."
                })

                setStep(2)

            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })

            }

        }catch (e) {

            setIsLoading(false)

            await Swal.fire({
                icon: "error",
                title: "Fail",
                text: "A connection error occurred"
            })

        }

    }

    const step2 = async () => {
        setIsLoading(true)

        let url = base_url + "/facilities/verify-institution"



        try{

            //const response

        }catch (e) {
            setIsLoading(false)

            await Swal.fire({
                icon: "warning",
                title: "Disconnect",
                text: "Unable to initiate server connection"
            })

            return

        }
    }

    return (
        <></>
    )
}

export default CreateClinic