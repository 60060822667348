import React, {useEffect} from "react";
import DashboardFooter from "../layouts/dashboard_footer";
import Swal from "sweetalert2";
import axios from "axios";
import localForage from "localforage";
import {useBaseUrl} from "../../utils/utils";


const LoginPatient: React.FC = () => {

    const base_url = useBaseUrl()

    useEffect(()=> {
        document.title = "Project Uzima - Patient"

    }, []);

    const divStyle = {
        borderRadius: "3% "
    }

    const handleSubmit = async (event: any) => {
        let url = `${base_url}/admin/login`;
        event.preventDefault();

        try{
            const formData = new FormData(event.target);
            const response = await axios.post(url, formData);

            console.log(formData);

            if(response.data.status == 'success'){

                await Swal.fire({
                    icon: "success",
                    title: "Response",
                    text: "You will be redirected in a moment"
                })

                try{

                    await localForage.setItem("patient_id", response.data.id);
                    await localForage.setItem("patient_token", response.data.token);

                }catch (e){

                    await Swal.fire({
                        icon: "error",
                        title: "Response",
                        text: "please retry. Login failed."
                    })

                }

                event.target.reset();

                window.location.href="/patient/home";

            }else{
                await Swal.fire({
                    icon: "error",
                    title: "Response",
                    text: response.data.message
                })
            }
        } catch (error) {
            await Swal.fire({
                icon: "warning",
                title: "Response",
                text: "You are offline. Check your internet connection and try again"
            })
        }
    }

    return(
        <>

            <div className={"container-fluid"}>

                <div className={"row"}>

                    <div className={"col-xxl-4 col-xl-4 col-lg-4 col-md-2"}> </div>

                    <div className={"col-xxl-4 col-xl-4 col-lg-4 col-md-8"}>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4 text-center"}> <h3>Get Started - Patients</h3> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>

                        <div className={"row mt-4"}> </div>



                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <div className="card card-animate" style={divStyle}>
                                    <div className="card-body p-4 ">
                                        <div className="mx-auto avatar-md mb-3">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/start_login.png`} alt="" className="img-fluid" />
                                        </div>
                                        <h5 className="card-title mb-1 text-center">Patient Login</h5>


                                        <form method={"POST"}>
                                            <h6 className={"form-label mt-4"}>  Email</h6>
                                            <input type={"email"} name={"email"} required={true} className={"form-control"}/>

                                            <h6 className={"form-label mt-4"}>  Password</h6>
                                            <input type={"password"} name={"password"} required={true} className={"form-control"}/>

                                            <p className={"text-center"}><button className="btn btn-outline-primary btn-rounded mt-4" onClick={handleSubmit}>Login</button></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={"col-xxl-4 col-xl-4 col-lg-4 col-md-2"}> </div>

                </div>

            </div>

            <DashboardFooter></DashboardFooter>


        </>
    )
}

export default LoginPatient;