import React from 'react'

const InvoiceSummary: React.FC = () => {

    return (
        <>
            <div className={"card mt-3"}>
                <div className={"card-header"} style={
                    {backgroundColor: "#F9FBFC", border: "None"}
                }>
                    <h6 className={"text-center"}> Invoice Summary</h6>
                </div>
                <div className={"card-body"}>
                    <div className="row">
                        <div className="col-xl-3 col-md-6">

                            <div className="card card-animate">

                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-medium text-muted mb-0">
                                                Sent</p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <h5 className="text-success fs-14 mb-0">
                                                <i className="ri-arrow-right-up-line fs-13 align-middle"></i> +89.24
                                                %
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">Ksh. <span
                                                className="counter-value" data-target="559.25">559</span>k
                                            </h4>
                                            <span className="badge bg-warning me-1">2,258</span> <span
                                            className="text-muted"> sent</span>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-light rounded fs-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-file-text text-success icon-dual-success"><path
                                                    d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline
                                                    points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8"
                                                                                             y2="13"></line><line
                                                    x1="16" y1="17" x2="8" y2="17"></line><polyline
                                                    points="10 9 9 9 8 9"></polyline></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="col-xl-3 col-md-6">

                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-medium text-muted mb-0">Paid
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <h5 className="text-danger fs-14 mb-0">
                                                <i className="ri-arrow-right-down-line fs-13 align-middle"></i> +8.09
                                                %
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">Ksh. <span
                                                className="counter-value" data-target="409.66">409</span>k
                                            </h4>
                                            <span className="badge bg-warning me-1">1,958</span> <span
                                            className="text-muted">Paid </span>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-light rounded fs-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-check-square text-success icon-dual-success"><polyline
                                                    points="9 11 12 14 22 4"></polyline><path
                                                    d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="col-xl-3 col-md-6">

                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-medium text-muted mb-0">Unpaid
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <h5 className="text-danger fs-14 mb-0">
                                                <i className="ri-arrow-right-down-line fs-13 align-middle"></i> +9.01
                                                %
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">Ksh. <span
                                                className="counter-value" data-target="136.98">136</span>k
                                            </h4>
                                            <span className="badge bg-warning me-1">338</span> <span
                                            className="text-muted">Unpaid </span>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-light rounded fs-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-clock text-success icon-dual-success"><circle
                                                    cx="12" cy="12" r="10"></circle><polyline
                                                    points="12 6 12 12 16 14"></polyline></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="col-xl-3 col-md-6">

                            <div className="card card-animate">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-medium text-muted mb-0">Cancelled
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <h5 className="text-success fs-14 mb-0">
                                                <i className="ri-arrow-right-up-line fs-13 align-middle"></i> +7.55
                                                %
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">Ksh. <span
                                                className="counter-value" data-target="84.20">84</span>k
                                            </h4>
                                            <span className="badge bg-warning me-1">502</span> <span
                                            className="text-muted">Cancelled </span>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-light rounded fs-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-x-octagon text-success icon-dual-success"><polygon
                                                    points={"7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"}> </polygon><line
                                                    x1={"15"} y1={"9"} x2="9" y2="15"> </line> <line x1="9" y1="9" x2="15"
                                                                                                     y2="15"> </line> </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default InvoiceSummary