import React from "react"
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import DashboardFooter from "../../layouts/dashboard_footer";

const PatientHistory: React.FC = () => {

    return (
        <>
            <DashboardHeader />
            <SideMenu />

            <div className={"main-content"}>

                <div className={"page-content"}>

                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">History Timeline</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a href="javascript: void(0);">Patients</a>
                                            </li>
                                            <li className="breadcrumb-item active">Patient History</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div>
                                    <h5>Center Timeline</h5>
                                    <div className="timeline">
                                        <div className="timeline-item left">
                                            <i className="icon ri-stack-line"> </i>
                                            <div className="date">15 Dec 2023</div>
                                            <div className="content">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt=""
                                                             className="avatar-sm rounded" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="fs-15">Final Follow Up
                                                        </h5>
                                                        <p className="text-muted mb-2">Presctiption: None</p>
                                                        <p className="text-muted mb-2">Lab Test: Perfect</p>
                                                        <p className="text-muted mb-2">Assessment: Balanced</p>
                                                        <p className="text-muted mb-2">Physical: Balanced</p>
                                                        <p className="text-muted mb-2">Psychology: Perfect</p>

                                                        <div className="hstack gap-2">
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🔥</span> 19</a>
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🤩</span> 22</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="timeline-item right">
                                            <i className="icon ri-vip-diamond-line"> </i>
                                            <div className="date">22 Oct 2023</div>
                                            <div className="content">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt=""
                                                             className="avatar-sm rounded" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="fs-15">Final Follow Up
                                                        </h5>
                                                        <p className="text-muted mb-2">Presctiption: None</p>
                                                        <p className="text-muted mb-2">Lab Test: Perfect</p>
                                                        <p className="text-muted mb-2">Assessment: Balanced</p>
                                                        <p className="text-muted mb-2">Physical: Balanced</p>
                                                        <p className="text-muted mb-2">Psychology: Perfect</p>

                                                        <div className="hstack gap-2">
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🔥</span> 19</a>
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🤩</span> 22</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="timeline-item left">
                                            <i className="icon ri-gift-line"></i>
                                            <div className="date">10 Jul 2023</div>
                                            <div className="content">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt=""
                                                             className="avatar-sm rounded" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="fs-15">Final Follow Up
                                                        </h5>
                                                        <p className="text-muted mb-2">Presctiption: None</p>
                                                        <p className="text-muted mb-2">Lab Test: Perfect</p>
                                                        <p className="text-muted mb-2">Assessment: Balanced</p>
                                                        <p className="text-muted mb-2">Physical: Balanced</p>
                                                        <p className="text-muted mb-2">Psychology: Perfect</p>

                                                        <div className="hstack gap-2">
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🔥</span> 19</a>
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🤩</span> 22</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="timeline-item right">
                                            <i className="icon ri-shield-star-line"></i>
                                            <div className="date">18 May 2023</div>
                                            <div className="content">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt=""
                                                             className="avatar-sm rounded" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="fs-15">Final Follow Up
                                                        </h5>
                                                        <p className="text-muted mb-2">Presctiption: None</p>
                                                        <p className="text-muted mb-2">Lab Test: Perfect</p>
                                                        <p className="text-muted mb-2">Assessment: Balanced</p>
                                                        <p className="text-muted mb-2">Physical: Balanced</p>
                                                        <p className="text-muted mb-2">Psychology: Perfect</p>

                                                        <div className="hstack gap-2">
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🔥</span> 19</a>
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🤩</span> 22</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="timeline-item left">
                                            <i className="icon ri-user-smile-line"></i>
                                            <div className="date">10 Feb 2023</div>
                                            <div className="content">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt=""
                                                             className="avatar-sm rounded" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="fs-15">Final Follow Up
                                                        </h5>
                                                        <p className="text-muted mb-2">Presctiption: None</p>
                                                        <p className="text-muted mb-2">Lab Test: Perfect</p>
                                                        <p className="text-muted mb-2">Assessment: Balanced</p>
                                                        <p className="text-muted mb-2">Physical: Balanced</p>
                                                        <p className="text-muted mb-2">Psychology: Perfect</p>

                                                        <div className="hstack gap-2">
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🔥</span> 19</a>
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🤩</span> 22</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="timeline-item right">
                                            <i className="icon ri-fire-line"> </i>
                                            <div className="date">01 Jan 2023</div>
                                            <div className="content">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt=""
                                                             className="avatar-sm rounded" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h5 className="fs-15">Final Follow Up
                                                        </h5>
                                                        <p className="text-muted mb-2">Presctiption: None</p>
                                                        <p className="text-muted mb-2">Lab Test: Perfect</p>
                                                        <p className="text-muted mb-2">Assessment: Balanced</p>
                                                        <p className="text-muted mb-2">Physical: Balanced</p>
                                                        <p className="text-muted mb-2">Psychology: Perfect</p>

                                                        <div className="hstack gap-2">
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🔥</span> 19</a>
                                                            <a className="btn btn-sm btn-light"><span
                                                                className="me-1">🤩</span> 22</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <div>
                                    <h5>Horizontal Timeline</h5>
                                    <div className="horizontal-timeline my-3">
                                        <div
                                            className="swiper timelineSlider swiper-initialized swiper-horizontal swiper-backface-hidden">
                                            <div className="swiper-wrapper" id="swiper-wrapper-9af4a2e5824bb9dc"
                                                 aria-live="off"
                                                 style={{transitionDuration: "0ms", transform: "translate3d(0px, 0px, 0px)"}}>
                                                <div className="swiper-slide swiper-slide-active"
                                                     style={{width: "201.2px", marginRight: "50px"}} role="group"
                                                     aria-label="1 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted fw-medium mb-0">December,
                                                                    2023</p>
                                                                <h6 className="mb-0">Plateform Development</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">10 : 35 PM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="swiper-slide swiper-slide-next"
                                                     style={{width: "201.2px", marginRight: "50px"}} role="group"
                                                     aria-label="2 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted mb-1">January, 2021</p>
                                                                <h6 className="mb-0">Release Bank &amp; Cards Phase</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">8 : 10 AM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="swiper-slide"
                                                     style={{width: "201.2px", marginRight: "50px"}} role="group"
                                                     aria-label="3 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted mb-1">March, 2021</p>
                                                                <h6 className="mb-0">Beta Launch of Plateform</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">01 : 47 AM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="swiper-slide"
                                                     style={{width: "201.2px", marginRight: "50px"}} role="group"
                                                     aria-label="4 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted mb-1">April, 2023</p>
                                                                <h6 className="mb-0">First Crypto Bank Transfers</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">07 : 53 AM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="swiper-slide"
                                                     style={{width: "201.2px", marginRight: "50px;"}} role="group"
                                                     aria-label="5 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted mb-1">May, 2021</p>
                                                                <h6 className="mb-0">Launch Ethbay Services</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">4 : 05 AM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="swiper-slide"
                                                     style={{width: "201.2px", marginRight: "50px"}} role="group"
                                                     aria-label="6 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted mb-1">June, 2021</p>
                                                                <h6 className="mb-0">Fastest Crypto Transaction</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">02 : 40 AM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="swiper-slide"
                                                     style={{width: "201.2px", marginRight: "50px"}} role="group"
                                                     aria-label="7 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted mb-1">July, 2021</p>
                                                                <h6 className="mb-0">Crypto Blockchain Release</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">9 : 27 AM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="swiper-slide"
                                                     style={{width: "201.2px", marginRight: "50px"}} role="group"
                                                     aria-label="8 / 8">
                                                    <div className="card pt-2 border-0 item-box text-center">
                                                        <div className="timeline-content p-3 rounded">
                                                            <div>
                                                                <p className="text-muted mb-1">August, 2021</p>
                                                                <h6 className="mb-0">Launch Ethereum Classifieds</h6>
                                                            </div>
                                                        </div>
                                                        <div className="time"><span className="badge bg-success">06 : 19 AM</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="swiper-button-next" tabIndex={0} role="button"
                                                 aria-label="Next slide" aria-controls="swiper-wrapper-9af4a2e5824bb9dc"
                                                 aria-disabled="false"> </div>
                                            <div className="swiper-button-prev swiper-button-disabled" tabIndex={-1}
                                                 role="button" aria-label="Previous slide"
                                                 aria-controls="swiper-wrapper-9af4a2e5824bb9dc"
                                                 aria-disabled="true"> </div>
                                            <span className="swiper-notification" aria-live="assertive"
                                                  aria-atomic="true"> </span> </div>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>

                </div>

                <DashboardFooter />
            </div>

            <canvas />

        </>
    )
}

export default PatientHistory