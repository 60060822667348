import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {useAppName, useBaseUrl, useCounties, usePostData} from "../../utils/utils";
import Swal from "sweetalert2";

const NewRegister: React.FC = () =>{


    useEffect(()=>{
        document.title = "Registration form"
        GetCounties()
        GetCategories()

    }, [])


    const [step, setStep] = useState(0)
    const [subStep, setSubStep] = useState(0)

    const [fName, setFName] = useState<any>("First")
    const [lName, setLName] = useState<any>("Last")
    const [email, setEmail] = useState<any>("user@email.com")
    const [counties, setCounties] = useState<any>([])
    const [categories, setCategories] = useState<any>([])

    const [selectedCounty, setSelectedCounty] = useState<any>()
    const [selectedTown, setSelectedTown] = useState<any>()
    const [institution, setInstitution] = useState<any>()
    const [institutionEmail, setInstitutionEmail] = useState<any>()
    const [towns, setTowns] = useState<any>([])
    const [tenb, setTenb] = useState<boolean>(false)
    const [facility, setFacility] = useState<string>("")
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const [firstName, setFirstName] = useState<any>()
    const [lastName, setLastName] = useState<any>()
    const [password, setPassword] = useState<any>("")
    const [passEnable, setPassEnable] = useState<any>()
    const [cPass, setCPass] = useState<any>("")
    const [lastTest, setLastTest] = useState("a user")

    const base_url = useBaseUrl()

    const app_name = useAppName()


    const GetCounties = async () => {

        const url = `${useBaseUrl()}/facilities/get-counties`

        const formaData = new FormData()

        formaData.append("selector", "9999")

        const resp = await usePostData(url, formaData)

        if (resp !== undefined){

            setCounties(resp)

        }else{
            setCounties([])
            console.log("failed")
        }
    }

    const GetTowns = async () => {

        let url = `${base_url}/facilities/get-post-towns`

        const formData = new FormData()

        formData.append("county_id", selectedCounty)

        const resp = await usePostData(url, formData)

        if(resp !== undefined){

            setTowns(resp)

        }else{
            await Swal.fire({
                icon: "question",
                title: "Fetch cancelled",
                text: "Please refresh the towns manually."
            })
        }

    }

    const GetCategories = async () => {

        let url = `${base_url}/facilities/categories`

        const formData = new FormData()

        const resp = await usePostData(url, formData)

        if (resp !== undefined){
            setCategories(resp)
        }else{
            await Swal.fire({
                icon: "question",
                title: "Invalid",
                text: "Server response was rejected please try again"
            })
        }
    }



    const RegisterUser = async () => {

        let url = `${base_url}/facilities/newreg`

        const formData = new FormData()
        formData.append("county", selectedCounty)
        formData.append("town", selectedTown)
        formData.append("institution", institution)
        formData.append("facility", facility)
        formData.append("category", selectedCategory)
        formData.append("fname", firstName)
        formData.append("lname", lastName)
        formData.append("password", password)
        formData.append("email", email)
        formData.append("institution_email", institutionEmail)

        const resp = await usePostData(url, formData)

        if (resp !== undefined){

            if (resp === "created"){

                await Swal.fire({
                    icon: "success",
                    title: "Registered",
                    text: "You've successfully created an Uzima Account! We may ask for more information as you continue to use our system"
                })

                setSubStep(4)

            }

        }else {

            await Swal.fire({
                icon: "info",
                title: "Unusual response",
                text: "Your request was processed successfully but the server gave an unusual response. Please retry or proceed to the next step."
            })

        }

    }

    useEffect(()=>{
        if (parseInt(selectedCounty) !== 999 && selectedCounty !== undefined) {
            GetTowns();
            setTenb(true);
        } else {
            setTenb(false);
        }
    }, [selectedCounty])

    return (

        <>
            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4 text-center"}> <h3>Get Started - Registration</h3> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}> </div>

            <div className={"row mt-4"}>
                <div className={"col-md-3"}>

                </div>

                <div className={"col-md-6"}>

                    <div className={"container-fluid"}>

                        <div className={"card"} style={{fontSize: "14px"}}>

                            <div className={"card-header"} style={{backgroundColor: "#29b0ea", fontWeight: "Bold" ,color: "white",  border: "None"}}>
                                {step===0 && (
                                    <>
                                        Getting Ready!
                                    </>
                                )}

                                {step===2 && (
                                    <section>

                                        Patient Registration
                                    </section>
                                )}

                                {step===3 && (
                                    <section>
                                        {subStep === 0 &&(
                                            <>
                                                Partner Registration - Before we begin!
                                            </>
                                        )}

                                        {subStep === 1 &&(
                                            <>
                                                Partner Registration - Institution Name & Location
                                            </>
                                        )}

                                        {subStep === 2 && (
                                            <>
                                                Add Facility to {institution}
                                            </>
                                        )}

                                        {subStep === 3 && (
                                            <>
                                                Almost done! Add {lastTest} to {facility}
                                            </>
                                        )}
                                    </section>
                                )}

                            </div>

                            <div className={"card-body"}>
                                {step===0 && (
                                    <>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/patient.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Patient</h5>

                                                        <p><button onClick={()=>{
                                                            setStep(2)
                                                        }} className="btn btn-rounded btn-outline-primary btn-rounded btn-lg mt-4"
                                                                 >I'm a Patient</button></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/partners.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Partner</h5>

                                                        <p><button onClick={()=>{
                                                            setStep(3)
                                                        }} className="btn btn-rounded btn-outline-primary btn-rounded btn-lg mt-4"
                                                        >I'm a Partner</button></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"col"}>
                                                <div className="card card-animate" style={{borderRadius: "3%"}}>
                                                    <div className="card-body p-4 text-center">
                                                        <div className="mx-auto avatar-md mb-3">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/back.png`} alt="" className="img-fluid" />
                                                        </div>
                                                        <h5 className="card-title mb-1">Home Page</h5>

                                                        <p><Link to={"/search"} className="btn btn-rounded btn-outline-primary btn-rounded btn-lg mt-4"
                                                        >Load Home Page</Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {step===2 && (
                                    <>
                                        {subStep===0 && (
                                            <>
                                                <div className={"row mt-2"}>
                                                    <div className={"col"}>

                                                        <h5>Hello there! Let's start with your name(s):</h5>

                                                        <label className={"mt-2"}>First Name</label>
                                                        <input value={fName} className={"form-control"} onChange={(e)=>{setFName(e.currentTarget.value)}}/>

                                                        <label className={"mt-2"}>Last Name</label>
                                                        <input value={lName} className={"form-control"} onChange={(e)=>{setLName(e.currentTarget.value)}}/>

                                                    </div>
                                                </div>

                                            </>
                                        )}

                                        {subStep===1 && (
                                            <>
                                                <div className={"row"}>
                                                    <div className={"col"}>

                                                        <h5>Hi {fName}! Just 2 easy steps to go!</h5>

                                                        <div className={"row"}>
                                                            <div className={"col"}>

                                                            </div>

                                                            <div className={"col"}>

                                                            </div>
                                                        </div>

                                                        <label className={"mt-2"}><i className={"bx bx-at"}> </i> Email Address</label>
                                                        <input className={"form-control"} type={"email"} onChange={(e)=>{setEmail(e.currentTarget.value)}}/>

                                                        <label className={"mt-2"}><i className={"bx bx-key"}> </i> Password</label>
                                                        <input className={"form-control"} type={"password"} onChange={(e)=>{setPassword(e.currentTarget.value)}}/>

                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {subStep===2 &&(
                                            <>
                                                <div className={"row"}>
                                                    <div className={"col"}>

                                                        <h5>Hi {fName}! Just 1 more easy step to go!</h5>

                                                        <p>We use machine learning to help improve your experience on this app. You can choose some categories to help us kickstart the experience</p>

                                                        <div className={"row"}>
                                                            <div className={"col"}>
                                                                <label>Doctors</label> &nbsp;
                                                                <input type={"checkbox"}/> <br />

                                                                <label>Clinics</label> &nbsp;
                                                                <input type={"checkbox"}/><br />

                                                                <label>Hospitals</label> &nbsp;
                                                                <input type={"checkbox"}/> <br />

                                                            </div>

                                                            <div className={"col"}>
                                                                <label>Pharmacies</label> &nbsp;
                                                                <input type={"checkbox"}/>  <br />

                                                                <label>Laboratories</label> &nbsp;
                                                                <input type={"checkbox"}/> <br />

                                                                <label>Ambulances</label> &nbsp;
                                                                <input type={"checkbox"}/> <br />

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </>
                                        )}

                                        {subStep===3 && (
                                            <>
                                                <div className={"row"}>
                                                    <div className={"col"}>

                                                        <h5>Hi {fName}! Final Step!</h5>

                                                        {fName} {lName} <br />

                                                        {email} <br />

                                                        <p>This is just basic data to get you started. You may be required to submit more info once logged in</p>

                                                        <p>By Proceeding you agree to our terms of use and privacy policy.</p>
                                                    </div>

                                                </div>

                                            </>
                                        )}

                                    </>
                                )}

                                {step === 3 &&(

                                    <>
                                        {subStep === 0 && (
                                            <>
                                                <div className={"row mt-2"}>
                                                    <div className={"col"}>

                                                        <p>
                                                            <em>Welcome to {app_name}! The registration process is quite easy.</em>
                                                            <ul className={"mt-2"}>
                                                                <li><em>We'll just ask a few basic questions to create your account then later you can update any other info in the event it is requested.</em></li>
                                                            </ul>
                                                        </p>

                                                        <div className={"row mt-3 text-center"}>

                                                            <div className={"col"}>
                                                                <button onClick={(e)=>{setSubStep(1)}} style={{width: "80%"}} className={"btn btn-outline-success btn-rounded"}>

                                                                    Alright! I'm Ready!

                                                                </button>
                                                            </div>

                                                            <div className={"col"}>
                                                                <button style={{width: "80%"}} onClick={(e)=>{setStep(0); setSubStep(0)}} className={"btn btn-outline-danger btn-rounded"}>
                                                                    Maybe Later!
                                                                </button>
                                                            </div>


                                                        </div>



                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {subStep === 1 && (
                                            <div className={"row mt-2"}>
                                                <div className={"col"}>

                                                    <p><em>Hello there! To get started let's create your Institution. Your Institution will nest all your facilities whether one or multiple.</em></p>

                                                    <p><em>If you do not have multiple facilities it will still allow you to add in future!</em></p>

                                                    <label style={{fontWeight: "Bold"}}> <i className={"bx bx-clinic"}> </i> - Institution Name</label>
                                                    <input className={"form-control mt-1"} value={institution} onChange={(e)=>{setInstitution(e.currentTarget.value)}}/>

                                                    <label style={{fontWeight: "Bold"}}> <i className={"bx bx-location-plus mt-4"}> </i> - Institution County &nbsp; <span> <button className={"btn btn-outline-primary"} onClick={(e)=>{GetCounties()}}> <i className="bx bx-refresh"> </i> Reload</button> </span></label>
                                                    <select className={"form-control"} onChange={(e)=>{
                                                        let townSel = e.currentTarget.value

                                                        setSelectedCounty(townSel)
                                                    }}>
                                                        <option value={999}>Choose County</option>

                                                        {Object.values(counties).map((cty: any)=>(
                                                            <option value={cty.county_code}>{cty.county}</option>
                                                        ))}

                                                    </select>

                                                    {tenb && (
                                                        <>
                                                            <label style={{fontWeight: "Bold"}}> <i className={"bx bx-location-plus mt-4"}> </i> - Institution Town &nbsp; <span> <button className={"btn btn-outline-primary"} onClick={(e)=>{GetTowns()}}> <i className="bx bx-refresh"> </i> Reload</button> </span></label>
                                                            <select className={"form-control mt-2"} onChange={(e)=>{
                                                                setSelectedTown(parseInt(e.currentTarget.value))
                                                            }}>
                                                                <option value={9999}>Choose Town</option>

                                                                {Object.values(towns).map((cty: any)=>(
                                                                    <option value={cty.town_id}>{cty.town}</option>
                                                                ))}

                                                            </select>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {subStep === 2 && (
                                            <span style={{fontSize: "14px"}}>
                                                <em >
                                                    Now let's add a facility to your institution: <span style={{fontWeight: "Bold"}}>{institution} </span>
                                                </em>

                                                <div className={"row mt-3"}>
                                                    <div className={"col"}>

                                                        <label className={"mt-2"}>Facility Name:</label>
                                                        <input className={"form-control mt-1" } value={facility} onChange={(e)=>{
                                                            setFacility(e.currentTarget.value)
                                                        }} />

                                                        <label className={"mt-2"}>Facility Category:</label>

                                                        <select className={"form-control mt-1"} onChange={(e)=>{
                                                            setSelectedCategory(e.currentTarget.value)
                                                        }}>
                                                            <option value={99999}>Choose One</option>
                                                            {Object.values(categories).map((cat: any)=>(
                                                                <option value={cat.id}> {cat.name}</option>
                                                            ))}

                                                        </select>

                                                    </div>
                                                </div>
                                            </span>
                                        )}

                                        {subStep === 3 && (
                                            <span style={{fontSize: "14px"}}>
                                                <em>
                                                    Add a user, this person will be responsible for logging ino the facility dashboard and conducting all activity.
                                                </em>

                                                <div className={"row"}>
                                                    <div className={"col"}>

                                                        <label className={"mt-2"}>First Name:</label>

                                                        <input className={"form-control"} value={firstName} onChange={(e)=>{

                                                            setFirstName(e.currentTarget.value)
                                                            setLastTest(e.currentTarget.value)
                                                        }}/>


                                                    </div>

                                                    <div className={"col"}>

                                                        <label className={"mt-2"}>Last Name:</label>

                                                        <input className={"form-control"} value={lastName} onChange={(e)=>{
                                                            setLastName(e.currentTarget.value)
                                                        }}/>

                                                    </div>

                                                </div>

                                                <div className={"row"}>
                                                    <div className={"col"}>

                                                        <label style={{fontSize: "14px"}} className={"mt-2"}>Contact Email:</label>

                                                        <input type={"email"} value={email} className={"form-control"} onChange={(e)=>{
                                                            setEmail(e.currentTarget.value)
                                                        }} />



                                                    </div>
                                                </div>

                                                <div className={"row"}>
                                                    <div className={"col"}>

                                                        <label style={{fontSize: "14px"}} className={"mt-2"}>Password:</label>

                                                        <input type={"password"} className={"form-control"} onChange={(e)=>{
                                                            setPassword(e.currentTarget.value)
                                                        }} />



                                                    </div>
                                                </div>

                                                <div className={"row"}>
                                                    <div className={"col"}>

                                                        {password !== cPass && (
                                                            <span className={"text-danger"}>Passwords do not match!</span>
                                                        )}

                                                        {password !== "" && password === cPass && (
                                                            <span className={"text-success"}>Passwords match!</span>
                                                        )}

                                                        <label style={{fontSize: "14px"}} className={"mt-2"}>Confirm Password:</label>

                                                        <input type={"password"} className={"form-control"} onChange={(e)=>{
                                                            setCPass(e.currentTarget.value)
                                                        }} />



                                                    </div>
                                                </div>

                                            </span>
                                        )}

                                    </>

                                )}

                            </div>

                            <div className={"card-footer"}>

                                {step===2 &&(
                                    <>
                                        <div className={"row mt-2 mb-2"}>
                                            <div className={"col-md-3"}>
                                                <button className={"btn btn-primary"} onClick={()=>{
                                                    if(subStep <= 3){
                                                        setSubStep(subStep+1)
                                                    }
                                                }}>Next Step</button>
                                            </div>

                                            {subStep === 3 && (
                                                <div className={"col-md-3"}>
                                                    <button className={"btn btn-success"}>
                                                        Finish and Register
                                                    </button>
                                                </div>
                                            )}



                                            <div className={"col-md-3"}>
                                                <button className={"btn btn-warning"} onClick={()=>{
                                                    setStep(0)
                                                    setSubStep(0)
                                                }}>Go back</button>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {step === 3 &&(
                                    <>
                                        {subStep===0 && (
                                            <>

                                            </>
                                        )}

                                        {subStep===1 && (
                                            <>
                                                <div className={"row"}>
                                                    <div className={"col-md-4"}>
                                                        <button className={"btn btn-primary"} onClick={(e)=>{
                                                            setSubStep(2)
                                                        }} style={{width: "100%"}}>Next Step</button>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <button className={"btn btn-primary"} onClick={(e)=>{
                                                            setSelectedCounty(999)
                                                            setSelectedTown(9999)
                                                            setInstitution("")
                                                        }} style={{width: "100%"}}>Clear Fields</button>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <button className={"btn btn-primary"} onClick={(e)=>{
                                                            setSubStep(0)
                                                            setStep(0)
                                                        }} style={{width: "100%"}}>Cancel Process</button>
                                                    </div>
                                                </div>

                                            </>
                                        )}

                                        {subStep===2 && (
                                            <div className={"row"}>
                                                <div className={"col-md-4"}>
                                                    <button className={"btn btn-primary"} onClick={(e)=>{
                                                        setSubStep(3)
                                                    }} style={{width: "100%"}}>Next Step</button>
                                                </div>

                                                <div className={"col-md-4"}>
                                                    <button className={"btn btn-primary"} onClick={(e)=>{
                                                        setFacility("")
                                                        setSelectedCategory(null)
                                                    }} style={{width: "100%"}}>Clear Fields</button>
                                                </div>

                                                <div className={"col-md-4"}>
                                                    <button className={"btn btn-primary"} onClick={(e)=>{
                                                        setSubStep(0)
                                                        setStep(0)
                                                    }} style={{width: "100%"}}>Cancel Process</button>
                                                </div>
                                            </div>
                                        )}

                                        {subStep === 3 && (
                                            <>
                                                <div className={"row"}>
                                                    <div className={"col-md-4"}>
                                                        <button className={"btn btn-primary"} disabled={password !== "" && password !== cPass} onClick={(e)=>{
                                                            RegisterUser()
                                                        }} style={{width: "100%"}}>Register Now</button>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <button className={"btn btn-primary"} onClick={(e)=>{
                                                            setFirstName("")
                                                            setLastName("")
                                                            setPassword("")
                                                            setSelectedCategory(null)
                                                        }} style={{width: "100%"}}>Clear Fields</button>
                                                    </div>

                                                    <div className={"col-md-4"}>
                                                        <button className={"btn btn-primary"} onClick={(e)=>{
                                                            setSubStep(0)
                                                            setStep(0)
                                                        }} style={{width: "100%"}}>Cancel Process</button>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {subStep === 4 && (
                                            <>
                                                <div className={"row"}>
                                                    <div className={"col"}>
                                                        <Link to={"/institution/newlog"} className="card card-animate" style={{borderRadius: "3%"}}>
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/login.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Login</h5>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className={"col"}>
                                                        <Link to={"/institution/newreg"} className="card card-animate" style={{borderRadius: "3%"}}>
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/register.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Register Another</h5>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className={"col"}>
                                                        <Link to={"/"} className="card card-animate" style={{borderRadius: "3%"}}>
                                                            <div className="card-body p-4 text-center">
                                                                <div className="mx-auto avatar-md mb-3">
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/home.png`} alt="" className="img-fluid" />
                                                                </div>
                                                                <h5 className="card-title mb-1">Go Home</h5>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                            </div>

                        </div>

                    </div>

                </div>

                <div className={"col-md-3"}>

                </div>
            </div>


        </>
    )
}

export default NewRegister