import React, {useEffect, useState} from "react";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import Canvas from "../layouts/dahboard_canvas";

const CalFlat: React.FC = () => {
    useEffect(()=>{
        document.title = "Clinic Calendar"
    })
    const [selectedDate, setSelectedDate] = useState();

    const handleDateChange = (date: any) => {
        setSelectedDate(date[0]);
    }

    const dateCellStyles = {
        width: '40px', 
        height: '40px',
    };

    return(
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>
            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Events Calendar</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href={"/calendar"}>Calendar</a></li>
                                            <li className="breadcrumb-item active">Event List</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col"}>
                                <div className={"card card-animate"}>
                                    <div className={"card-body"}>
                                        <Flatpickr
                                            value={selectedDate}
                                            className={"form-control"}
                                            onChange={handleDateChange}
                                            options={{
                                                mode: 'multiple',
                                                inline: true,
                                                dateFormat: 'Y-m-d',
                                                enableTime: false,
                                                minDate: 'today',
                                                showMonths: 1,
                                                onChange: handleDateChange,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row mt-3"}>
                            <div className={"col"}>
                                <div className={"card"}>
                                    <div className={"card-body"}>
                                        <div className={"alert alert-info"}>
                                            There are no events at the moment. Add an event to a collaboration to see it here!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Canvas></Canvas>

        </>
    )
}

export default CalFlat