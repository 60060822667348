import React, {useEffect, useState} from "react";
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import Canvas from "../layouts/dahboard_canvas";
import set = Reflect.set;
import SearchClinic from "./search_clinic";
import Swal from "sweetalert2";
import axios from "axios";
import {useAppName, useBaseUrl} from "../../utils/utils";
import {Link} from "react-router-dom";
import Staff from "./Staff";

interface Service {
    service_title: string;
    service_subtitle: string;
    service_image: string;
}

const ClinicLauncher: React.FC = () =>{

    const app_name = useAppName()

    useEffect(()=>{
        document.title = `${app_name} - My Facility`;
    }, [])

    const [selected, setSelected] = useState("none")

    const base_url = useBaseUrl()

    const services: Service[] = [
        {
            service_title: 'Root Canal',
            service_subtitle: 'RC',
            service_image: 'img-1.png',
        },
        {
            service_title: 'Extraction',
            service_subtitle: 'EC',
            service_image: 'img-2.png',
        },
        {
            service_title: 'Service 3',
            service_subtitle: 'Subtitle 3',
            service_image: 'img-3.png',
        },
        {
            service_title: 'Service 4',
            service_subtitle: 'Subtitle 4',
            service_image: 'img-4.png',
        },
        {
            service_title: 'Service 5',
            service_subtitle: 'Subtitle 5',
            service_image: 'img-5.png',
        },
        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },
        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },
    ];

    const shuffleArray = <T extends unknown>(array: T[]): T[] => {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const shuffledServices = shuffleArray(services);

    const rows: JSX.Element[][] = [];
    let currentRow: JSX.Element[] = [];

    shuffledServices.forEach((service, index) => {
        const { service_title, service_subtitle, service_image } = service;

        const card = (
            <div className="col" key={index}>
                <div className="card card-animate">
                    <div className="card-body p-4 text-center">
                        <div className="mx-auto avatar-md mb-3">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/companies/${service_image}`}
                                alt=""
                                className="img-fluid rounded-circle"
                            />
                        </div>
                        <h5 className="card-title mb-1">{service_title}</h5>
                        <p className="text-muted mb-0">{service_subtitle}</p>
                    </div>
                </div>
            </div>
        );

        currentRow.push(card);

        if (currentRow.length === 5 || index === shuffledServices.length - 1) {
            rows.push(currentRow);
            currentRow = [];
        }
    });

    const [pageGuide, setPageGuide] = useState("My Facility")
    const [step, setStep] = useState(1)

    const Get_services = async () =>{

        const initialState = {
            step: 0,
            subStep: 0
        }

        try{

            const response = await axios.post(`${base_url}/facilities/get-service`)

            const {status, message, data} = response.data

            if(status=="success"){



            }else{

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                })

                return

            }

        }catch (e) {
            await Swal.fire({
                icon: "warning",
                title: "Fail",
                text: "Server connection failed to establish"
            })
        }
    }



    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Institution - {pageGuide}</h4>

                                    <div className={"page-title-right"}>
                                        <ol className={"breadcrumb m-0"}>
                                            <li className={"breadcrumb-item"}><a href={"/institution/clinics/launch"}>Clinics</a></li>
                                            <li className={"breadcrumb-item active"}>Launcher</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row mt-2"}>

                            <div className={"col text-center"}>

                                <h4>Facility Manager</h4>

                            </div>

                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col text-center"}>
                                <h6>An assortment of digital conferencing tools to enable remote communication and monitoring by users on this platform</h6>
                            </div>
                        </div>

                        {step==1 && (
                            <>
                                <div className={"row mt-4"}>

                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "3%"}} onClick={(e:any)=>{setStep(2)}}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/clinic_search.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">My Partners</h5>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col"}>
                                        <div className="card card-animate" style={{borderRadius: "3%"}} onClick={(e:any)=>{setStep(3)}}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/clinic_ambulance.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">My Services</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col"}>
                                        <div className="card card-animate" style={{borderRadius: "3%"}} onClick={(e)=>{
                                            setStep(4)
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/clinic_doctor.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">My Staff</h5>


                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "3%"}} onClick={()=>{setStep(5)}}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/clinic_contract.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">My Contracts</h5>

                                            </div>
                                        </div>

                                    </div>

                                    <div className={"col"}>

                                        <div className="card card-animate" style={{borderRadius: "3%"}} onClick={()=>{setStep(6)}}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/partner.png`} alt=""
                                                         className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Partners</h5>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </>
                        )}

                        {step==2 &&(
                            <SearchClinic />
                        )}
                        {step==3 && (
                            <>
                                {rows.map((row, rowIndex) => (
                                    <div className="row" key={rowIndex}>
                                        {row}
                                    </div>
                                ))}
                            </>
                        )}

                        {step === 4 && (
                            <Staff />
                        )}

                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <Canvas></Canvas>
        </>
    )
}

export default ClinicLauncher;