import React from 'react'

const Loading: React.FC = () => {

    return (
        <section>

            Loading...

        </section>
    )
}

export default Loading

