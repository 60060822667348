import React, {useEffect, useReducer, useState} from "react";
import localforage from "localforage";
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import {useAppName} from "../../utils/utils";
import {Link} from "react-router-dom";

const VideoChat: React.FC = () => {

    const initialState = {
        clinicName: "",
        appName: useAppName(),
        fname: "",
        pageTitle: "Telehealth App",
        step: 0
    }



    const reducer = (state: any, action: any) =>{

        switch (action.type){

            case "SET_CLINIC_NAME":
                return {...state, clinicName: action.payload}

            case "SET_FIRST_NAME":
                return {...state, fname: action.payload}

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            default:
                return state

        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(()=>{
        document.title = "Uzima - Telehealth Tools"

        localforage.getItem("clinic_name").then((clini)=>{
            dispatch({type: "SET_CLINIC_NAME", payload: clini})
        })

        localforage.getItem("fname").then((fnm: any)=>{

            dispatch({type: "SET_FIRST_NAME", payload: fnm})

        })
    }, [])

    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        <div className="row mt-2">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0  ">{state.clinicName} - {state.pageTitle} &nbsp;
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href="javascript: void(0);">Dashboards</a></li>
                                            <li className="breadcrumb-item active">{state.pageTitle}</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col text-center"}>


                            </div>

                        </div>

                        <div className={"row mt-4"}>

                            <div className={"col text-center"}>

                                <h4>Telehealth Tools</h4>

                            </div>

                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col text-center"}>
                                <h6>An assortment of digital conferencing tools to enable remote communication and monitoring by users on this platform</h6>
                            </div>
                        </div>

                        {state.step===0 && (
                            <div className={"row mt-4"}>

                                <div className={"col"}>

                                    <Link to={"/institution/home"} className="card card-animate" style={{borderRadius: "0%"}} >
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/dashboard.png`} alt=""
                                                     className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Dashboard</h5>
                                        </div>
                                    </Link>

                                </div>

                                <div className={"col"}>

                                    <div  className="card card-animate" onClick={(e)=>{
                                        dispatch({type: "SET_SUB_STEP", payload: 0})
                                        dispatch({type: "SET_STEP", payload: 1})
                                    }} style={{borderRadius: "0%"}} >
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/create.png`} alt=""
                                                     className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">Create</h5>
                                        </div>
                                    </div>

                                </div>

                                <div className={"col"}>

                                    <div className="card card-animate" style={{borderRadius: "0%"}} >
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/list.png`} alt=""
                                                     className="img-fluid"/>
                                            </div>
                                            <h5 className="card-title mb-1">My Schedule</h5>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )}

                        {state.step ===1 && (
                            <>
                                {state.subStep === 0 && (

                                    <>
                                        {/*First step meeting create*/}

                                        <div className={"row mt-4"}>

                                            <div className={"col-md-2"}>

                                            </div>

                                            <div className={"col-md-8"}>

                                                <div className={"card"}>
                                                    <div className={"card-header"}>

                                                    </div>

                                                    <div className={"card-body"}>
                                                        <label></label>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className={"col-md-2"}>

                                            </div>
                                        </div>

                                    </>

                                )}
                            </>
                        )}


                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <canvas></canvas>
        </>
    )
}

export default VideoChat