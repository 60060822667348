import React, {useContext, Suspense} from 'react';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {HomeContext, HomeProvider} from "./institutions/dashboard_pages/HomeProvider";
import DMainTags from "./institutions/layouts/dashboard_main_tags";
import {DClinics} from "./institutions/layouts/dashboard_clinic_list";
import ClinicInfo from "./institutions/layouts/dashboard_clinic_details";
import FileManager from "./institutions/layouts/dashboard_file_manager";
import Chat from "./institutions/layouts/dashboard_chat";
import CollaborationList from "./institutions/layouts/dashboard_collaborations_list";
import Contracts from "./institutions/layouts/dashboard_contracts";
import Collaboration from "./institutions/layouts/dashboard_collaboration_summary";
import AddClinic from "./institutions/layouts/dashboard_clinic_add";
import ClinicProfessionals from "./institutions/layouts/dashboard_professionals";
import HomePage from "./institutions/dashboard_pages/uzima_home";
import PatientStart from "./institutions/dashboard_pages/patient_start";
import ClinicStart from "./institutions/dashboard_pages/institution_start";
import PartnerStart from "./institutions/dashboard_pages/partner_start";
import AdminStart from "./institutions/dashboard_pages/admin_start";
import LoginClinic from "./institutions/dashboard_pages/institution_login";
import LoginPartner from "./institutions/dashboard_pages/partner_login";
import LoginPatient from "./institutions/dashboard_pages/patient_login";
import CreateCollaboration from "./institutions/layouts/dashboard_create_collaboration";
import PatientRecords from "./institutions/layouts/dashboard_ehr";
import AllRecords from "./institutions/layouts/dashboard_ehr_list";
import ShareRecords from "./institutions/layouts/dashboard_ehr_share";
import SharedRecords from "./institutions/layouts/dashboard_ehr_shared";
import CalFlat from "./institutions/dashboard_pages/calendar";
import SearchServices from "./institutions/layouts/dashboard_service_search";
import FindCollaborators from "./institutions/layouts/dashboard_find_service";
import Applicants from "./institutions/layouts/dashboard_service_applicants";
import CreateMedicalRecord from "./institutions/layouts/dashboard_ehr_create";
import ClinicLauncher from "./institutions/dashboard_pages/clinic_start";
import CreateClinic from "./institutions/dashboard_pages/create_institution";
import PartnerHome from "./partners/dashboard_pages/partner_home";
import LoadPartners from "./institutions/layouts/dashboard_partners";
import RegisterInstitution from "./institutions/dashboard_pages/institution_register";
import ResourcesInit from "./institutions/dashboard_pages/Resources";
import ResearchPartners from "./institutions/dashboard_pages/Research";
import Billing from "./institutions/dashboard_pages/Billing";
import Messages from "./institutions/dashboard_pages/Messages";
import Help from "./institutions/dashboard_pages/Help";
import TaskBoard from "./institutions/dashboard_pages/TaskBoard";
import Profile from "./institutions/dashboard_pages/Proile";
import Backup from "./institutions/dashboard_pages/Backup";
import Loading from "./institutions/dashboard_pages/Loading";
import CollaborationLauncher from "./institutions/dashboard_pages/Collaborations";
import UzimaEquipment from "./institutions/dashboard_pages/Equipment";
import NewRegister from "./institutions/dashboard_pages/NewRegister";
import NewLogin from "./institutions/dashboard_pages/NewLogin";
import SearchEl from "./institutions/dashboard_pages/Search";
import Settings from "./institutions/dashboard_pages/Settings";
import SaaSApi from "./institutions/dashboard_pages/SaaSApi";
import AddStaff from "./institutions/dashboard_pages/AddStaff";
import SuspendStaff from "./institutions/dashboard_pages/SuspendStaff";
import Notifications from "./institutions/Notifications";
import VideoChat from "./institutions/dashboard_pages/Video";
import PatientDashboard from "./patients/Dashboard";
import PatientDashboardEHR from "./patients/PatientDashboardEHR";
import ServicesHome from "./institutions/dashboard_pages/Services/home";
import PartnershipRequests from "./institutions/dashboard_pages/Services/PartnershipRequests";
import ReceivedRequests from "./institutions/dashboard_pages/Services/ReceivedRequests";
import BillingHome from "./institutions/dashboard_pages/Billing/BillingHome";
import CommunicationHome from "./institutions/dashboard_pages/Communications/CommunicationHome";
import Accounting from "./institutions/dashboard_pages/Accounting/Accounting";
import PendingInvoices from "./institutions/dashboard_pages/Accounting/PendingInvoices";
import InvoiceDetails from "./institutions/dashboard_pages/Accounting/InvoiceDetails";
import PatientCenter from "./institutions/dashboard_pages/Patients/PatientCenter";
import PatientList from "./institutions/dashboard_pages/Patients/PatientList";
import PatientHistory from "./institutions/dashboard_pages/Patients/PatientHistory";

const App: React.FC = () => {
  const content = useContext(HomeContext);
  return (
      <>
        <Router>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path={"/institution/home"} element={<DMainTags />} />
                <Route path={"/search"} element={<SearchEl />} />
                <Route path={"/institution/settings"} element={<Settings />} />
                <Route path={"/institution/api"} element={<SaaSApi /> } />

                <Route path={"/"} element={<HomePage />} />
                <Route path={"/calendar"} element={<CalFlat />} />

                {/*Accounting*/}
                <Route path={"/institution/accounting"} element={<Accounting />} />
                <Route path={"/institution/accounting/pending-invoices"} element={<PendingInvoices />} />
                <Route path={"/institution/accounting/invoice"} element={<InvoiceDetails />} />

                {/*Institutions*/}
                <Route path={"/institution/newreg"} element={<NewRegister />} />
                <Route path={"/institution/newlog"} element={<NewLogin />} />
                <Route path={"/institution/register"} element={<RegisterInstitution />} />
                <Route path={"/institution/start"} element={<ClinicStart />} />
                <Route path={"/institution/create"} element={<CreateClinic />} />
                <Route path={"/institution/login"} element={<LoginClinic />} />
                <Route path={"/institution/clinics"} element={<DClinics />} />
                <Route path={"institution/clinics/launch"} element={<ClinicLauncher />} />
                <Route path={"/institution/clinic-details"} element={<ClinicInfo />} />
                <Route path={"/institution/file-manager"} element={<FileManager />} />
                <Route path={"/institution/chat"} element={<Chat />} />
                <Route path={"/institution/add-clinic"} element={<AddClinic />} />
                <Route path={"/institution/professionals"} element={<ClinicProfessionals />} />
                <Route path={"institution/add-staff"} element={<AddStaff />} />
                <Route path={"/institution/suspend-staff"} element={<SuspendStaff />} />


                {/*Collaborations*/}
                <Route path={"/institution/collaborations"} element={<CollaborationLauncher />} />
                <Route path={"/institution/contracts"} element={<Contracts />} />


                {/*misc*/}
                <Route path={"/institution/profile"} element={<Profile />} />
                <Route path={"/institution/create-backup"} element={<Backup />} />

                {/*Communication*/}
                <Route path={"/institution/communication"} element={<CommunicationHome />} />
                <Route path={"/institution/messaging"} element={<Messages />} />
                <Route path={"/institution/notifications"} element={<Notifications />} />
                <Route path={"/institution/video"} element={<VideoChat />} />
                <Route path={"/institution/support"} element={<Help />} />
                <Route path={"/institution/taskboard"} element={<TaskBoard />} />

                {/*Billing*/}
                <Route path={"/institution/billing"} element={<Billing />} />
                <Route path={"/institution/billing/home"} element={<BillingHome />} />


                {/*services*/}
                <Route path={"/institution/services"} element={<SearchServices />} />
                <Route path={"/institution/service-lander"} element={ <ServicesHome /> } />
                <Route path={"/institution/services/partnership-requests"} element={ <PartnershipRequests />} />
                <Route path={"/institution/services/received-requests"} element={ <ReceivedRequests />} />
                <Route path={"/institution/fnd-service"} element={<FindCollaborators />} />
                <Route path={"/institution/applicants"} element={<Applicants />} />


                {/*Facility Resources*/}
                <Route path={"/institution/resources"} element={< ResourcesInit />} />
                <Route path={"/institution/rnp"} element={<ResearchPartners />} />

                {/*clinic ehr*/}
                <Route path={"/institution/ehr"} element={<PatientRecords />} />
                <Route path={"/institution/ehr/new"} element={<CreateMedicalRecord />} />
                <Route path={"/institution/ehr/list"} element={<AllRecords />} />
                <Route path={"/institution/ehr/share"} element={<ShareRecords />} />
                <Route path={"/institution/ehr/shared"} element={<SharedRecords />} />
                <Route path={"/institutions/partners/:category/:subcat"} element={<LoadPartners />} />

                {/*patients*/}
                <Route path={"/institution/patients"} element={<PatientCenter />} />
                <Route path={"/institution/patient-list"} element={<PatientList />} />
                <Route path={"/institution/patient-history"} element={<PatientHistory />} />
                <Route path={"/patient/login"} element={<LoginPatient />} />
                <Route path={"/patient/start"} element={<PatientStart />} />
                <Route path={"/patient/dashboard"} element={<PatientDashboard />} />
                <Route path={"/patient/dashboard/ehr"} element={<PatientDashboardEHR />} />


                {/*partnerts*/}
                <Route path={"/partner/start"} element={<PartnerStart />} />
                <Route path={"/partner/login"} element={<LoginPartner />} />
                <Route path={"/partner/home"} element={<PartnerHome />} />


                {/*admins*/}
                <Route path={"/admin/start"} element={<AdminStart />} />

                {/*/equipment/*/}
                <Route path={"institution/collaborations/equipment"} element={<UzimaEquipment />} />


              </Routes>
            </Suspense>
        </Router>
      </>
  );

}

export default App;
