import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import PartneHeader from "../../partners/layouts/partner_header";
import PartnerMenu from "../../partners/layouts/partner_sidemenu";
import DashboardFooter from "./dashboard_footer";
import Canvas from "./dahboard_canvas";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import PartnerProcessor from "../dashboard_pages/PartnerProcessor";
import BreadCrumb from "./components/breadcrumbs";
import axios from "axios";
import Swal from "sweetalert2";
import localforage from "localforage";
import {useBaseUrl} from "../../utils/utils";

interface counties  {
    county: string,
    county_code: number
}

const LoadPartners: React.FC = () => {
    useEffect(()=>{
        document.title="Find Partners"
        getAllCounties(countyId)
    },[])

    const clinic_id = localforage.getItem("clinic_id")
    const staff_id = localforage.getItem("staff_id")
    const institution_id = localforage.getItem("institution_id")
    const base_url = useBaseUrl()

    const {category, subcat} = useParams()
    const [partnerId, setPartnerId] = useState<any>()
    const [countyId, setCountyId] = useState<any>("")
    const [counties, setCounties] = useState<any[]>([])
    const [townId, setTownId] = useState<any>("")
    const [town, setTown] = useState<any>()
    const [townReady, setTownReady] = useState<boolean>(true)
    const [towns, setTowns] = useState<any[]>([])
    const [county, setCounty] = useState<any>()
    const [clinicId, setClinicId] = useState<any>()


    const getAllTowns = async (town_id= townId) => {

        let town_url = base_url + "/facilities/get-towns";

        try{

            const response = await axios.post(town_url, {
                town_id: town_id,
                county_id: countyId,
                clinic_id: clinic_id,
                staff_id: staff_id,
                institution_id: institution_id
            });

            const {status, message, data} = response.data;

            if(status == "success"){
                if(Object.keys(data).length == 1){

                    setTown(data);

                    setTownReady(false)

                }else if(Object.keys(data).length > 1){

                    setTowns(data);

                    setTownReady(false);

                } else {

                    setTownReady(true)

                    await Swal.fire({
                        title: "Warning",
                        icon: "warning",
                        text: "The server returned zero(0) towns. Filter by towns will be unavailable"
                    })
                }
            }else if(status == "error"){

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                });
            }


        }catch (e) {

            await Swal.fire({
                icon: "warning",
                title: "Towns",
                text: "Unable to fetch towns. Filter by towns will be unavailable"
            })

            return

        }
    }

    const getAllCounties = async (county_id = countyId) => {

        let county_url = base_url + "/facilities/get-counties";

        try{

            const response = await axios.post(county_url, {
                county_id: countyId,
                clinic_id: clinic_id,
                staff_id: staff_id,
                institution_id: institution_id
            });

            const {status, message, data} = response.data;

            if(status == "success"){
                if(Object.keys(data).length == 1){

                    const response_data: counties[] = data

                    setCounty(response_data);

                    setTownReady(false)

                }else if(Object.keys(data).length > 1){

                    const response_data: counties[] = data

                    setCounties(response_data);

                    setTownReady(false);

                } else {
                    setTownReady(true)

                    await Swal.fire({
                        title: "Warning",
                        icon: "warning",
                        text: "The server returned zero(0) counties. Filter by location will be unavailable"
                    })
                }
            }else if(status == "error"){
                setTownReady(true)
                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: message
                });
            }


        }catch (e) {
            setTownReady(true)

            await Swal.fire({
                icon: "warning",
                title: "Towns",
                text: "Unable to fetch counties. Filter by towns will be unavailable"
            })

        }

    }



    function toCaps(str: any): any {
        const words = str.split(' ');
        const capitalizedWords = words.map((word:any) => {
            const firstLetter = word.charAt(0).toUpperCase();
            const restOfWord = word.slice(1);
            return `${firstLetter}${restOfWord}`;
        });
        return capitalizedWords.join(' ');
    }

    const title ="Title"
    const url = "https://google.com"


    return(
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>
                    <div className={"container-fluid"}>
                        <BreadCrumb heading={"Find Partners "} start={"Partners"} fini={toCaps(subcat)}></BreadCrumb>

                        <div className="card">
                            <div className="card-header border-0 rounded">
                                <div className="row g-2">
                                    <div className="col-xl-3">
                                        <div className="search-box">
                                            <input type="text" className="form-control search" placeholder="Type something" />
                                            <i className="ri-search-line search-icon"> </i>
                                        </div>
                                    </div>

                                    <div className={"col ms-auto"}>
                                        <div>
                                            <select className="form-control" >

                                                <option value="99999999">All</option>


                                            </select>
                                        </div>
                                    </div>
                                    <div className={"col ms-auto"}>
                                        <select className="form-control" value={countyId}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    const selectedCountyId = parseInt(e.currentTarget.value);
                                                    setCountyId(selectedCountyId);
                                                    getAllTowns(selectedCountyId)

                                                }}
                                        >
                                            <option >Select County</option>
                                            {
                                                Object.values(counties).map((item:any) => (
                                                    <option  value={item.county_code}>{item.county} County </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className={"col ms-auto"}>

                                        <select className="form-control" disabled={townReady} onChange={(e: React.ChangeEvent<HTMLSelectElement>)=>{
                                            const selectedTown = parseInt(e.currentTarget.value);
                                            setTownId(selectedTown)
                                        }}>
                                            <option >Town</option>
                                            {Object.keys(towns).length > 1 &&
                                            (Object.values(towns).map((item) => (
                                                <option value={item.id} > {item.town} Town</option>
                                            )))
                                            }
                                            { town === 1 &&
                                            <option value={town.town_id}> {town.town}</option>
                                            }
                                            {
                                                <option>Choose Town</option>
                                            }
                                        </select>
                                    </div>

                                    <div className="col-lg-auto">
                                        <div className="hstack gap-2">
                                            <button type="button" className="btn btn-success"><i className="ri-equalizer-fill me-1 align-bottom"> </i> More Filters </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <PartnerProcessor category={category} subcat={subcat} partnerId={partnerId} county={countyId} town={townId}></PartnerProcessor>

                    </div>
                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <Canvas></Canvas>
        </>
    )
}

export default LoadPartners;