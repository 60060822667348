import React from 'react'
import {Link} from "react-router-dom";


const PatientDashboardSideMenu: React.FC = () => {


    return (
        <>
            <div className="app-menu navbar-menu">

                <div className="navbar-brand-box">

                    <Link to={"/patient/dashboard"} className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="48" />
                    </span>
                        <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`} alt="" height="17" />
                    </span>
                    </Link>

                    <Link to={"/patient/dashboard"} className="logo logo-light">
                    <span className="logo-sm">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="22" />
                    </span>
                        <span className="logo-lg">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`} alt="" height="44" />
                    </span>
                    </Link>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                        <i className="ri-record-circle-line"> </i>
                    </button>
                </div>

                <div id="scrollbar">
                    <div className="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="menu-title"><span data-key="t-menu">Menu</span></li>
                            <li className="nav-item">
                                <Link className="nav-link menu-link" to={"/patient/dashboard"} >
                                    <i className="bx bxs-dashboard"> </i> <span data-key="t-dashboards">Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href={"#sidebarCommunication"} className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarCommunication" data-key="t-ecommerce">
                                    <i className="bx bx-phone "> </i> Communications
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarCommunication">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/notifications" className="nav-link"
                                                  data-key="t-products"> Notifications <span className="topbar-badge badge rounded-pill bg-success">0 </span> </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/messaging" className="nav-link"
                                                  data-key="t-products"> Messages <span className="topbar-badge badge rounded-pill bg-success">0 </span>  </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/video" className="nav-link"
                                                  data-key="t-product-Details"> E-Conferencing</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/document_exchange" className="nav-link"
                                                  data-key="t-orders"> Document Exchange </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
                                    <i className="bx bx-layer"> </i> <span data-key="t-apps">Uzima - Apps</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarApps">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/calendar"} className="nav-link" data-key="t-calendar"> Calendar </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/institution/chat" className="nav-link" data-key="t-chat"> Chat </Link>
                                        </li>

                                        <li className="nav-item">
                                            <a href="#sidebarTickets" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarTickets"
                                               data-key="t-supprt-tickets"> Support Tickets
                                            </a>
                                            <div className="collapse menu-dropdown" id="sidebarTickets">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <Link to="/patient/dashboard/ticket-list" className="nav-link"
                                                              data-key="t-list-view"> List View </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/patient/dashboard/open-ticket" className="nav-link"
                                                              data-key="t-ticket-details"> Ticket Details </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/file-manager" className="nav-link"> <span
                                                data-key="t-file-manager">File Manager</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/contracts" className="nav-link"> <span
                                                data-key="t-to-do">Contracts</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/api-key" className="nav-link">
                                                <span data-key="t-api-key">API </span>
                                                <span className="badge badge-pill bg-success" data-key="t-new">Core</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>






                            <li className="menu-title"> <i className="ri-more-fill"></i> <span data-key="t-components">Uzima -  System</span>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarUI" data-bs-toggle="collapse"
                                   role="button" aria-expanded="false" aria-controls="sidebarUI">
                                    <i className="bx bx-palette"> </i> <span data-key="t-base-ui">My EHR Records</span>
                                </a>
                                <div className="collapse menu-dropdown mega-dropdown-menu" id="sidebarUI">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="nav nav-sm flex-column">
                                                <li className="nav-item">
                                                    <Link to={"/patient/dashboard/ehr"} className="nav-link" data-key="t-alerts">Launch</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className={"nav-item"}>
                                <a className={"nav-link menu-link"} href={"#sidebarPartners"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={"false"} aria-controls={"sidebarPartners"}>
                                    <i className="bx bx-clinic"> </i>
                                    <span data-key={"t-advance-ui"}> Treatments</span>
                                </a>
                                <div className={"collapse menu-dropdown"} id={"sidebarPartners"}>
                                    <ul className={"nav nav-sm flex-column"}>
                                        <li className={"nav-item"}>
                                            <Link to={"/patient/dashboard/appointments"} className={"nav-link"}>My Schedules</Link>
                                        </li>

                                        <li className={"nav-item"}>
                                            <Link to={"/patient/dashboard/medication"} className={"nav-link"}>My Medications</Link>
                                        </li>

                                        <li className={"nav-item"}>
                                            <Link to={"/patient/dashboard/lab"} className={"nav-link"}>My Lab tests</Link>
                                        </li>

                                        <li className={"nav-item"}>
                                            <Link to={"/patient/dashboard/care"} className={"nav-link"}>My Care Plans</Link>
                                        </li>

                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link menu-link" href={"#sidebarAdvanceUI"} data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAdvanceUI">
                                    <i className={"bx bx-briefcase-alt"}> </i> <span data-key="t-advance-ui">Finance & Billing</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarAdvanceUI">
                                    <ul className="nav nav-sm flex-column">

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">

                                <a href="#sidebarProjects" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarProjects" data-key="t-projects">
                                    <i className="bx bx-server"> </i>
                                    Health Tracker
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarProjects">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/diary"} className="nav-link"> Health Diary</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/follow" className="nav-link"> Follow Ups</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/reminders" className="nav-link"> Reminders </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to={"/patient/dashboard/resources"}
                                      role="button" aria-controls="sidebarForms">
                                    <i className="bx bx-receipt"> </i> <span data-key="t-forms">Resources</span>
                                </Link>

                            </li>




                            <li className="menu-title"><i className="ri-more-fill"> </i> <span  data-key="t-pages">Account</span></li>



                            <li className="nav-item">
                                <a className="nav-link menu-link" href="#sidebarPages" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarPages">
                                    <i className="bx bx-file"> </i>
                                    <span data-key="t-pages">Help & Support</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarPages">
                                    <ul className="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/faqs" className="nav-link" data-key="t-starter"> FAQs</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/ticket-list" className="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarProfile" data-key="t-profile">
                                                Tickets
                                            </Link>
                                            <div className="collapse menu-dropdown" id="sidebarProfile">
                                                <ul className="nav nav-sm flex-column">
                                                    <li className="nav-item">
                                                        <Link to="/patient/dashboard/create-ticket" className="nav-link"
                                                              data-key="t-simple-page"> Create Ticket </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/patient/dashboard/ticket-list" className="nav-link"
                                                              data-key="t-settings"> My Tickets </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/notices"} className="nav-link" data-key="t-team"> Notices</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/timelines"} className="nav-link" data-key="t-timeline"> Timeline </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="#" className="nav-link" data-key="t-maintenance"> Maintenance </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/help"} className="nav-link" data-key="t-coming-soon"> Account Help </Link>
                                        </li>
                                        <li className="nav-item">
                                            <a href={"/patient/dashboard/documentation"} className="nav-link" data-key="t-sitemap"> Documentation </a>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/billing-help"} className="nav-link" data-key="t-search-results"> Billing
                                                help </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/privacy" className="nav-link"><span data-key="t-privacy-policy">Privacy Policy</span>
                                                <span className="badge badge-pill bg-success"
                                                      data-key="t-new">New</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/patient/dashboard/terms" className="nav-link"><span data-key="t-term-conditions">Term & Conditions</span>
                                                <span className="badge badge-pill bg-success"
                                                      data-key="t-new">New</span></Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className={"nav-item"}>
                                <a className={"nav-link menu-link"} href={"#sidebarBackups"} data-bs-toggle={"collapse"} role={"button"} aria-expanded={"false"} aria-controls={"sidebarBackups"}>
                                    <i className="bx bx-bot"></i>
                                    <span data-key={"t-backups"}>Easy Backup</span>
                                </a>
                                <div className="collapse menu-dropdown" id="sidebarBackups">
                                    <ul className="nav nav-sm flex-column">

                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/create-backup"} className="nav-link" data-key="t-backups">Create Backup</Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/restore-backup"} className="nav-link" data-key="t-backups">Restore Backup</Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/request-backup"} className="nav-link" data-key="t-backups">My Backups</Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to={"/patient/dashboard/request-backup"} className="nav-link" data-key="t-backups">Request Access</Link>
                                        </li>

                                    </ul>

                                </div>

                            </li>

                        </ul>
                    </div>
                </div>

                <div className="sidebar-background"> </div>
            </div>

        </>
    )

}

export default PatientDashboardSideMenu